// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' &&
  module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = "lbServices";
}

(function(window, angular, undefined) {
  'use strict';

  var urlBase = "https://api.staging.smartbrokr.com";
  var authHeader = 'authorization';

  function getHost(url) {
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }
  // need to use the urlBase as the base to handle multiple
  // loopback servers behind a proxy/gateway where the host
  // would be the same.
  var urlBaseHost = getHost(urlBase) ? urlBase : location.host;

/**
 * @ngdoc overview
 * @name lbServices
 * @module
 * @description
 *
 * The `lbServices` module provides services for interacting with
 * the models exposed by the LoopBack server via the REST API.
 *
 */
  var module = angular.module("lbServices", ['ngResource']);

/**
 * @ngdoc object
 * @name lbServices.Province
 * @header lbServices.Province
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Province` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Province",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Provinces/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Province.regions.findById() instead.
            "prototype$__findById__regions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/regions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Province.regions.destroyById() instead.
            "prototype$__destroyById__regions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/regions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Province.regions.updateById() instead.
            "prototype$__updateById__regions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/regions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Province.cities.findById() instead.
            "prototype$__findById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/:fk",
              method: "GET",
            },

            // INTERNAL. Use Province.cities.destroyById() instead.
            "prototype$__destroyById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Province.cities.updateById() instead.
            "prototype$__updateById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Province.cities.link() instead.
            "prototype$__link__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Province.cities.unlink() instead.
            "prototype$__unlink__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Province.cities.exists() instead.
            "prototype$__exists__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Province.country() instead.
            "prototype$__get__country": {
              url: urlBase + "/Provinces/:id/country",
              method: "GET",
            },

            // INTERNAL. Use Province.regions() instead.
            "prototype$__get__regions": {
              isArray: true,
              url: urlBase + "/Provinces/:id/regions",
              method: "GET",
            },

            // INTERNAL. Use Province.regions.create() instead.
            "prototype$__create__regions": {
              url: urlBase + "/Provinces/:id/regions",
              method: "POST",
            },

            // INTERNAL. Use Province.regions.destroyAll() instead.
            "prototype$__delete__regions": {
              url: urlBase + "/Provinces/:id/regions",
              method: "DELETE",
            },

            // INTERNAL. Use Province.regions.count() instead.
            "prototype$__count__regions": {
              url: urlBase + "/Provinces/:id/regions/count",
              method: "GET",
            },

            // INTERNAL. Use Province.cities() instead.
            "prototype$__get__cities": {
              isArray: true,
              url: urlBase + "/Provinces/:id/cities",
              method: "GET",
            },

            // INTERNAL. Use Province.cities.create() instead.
            "prototype$__create__cities": {
              url: urlBase + "/Provinces/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Province.cities.destroyAll() instead.
            "prototype$__delete__cities": {
              url: urlBase + "/Provinces/:id/cities",
              method: "DELETE",
            },

            // INTERNAL. Use Province.cities.count() instead.
            "prototype$__count__cities": {
              url: urlBase + "/Provinces/:id/cities/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#create
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Provinces",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#patchOrCreate
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Provinces",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#replaceOrCreate
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Provinces/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#upsertWithWhere
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Provinces/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#exists
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Provinces/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#findById
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Provinces/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#replaceById
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Provinces/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#find
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Provinces",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#findOne
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Provinces/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#updateAll
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Provinces/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#deleteById
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Provinces/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#count
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Provinces/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#prototype$patchAttributes
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Provinces/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#createChangeStream
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Provinces/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Province#createMany
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Provinces",
              method: "POST",
            },

            // INTERNAL. Use Region.province() instead.
            "::get::Region::province": {
              url: urlBase + "/Regions/:id/province",
              method: "GET",
            },

            // INTERNAL. Use Country.provinces.findById() instead.
            "::findById::Country::provinces": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Countries/:id/provinces/:fk",
              method: "GET",
            },

            // INTERNAL. Use Country.provinces.destroyById() instead.
            "::destroyById::Country::provinces": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Countries/:id/provinces/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Country.provinces.updateById() instead.
            "::updateById::Country::provinces": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Countries/:id/provinces/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Country.provinces() instead.
            "::get::Country::provinces": {
              isArray: true,
              url: urlBase + "/Countries/:id/provinces",
              method: "GET",
            },

            // INTERNAL. Use Country.provinces.create() instead.
            "::create::Country::provinces": {
              url: urlBase + "/Countries/:id/provinces",
              method: "POST",
            },

            // INTERNAL. Use Country.provinces.createMany() instead.
            "::createMany::Country::provinces": {
              isArray: true,
              url: urlBase + "/Countries/:id/provinces",
              method: "POST",
            },

            // INTERNAL. Use Country.provinces.destroyAll() instead.
            "::delete::Country::provinces": {
              url: urlBase + "/Countries/:id/provinces",
              method: "DELETE",
            },

            // INTERNAL. Use Country.provinces.count() instead.
            "::count::Country::provinces": {
              url: urlBase + "/Countries/:id/provinces/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Province#upsert
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Province#updateOrCreate
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Province#patchOrCreateWithWhere
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Province#update
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Province#destroyById
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Province#removeById
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Province#prototype$updateAttributes
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Province#modelName
        * @propertyOf lbServices.Province
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Province`.
        */
        R.modelName = "Province";

    /**
     * @ngdoc object
     * @name lbServices.Province.regions
     * @header lbServices.Province.regions
     * @object
     * @description
     *
     * The object `Province.regions` groups methods
     * manipulating `Region` instances related to `Province`.
     *
     * Call {@link lbServices.Province#regions Province.regions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Province#regions
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Queries regions of Province.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R.regions = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::get::Province::regions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.regions#count
             * @methodOf lbServices.Province.regions
             *
             * @description
             *
             * Counts regions of Province.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.regions.count = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::count::Province::regions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.regions#create
             * @methodOf lbServices.Province.regions
             *
             * @description
             *
             * Creates a new instance in regions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R.regions.create = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::create::Province::regions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.regions#createMany
             * @methodOf lbServices.Province.regions
             *
             * @description
             *
             * Creates a new instance in regions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R.regions.createMany = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::createMany::Province::regions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.regions#destroyAll
             * @methodOf lbServices.Province.regions
             *
             * @description
             *
             * Deletes all regions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.regions.destroyAll = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::delete::Province::regions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.regions#destroyById
             * @methodOf lbServices.Province.regions
             *
             * @description
             *
             * Delete a related item by id for regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for regions
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.regions.destroyById = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::destroyById::Province::regions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.regions#findById
             * @methodOf lbServices.Province.regions
             *
             * @description
             *
             * Find a related item by id for regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for regions
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R.regions.findById = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::findById::Province::regions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.regions#updateById
             * @methodOf lbServices.Province.regions
             *
             * @description
             *
             * Update a related item by id for regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `fk` – `{*}` - Foreign key for regions
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R.regions.updateById = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::updateById::Province::regions"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Province.cities
     * @header lbServices.Province.cities
     * @object
     * @description
     *
     * The object `Province.cities` groups methods
     * manipulating `City` instances related to `Province`.
     *
     * Call {@link lbServices.Province#cities Province.cities()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Province#cities
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Queries cities of Province.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::get::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#count
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Counts cities of Province.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.cities.count = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::count::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#create
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Creates a new instance in cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.create = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::create::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#createMany
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Creates a new instance in cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.createMany = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::createMany::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#destroyAll
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Deletes all cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.cities.destroyAll = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::delete::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#destroyById
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Delete a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.cities.destroyById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::destroyById::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#exists
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Check the existence of cities relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.exists = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::exists::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#findById
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Find a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.findById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::findById::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#link
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Add a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.link = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::link::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#unlink
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Remove the cities relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.cities.unlink = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::unlink::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province.cities#updateById
             * @methodOf lbServices.Province.cities
             *
             * @description
             *
             * Update a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.updateById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::updateById::Province::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Province#country
             * @methodOf lbServices.Province
             *
             * @description
             *
             * Fetches belongsTo relation country.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Province id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
        R.country = function() {
          var TargetResource = $injector.get("Country");
          var action = TargetResource["::get::Province::country"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Region
 * @header lbServices.Region
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Region` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Region",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Regions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Region.province() instead.
            "prototype$__get__province": {
              url: urlBase + "/Regions/:id/province",
              method: "GET",
            },

            // INTERNAL. Use Region.cities.findById() instead.
            "prototype$__findById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Regions/:id/cities/:fk",
              method: "GET",
            },

            // INTERNAL. Use Region.cities.destroyById() instead.
            "prototype$__destroyById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Regions/:id/cities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Region.cities.updateById() instead.
            "prototype$__updateById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Regions/:id/cities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Region.cities() instead.
            "prototype$__get__cities": {
              isArray: true,
              url: urlBase + "/Regions/:id/cities",
              method: "GET",
            },

            // INTERNAL. Use Region.cities.create() instead.
            "prototype$__create__cities": {
              url: urlBase + "/Regions/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Region.cities.destroyAll() instead.
            "prototype$__delete__cities": {
              url: urlBase + "/Regions/:id/cities",
              method: "DELETE",
            },

            // INTERNAL. Use Region.cities.count() instead.
            "prototype$__count__cities": {
              url: urlBase + "/Regions/:id/cities/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#create
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Regions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#patchOrCreate
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Regions",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#replaceOrCreate
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Regions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#upsertWithWhere
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Regions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#exists
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Regions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#findById
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Regions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#replaceById
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Regions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#find
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Regions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#findOne
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Regions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#updateAll
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Regions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#deleteById
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Regions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#count
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Regions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#prototype$patchAttributes
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Regions/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#createChangeStream
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Regions/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Region#createMany
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Regions",
              method: "POST",
            },

            // INTERNAL. Use Province.regions.findById() instead.
            "::findById::Province::regions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/regions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Province.regions.destroyById() instead.
            "::destroyById::Province::regions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/regions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Province.regions.updateById() instead.
            "::updateById::Province::regions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/regions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Province.regions() instead.
            "::get::Province::regions": {
              isArray: true,
              url: urlBase + "/Provinces/:id/regions",
              method: "GET",
            },

            // INTERNAL. Use Province.regions.create() instead.
            "::create::Province::regions": {
              url: urlBase + "/Provinces/:id/regions",
              method: "POST",
            },

            // INTERNAL. Use Province.regions.createMany() instead.
            "::createMany::Province::regions": {
              isArray: true,
              url: urlBase + "/Provinces/:id/regions",
              method: "POST",
            },

            // INTERNAL. Use Province.regions.destroyAll() instead.
            "::delete::Province::regions": {
              url: urlBase + "/Provinces/:id/regions",
              method: "DELETE",
            },

            // INTERNAL. Use Province.regions.count() instead.
            "::count::Province::regions": {
              url: urlBase + "/Provinces/:id/regions/count",
              method: "GET",
            },

            // INTERNAL. Use City.region() instead.
            "::get::City::region": {
              url: urlBase + "/cities/:id/region",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Region#upsert
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Region#updateOrCreate
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Region#patchOrCreateWithWhere
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Region#update
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Region#destroyById
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Region#removeById
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Region#prototype$updateAttributes
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Region#modelName
        * @propertyOf lbServices.Region
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Region`.
        */
        R.modelName = "Region";


            /**
             * @ngdoc method
             * @name lbServices.Region#province
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Fetches belongsTo relation province.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R.province = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::get::Region::province"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Region.cities
     * @header lbServices.Region.cities
     * @object
     * @description
     *
     * The object `Region.cities` groups methods
     * manipulating `City` instances related to `Region`.
     *
     * Call {@link lbServices.Region#cities Region.cities()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Region#cities
             * @methodOf lbServices.Region
             *
             * @description
             *
             * Queries cities of Region.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::get::Region::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Region.cities#count
             * @methodOf lbServices.Region.cities
             *
             * @description
             *
             * Counts cities of Region.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.cities.count = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::count::Region::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Region.cities#create
             * @methodOf lbServices.Region.cities
             *
             * @description
             *
             * Creates a new instance in cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.create = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::create::Region::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Region.cities#createMany
             * @methodOf lbServices.Region.cities
             *
             * @description
             *
             * Creates a new instance in cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.createMany = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::createMany::Region::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Region.cities#destroyAll
             * @methodOf lbServices.Region.cities
             *
             * @description
             *
             * Deletes all cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.cities.destroyAll = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::delete::Region::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Region.cities#destroyById
             * @methodOf lbServices.Region.cities
             *
             * @description
             *
             * Delete a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.cities.destroyById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::destroyById::Region::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Region.cities#findById
             * @methodOf lbServices.Region.cities
             *
             * @description
             *
             * Find a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.findById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::findById::Region::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Region.cities#updateById
             * @methodOf lbServices.Region.cities
             *
             * @description
             *
             * Update a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Region id
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.updateById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::updateById::Region::cities"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.City
 * @header lbServices.City
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `City` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "City",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/cities/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use City.region() instead.
            "prototype$__get__region": {
              url: urlBase + "/cities/:id/region",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#create
             * @methodOf lbServices.City
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/cities",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#patchOrCreate
             * @methodOf lbServices.City
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/cities",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#replaceOrCreate
             * @methodOf lbServices.City
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/cities/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#upsertWithWhere
             * @methodOf lbServices.City
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/cities/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#exists
             * @methodOf lbServices.City
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/cities/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#findById
             * @methodOf lbServices.City
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/cities/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#replaceById
             * @methodOf lbServices.City
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/cities/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#find
             * @methodOf lbServices.City
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/cities",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#findOne
             * @methodOf lbServices.City
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/cities/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#updateAll
             * @methodOf lbServices.City
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/cities/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#deleteById
             * @methodOf lbServices.City
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/cities/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#count
             * @methodOf lbServices.City
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/cities/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#prototype$patchAttributes
             * @methodOf lbServices.City
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - City id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/cities/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#createChangeStream
             * @methodOf lbServices.City
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/cities/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.City#createMany
             * @methodOf lbServices.City
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/cities",
              method: "POST",
            },

            // INTERNAL. Use Province.cities.findById() instead.
            "::findById::Province::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/:fk",
              method: "GET",
            },

            // INTERNAL. Use Province.cities.destroyById() instead.
            "::destroyById::Province::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Province.cities.updateById() instead.
            "::updateById::Province::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Province.cities.link() instead.
            "::link::Province::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Province.cities.unlink() instead.
            "::unlink::Province::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Province.cities.exists() instead.
            "::exists::Province::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Provinces/:id/cities/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Province.cities() instead.
            "::get::Province::cities": {
              isArray: true,
              url: urlBase + "/Provinces/:id/cities",
              method: "GET",
            },

            // INTERNAL. Use Province.cities.create() instead.
            "::create::Province::cities": {
              url: urlBase + "/Provinces/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Province.cities.createMany() instead.
            "::createMany::Province::cities": {
              isArray: true,
              url: urlBase + "/Provinces/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Province.cities.destroyAll() instead.
            "::delete::Province::cities": {
              url: urlBase + "/Provinces/:id/cities",
              method: "DELETE",
            },

            // INTERNAL. Use Province.cities.count() instead.
            "::count::Province::cities": {
              url: urlBase + "/Provinces/:id/cities/count",
              method: "GET",
            },

            // INTERNAL. Use Region.cities.findById() instead.
            "::findById::Region::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Regions/:id/cities/:fk",
              method: "GET",
            },

            // INTERNAL. Use Region.cities.destroyById() instead.
            "::destroyById::Region::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Regions/:id/cities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Region.cities.updateById() instead.
            "::updateById::Region::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Regions/:id/cities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Region.cities() instead.
            "::get::Region::cities": {
              isArray: true,
              url: urlBase + "/Regions/:id/cities",
              method: "GET",
            },

            // INTERNAL. Use Region.cities.create() instead.
            "::create::Region::cities": {
              url: urlBase + "/Regions/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Region.cities.createMany() instead.
            "::createMany::Region::cities": {
              isArray: true,
              url: urlBase + "/Regions/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Region.cities.destroyAll() instead.
            "::delete::Region::cities": {
              url: urlBase + "/Regions/:id/cities",
              method: "DELETE",
            },

            // INTERNAL. Use Region.cities.count() instead.
            "::count::Region::cities": {
              url: urlBase + "/Regions/:id/cities/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.cities.findById() instead.
            "::findById::Buyer::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.cities.destroyById() instead.
            "::destroyById::Buyer::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.cities.updateById() instead.
            "::updateById::Buyer::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.cities.link() instead.
            "::link::Buyer::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.cities.unlink() instead.
            "::unlink::Buyer::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.cities.exists() instead.
            "::exists::Buyer::cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.buyerLocations.findById() instead.
            "::findById::Buyer::buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerLocations.destroyById() instead.
            "::destroyById::Buyer::buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerLocations.updateById() instead.
            "::updateById::Buyer::buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.buyerLocations.link() instead.
            "::link::Buyer::buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.buyerLocations.unlink() instead.
            "::unlink::Buyer::buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerLocations.exists() instead.
            "::exists::Buyer::buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.cities() instead.
            "::get::Buyer::cities": {
              isArray: true,
              url: urlBase + "/Buyers/:id/cities",
              method: "GET",
            },

            // INTERNAL. Use Buyer.cities.create() instead.
            "::create::Buyer::cities": {
              url: urlBase + "/Buyers/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Buyer.cities.createMany() instead.
            "::createMany::Buyer::cities": {
              isArray: true,
              url: urlBase + "/Buyers/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Buyer.cities.destroyAll() instead.
            "::delete::Buyer::cities": {
              url: urlBase + "/Buyers/:id/cities",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.cities.count() instead.
            "::count::Buyer::cities": {
              url: urlBase + "/Buyers/:id/cities/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerLocations() instead.
            "::get::Buyer::buyerLocations": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerLocations",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerLocations.create() instead.
            "::create::Buyer::buyerLocations": {
              url: urlBase + "/Buyers/:id/buyerLocations",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerLocations.createMany() instead.
            "::createMany::Buyer::buyerLocations": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerLocations",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerLocations.destroyAll() instead.
            "::delete::Buyer::buyerLocations": {
              url: urlBase + "/Buyers/:id/buyerLocations",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerLocations.count() instead.
            "::count::Buyer::buyerLocations": {
              url: urlBase + "/Buyers/:id/buyerLocations/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.regionsCovered.findById() instead.
            "::findById::Broker::regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.regionsCovered.destroyById() instead.
            "::destroyById::Broker::regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.regionsCovered.updateById() instead.
            "::updateById::Broker::regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.regionsCovered.link() instead.
            "::link::Broker::regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.regionsCovered.unlink() instead.
            "::unlink::Broker::regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.regionsCovered.exists() instead.
            "::exists::Broker::regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.regionsCovered() instead.
            "::get::Broker::regionsCovered": {
              isArray: true,
              url: urlBase + "/Brokers/:id/regionsCovered",
              method: "GET",
            },

            // INTERNAL. Use Broker.regionsCovered.create() instead.
            "::create::Broker::regionsCovered": {
              url: urlBase + "/Brokers/:id/regionsCovered",
              method: "POST",
            },

            // INTERNAL. Use Broker.regionsCovered.createMany() instead.
            "::createMany::Broker::regionsCovered": {
              isArray: true,
              url: urlBase + "/Brokers/:id/regionsCovered",
              method: "POST",
            },

            // INTERNAL. Use Broker.regionsCovered.destroyAll() instead.
            "::delete::Broker::regionsCovered": {
              url: urlBase + "/Brokers/:id/regionsCovered",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.regionsCovered.count() instead.
            "::count::Broker::regionsCovered": {
              url: urlBase + "/Brokers/:id/regionsCovered/count",
              method: "GET",
            },

            // INTERNAL. Use BuyerCity.city() instead.
            "::get::BuyerCity::city": {
              url: urlBase + "/BuyerCities/:id/city",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.City#upsert
             * @methodOf lbServices.City
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.City#updateOrCreate
             * @methodOf lbServices.City
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.City#patchOrCreateWithWhere
             * @methodOf lbServices.City
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.City#update
             * @methodOf lbServices.City
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.City#destroyById
             * @methodOf lbServices.City
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.City#removeById
             * @methodOf lbServices.City
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.City#prototype$updateAttributes
             * @methodOf lbServices.City
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - City id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.City#modelName
        * @propertyOf lbServices.City
        * @description
        * The name of the model represented by this $resource,
        * i.e. `City`.
        */
        R.modelName = "City";


            /**
             * @ngdoc method
             * @name lbServices.City#region
             * @methodOf lbServices.City
             *
             * @description
             *
             * Fetches belongsTo relation region.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - City id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Region` object.)
             * </em>
             */
        R.region = function() {
          var TargetResource = $injector.get("Region");
          var action = TargetResource["::get::City::region"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Message
 * @header lbServices.Message
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Message` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Message",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Messages/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Message.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Messages/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Message.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Message.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Message.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Message.files.link() instead.
            "prototype$__link__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Message.files.unlink() instead.
            "prototype$__unlink__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Message.files.exists() instead.
            "prototype$__exists__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Message.sender() instead.
            "prototype$__get__sender": {
              url: urlBase + "/Messages/:id/sender",
              method: "GET",
            },

            // INTERNAL. Use Message.conversation() instead.
            "prototype$__get__conversation": {
              url: urlBase + "/Messages/:id/conversation",
              method: "GET",
            },

            // INTERNAL. Use Message.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Messages/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Message.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Messages/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Message.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Messages/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Message.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Messages/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#create
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Messages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#patchOrCreate
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Messages",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#replaceOrCreate
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Messages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#upsertWithWhere
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Messages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#exists
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Messages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#findById
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Messages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#replaceById
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Messages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#find
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Messages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#findOne
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Messages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#updateAll
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Messages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#deleteById
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Messages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#count
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Messages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#prototype$patchAttributes
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Messages/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#createChangeStream
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Messages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#uploadFile
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Uploads an image for smartbrokr
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "uploadFile": {
              url: urlBase + "/Messages/:id/uploadFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#downloadFile
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/Messages/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#downloadFiles
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/Messages/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Message#createMany
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Messages",
              method: "POST",
            },

            // INTERNAL. Use Conversation.messages.findById() instead.
            "::findById::Conversation::messages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/messages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Conversation.messages.destroyById() instead.
            "::destroyById::Conversation::messages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/messages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.messages.updateById() instead.
            "::updateById::Conversation::messages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/messages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.messages() instead.
            "::get::Conversation::messages": {
              isArray: true,
              url: urlBase + "/Conversations/:id/messages",
              method: "GET",
            },

            // INTERNAL. Use Conversation.messages.create() instead.
            "::create::Conversation::messages": {
              url: urlBase + "/Conversations/:id/messages",
              method: "POST",
            },

            // INTERNAL. Use Conversation.messages.createMany() instead.
            "::createMany::Conversation::messages": {
              isArray: true,
              url: urlBase + "/Conversations/:id/messages",
              method: "POST",
            },

            // INTERNAL. Use Conversation.messages.destroyAll() instead.
            "::delete::Conversation::messages": {
              url: urlBase + "/Conversations/:id/messages",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.messages.count() instead.
            "::count::Conversation::messages": {
              url: urlBase + "/Conversations/:id/messages/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Message#upsert
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Message#updateOrCreate
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Message#patchOrCreateWithWhere
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Message#update
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Message#destroyById
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Message#removeById
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Message#prototype$updateAttributes
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Message#modelName
        * @propertyOf lbServices.Message
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Message`.
        */
        R.modelName = "Message";


            /**
             * @ngdoc method
             * @name lbServices.Message#agency
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Message::agency"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Message.files
     * @header lbServices.Message.files
     * @object
     * @description
     *
     * The object `Message.files` groups methods
     * manipulating `SmartFile` instances related to `Message`.
     *
     * Call {@link lbServices.Message#files Message.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Message#files
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Queries files of Message.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#count
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Counts files of Message.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#create
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#createMany
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#destroyAll
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#destroyById
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#exists
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Check the existence of files relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.exists = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::exists::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#findById
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#link
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Add a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.link = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::link::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#unlink
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Remove the files relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.unlink = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::unlink::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message.files#updateById
             * @methodOf lbServices.Message.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Message::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message#sender
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Fetches belongsTo relation sender.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.sender = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Message::sender"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Message#conversation
             * @methodOf lbServices.Message
             *
             * @description
             *
             * Fetches belongsTo relation conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Message id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversation = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::get::Message::conversation"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Note
 * @header lbServices.Note
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Note` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Note",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Notes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Note#prototype$__get__about
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Fetches belongsTo relation about.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "prototype$__get__about": {
              url: urlBase + "/Notes/:id/about",
              method: "GET",
            },

            // INTERNAL. Use Note.createdBy() instead.
            "prototype$__get__createdBy": {
              url: urlBase + "/Notes/:id/createdBy",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#create
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Notes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#patchOrCreate
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Notes",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#replaceOrCreate
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Notes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#upsertWithWhere
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Notes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#exists
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Notes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#findById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Notes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#replaceById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Notes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#find
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Notes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#findOne
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Notes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#updateAll
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Notes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#deleteById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Notes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#count
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Notes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#prototype$patchAttributes
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Notes/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#createChangeStream
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Notes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#createMany
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Notes",
              method: "POST",
            },

            // INTERNAL. Use Supplier.notesAbout.findById() instead.
            "::findById::Supplier::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/notesAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.notesAbout.destroyById() instead.
            "::destroyById::Supplier::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/notesAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.notesAbout.updateById() instead.
            "::updateById::Supplier::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/notesAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.notesAbout() instead.
            "::get::Supplier::notesAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/notesAbout",
              method: "GET",
            },

            // INTERNAL. Use Supplier.notesAbout.create() instead.
            "::create::Supplier::notesAbout": {
              url: urlBase + "/Suppliers/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.notesAbout.createMany() instead.
            "::createMany::Supplier::notesAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.notesAbout.destroyAll() instead.
            "::delete::Supplier::notesAbout": {
              url: urlBase + "/Suppliers/:id/notesAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.notesAbout.count() instead.
            "::count::Supplier::notesAbout": {
              url: urlBase + "/Suppliers/:id/notesAbout/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notes.findById() instead.
            "::findById::SbUser::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notes/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notes.destroyById() instead.
            "::destroyById::SbUser::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notes.updateById() instead.
            "::updateById::SbUser::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.notesAbout.findById() instead.
            "::findById::SbUser::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notesAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notesAbout.destroyById() instead.
            "::destroyById::SbUser::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notesAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notesAbout.updateById() instead.
            "::updateById::SbUser::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notesAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.notes() instead.
            "::get::SbUser::notes": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notes",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notes.create() instead.
            "::create::SbUser::notes": {
              url: urlBase + "/SbUsers/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notes.createMany() instead.
            "::createMany::SbUser::notes": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notes.destroyAll() instead.
            "::delete::SbUser::notes": {
              url: urlBase + "/SbUsers/:id/notes",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notes.count() instead.
            "::count::SbUser::notes": {
              url: urlBase + "/SbUsers/:id/notes/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notesAbout() instead.
            "::get::SbUser::notesAbout": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notesAbout",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notesAbout.create() instead.
            "::create::SbUser::notesAbout": {
              url: urlBase + "/SbUsers/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notesAbout.createMany() instead.
            "::createMany::SbUser::notesAbout": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notesAbout.destroyAll() instead.
            "::delete::SbUser::notesAbout": {
              url: urlBase + "/SbUsers/:id/notesAbout",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notesAbout.count() instead.
            "::count::SbUser::notesAbout": {
              url: urlBase + "/SbUsers/:id/notesAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.notesAbout.findById() instead.
            "::findById::Listing::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notesAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.notesAbout.destroyById() instead.
            "::destroyById::Listing::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notesAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notesAbout.updateById() instead.
            "::updateById::Listing::notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notesAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.notesAbout() instead.
            "::get::Listing::notesAbout": {
              isArray: true,
              url: urlBase + "/Listings/:id/notesAbout",
              method: "GET",
            },

            // INTERNAL. Use Listing.notesAbout.create() instead.
            "::create::Listing::notesAbout": {
              url: urlBase + "/Listings/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use Listing.notesAbout.createMany() instead.
            "::createMany::Listing::notesAbout": {
              isArray: true,
              url: urlBase + "/Listings/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use Listing.notesAbout.destroyAll() instead.
            "::delete::Listing::notesAbout": {
              url: urlBase + "/Listings/:id/notesAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notesAbout.count() instead.
            "::count::Listing::notesAbout": {
              url: urlBase + "/Listings/:id/notesAbout/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Note#upsert
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Note#updateOrCreate
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Note#patchOrCreateWithWhere
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Note#update
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Note#destroyById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Note#removeById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Note#prototype$updateAttributes
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Note#modelName
        * @propertyOf lbServices.Note
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Note`.
        */
        R.modelName = "Note";

    /**
     * @ngdoc object
     * @name lbServices.Note.createdBy
     * @header lbServices.Note.createdBy
     * @object
     * @description
     *
     * The object `Note.createdBy` groups methods
     * manipulating `SbUser` instances related to `Note`.
     *
     * Call {@link lbServices.Note#createdBy Note.createdBy()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Note#createManydBy
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Fetches belongsTo relation createdBy.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.createManydBy = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Note::createManydBy"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Note#createdBy
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Fetches belongsTo relation createdBy.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.createdBy = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Note::createdBy"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Language
 * @header lbServices.Language
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Language` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Language",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Languages/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Language#create
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Languages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#patchOrCreate
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Languages",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#replaceOrCreate
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Languages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#upsertWithWhere
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Languages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#exists
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Languages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#findById
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Languages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#replaceById
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Languages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#find
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Languages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#findOne
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Languages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#updateAll
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Languages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#deleteById
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Languages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#count
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Languages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#prototype$patchAttributes
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Language id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Languages/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#createChangeStream
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Languages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Language#createMany
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Languages",
              method: "POST",
            },

            // INTERNAL. Use Characteristic.language() instead.
            "::get::Characteristic::language": {
              url: urlBase + "/Characteristics/:id/language",
              method: "GET",
            },

            // INTERNAL. Use SbUser.languages.findById() instead.
            "::findById::SbUser::languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.languages.destroyById() instead.
            "::destroyById::SbUser::languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.languages.updateById() instead.
            "::updateById::SbUser::languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.languages.link() instead.
            "::link::SbUser::languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.languages.unlink() instead.
            "::unlink::SbUser::languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.languages.exists() instead.
            "::exists::SbUser::languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.languages() instead.
            "::get::SbUser::languages": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/languages",
              method: "GET",
            },

            // INTERNAL. Use SbUser.languages.create() instead.
            "::create::SbUser::languages": {
              url: urlBase + "/SbUsers/:id/languages",
              method: "POST",
            },

            // INTERNAL. Use SbUser.languages.createMany() instead.
            "::createMany::SbUser::languages": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/languages",
              method: "POST",
            },

            // INTERNAL. Use SbUser.languages.destroyAll() instead.
            "::delete::SbUser::languages": {
              url: urlBase + "/SbUsers/:id/languages",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.languages.count() instead.
            "::count::SbUser::languages": {
              url: urlBase + "/SbUsers/:id/languages/count",
              method: "GET",
            },

            // INTERNAL. Use Account.mainLanguage() instead.
            "::get::Account::mainLanguage": {
              url: urlBase + "/Accounts/:id/mainLanguage",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Language#upsert
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Language#updateOrCreate
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Language#patchOrCreateWithWhere
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Language#update
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Language#destroyById
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Language#removeById
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Language#prototype$updateAttributes
             * @methodOf lbServices.Language
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Language id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Language#modelName
        * @propertyOf lbServices.Language
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Language`.
        */
        R.modelName = "Language";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Bookmark
 * @header lbServices.Bookmark
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Bookmark` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Bookmark",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Bookmarks/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Bookmark.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Bookmarks/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#create
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Bookmarks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#patchOrCreate
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Bookmarks",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#replaceOrCreate
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Bookmarks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#upsertWithWhere
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Bookmarks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#exists
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Bookmarks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#findById
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Bookmarks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#replaceById
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Bookmarks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#find
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Bookmarks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#findOne
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Bookmarks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#updateAll
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Bookmarks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#deleteById
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Bookmarks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#count
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Bookmarks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#prototype$patchAttributes
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Bookmark id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Bookmarks/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#createChangeStream
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Bookmarks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#createMany
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Bookmarks",
              method: "POST",
            },

            // INTERNAL. Use SbUser.bookmarks.findById() instead.
            "::findById::SbUser::bookmarks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/bookmarks/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.bookmarks.destroyById() instead.
            "::destroyById::SbUser::bookmarks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/bookmarks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.bookmarks.updateById() instead.
            "::updateById::SbUser::bookmarks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/bookmarks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.bookmarks() instead.
            "::get::SbUser::bookmarks": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/bookmarks",
              method: "GET",
            },

            // INTERNAL. Use SbUser.bookmarks.create() instead.
            "::create::SbUser::bookmarks": {
              url: urlBase + "/SbUsers/:id/bookmarks",
              method: "POST",
            },

            // INTERNAL. Use SbUser.bookmarks.createMany() instead.
            "::createMany::SbUser::bookmarks": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/bookmarks",
              method: "POST",
            },

            // INTERNAL. Use SbUser.bookmarks.destroyAll() instead.
            "::delete::SbUser::bookmarks": {
              url: urlBase + "/SbUsers/:id/bookmarks",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.bookmarks.count() instead.
            "::count::SbUser::bookmarks": {
              url: urlBase + "/SbUsers/:id/bookmarks/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Bookmark#upsert
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#updateOrCreate
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#patchOrCreateWithWhere
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#update
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#destroyById
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#removeById
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Bookmark#prototype$updateAttributes
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Bookmark id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Bookmark#modelName
        * @propertyOf lbServices.Bookmark
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Bookmark`.
        */
        R.modelName = "Bookmark";


            /**
             * @ngdoc method
             * @name lbServices.Bookmark#user
             * @methodOf lbServices.Bookmark
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Bookmark id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Bookmark::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Auditor
 * @header lbServices.Auditor
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Auditor` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Auditor",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Auditors/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Auditor.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Auditors/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Auditor.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Auditors/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#create
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Auditors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#patchOrCreate
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Auditors",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#replaceOrCreate
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Auditors/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#upsertWithWhere
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Auditors/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#exists
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Auditors/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#findById
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Auditors/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#replaceById
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Auditors/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#find
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Auditors",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#findOne
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Auditors/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#updateAll
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Auditors/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#deleteById
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Auditors/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#count
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Auditors/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#prototype$patchAttributes
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Auditor id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Auditors/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#createChangeStream
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Auditors/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#getListings
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Gets auditor's listings
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `agencyId` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of listings.
             */
            "getListings": {
              isArray: true,
              url: urlBase + "/Auditors/:id/listings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#getAuditorsAgencies
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Get all the auditors agencies and their legal names
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `agencyIds` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of agency names
             */
            "getAuditorsAgencies": {
              url: urlBase + "/Auditors/:id/getAuditorsAgencies",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#expireAuditor
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Expires the auditor
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns deleted object
             */
            "expireAuditor": {
              url: urlBase + "/Auditors/:id/expireAuditor",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Auditor#createMany
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Auditors",
              method: "POST",
            },

            // INTERNAL. Use Agency.auditors.findById() instead.
            "::findById::Agency::auditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/auditors/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.auditors.destroyById() instead.
            "::destroyById::Agency::auditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/auditors/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.auditors.updateById() instead.
            "::updateById::Agency::auditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/auditors/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.auditors() instead.
            "::get::Agency::auditors": {
              isArray: true,
              url: urlBase + "/Agencies/:id/auditors",
              method: "GET",
            },

            // INTERNAL. Use Agency.auditors.create() instead.
            "::create::Agency::auditors": {
              url: urlBase + "/Agencies/:id/auditors",
              method: "POST",
            },

            // INTERNAL. Use Agency.auditors.createMany() instead.
            "::createMany::Agency::auditors": {
              isArray: true,
              url: urlBase + "/Agencies/:id/auditors",
              method: "POST",
            },

            // INTERNAL. Use Agency.auditors.destroyAll() instead.
            "::delete::Agency::auditors": {
              url: urlBase + "/Agencies/:id/auditors",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.auditors.count() instead.
            "::count::Agency::auditors": {
              url: urlBase + "/Agencies/:id/auditors/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.auditorProfile.findById() instead.
            "::findById::SbUser::auditorProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/auditorProfile/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.auditorProfile.destroyById() instead.
            "::destroyById::SbUser::auditorProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/auditorProfile/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.auditorProfile.updateById() instead.
            "::updateById::SbUser::auditorProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/auditorProfile/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.auditorProfile() instead.
            "::get::SbUser::auditorProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/auditorProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.auditorProfile.create() instead.
            "::create::SbUser::auditorProfile": {
              url: urlBase + "/SbUsers/:id/auditorProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.auditorProfile.createMany() instead.
            "::createMany::SbUser::auditorProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/auditorProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.auditorProfile.destroyAll() instead.
            "::delete::SbUser::auditorProfile": {
              url: urlBase + "/SbUsers/:id/auditorProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.auditorProfile.count() instead.
            "::count::SbUser::auditorProfile": {
              url: urlBase + "/SbUsers/:id/auditorProfile/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Auditor#upsert
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Auditor#updateOrCreate
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Auditor#patchOrCreateWithWhere
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Auditor#update
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Auditor#destroyById
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Auditor#removeById
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Auditor#prototype$updateAttributes
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Auditor id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Auditor#modelName
        * @propertyOf lbServices.Auditor
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Auditor`.
        */
        R.modelName = "Auditor";


            /**
             * @ngdoc method
             * @name lbServices.Auditor#agency
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Auditor id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Auditor::agency"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Auditor#user
             * @methodOf lbServices.Auditor
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Auditor id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Auditor::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Notary
 * @header lbServices.Notary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Notary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Notary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Notaries/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Notary.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Notaries/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Notary.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Notaries/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Notary.listings.findById() instead.
            "prototype$__findById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Notary.listings.destroyById() instead.
            "prototype$__destroyById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Notary.listings.updateById() instead.
            "prototype$__updateById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Notary.listings.link() instead.
            "prototype$__link__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Notary.listings.unlink() instead.
            "prototype$__unlink__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Notary.listings.exists() instead.
            "prototype$__exists__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Notary.listings() instead.
            "prototype$__get__listings": {
              isArray: true,
              url: urlBase + "/Notaries/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Notary.listings.create() instead.
            "prototype$__create__listings": {
              url: urlBase + "/Notaries/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Notary.listings.destroyAll() instead.
            "prototype$__delete__listings": {
              url: urlBase + "/Notaries/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Notary.listings.count() instead.
            "prototype$__count__listings": {
              url: urlBase + "/Notaries/:id/listings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#create
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Notaries",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#patchOrCreate
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Notaries",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#replaceOrCreate
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Notaries/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#upsertWithWhere
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Notaries/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#exists
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Notaries/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#findById
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Notaries/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#replaceById
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Notaries/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#find
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Notaries",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#findOne
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Notaries/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#updateAll
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Notaries/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#deleteById
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Notaries/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#count
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Notaries/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#prototype$patchAttributes
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Notaries/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#createChangeStream
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Notaries/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#getListingDocuments
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Gets a listing's documents for notary
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `listingId` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * All documents that belong to that listing.
             */
            "getListingDocuments": {
              isArray: true,
              url: urlBase + "/Notaries/:id/listings/:listingId/documents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#expireNotary
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Expires the notary
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns deleted object
             */
            "expireNotary": {
              url: urlBase + "/Notaries/:id/expireNotary",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notary#createMany
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Notaries",
              method: "POST",
            },

            // INTERNAL. Use Agency.notaries.findById() instead.
            "::findById::Agency::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/notaries/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.notaries.destroyById() instead.
            "::destroyById::Agency::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/notaries/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.notaries.updateById() instead.
            "::updateById::Agency::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/notaries/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.notaries() instead.
            "::get::Agency::notaries": {
              isArray: true,
              url: urlBase + "/Agencies/:id/notaries",
              method: "GET",
            },

            // INTERNAL. Use Agency.notaries.create() instead.
            "::create::Agency::notaries": {
              url: urlBase + "/Agencies/:id/notaries",
              method: "POST",
            },

            // INTERNAL. Use Agency.notaries.createMany() instead.
            "::createMany::Agency::notaries": {
              isArray: true,
              url: urlBase + "/Agencies/:id/notaries",
              method: "POST",
            },

            // INTERNAL. Use Agency.notaries.destroyAll() instead.
            "::delete::Agency::notaries": {
              url: urlBase + "/Agencies/:id/notaries",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.notaries.count() instead.
            "::count::Agency::notaries": {
              url: urlBase + "/Agencies/:id/notaries/count",
              method: "GET",
            },

            // INTERNAL. Use ListingNotary.notary() instead.
            "::get::ListingNotary::notary": {
              url: urlBase + "/ListingNotaries/:id/notary",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notaryProfile.findById() instead.
            "::findById::SbUser::notaryProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notaryProfile/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notaryProfile.destroyById() instead.
            "::destroyById::SbUser::notaryProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notaryProfile/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notaryProfile.updateById() instead.
            "::updateById::SbUser::notaryProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notaryProfile/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.notaryProfile() instead.
            "::get::SbUser::notaryProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notaryProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notaryProfile.create() instead.
            "::create::SbUser::notaryProfile": {
              url: urlBase + "/SbUsers/:id/notaryProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notaryProfile.createMany() instead.
            "::createMany::SbUser::notaryProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notaryProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notaryProfile.destroyAll() instead.
            "::delete::SbUser::notaryProfile": {
              url: urlBase + "/SbUsers/:id/notaryProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notaryProfile.count() instead.
            "::count::SbUser::notaryProfile": {
              url: urlBase + "/SbUsers/:id/notaryProfile/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.notaries.findById() instead.
            "::findById::Listing::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.notaries.destroyById() instead.
            "::destroyById::Listing::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notaries.updateById() instead.
            "::updateById::Listing::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.notaries.link() instead.
            "::link::Listing::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.notaries.unlink() instead.
            "::unlink::Listing::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notaries.exists() instead.
            "::exists::Listing::notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.notaries() instead.
            "::get::Listing::notaries": {
              isArray: true,
              url: urlBase + "/Listings/:id/notaries",
              method: "GET",
            },

            // INTERNAL. Use Listing.notaries.create() instead.
            "::create::Listing::notaries": {
              url: urlBase + "/Listings/:id/notaries",
              method: "POST",
            },

            // INTERNAL. Use Listing.notaries.createMany() instead.
            "::createMany::Listing::notaries": {
              isArray: true,
              url: urlBase + "/Listings/:id/notaries",
              method: "POST",
            },

            // INTERNAL. Use Listing.notaries.destroyAll() instead.
            "::delete::Listing::notaries": {
              url: urlBase + "/Listings/:id/notaries",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notaries.count() instead.
            "::count::Listing::notaries": {
              url: urlBase + "/Listings/:id/notaries/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Notary#upsert
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Notary#updateOrCreate
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Notary#patchOrCreateWithWhere
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Notary#update
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Notary#destroyById
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notary#removeById
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notary#prototype$updateAttributes
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Notary#modelName
        * @propertyOf lbServices.Notary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Notary`.
        */
        R.modelName = "Notary";


            /**
             * @ngdoc method
             * @name lbServices.Notary#agency
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Notary::agency"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary#user
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Notary::user"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Notary.listings
     * @header lbServices.Notary.listings
     * @object
     * @description
     *
     * The object `Notary.listings` groups methods
     * manipulating `Listing` instances related to `Notary`.
     *
     * Call {@link lbServices.Notary#listings Notary.listings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Notary#listings
             * @methodOf lbServices.Notary
             *
             * @description
             *
             * Queries listings of Notary.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#count
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Counts listings of Notary.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listings.count = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::count::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#create
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.create = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::create::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#createMany
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.createMany = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::createMany::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#destroyAll
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Deletes all listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyAll = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::delete::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#destroyById
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Delete a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::destroyById::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#exists
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Check the existence of listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.exists = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::exists::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#findById
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Find a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.findById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::findById::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#link
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Add a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.link = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::link::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#unlink
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Remove the listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.unlink = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::unlink::Notary::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notary.listings#updateById
             * @methodOf lbServices.Notary.listings
             *
             * @description
             *
             * Update a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notary id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.updateById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::updateById::Notary::listings"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Seller
 * @header lbServices.Seller
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Seller` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Seller",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Sellers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Seller.tasksAbout.findById() instead.
            "prototype$__findById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.tasksAbout.destroyById() instead.
            "prototype$__destroyById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.tasksAbout.updateById() instead.
            "prototype$__updateById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.logsAbout.findById() instead.
            "prototype$__findById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.logsAbout.destroyById() instead.
            "prototype$__destroyById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.logsAbout.updateById() instead.
            "prototype$__updateById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.sellerBrokers.findById() instead.
            "prototype$__findById__sellerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/sellerBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.sellerBrokers.destroyById() instead.
            "prototype$__destroyById__sellerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/sellerBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.sellerBrokers.updateById() instead.
            "prototype$__updateById__sellerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/sellerBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Sellers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Seller.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.brokers.link() instead.
            "prototype$__link__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.brokers.unlink() instead.
            "prototype$__unlink__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.brokers.exists() instead.
            "prototype$__exists__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Seller.listings.findById() instead.
            "prototype$__findById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.listings.destroyById() instead.
            "prototype$__destroyById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.listings.updateById() instead.
            "prototype$__updateById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.listings.link() instead.
            "prototype$__link__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.listings.unlink() instead.
            "prototype$__unlink__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.listings.exists() instead.
            "prototype$__exists__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Seller.suppliers.findById() instead.
            "prototype$__findById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.suppliers.destroyById() instead.
            "prototype$__destroyById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.suppliers.updateById() instead.
            "prototype$__updateById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.suppliers.link() instead.
            "prototype$__link__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.suppliers.unlink() instead.
            "prototype$__unlink__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.suppliers.exists() instead.
            "prototype$__exists__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Seller.tasksAbout() instead.
            "prototype$__get__tasksAbout": {
              isArray: true,
              url: urlBase + "/Sellers/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use Seller.tasksAbout.create() instead.
            "prototype$__create__tasksAbout": {
              url: urlBase + "/Sellers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Seller.tasksAbout.destroyAll() instead.
            "prototype$__delete__tasksAbout": {
              url: urlBase + "/Sellers/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.tasksAbout.count() instead.
            "prototype$__count__tasksAbout": {
              url: urlBase + "/Sellers/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Seller.logsAbout() instead.
            "prototype$__get__logsAbout": {
              isArray: true,
              url: urlBase + "/Sellers/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use Seller.logsAbout.create() instead.
            "prototype$__create__logsAbout": {
              url: urlBase + "/Sellers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Seller.logsAbout.destroyAll() instead.
            "prototype$__delete__logsAbout": {
              url: urlBase + "/Sellers/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.logsAbout.count() instead.
            "prototype$__count__logsAbout": {
              url: urlBase + "/Sellers/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Seller.sellerBrokers() instead.
            "prototype$__get__sellerBrokers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/sellerBrokers",
              method: "GET",
            },

            // INTERNAL. Use Seller.sellerBrokers.create() instead.
            "prototype$__create__sellerBrokers": {
              url: urlBase + "/Sellers/:id/sellerBrokers",
              method: "POST",
            },

            // INTERNAL. Use Seller.sellerBrokers.destroyAll() instead.
            "prototype$__delete__sellerBrokers": {
              url: urlBase + "/Sellers/:id/sellerBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.sellerBrokers.count() instead.
            "prototype$__count__sellerBrokers": {
              url: urlBase + "/Sellers/:id/sellerBrokers/count",
              method: "GET",
            },

            // INTERNAL. Use Seller.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Seller.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/Sellers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Seller.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/Sellers/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/Sellers/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Seller.listings() instead.
            "prototype$__get__listings": {
              isArray: true,
              url: urlBase + "/Sellers/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Seller.listings.create() instead.
            "prototype$__create__listings": {
              url: urlBase + "/Sellers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Seller.listings.destroyAll() instead.
            "prototype$__delete__listings": {
              url: urlBase + "/Sellers/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.listings.count() instead.
            "prototype$__count__listings": {
              url: urlBase + "/Sellers/:id/listings/count",
              method: "GET",
            },

            // INTERNAL. Use Seller.suppliers() instead.
            "prototype$__get__suppliers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Seller.suppliers.create() instead.
            "prototype$__create__suppliers": {
              url: urlBase + "/Sellers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Seller.suppliers.destroyAll() instead.
            "prototype$__delete__suppliers": {
              url: urlBase + "/Sellers/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.suppliers.count() instead.
            "prototype$__count__suppliers": {
              url: urlBase + "/Sellers/:id/suppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#create
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Sellers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#patchOrCreate
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Sellers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#replaceOrCreate
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Sellers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#upsertWithWhere
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Sellers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#exists
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Sellers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#findById
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Sellers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#replaceById
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Sellers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#find
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Sellers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#findOne
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Sellers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#updateAll
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Sellers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#deleteById
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Sellers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#count
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Sellers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#prototype$patchAttributes
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Sellers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#createChangeStream
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Sellers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#sendReport
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Sends an email with a report about a listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `sellerId` – `{string=}` -
             *
             *  - `report` – `{object=}` -
             *
             *  - `brokerId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * True if operation ran with no errors
             */
            "sendReport": {
              url: urlBase + "/Sellers/sendReport",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#getPublicImage
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "getPublicImage": {
              url: urlBase + "/Sellers/:id/public/image",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#getVisits
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Gets the visits related to a model
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "getVisits": {
              isArray: true,
              url: urlBase + "/Sellers/:id/all-visits",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#getAppointments
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Gets the appointments related to a model
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "getAppointments": {
              isArray: true,
              url: urlBase + "/Sellers/:id/appointments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#sentEmails
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Gets the emails sent by the buyer
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "sentEmails": {
              isArray: true,
              url: urlBase + "/Sellers/:id/sent-emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Seller#createMany
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Sellers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.sellers.findById() instead.
            "::findById::Supplier::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.sellers.destroyById() instead.
            "::destroyById::Supplier::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.sellers.updateById() instead.
            "::updateById::Supplier::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.sellers.link() instead.
            "::link::Supplier::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.sellers.unlink() instead.
            "::unlink::Supplier::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.sellers.exists() instead.
            "::exists::Supplier::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Supplier.sellers() instead.
            "::get::Supplier::sellers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/sellers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.sellers.create() instead.
            "::create::Supplier::sellers": {
              url: urlBase + "/Suppliers/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.sellers.createMany() instead.
            "::createMany::Supplier::sellers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.sellers.destroyAll() instead.
            "::delete::Supplier::sellers": {
              url: urlBase + "/Suppliers/:id/sellers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.sellers.count() instead.
            "::count::Supplier::sellers": {
              url: urlBase + "/Suppliers/:id/sellers/count",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.seller() instead.
            "::get::SellerBroker::seller": {
              url: urlBase + "/SellerBrokers/:id/seller",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.seller() instead.
            "::get::ListingSeller::seller": {
              url: urlBase + "/ListingSellers/:id/seller",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sellerProfile() instead.
            "::get::SbUser::sellerProfile": {
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sellerProfile.create() instead.
            "::create::SbUser::sellerProfile": {
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sellerProfile.createMany() instead.
            "::createMany::SbUser::sellerProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sellerProfile.update() instead.
            "::update::SbUser::sellerProfile": {
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sellerProfile.destroy() instead.
            "::destroy::SbUser::sellerProfile": {
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sellers.findById() instead.
            "::findById::Broker::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sellers.destroyById() instead.
            "::destroyById::Broker::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sellers.updateById() instead.
            "::updateById::Broker::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sellers.link() instead.
            "::link::Broker::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sellers.unlink() instead.
            "::unlink::Broker::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sellers.exists() instead.
            "::exists::Broker::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.sellers() instead.
            "::get::Broker::sellers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sellers",
              method: "GET",
            },

            // INTERNAL. Use Broker.sellers.create() instead.
            "::create::Broker::sellers": {
              url: urlBase + "/Brokers/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Broker.sellers.createMany() instead.
            "::createMany::Broker::sellers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Broker.sellers.destroyAll() instead.
            "::delete::Broker::sellers": {
              url: urlBase + "/Brokers/:id/sellers",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sellers.count() instead.
            "::count::Broker::sellers": {
              url: urlBase + "/Brokers/:id/sellers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.sellers.findById() instead.
            "::findById::Listing::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.sellers.destroyById() instead.
            "::destroyById::Listing::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sellers.updateById() instead.
            "::updateById::Listing::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sellers.link() instead.
            "::link::Listing::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sellers.unlink() instead.
            "::unlink::Listing::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sellers.exists() instead.
            "::exists::Listing::sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.sellers() instead.
            "::get::Listing::sellers": {
              isArray: true,
              url: urlBase + "/Listings/:id/sellers",
              method: "GET",
            },

            // INTERNAL. Use Listing.sellers.create() instead.
            "::create::Listing::sellers": {
              url: urlBase + "/Listings/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Listing.sellers.createMany() instead.
            "::createMany::Listing::sellers": {
              isArray: true,
              url: urlBase + "/Listings/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Listing.sellers.destroyAll() instead.
            "::delete::Listing::sellers": {
              url: urlBase + "/Listings/:id/sellers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sellers.count() instead.
            "::count::Listing::sellers": {
              url: urlBase + "/Listings/:id/sellers/count",
              method: "GET",
            },

            // INTERNAL. Use SellerSupplier.seller() instead.
            "::get::SellerSupplier::seller": {
              url: urlBase + "/SellerSuppliers/:id/seller",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Seller#upsert
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Seller#updateOrCreate
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Seller#patchOrCreateWithWhere
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Seller#update
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Seller#destroyById
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Seller#removeById
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Seller#prototype$updateAttributes
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Seller#modelName
        * @propertyOf lbServices.Seller
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Seller`.
        */
        R.modelName = "Seller";

    /**
     * @ngdoc object
     * @name lbServices.Seller.tasksAbout
     * @header lbServices.Seller.tasksAbout
     * @object
     * @description
     *
     * The object `Seller.tasksAbout` groups methods
     * manipulating `Task` instances related to `Seller`.
     *
     * Call {@link lbServices.Seller#tasksAbout Seller.tasksAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Seller#tasksAbout
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Queries tasksAbout of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Seller::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.tasksAbout#count
             * @methodOf lbServices.Seller.tasksAbout
             *
             * @description
             *
             * Counts tasksAbout of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasksAbout.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::Seller::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.tasksAbout#create
             * @methodOf lbServices.Seller.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::Seller::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.tasksAbout#createMany
             * @methodOf lbServices.Seller.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::Seller::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.tasksAbout#destroyAll
             * @methodOf lbServices.Seller.tasksAbout
             *
             * @description
             *
             * Deletes all tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::Seller::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.tasksAbout#destroyById
             * @methodOf lbServices.Seller.tasksAbout
             *
             * @description
             *
             * Delete a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::Seller::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.tasksAbout#findById
             * @methodOf lbServices.Seller.tasksAbout
             *
             * @description
             *
             * Find a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::Seller::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.tasksAbout#updateById
             * @methodOf lbServices.Seller.tasksAbout
             *
             * @description
             *
             * Update a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::Seller::tasksAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Seller.logsAbout
     * @header lbServices.Seller.logsAbout
     * @object
     * @description
     *
     * The object `Seller.logsAbout` groups methods
     * manipulating `Log` instances related to `Seller`.
     *
     * Call {@link lbServices.Seller#logsAbout Seller.logsAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Seller#logsAbout
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Queries logsAbout of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::get::Seller::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.logsAbout#count
             * @methodOf lbServices.Seller.logsAbout
             *
             * @description
             *
             * Counts logsAbout of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.logsAbout.count = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::count::Seller::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.logsAbout#create
             * @methodOf lbServices.Seller.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.create = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::create::Seller::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.logsAbout#createMany
             * @methodOf lbServices.Seller.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.createMany = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::createMany::Seller::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.logsAbout#destroyAll
             * @methodOf lbServices.Seller.logsAbout
             *
             * @description
             *
             * Deletes all logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyAll = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::delete::Seller::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.logsAbout#destroyById
             * @methodOf lbServices.Seller.logsAbout
             *
             * @description
             *
             * Delete a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::destroyById::Seller::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.logsAbout#findById
             * @methodOf lbServices.Seller.logsAbout
             *
             * @description
             *
             * Find a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.findById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::findById::Seller::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.logsAbout#updateById
             * @methodOf lbServices.Seller.logsAbout
             *
             * @description
             *
             * Update a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.updateById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::updateById::Seller::logsAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Seller.sellerBrokers
     * @header lbServices.Seller.sellerBrokers
     * @object
     * @description
     *
     * The object `Seller.sellerBrokers` groups methods
     * manipulating `SellerBroker` instances related to `Seller`.
     *
     * Call {@link lbServices.Seller#sellerBrokers Seller.sellerBrokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Seller#sellerBrokers
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Queries sellerBrokers of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R.sellerBrokers = function() {
          var TargetResource = $injector.get("SellerBroker");
          var action = TargetResource["::get::Seller::sellerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.sellerBrokers#count
             * @methodOf lbServices.Seller.sellerBrokers
             *
             * @description
             *
             * Counts sellerBrokers of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sellerBrokers.count = function() {
          var TargetResource = $injector.get("SellerBroker");
          var action = TargetResource["::count::Seller::sellerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.sellerBrokers#create
             * @methodOf lbServices.Seller.sellerBrokers
             *
             * @description
             *
             * Creates a new instance in sellerBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R.sellerBrokers.create = function() {
          var TargetResource = $injector.get("SellerBroker");
          var action = TargetResource["::create::Seller::sellerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.sellerBrokers#createMany
             * @methodOf lbServices.Seller.sellerBrokers
             *
             * @description
             *
             * Creates a new instance in sellerBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R.sellerBrokers.createMany = function() {
          var TargetResource = $injector.get("SellerBroker");
          var action = TargetResource["::createMany::Seller::sellerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.sellerBrokers#destroyAll
             * @methodOf lbServices.Seller.sellerBrokers
             *
             * @description
             *
             * Deletes all sellerBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellerBrokers.destroyAll = function() {
          var TargetResource = $injector.get("SellerBroker");
          var action = TargetResource["::delete::Seller::sellerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.sellerBrokers#destroyById
             * @methodOf lbServices.Seller.sellerBrokers
             *
             * @description
             *
             * Delete a related item by id for sellerBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellerBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellerBrokers.destroyById = function() {
          var TargetResource = $injector.get("SellerBroker");
          var action = TargetResource["::destroyById::Seller::sellerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.sellerBrokers#findById
             * @methodOf lbServices.Seller.sellerBrokers
             *
             * @description
             *
             * Find a related item by id for sellerBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellerBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R.sellerBrokers.findById = function() {
          var TargetResource = $injector.get("SellerBroker");
          var action = TargetResource["::findById::Seller::sellerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.sellerBrokers#updateById
             * @methodOf lbServices.Seller.sellerBrokers
             *
             * @description
             *
             * Update a related item by id for sellerBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for sellerBrokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R.sellerBrokers.updateById = function() {
          var TargetResource = $injector.get("SellerBroker");
          var action = TargetResource["::updateById::Seller::sellerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller#user
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Seller::user"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Seller.brokers
     * @header lbServices.Seller.brokers
     * @object
     * @description
     *
     * The object `Seller.brokers` groups methods
     * manipulating `Broker` instances related to `Seller`.
     *
     * Call {@link lbServices.Seller#brokers Seller.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Seller#brokers
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Queries brokers of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#count
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Counts brokers of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#create
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#createMany
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#destroyAll
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#destroyById
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#exists
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Check the existence of brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#findById
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#link
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Add a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#unlink
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Remove the brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::Seller::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.brokers#updateById
             * @methodOf lbServices.Seller.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::Seller::brokers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Seller.listings
     * @header lbServices.Seller.listings
     * @object
     * @description
     *
     * The object `Seller.listings` groups methods
     * manipulating `Listing` instances related to `Seller`.
     *
     * Call {@link lbServices.Seller#listings Seller.listings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Seller#listings
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Queries listings of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#count
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Counts listings of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listings.count = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::count::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#create
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.create = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::create::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#createMany
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.createMany = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::createMany::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#destroyAll
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Deletes all listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyAll = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::delete::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#destroyById
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Delete a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::destroyById::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#exists
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Check the existence of listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.exists = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::exists::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#findById
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Find a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.findById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::findById::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#link
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Add a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.link = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::link::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#unlink
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Remove the listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.unlink = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::unlink::Seller::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.listings#updateById
             * @methodOf lbServices.Seller.listings
             *
             * @description
             *
             * Update a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.updateById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::updateById::Seller::listings"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Seller.suppliers
     * @header lbServices.Seller.suppliers
     * @object
     * @description
     *
     * The object `Seller.suppliers` groups methods
     * manipulating `Supplier` instances related to `Seller`.
     *
     * Call {@link lbServices.Seller#suppliers Seller.suppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Seller#suppliers
             * @methodOf lbServices.Seller
             *
             * @description
             *
             * Queries suppliers of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#count
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Counts suppliers of Seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.suppliers.count = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::count::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#create
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.create = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::create::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#createMany
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.createMany = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::createMany::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#destroyAll
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Deletes all suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyAll = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::delete::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#destroyById
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Delete a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::destroyById::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#exists
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Check the existence of suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.exists = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::exists::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#findById
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Find a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.findById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::findById::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#link
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Add a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.link = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::link::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#unlink
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Remove the suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.unlink = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::unlink::Seller::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Seller.suppliers#updateById
             * @methodOf lbServices.Seller.suppliers
             *
             * @description
             *
             * Update a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Seller id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.updateById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::updateById::Seller::suppliers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Buyer
 * @header lbServices.Buyer
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Buyer` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Buyer",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Buyers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Buyer.cities.findById() instead.
            "prototype$__findById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.cities.destroyById() instead.
            "prototype$__destroyById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.cities.updateById() instead.
            "prototype$__updateById__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.cities.link() instead.
            "prototype$__link__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.cities.unlink() instead.
            "prototype$__unlink__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.cities.exists() instead.
            "prototype$__exists__cities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/cities/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.propertyTypes.findById() instead.
            "prototype$__findById__propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.propertyTypes.destroyById() instead.
            "prototype$__destroyById__propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.propertyTypes.updateById() instead.
            "prototype$__updateById__propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.propertyTypes.link() instead.
            "prototype$__link__propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.propertyTypes.unlink() instead.
            "prototype$__unlink__propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.propertyTypes.exists() instead.
            "prototype$__exists__propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.tasksAbout.findById() instead.
            "prototype$__findById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.tasksAbout.destroyById() instead.
            "prototype$__destroyById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.tasksAbout.updateById() instead.
            "prototype$__updateById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.logsAbout.findById() instead.
            "prototype$__findById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.logsAbout.destroyById() instead.
            "prototype$__destroyById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.logsAbout.updateById() instead.
            "prototype$__updateById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.buyerBrokers.findById() instead.
            "prototype$__findById__buyerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerBrokers.destroyById() instead.
            "prototype$__destroyById__buyerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerBrokers.updateById() instead.
            "prototype$__updateById__buyerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Buyers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Buyer.coBuyer() instead.
            "prototype$__get__coBuyer": {
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "GET",
            },

            // INTERNAL. Use Buyer.coBuyer.create() instead.
            "prototype$__create__coBuyer": {
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "POST",
            },

            // INTERNAL. Use Buyer.coBuyer.update() instead.
            "prototype$__update__coBuyer": {
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.coBuyer.destroy() instead.
            "prototype$__destroy__coBuyer": {
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.brokers.link() instead.
            "prototype$__link__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.brokers.unlink() instead.
            "prototype$__unlink__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.brokers.exists() instead.
            "prototype$__exists__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.listings.findById() instead.
            "prototype$__findById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.listings.destroyById() instead.
            "prototype$__destroyById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.listings.updateById() instead.
            "prototype$__updateById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.listings.link() instead.
            "prototype$__link__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.listings.unlink() instead.
            "prototype$__unlink__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.listings.exists() instead.
            "prototype$__exists__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.visits.findById() instead.
            "prototype$__findById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.visits.destroyById() instead.
            "prototype$__destroyById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.visits.updateById() instead.
            "prototype$__updateById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.visits.link() instead.
            "prototype$__link__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.visits.unlink() instead.
            "prototype$__unlink__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.visits.exists() instead.
            "prototype$__exists__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.buyerLocations.findById() instead.
            "prototype$__findById__buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerLocations.destroyById() instead.
            "prototype$__destroyById__buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerLocations.updateById() instead.
            "prototype$__updateById__buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.buyerLocations.link() instead.
            "prototype$__link__buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.buyerLocations.unlink() instead.
            "prototype$__unlink__buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerLocations.exists() instead.
            "prototype$__exists__buyerLocations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerLocations/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.buyerCities.findById() instead.
            "prototype$__findById__buyerCities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerCities/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerCities.destroyById() instead.
            "prototype$__destroyById__buyerCities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerCities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerCities.updateById() instead.
            "prototype$__updateById__buyerCities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerCities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.suppliers.findById() instead.
            "prototype$__findById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.suppliers.destroyById() instead.
            "prototype$__destroyById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.suppliers.updateById() instead.
            "prototype$__updateById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.suppliers.link() instead.
            "prototype$__link__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.suppliers.unlink() instead.
            "prototype$__unlink__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.suppliers.exists() instead.
            "prototype$__exists__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.cities() instead.
            "prototype$__get__cities": {
              isArray: true,
              url: urlBase + "/Buyers/:id/cities",
              method: "GET",
            },

            // INTERNAL. Use Buyer.cities.create() instead.
            "prototype$__create__cities": {
              url: urlBase + "/Buyers/:id/cities",
              method: "POST",
            },

            // INTERNAL. Use Buyer.cities.destroyAll() instead.
            "prototype$__delete__cities": {
              url: urlBase + "/Buyers/:id/cities",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.cities.count() instead.
            "prototype$__count__cities": {
              url: urlBase + "/Buyers/:id/cities/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.propertyTypes() instead.
            "prototype$__get__propertyTypes": {
              isArray: true,
              url: urlBase + "/Buyers/:id/propertyTypes",
              method: "GET",
            },

            // INTERNAL. Use Buyer.propertyTypes.create() instead.
            "prototype$__create__propertyTypes": {
              url: urlBase + "/Buyers/:id/propertyTypes",
              method: "POST",
            },

            // INTERNAL. Use Buyer.propertyTypes.destroyAll() instead.
            "prototype$__delete__propertyTypes": {
              url: urlBase + "/Buyers/:id/propertyTypes",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.propertyTypes.count() instead.
            "prototype$__count__propertyTypes": {
              url: urlBase + "/Buyers/:id/propertyTypes/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.tasksAbout() instead.
            "prototype$__get__tasksAbout": {
              isArray: true,
              url: urlBase + "/Buyers/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use Buyer.tasksAbout.create() instead.
            "prototype$__create__tasksAbout": {
              url: urlBase + "/Buyers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Buyer.tasksAbout.destroyAll() instead.
            "prototype$__delete__tasksAbout": {
              url: urlBase + "/Buyers/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.tasksAbout.count() instead.
            "prototype$__count__tasksAbout": {
              url: urlBase + "/Buyers/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.logsAbout() instead.
            "prototype$__get__logsAbout": {
              isArray: true,
              url: urlBase + "/Buyers/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use Buyer.logsAbout.create() instead.
            "prototype$__create__logsAbout": {
              url: urlBase + "/Buyers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Buyer.logsAbout.destroyAll() instead.
            "prototype$__delete__logsAbout": {
              url: urlBase + "/Buyers/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.logsAbout.count() instead.
            "prototype$__count__logsAbout": {
              url: urlBase + "/Buyers/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerBrokers() instead.
            "prototype$__get__buyerBrokers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerBrokers",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerBrokers.create() instead.
            "prototype$__create__buyerBrokers": {
              url: urlBase + "/Buyers/:id/buyerBrokers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerBrokers.destroyAll() instead.
            "prototype$__delete__buyerBrokers": {
              url: urlBase + "/Buyers/:id/buyerBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerBrokers.count() instead.
            "prototype$__count__buyerBrokers": {
              url: urlBase + "/Buyers/:id/buyerBrokers/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Buyer.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/Buyers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/Buyers/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/Buyers/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.listings() instead.
            "prototype$__get__listings": {
              isArray: true,
              url: urlBase + "/Buyers/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Buyer.listings.create() instead.
            "prototype$__create__listings": {
              url: urlBase + "/Buyers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Buyer.listings.destroyAll() instead.
            "prototype$__delete__listings": {
              url: urlBase + "/Buyers/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.listings.count() instead.
            "prototype$__count__listings": {
              url: urlBase + "/Buyers/:id/listings/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.visits() instead.
            "prototype$__get__visits": {
              isArray: true,
              url: urlBase + "/Buyers/:id/visits",
              method: "GET",
            },

            // INTERNAL. Use Buyer.visits.create() instead.
            "prototype$__create__visits": {
              url: urlBase + "/Buyers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Buyer.visits.destroyAll() instead.
            "prototype$__delete__visits": {
              url: urlBase + "/Buyers/:id/visits",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.visits.count() instead.
            "prototype$__count__visits": {
              url: urlBase + "/Buyers/:id/visits/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerLocations() instead.
            "prototype$__get__buyerLocations": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerLocations",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerLocations.create() instead.
            "prototype$__create__buyerLocations": {
              url: urlBase + "/Buyers/:id/buyerLocations",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerLocations.destroyAll() instead.
            "prototype$__delete__buyerLocations": {
              url: urlBase + "/Buyers/:id/buyerLocations",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerLocations.count() instead.
            "prototype$__count__buyerLocations": {
              url: urlBase + "/Buyers/:id/buyerLocations/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerCities() instead.
            "prototype$__get__buyerCities": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerCities",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerCities.create() instead.
            "prototype$__create__buyerCities": {
              url: urlBase + "/Buyers/:id/buyerCities",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerCities.destroyAll() instead.
            "prototype$__delete__buyerCities": {
              url: urlBase + "/Buyers/:id/buyerCities",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerCities.count() instead.
            "prototype$__count__buyerCities": {
              url: urlBase + "/Buyers/:id/buyerCities/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.suppliers() instead.
            "prototype$__get__suppliers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Buyer.suppliers.create() instead.
            "prototype$__create__suppliers": {
              url: urlBase + "/Buyers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.suppliers.destroyAll() instead.
            "prototype$__delete__suppliers": {
              url: urlBase + "/Buyers/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.suppliers.count() instead.
            "prototype$__count__suppliers": {
              url: urlBase + "/Buyers/:id/suppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#create
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Buyers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#patchOrCreate
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Buyers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#replaceOrCreate
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Buyers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#upsertWithWhere
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Buyers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#exists
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Buyers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#findById
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Buyers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#replaceById
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Buyers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#find
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Buyers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#findOne
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Buyers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#updateAll
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Buyers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#deleteById
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Buyers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#count
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Buyers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#prototype$patchAttributes
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Buyers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#createChangeStream
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Buyers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#getPublicImage
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "getPublicImage": {
              url: urlBase + "/Buyers/:id/public/image",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#getVisits
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Gets the visits related to a model
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "getVisits": {
              isArray: true,
              url: urlBase + "/Buyers/:id/all-visits",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#getAppointments
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Gets the appointments related to a model
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "getAppointments": {
              isArray: true,
              url: urlBase + "/Buyers/:id/appointments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#sentEmails
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Gets the emails sent by the buyer
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "sentEmails": {
              isArray: true,
              url: urlBase + "/Buyers/:id/sent-emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Buyer#createMany
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Buyers",
              method: "POST",
            },

            // INTERNAL. Use Visit.coBuyers.findById() instead.
            "::findById::Visit::coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyers.destroyById() instead.
            "::destroyById::Visit::coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyers.updateById() instead.
            "::updateById::Visit::coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.coBuyers.link() instead.
            "::link::Visit::coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.coBuyers.unlink() instead.
            "::unlink::Visit::coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyers.exists() instead.
            "::exists::Visit::coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Visit.coBuyers() instead.
            "::get::Visit::coBuyers": {
              isArray: true,
              url: urlBase + "/Visits/:id/coBuyers",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyers.create() instead.
            "::create::Visit::coBuyers": {
              url: urlBase + "/Visits/:id/coBuyers",
              method: "POST",
            },

            // INTERNAL. Use Visit.coBuyers.createMany() instead.
            "::createMany::Visit::coBuyers": {
              isArray: true,
              url: urlBase + "/Visits/:id/coBuyers",
              method: "POST",
            },

            // INTERNAL. Use Visit.coBuyers.destroyAll() instead.
            "::delete::Visit::coBuyers": {
              url: urlBase + "/Visits/:id/coBuyers",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyers.count() instead.
            "::count::Visit::coBuyers": {
              url: urlBase + "/Visits/:id/coBuyers/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.buyers.findById() instead.
            "::findById::Supplier::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.buyers.destroyById() instead.
            "::destroyById::Supplier::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.buyers.updateById() instead.
            "::updateById::Supplier::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.buyers.link() instead.
            "::link::Supplier::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.buyers.unlink() instead.
            "::unlink::Supplier::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.buyers.exists() instead.
            "::exists::Supplier::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Supplier.buyers() instead.
            "::get::Supplier::buyers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/buyers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.buyers.create() instead.
            "::create::Supplier::buyers": {
              url: urlBase + "/Suppliers/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.buyers.createMany() instead.
            "::createMany::Supplier::buyers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.buyers.destroyAll() instead.
            "::delete::Supplier::buyers": {
              url: urlBase + "/Suppliers/:id/buyers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.buyers.count() instead.
            "::count::Supplier::buyers": {
              url: urlBase + "/Suppliers/:id/buyers/count",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.buyer() instead.
            "::get::BuyerBroker::buyer": {
              url: urlBase + "/BuyerBrokers/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use Offer.buyer() instead.
            "::get::Offer::buyer": {
              url: urlBase + "/Offers/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use SbUser.buyerProfile() instead.
            "::get::SbUser::buyerProfile": {
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.buyerProfile.create() instead.
            "::create::SbUser::buyerProfile": {
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.buyerProfile.createMany() instead.
            "::createMany::SbUser::buyerProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.buyerProfile.update() instead.
            "::update::SbUser::buyerProfile": {
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.buyerProfile.destroy() instead.
            "::destroy::SbUser::buyerProfile": {
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.buyers.findById() instead.
            "::findById::Broker::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.buyers.destroyById() instead.
            "::destroyById::Broker::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.buyers.updateById() instead.
            "::updateById::Broker::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.buyers.link() instead.
            "::link::Broker::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.buyers.unlink() instead.
            "::unlink::Broker::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.buyers.exists() instead.
            "::exists::Broker::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.buyers() instead.
            "::get::Broker::buyers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/buyers",
              method: "GET",
            },

            // INTERNAL. Use Broker.buyers.create() instead.
            "::create::Broker::buyers": {
              url: urlBase + "/Brokers/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Broker.buyers.createMany() instead.
            "::createMany::Broker::buyers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Broker.buyers.destroyAll() instead.
            "::delete::Broker::buyers": {
              url: urlBase + "/Brokers/:id/buyers",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.buyers.count() instead.
            "::count::Broker::buyers": {
              url: urlBase + "/Brokers/:id/buyers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.buyers.findById() instead.
            "::findById::Listing::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.buyers.destroyById() instead.
            "::destroyById::Listing::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.buyers.updateById() instead.
            "::updateById::Listing::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.buyers.link() instead.
            "::link::Listing::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.buyers.unlink() instead.
            "::unlink::Listing::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.buyers.exists() instead.
            "::exists::Listing::buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.buyers() instead.
            "::get::Listing::buyers": {
              isArray: true,
              url: urlBase + "/Listings/:id/buyers",
              method: "GET",
            },

            // INTERNAL. Use Listing.buyers.create() instead.
            "::create::Listing::buyers": {
              url: urlBase + "/Listings/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Listing.buyers.createMany() instead.
            "::createMany::Listing::buyers": {
              isArray: true,
              url: urlBase + "/Listings/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Listing.buyers.destroyAll() instead.
            "::delete::Listing::buyers": {
              url: urlBase + "/Listings/:id/buyers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.buyers.count() instead.
            "::count::Listing::buyers": {
              url: urlBase + "/Listings/:id/buyers/count",
              method: "GET",
            },

            // INTERNAL. Use ListingBuyer.buyer() instead.
            "::get::ListingBuyer::buyer": {
              url: urlBase + "/ListingBuyers/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use VisitCoBuyer.buyer() instead.
            "::get::VisitCoBuyer::buyer": {
              url: urlBase + "/VisitCoBuyers/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use BuyerCity.buyer() instead.
            "::get::BuyerCity::buyer": {
              url: urlBase + "/BuyerCities/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use BuyerSupplier.buyer() instead.
            "::get::BuyerSupplier::buyer": {
              url: urlBase + "/BuyerSuppliers/:id/buyer",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Buyer#upsert
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Buyer#updateOrCreate
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Buyer#patchOrCreateWithWhere
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Buyer#update
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Buyer#destroyById
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Buyer#removeById
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Buyer#prototype$updateAttributes
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Buyer#modelName
        * @propertyOf lbServices.Buyer
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Buyer`.
        */
        R.modelName = "Buyer";

    /**
     * @ngdoc object
     * @name lbServices.Buyer.cities
     * @header lbServices.Buyer.cities
     * @object
     * @description
     *
     * The object `Buyer.cities` groups methods
     * manipulating `City` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#cities Buyer.cities()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#cities
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries cities of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::get::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#count
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Counts cities of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.cities.count = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::count::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#create
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Creates a new instance in cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.create = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::create::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#createMany
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Creates a new instance in cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.createMany = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::createMany::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#destroyAll
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Deletes all cities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.cities.destroyAll = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::delete::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#destroyById
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Delete a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.cities.destroyById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::destroyById::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#exists
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Check the existence of cities relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.exists = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::exists::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#findById
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Find a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.findById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::findById::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#link
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Add a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.link = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::link::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#unlink
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Remove the cities relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.cities.unlink = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::unlink::Buyer::cities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.cities#updateById
             * @methodOf lbServices.Buyer.cities
             *
             * @description
             *
             * Update a related item by id for cities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for cities
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.cities.updateById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::updateById::Buyer::cities"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.propertyTypes
     * @header lbServices.Buyer.propertyTypes
     * @object
     * @description
     *
     * The object `Buyer.propertyTypes` groups methods
     * manipulating `Code` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#propertyTypes Buyer.propertyTypes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#propertyTypes
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries propertyTypes of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.propertyTypes = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#count
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Counts propertyTypes of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.propertyTypes.count = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::count::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#create
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Creates a new instance in propertyTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.propertyTypes.create = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::create::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#createMany
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Creates a new instance in propertyTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.propertyTypes.createMany = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::createMany::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#destroyAll
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Deletes all propertyTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.propertyTypes.destroyAll = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::delete::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#destroyById
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Delete a related item by id for propertyTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for propertyTypes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.propertyTypes.destroyById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::destroyById::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#exists
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Check the existence of propertyTypes relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for propertyTypes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.propertyTypes.exists = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::exists::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#findById
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Find a related item by id for propertyTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for propertyTypes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.propertyTypes.findById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::findById::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#link
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Add a related item by id for propertyTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for propertyTypes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.propertyTypes.link = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::link::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#unlink
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Remove the propertyTypes relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for propertyTypes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.propertyTypes.unlink = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::unlink::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.propertyTypes#updateById
             * @methodOf lbServices.Buyer.propertyTypes
             *
             * @description
             *
             * Update a related item by id for propertyTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for propertyTypes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.propertyTypes.updateById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::updateById::Buyer::propertyTypes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.tasksAbout
     * @header lbServices.Buyer.tasksAbout
     * @object
     * @description
     *
     * The object `Buyer.tasksAbout` groups methods
     * manipulating `Task` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#tasksAbout Buyer.tasksAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#tasksAbout
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries tasksAbout of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Buyer::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.tasksAbout#count
             * @methodOf lbServices.Buyer.tasksAbout
             *
             * @description
             *
             * Counts tasksAbout of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasksAbout.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::Buyer::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.tasksAbout#create
             * @methodOf lbServices.Buyer.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::Buyer::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.tasksAbout#createMany
             * @methodOf lbServices.Buyer.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::Buyer::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.tasksAbout#destroyAll
             * @methodOf lbServices.Buyer.tasksAbout
             *
             * @description
             *
             * Deletes all tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::Buyer::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.tasksAbout#destroyById
             * @methodOf lbServices.Buyer.tasksAbout
             *
             * @description
             *
             * Delete a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::Buyer::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.tasksAbout#findById
             * @methodOf lbServices.Buyer.tasksAbout
             *
             * @description
             *
             * Find a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::Buyer::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.tasksAbout#updateById
             * @methodOf lbServices.Buyer.tasksAbout
             *
             * @description
             *
             * Update a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::Buyer::tasksAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.logsAbout
     * @header lbServices.Buyer.logsAbout
     * @object
     * @description
     *
     * The object `Buyer.logsAbout` groups methods
     * manipulating `Log` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#logsAbout Buyer.logsAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#logsAbout
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries logsAbout of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::get::Buyer::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.logsAbout#count
             * @methodOf lbServices.Buyer.logsAbout
             *
             * @description
             *
             * Counts logsAbout of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.logsAbout.count = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::count::Buyer::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.logsAbout#create
             * @methodOf lbServices.Buyer.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.create = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::create::Buyer::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.logsAbout#createMany
             * @methodOf lbServices.Buyer.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.createMany = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::createMany::Buyer::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.logsAbout#destroyAll
             * @methodOf lbServices.Buyer.logsAbout
             *
             * @description
             *
             * Deletes all logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyAll = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::delete::Buyer::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.logsAbout#destroyById
             * @methodOf lbServices.Buyer.logsAbout
             *
             * @description
             *
             * Delete a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::destroyById::Buyer::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.logsAbout#findById
             * @methodOf lbServices.Buyer.logsAbout
             *
             * @description
             *
             * Find a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.findById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::findById::Buyer::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.logsAbout#updateById
             * @methodOf lbServices.Buyer.logsAbout
             *
             * @description
             *
             * Update a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.updateById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::updateById::Buyer::logsAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.buyerBrokers
     * @header lbServices.Buyer.buyerBrokers
     * @object
     * @description
     *
     * The object `Buyer.buyerBrokers` groups methods
     * manipulating `BuyerBroker` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#buyerBrokers Buyer.buyerBrokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#buyerBrokers
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries buyerBrokers of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R.buyerBrokers = function() {
          var TargetResource = $injector.get("BuyerBroker");
          var action = TargetResource["::get::Buyer::buyerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerBrokers#count
             * @methodOf lbServices.Buyer.buyerBrokers
             *
             * @description
             *
             * Counts buyerBrokers of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.buyerBrokers.count = function() {
          var TargetResource = $injector.get("BuyerBroker");
          var action = TargetResource["::count::Buyer::buyerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerBrokers#create
             * @methodOf lbServices.Buyer.buyerBrokers
             *
             * @description
             *
             * Creates a new instance in buyerBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R.buyerBrokers.create = function() {
          var TargetResource = $injector.get("BuyerBroker");
          var action = TargetResource["::create::Buyer::buyerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerBrokers#createMany
             * @methodOf lbServices.Buyer.buyerBrokers
             *
             * @description
             *
             * Creates a new instance in buyerBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R.buyerBrokers.createMany = function() {
          var TargetResource = $injector.get("BuyerBroker");
          var action = TargetResource["::createMany::Buyer::buyerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerBrokers#destroyAll
             * @methodOf lbServices.Buyer.buyerBrokers
             *
             * @description
             *
             * Deletes all buyerBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyerBrokers.destroyAll = function() {
          var TargetResource = $injector.get("BuyerBroker");
          var action = TargetResource["::delete::Buyer::buyerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerBrokers#destroyById
             * @methodOf lbServices.Buyer.buyerBrokers
             *
             * @description
             *
             * Delete a related item by id for buyerBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyerBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyerBrokers.destroyById = function() {
          var TargetResource = $injector.get("BuyerBroker");
          var action = TargetResource["::destroyById::Buyer::buyerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerBrokers#findById
             * @methodOf lbServices.Buyer.buyerBrokers
             *
             * @description
             *
             * Find a related item by id for buyerBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyerBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R.buyerBrokers.findById = function() {
          var TargetResource = $injector.get("BuyerBroker");
          var action = TargetResource["::findById::Buyer::buyerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerBrokers#updateById
             * @methodOf lbServices.Buyer.buyerBrokers
             *
             * @description
             *
             * Update a related item by id for buyerBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for buyerBrokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R.buyerBrokers.updateById = function() {
          var TargetResource = $injector.get("BuyerBroker");
          var action = TargetResource["::updateById::Buyer::buyerBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer#user
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Buyer::user"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.coBuyer
     * @header lbServices.Buyer.coBuyer
     * @object
     * @description
     *
     * The object `Buyer.coBuyer` groups methods
     * manipulating `SbUser` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#coBuyer Buyer.coBuyer()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#coBuyer
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Fetches hasOne relation coBuyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.coBuyer = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Buyer::coBuyer"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.coBuyer#create
             * @methodOf lbServices.Buyer.coBuyer
             *
             * @description
             *
             * Creates a new instance in coBuyer of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.coBuyer.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Buyer::coBuyer"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.coBuyer#createMany
             * @methodOf lbServices.Buyer.coBuyer
             *
             * @description
             *
             * Creates a new instance in coBuyer of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.coBuyer.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Buyer::coBuyer"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.coBuyer#destroy
             * @methodOf lbServices.Buyer.coBuyer
             *
             * @description
             *
             * Deletes coBuyer of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.coBuyer.destroy = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroy::Buyer::coBuyer"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.coBuyer#update
             * @methodOf lbServices.Buyer.coBuyer
             *
             * @description
             *
             * Update coBuyer of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.coBuyer.update = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::update::Buyer::coBuyer"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.brokers
     * @header lbServices.Buyer.brokers
     * @object
     * @description
     *
     * The object `Buyer.brokers` groups methods
     * manipulating `Broker` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#brokers Buyer.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#brokers
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries brokers of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#count
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Counts brokers of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#create
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#createMany
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#destroyAll
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#destroyById
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#exists
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Check the existence of brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#findById
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#link
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Add a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#unlink
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Remove the brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::Buyer::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.brokers#updateById
             * @methodOf lbServices.Buyer.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::Buyer::brokers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.listings
     * @header lbServices.Buyer.listings
     * @object
     * @description
     *
     * The object `Buyer.listings` groups methods
     * manipulating `Listing` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#listings Buyer.listings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#listings
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries listings of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#count
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Counts listings of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listings.count = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::count::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#create
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.create = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::create::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#createMany
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.createMany = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::createMany::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#destroyAll
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Deletes all listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyAll = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::delete::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#destroyById
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Delete a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::destroyById::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#exists
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Check the existence of listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.exists = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::exists::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#findById
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Find a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.findById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::findById::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#link
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Add a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.link = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::link::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#unlink
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Remove the listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.unlink = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::unlink::Buyer::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.listings#updateById
             * @methodOf lbServices.Buyer.listings
             *
             * @description
             *
             * Update a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.updateById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::updateById::Buyer::listings"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.visits
     * @header lbServices.Buyer.visits
     * @object
     * @description
     *
     * The object `Buyer.visits` groups methods
     * manipulating `Visit` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#visits Buyer.visits()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#visits
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries visits of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::get::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#count
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Counts visits of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.visits.count = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::count::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#create
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Creates a new instance in visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.create = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::create::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#createMany
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Creates a new instance in visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.createMany = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::createMany::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#destroyAll
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Deletes all visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.destroyAll = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::delete::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#destroyById
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Delete a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.destroyById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::destroyById::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#exists
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Check the existence of visits relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.exists = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::exists::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#findById
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Find a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.findById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::findById::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#link
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Add a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.link = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::link::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#unlink
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Remove the visits relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.unlink = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::unlink::Buyer::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.visits#updateById
             * @methodOf lbServices.Buyer.visits
             *
             * @description
             *
             * Update a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.updateById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::updateById::Buyer::visits"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.buyerLocations
     * @header lbServices.Buyer.buyerLocations
     * @object
     * @description
     *
     * The object `Buyer.buyerLocations` groups methods
     * manipulating `City` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#buyerLocations Buyer.buyerLocations()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#buyerLocations
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries buyerLocations of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.buyerLocations = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::get::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#count
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Counts buyerLocations of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.buyerLocations.count = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::count::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#create
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Creates a new instance in buyerLocations of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.buyerLocations.create = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::create::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#createMany
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Creates a new instance in buyerLocations of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.buyerLocations.createMany = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::createMany::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#destroyAll
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Deletes all buyerLocations of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyerLocations.destroyAll = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::delete::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#destroyById
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Delete a related item by id for buyerLocations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyerLocations
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyerLocations.destroyById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::destroyById::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#exists
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Check the existence of buyerLocations relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyerLocations
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.buyerLocations.exists = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::exists::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#findById
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Find a related item by id for buyerLocations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyerLocations
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.buyerLocations.findById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::findById::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#link
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Add a related item by id for buyerLocations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for buyerLocations
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.buyerLocations.link = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::link::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#unlink
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Remove the buyerLocations relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyerLocations
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyerLocations.unlink = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::unlink::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerLocations#updateById
             * @methodOf lbServices.Buyer.buyerLocations
             *
             * @description
             *
             * Update a related item by id for buyerLocations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for buyerLocations
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.buyerLocations.updateById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::updateById::Buyer::buyerLocations"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.buyerCities
     * @header lbServices.Buyer.buyerCities
     * @object
     * @description
     *
     * The object `Buyer.buyerCities` groups methods
     * manipulating `BuyerCity` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#buyerCities Buyer.buyerCities()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#buyerCities
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries buyerCities of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R.buyerCities = function() {
          var TargetResource = $injector.get("BuyerCity");
          var action = TargetResource["::get::Buyer::buyerCities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerCities#count
             * @methodOf lbServices.Buyer.buyerCities
             *
             * @description
             *
             * Counts buyerCities of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.buyerCities.count = function() {
          var TargetResource = $injector.get("BuyerCity");
          var action = TargetResource["::count::Buyer::buyerCities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerCities#create
             * @methodOf lbServices.Buyer.buyerCities
             *
             * @description
             *
             * Creates a new instance in buyerCities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R.buyerCities.create = function() {
          var TargetResource = $injector.get("BuyerCity");
          var action = TargetResource["::create::Buyer::buyerCities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerCities#createMany
             * @methodOf lbServices.Buyer.buyerCities
             *
             * @description
             *
             * Creates a new instance in buyerCities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R.buyerCities.createMany = function() {
          var TargetResource = $injector.get("BuyerCity");
          var action = TargetResource["::createMany::Buyer::buyerCities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerCities#destroyAll
             * @methodOf lbServices.Buyer.buyerCities
             *
             * @description
             *
             * Deletes all buyerCities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyerCities.destroyAll = function() {
          var TargetResource = $injector.get("BuyerCity");
          var action = TargetResource["::delete::Buyer::buyerCities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerCities#destroyById
             * @methodOf lbServices.Buyer.buyerCities
             *
             * @description
             *
             * Delete a related item by id for buyerCities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyerCities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyerCities.destroyById = function() {
          var TargetResource = $injector.get("BuyerCity");
          var action = TargetResource["::destroyById::Buyer::buyerCities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerCities#findById
             * @methodOf lbServices.Buyer.buyerCities
             *
             * @description
             *
             * Find a related item by id for buyerCities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyerCities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R.buyerCities.findById = function() {
          var TargetResource = $injector.get("BuyerCity");
          var action = TargetResource["::findById::Buyer::buyerCities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.buyerCities#updateById
             * @methodOf lbServices.Buyer.buyerCities
             *
             * @description
             *
             * Update a related item by id for buyerCities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for buyerCities
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R.buyerCities.updateById = function() {
          var TargetResource = $injector.get("BuyerCity");
          var action = TargetResource["::updateById::Buyer::buyerCities"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Buyer.suppliers
     * @header lbServices.Buyer.suppliers
     * @object
     * @description
     *
     * The object `Buyer.suppliers` groups methods
     * manipulating `Supplier` instances related to `Buyer`.
     *
     * Call {@link lbServices.Buyer#suppliers Buyer.suppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Buyer#suppliers
             * @methodOf lbServices.Buyer
             *
             * @description
             *
             * Queries suppliers of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#count
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Counts suppliers of Buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.suppliers.count = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::count::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#create
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.create = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::create::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#createMany
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.createMany = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::createMany::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#destroyAll
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Deletes all suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyAll = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::delete::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#destroyById
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Delete a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::destroyById::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#exists
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Check the existence of suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.exists = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::exists::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#findById
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Find a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.findById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::findById::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#link
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Add a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.link = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::link::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#unlink
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Remove the suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.unlink = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::unlink::Buyer::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Buyer.suppliers#updateById
             * @methodOf lbServices.Buyer.suppliers
             *
             * @description
             *
             * Update a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Buyer id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.updateById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::updateById::Buyer::suppliers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Agency
 * @header lbServices.Agency
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Agency` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Agency",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Agencies/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Agency.auditors.findById() instead.
            "prototype$__findById__auditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/auditors/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.auditors.destroyById() instead.
            "prototype$__destroyById__auditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/auditors/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.auditors.updateById() instead.
            "prototype$__updateById__auditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/auditors/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.notaries.findById() instead.
            "prototype$__findById__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/notaries/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.notaries.destroyById() instead.
            "prototype$__destroyById__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/notaries/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.notaries.updateById() instead.
            "prototype$__updateById__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/notaries/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.certificateType() instead.
            "prototype$__get__certificateType": {
              url: urlBase + "/Agencies/:id/certificateType",
              method: "GET",
            },

            // INTERNAL. Use Agency.folders.findById() instead.
            "prototype$__findById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.folders.destroyById() instead.
            "prototype$__destroyById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.folders.updateById() instead.
            "prototype$__updateById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.offices.findById() instead.
            "prototype$__findById__offices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/offices/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.offices.destroyById() instead.
            "prototype$__destroyById__offices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/offices/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.offices.updateById() instead.
            "prototype$__updateById__offices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/offices/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.taskTemplates.findById() instead.
            "prototype$__findById__taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/taskTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.taskTemplates.destroyById() instead.
            "prototype$__destroyById__taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/taskTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.taskTemplates.updateById() instead.
            "prototype$__updateById__taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/taskTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.emailTemplates.findById() instead.
            "prototype$__findById__emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/emailTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.emailTemplates.destroyById() instead.
            "prototype$__destroyById__emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/emailTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.emailTemplates.updateById() instead.
            "prototype$__updateById__emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/emailTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.owners.findById() instead.
            "prototype$__findById__owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/owners/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.owners.destroyById() instead.
            "prototype$__destroyById__owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/owners/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.owners.updateById() instead.
            "prototype$__updateById__owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/owners/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.members.findById() instead.
            "prototype$__findById__members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/members/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.members.destroyById() instead.
            "prototype$__destroyById__members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/members/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.members.updateById() instead.
            "prototype$__updateById__members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/members/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyMembers.findById() instead.
            "prototype$__findById__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyMembers.destroyById() instead.
            "prototype$__destroyById__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyMembers.updateById() instead.
            "prototype$__updateById__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyMembers.link() instead.
            "prototype$__link__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyMembers.unlink() instead.
            "prototype$__unlink__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyMembers.exists() instead.
            "prototype$__exists__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.agencyOwners.findById() instead.
            "prototype$__findById__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyOwners.destroyById() instead.
            "prototype$__destroyById__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyOwners.updateById() instead.
            "prototype$__updateById__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyOwners.link() instead.
            "prototype$__link__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyOwners.unlink() instead.
            "prototype$__unlink__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyOwners.exists() instead.
            "prototype$__exists__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.agencyAuditors.findById() instead.
            "prototype$__findById__agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyAuditors.destroyById() instead.
            "prototype$__destroyById__agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyAuditors.updateById() instead.
            "prototype$__updateById__agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyAuditors.link() instead.
            "prototype$__link__agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyAuditors.unlink() instead.
            "prototype$__unlink__agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyAuditors.exists() instead.
            "prototype$__exists__agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.agencyNotaries.findById() instead.
            "prototype$__findById__agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyNotaries.destroyById() instead.
            "prototype$__destroyById__agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyNotaries.updateById() instead.
            "prototype$__updateById__agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyNotaries.link() instead.
            "prototype$__link__agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyNotaries.unlink() instead.
            "prototype$__unlink__agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyNotaries.exists() instead.
            "prototype$__exists__agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyBrokers.findById() instead.
            "prototype$__findById__agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyBrokers.destroyById() instead.
            "prototype$__destroyById__agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyBrokers.updateById() instead.
            "prototype$__updateById__agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyBrokers.link() instead.
            "prototype$__link__agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyBrokers.unlink() instead.
            "prototype$__unlink__agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyBrokers.exists() instead.
            "prototype$__exists__agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.subscriptions() instead.
            "prototype$__get__subscriptions": {
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "GET",
            },

            // INTERNAL. Use Agency.subscriptions.create() instead.
            "prototype$__create__subscriptions": {
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "POST",
            },

            // INTERNAL. Use Agency.subscriptions.update() instead.
            "prototype$__update__subscriptions": {
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "PUT",
            },

            // INTERNAL. Use Agency.subscriptions.destroy() instead.
            "prototype$__destroy__subscriptions": {
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.auditors() instead.
            "prototype$__get__auditors": {
              isArray: true,
              url: urlBase + "/Agencies/:id/auditors",
              method: "GET",
            },

            // INTERNAL. Use Agency.auditors.create() instead.
            "prototype$__create__auditors": {
              url: urlBase + "/Agencies/:id/auditors",
              method: "POST",
            },

            // INTERNAL. Use Agency.auditors.destroyAll() instead.
            "prototype$__delete__auditors": {
              url: urlBase + "/Agencies/:id/auditors",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.auditors.count() instead.
            "prototype$__count__auditors": {
              url: urlBase + "/Agencies/:id/auditors/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.notaries() instead.
            "prototype$__get__notaries": {
              isArray: true,
              url: urlBase + "/Agencies/:id/notaries",
              method: "GET",
            },

            // INTERNAL. Use Agency.notaries.create() instead.
            "prototype$__create__notaries": {
              url: urlBase + "/Agencies/:id/notaries",
              method: "POST",
            },

            // INTERNAL. Use Agency.notaries.destroyAll() instead.
            "prototype$__delete__notaries": {
              url: urlBase + "/Agencies/:id/notaries",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.notaries.count() instead.
            "prototype$__count__notaries": {
              url: urlBase + "/Agencies/:id/notaries/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.folders() instead.
            "prototype$__get__folders": {
              isArray: true,
              url: urlBase + "/Agencies/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use Agency.folders.create() instead.
            "prototype$__create__folders": {
              url: urlBase + "/Agencies/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Agency.folders.destroyAll() instead.
            "prototype$__delete__folders": {
              url: urlBase + "/Agencies/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.folders.count() instead.
            "prototype$__count__folders": {
              url: urlBase + "/Agencies/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.offices() instead.
            "prototype$__get__offices": {
              isArray: true,
              url: urlBase + "/Agencies/:id/offices",
              method: "GET",
            },

            // INTERNAL. Use Agency.offices.create() instead.
            "prototype$__create__offices": {
              url: urlBase + "/Agencies/:id/offices",
              method: "POST",
            },

            // INTERNAL. Use Agency.offices.destroyAll() instead.
            "prototype$__delete__offices": {
              url: urlBase + "/Agencies/:id/offices",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.offices.count() instead.
            "prototype$__count__offices": {
              url: urlBase + "/Agencies/:id/offices/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.taskTemplates() instead.
            "prototype$__get__taskTemplates": {
              isArray: true,
              url: urlBase + "/Agencies/:id/taskTemplates",
              method: "GET",
            },

            // INTERNAL. Use Agency.taskTemplates.create() instead.
            "prototype$__create__taskTemplates": {
              url: urlBase + "/Agencies/:id/taskTemplates",
              method: "POST",
            },

            // INTERNAL. Use Agency.taskTemplates.destroyAll() instead.
            "prototype$__delete__taskTemplates": {
              url: urlBase + "/Agencies/:id/taskTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.taskTemplates.count() instead.
            "prototype$__count__taskTemplates": {
              url: urlBase + "/Agencies/:id/taskTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.emailTemplates() instead.
            "prototype$__get__emailTemplates": {
              isArray: true,
              url: urlBase + "/Agencies/:id/emailTemplates",
              method: "GET",
            },

            // INTERNAL. Use Agency.emailTemplates.create() instead.
            "prototype$__create__emailTemplates": {
              url: urlBase + "/Agencies/:id/emailTemplates",
              method: "POST",
            },

            // INTERNAL. Use Agency.emailTemplates.destroyAll() instead.
            "prototype$__delete__emailTemplates": {
              url: urlBase + "/Agencies/:id/emailTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.emailTemplates.count() instead.
            "prototype$__count__emailTemplates": {
              url: urlBase + "/Agencies/:id/emailTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.owners() instead.
            "prototype$__get__owners": {
              isArray: true,
              url: urlBase + "/Agencies/:id/owners",
              method: "GET",
            },

            // INTERNAL. Use Agency.owners.create() instead.
            "prototype$__create__owners": {
              url: urlBase + "/Agencies/:id/owners",
              method: "POST",
            },

            // INTERNAL. Use Agency.owners.destroyAll() instead.
            "prototype$__delete__owners": {
              url: urlBase + "/Agencies/:id/owners",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.owners.count() instead.
            "prototype$__count__owners": {
              url: urlBase + "/Agencies/:id/owners/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.members() instead.
            "prototype$__get__members": {
              isArray: true,
              url: urlBase + "/Agencies/:id/members",
              method: "GET",
            },

            // INTERNAL. Use Agency.members.create() instead.
            "prototype$__create__members": {
              url: urlBase + "/Agencies/:id/members",
              method: "POST",
            },

            // INTERNAL. Use Agency.members.destroyAll() instead.
            "prototype$__delete__members": {
              url: urlBase + "/Agencies/:id/members",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.members.count() instead.
            "prototype$__count__members": {
              url: urlBase + "/Agencies/:id/members/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyMembers() instead.
            "prototype$__get__agencyMembers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyMembers",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyMembers.create() instead.
            "prototype$__create__agencyMembers": {
              url: urlBase + "/Agencies/:id/agencyMembers",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyMembers.destroyAll() instead.
            "prototype$__delete__agencyMembers": {
              url: urlBase + "/Agencies/:id/agencyMembers",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyMembers.count() instead.
            "prototype$__count__agencyMembers": {
              url: urlBase + "/Agencies/:id/agencyMembers/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyOwners() instead.
            "prototype$__get__agencyOwners": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyOwners",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyOwners.create() instead.
            "prototype$__create__agencyOwners": {
              url: urlBase + "/Agencies/:id/agencyOwners",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyOwners.destroyAll() instead.
            "prototype$__delete__agencyOwners": {
              url: urlBase + "/Agencies/:id/agencyOwners",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyOwners.count() instead.
            "prototype$__count__agencyOwners": {
              url: urlBase + "/Agencies/:id/agencyOwners/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyAuditors() instead.
            "prototype$__get__agencyAuditors": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyAuditors",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyAuditors.create() instead.
            "prototype$__create__agencyAuditors": {
              url: urlBase + "/Agencies/:id/agencyAuditors",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyAuditors.destroyAll() instead.
            "prototype$__delete__agencyAuditors": {
              url: urlBase + "/Agencies/:id/agencyAuditors",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyAuditors.count() instead.
            "prototype$__count__agencyAuditors": {
              url: urlBase + "/Agencies/:id/agencyAuditors/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyNotaries() instead.
            "prototype$__get__agencyNotaries": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyNotaries",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyNotaries.create() instead.
            "prototype$__create__agencyNotaries": {
              url: urlBase + "/Agencies/:id/agencyNotaries",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyNotaries.destroyAll() instead.
            "prototype$__delete__agencyNotaries": {
              url: urlBase + "/Agencies/:id/agencyNotaries",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyNotaries.count() instead.
            "prototype$__count__agencyNotaries": {
              url: urlBase + "/Agencies/:id/agencyNotaries/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Agency.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/Agencies/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Agency.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/Agencies/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/Agencies/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyBrokers() instead.
            "prototype$__get__agencyBrokers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyBrokers",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyBrokers.create() instead.
            "prototype$__create__agencyBrokers": {
              url: urlBase + "/Agencies/:id/agencyBrokers",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyBrokers.destroyAll() instead.
            "prototype$__delete__agencyBrokers": {
              url: urlBase + "/Agencies/:id/agencyBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyBrokers.count() instead.
            "prototype$__count__agencyBrokers": {
              url: urlBase + "/Agencies/:id/agencyBrokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#create
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Agencies",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#patchOrCreate
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Agencies",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#replaceOrCreate
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Agencies/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#upsertWithWhere
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Agencies/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#exists
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Agencies/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#findById
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Agencies/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#replaceById
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Agencies/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#find
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Agencies",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#findOne
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Agencies/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#updateAll
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Agencies/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#deleteById
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Agencies/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#count
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Agencies/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#prototype$patchAttributes
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Agencies/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#createChangeStream
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Agencies/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#generateLastMonthListingsByBroker
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `start` – `{date=}` -
             *
             *  - `end` – `{date=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns created last month listings by broker report.
             */
            "generateLastMonthListingsByBroker": {
              url: urlBase + "/Agencies/:id/generate/lastMonthListingsByBroker/start/:start/end/:end",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#generateLastMonthSalesByBroker
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `start` – `{date=}` -
             *
             *  - `end` – `{date=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns created last month sales by broker report.
             */
            "generateLastMonthSalesByBroker": {
              url: urlBase + "/Agencies/:id/generate/lastMonthSalesByBroker",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#generateSalesByRegion
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `start` – `{date=}` -
             *
             *  - `end` – `{date=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns created sales by region report.
             */
            "generateSalesByRegion": {
              url: urlBase + "/Agencies/:id/generate/salesByRegion/start/:start/end/:end",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#generateListingExpiryReport
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `start` – `{date=}` -
             *
             *  - `end` – `{date=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns created listing expiry report.
             */
            "generateListingExpiryReport": {
              url: urlBase + "/Agencies/:id/generate/listingExpiryReport",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#generateYTDListings
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns created ytd listings report.
             */
            "generateYTDListings": {
              url: urlBase + "/Agencies/:id/generate/YTDListings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getExpiredBetweenDates
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             *  - `start` – `{string=}` -
             *
             *  - `end` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of listings.
             */
            "getExpiredBetweenDates": {
              isArray: true,
              url: urlBase + "/Agencies/:id/getExpiredBetweenDates/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getPeople
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Gets brokers, members and owners of an agency
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `onlyActive` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns object of agency with users.
             */
            "getPeople": {
              url: urlBase + "/Agencies/:id/getPeople",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getListings
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{string=}` -
             *
             *  - `brokerId` – `{string=}` -
             *
             *  - `officeId` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of listings.
             */
            "getListings": {
              isArray: true,
              url: urlBase + "/Agencies/:id/listings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getListingsYTD
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of listings.
             */
            "getListingsYTD": {
              url: urlBase + "/Agencies/:id/YTD",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getUsersAndRoles
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Array of users related to that agency.
             */
            "getUsersAndRoles": {
              isArray: true,
              url: urlBase + "/Agencies/:id/getUsersAndRoles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#toggleUserRole
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Activates or Deactivates a role for a user
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `updates` – `{object}` -
             *
             *  - `sbUserId` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "toggleUserRole": {
              url: urlBase + "/Agencies/:id/toggleUserRole",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#findPersonByAccount
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Find profile ID based on account ID. Used to get newly created user after creating a new account for an agency person.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `accountId` – `{string}` -
             *
             *  - `type` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `profileId` – `{string=}` -
             */
            "findPersonByAccount": {
              url: urlBase + "/Agencies/:id/findPersonByAccount",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#isRegistered
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with boolean indicating whether agency is registered in the system or not
             */
            "isRegistered": {
              url: urlBase + "/Agencies/isRegistered",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getListOfNames
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Gets a list of Agency names based on their ids
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `ids` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of agency names
             */
            "getListOfNames": {
              url: urlBase + "/Agencies/getListOfNames",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#toggleBroker
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `brokerId` – `{string=}` -
             *
             *  - `down` – `{boolean=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns modified broker.
             */
            "toggleBroker": {
              url: urlBase + "/Agencies/:id/toggleBroker",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getAdvancedSearch
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Gets the data from the advanced search
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `filter` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of buyers/listings/seller/suppliers.
             */
            "getAdvancedSearch": {
              isArray: true,
              url: urlBase + "/Agencies/:id/getAdvancedSearch",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#generateVisitsByBroker
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             *  - `start` – `{string=}` -
             *
             *  - `end` – `{string=}` -
             *
             *  - `listingId` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns created last month listings by broker report.
             */
            "generateVisitsByBroker": {
              url: urlBase + "/Agencies/:id/agencyBrokers/:fk/generateVisitsByBroker",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getSentEmails
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Get the emails sent to someone from the agency
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `relation` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "getSentEmails": {
              isArray: true,
              url: urlBase + "/Agencies/:id/:relation/:fk/sent-emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#getSearchFilters
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Gets the filters for advanced search.(max price of listing & list of brokers)
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "getSearchFilters": {
              url: urlBase + "/Agencies/:id/getSearchFilters",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Agency#createMany
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Agencies",
              method: "POST",
            },

            // INTERNAL. Use Message.agency() instead.
            "::get::Message::agency": {
              url: urlBase + "/Messages/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Auditor.agency() instead.
            "::get::Auditor::agency": {
              url: urlBase + "/Auditors/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Notary.agency() instead.
            "::get::Notary::agency": {
              url: urlBase + "/Notaries/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Log.relatedAgency() instead.
            "::get::Log::relatedAgency": {
              url: urlBase + "/Logs/:id/relatedAgency",
              method: "GET",
            },

            // INTERNAL. Use Folder.agency() instead.
            "::get::Folder::agency": {
              url: urlBase + "/Folders/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Office.agency() instead.
            "::get::Office::agency": {
              url: urlBase + "/Offices/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.agency() instead.
            "::get::TaskTemplate::agency": {
              url: urlBase + "/TaskTemplates/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.agency() instead.
            "::get::EmailTemplate::agency": {
              url: urlBase + "/EmailTemplates/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Manager.agency() instead.
            "::get::Manager::agency": {
              url: urlBase + "/Managers/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.agency() instead.
            "::get::AgencyOwner::agency": {
              url: urlBase + "/AgencyOwners/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.agency() instead.
            "::get::AgencyMember::agency": {
              url: urlBase + "/AgencyMembers/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedAgencies.findById() instead.
            "::findById::SbUser::ownedAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedAgencies/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedAgencies.destroyById() instead.
            "::destroyById::SbUser::ownedAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedAgencies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.ownedAgencies.updateById() instead.
            "::updateById::SbUser::ownedAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedAgencies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.ownedAgencies() instead.
            "::get::SbUser::ownedAgencies": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/ownedAgencies",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedAgencies.create() instead.
            "::create::SbUser::ownedAgencies": {
              url: urlBase + "/SbUsers/:id/ownedAgencies",
              method: "POST",
            },

            // INTERNAL. Use SbUser.ownedAgencies.createMany() instead.
            "::createMany::SbUser::ownedAgencies": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/ownedAgencies",
              method: "POST",
            },

            // INTERNAL. Use SbUser.ownedAgencies.destroyAll() instead.
            "::delete::SbUser::ownedAgencies": {
              url: urlBase + "/SbUsers/:id/ownedAgencies",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.ownedAgencies.count() instead.
            "::count::SbUser::ownedAgencies": {
              url: urlBase + "/SbUsers/:id/ownedAgencies/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.agency() instead.
            "::get::Broker::agency": {
              url: urlBase + "/Brokers/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Subscription.agency() instead.
            "::get::Subscription::agency": {
              url: urlBase + "/Subscriptions/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Conversation.agency() instead.
            "::get::Conversation::agency": {
              url: urlBase + "/Conversations/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencies.findById() instead.
            "::findById::SmartSearch::agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencies.destroyById() instead.
            "::destroyById::SmartSearch::agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencies.updateById() instead.
            "::updateById::SmartSearch::agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencies.link() instead.
            "::link::SmartSearch::agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencies.unlink() instead.
            "::unlink::SmartSearch::agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencies.exists() instead.
            "::exists::SmartSearch::agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartSearch.agencies() instead.
            "::get::SmartSearch::agencies": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencies",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencies.create() instead.
            "::create::SmartSearch::agencies": {
              url: urlBase + "/SmartSearches/:id/agencies",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencies.createMany() instead.
            "::createMany::SmartSearch::agencies": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencies",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencies.destroyAll() instead.
            "::delete::SmartSearch::agencies": {
              url: urlBase + "/SmartSearches/:id/agencies",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencies.count() instead.
            "::count::SmartSearch::agencies": {
              url: urlBase + "/SmartSearches/:id/agencies/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Agency#upsert
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Agency#updateOrCreate
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Agency#patchOrCreateWithWhere
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Agency#update
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Agency#destroyById
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Agency#removeById
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Agency#prototype$updateAttributes
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Agency#modelName
        * @propertyOf lbServices.Agency
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Agency`.
        */
        R.modelName = "Agency";

    /**
     * @ngdoc object
     * @name lbServices.Agency.auditors
     * @header lbServices.Agency.auditors
     * @object
     * @description
     *
     * The object `Agency.auditors` groups methods
     * manipulating `Auditor` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#auditors Agency.auditors()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#auditors
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries auditors of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditors = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::get::Agency::auditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.auditors#count
             * @methodOf lbServices.Agency.auditors
             *
             * @description
             *
             * Counts auditors of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.auditors.count = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::count::Agency::auditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.auditors#create
             * @methodOf lbServices.Agency.auditors
             *
             * @description
             *
             * Creates a new instance in auditors of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditors.create = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::create::Agency::auditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.auditors#createMany
             * @methodOf lbServices.Agency.auditors
             *
             * @description
             *
             * Creates a new instance in auditors of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditors.createMany = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::createMany::Agency::auditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.auditors#destroyAll
             * @methodOf lbServices.Agency.auditors
             *
             * @description
             *
             * Deletes all auditors of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.auditors.destroyAll = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::delete::Agency::auditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.auditors#destroyById
             * @methodOf lbServices.Agency.auditors
             *
             * @description
             *
             * Delete a related item by id for auditors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for auditors
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.auditors.destroyById = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::destroyById::Agency::auditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.auditors#findById
             * @methodOf lbServices.Agency.auditors
             *
             * @description
             *
             * Find a related item by id for auditors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for auditors
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditors.findById = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::findById::Agency::auditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.auditors#updateById
             * @methodOf lbServices.Agency.auditors
             *
             * @description
             *
             * Update a related item by id for auditors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for auditors
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditors.updateById = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::updateById::Agency::auditors"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.notaries
     * @header lbServices.Agency.notaries
     * @object
     * @description
     *
     * The object `Agency.notaries` groups methods
     * manipulating `Notary` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#notaries Agency.notaries()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#notaries
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries notaries of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::get::Agency::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.notaries#count
             * @methodOf lbServices.Agency.notaries
             *
             * @description
             *
             * Counts notaries of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notaries.count = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::count::Agency::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.notaries#create
             * @methodOf lbServices.Agency.notaries
             *
             * @description
             *
             * Creates a new instance in notaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.create = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::create::Agency::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.notaries#createMany
             * @methodOf lbServices.Agency.notaries
             *
             * @description
             *
             * Creates a new instance in notaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.createMany = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::createMany::Agency::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.notaries#destroyAll
             * @methodOf lbServices.Agency.notaries
             *
             * @description
             *
             * Deletes all notaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notaries.destroyAll = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::delete::Agency::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.notaries#destroyById
             * @methodOf lbServices.Agency.notaries
             *
             * @description
             *
             * Delete a related item by id for notaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notaries.destroyById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::destroyById::Agency::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.notaries#findById
             * @methodOf lbServices.Agency.notaries
             *
             * @description
             *
             * Find a related item by id for notaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.findById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::findById::Agency::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.notaries#updateById
             * @methodOf lbServices.Agency.notaries
             *
             * @description
             *
             * Update a related item by id for notaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.updateById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::updateById::Agency::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency#certificateType
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Fetches belongsTo relation certificateType.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.certificateType = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Agency::certificateType"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.folders
     * @header lbServices.Agency.folders
     * @object
     * @description
     *
     * The object `Agency.folders` groups methods
     * manipulating `Folder` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#folders Agency.folders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#folders
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries folders of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::Agency::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.folders#count
             * @methodOf lbServices.Agency.folders
             *
             * @description
             *
             * Counts folders of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.folders.count = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::count::Agency::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.folders#create
             * @methodOf lbServices.Agency.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::Agency::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.folders#createMany
             * @methodOf lbServices.Agency.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::Agency::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.folders#destroyAll
             * @methodOf lbServices.Agency.folders
             *
             * @description
             *
             * Deletes all folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyAll = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::delete::Agency::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.folders#destroyById
             * @methodOf lbServices.Agency.folders
             *
             * @description
             *
             * Delete a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroyById::Agency::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.folders#findById
             * @methodOf lbServices.Agency.folders
             *
             * @description
             *
             * Find a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.findById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::findById::Agency::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.folders#updateById
             * @methodOf lbServices.Agency.folders
             *
             * @description
             *
             * Update a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.updateById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::updateById::Agency::folders"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.offices
     * @header lbServices.Agency.offices
     * @object
     * @description
     *
     * The object `Agency.offices` groups methods
     * manipulating `Office` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#offices Agency.offices()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#offices
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries offices of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R.offices = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::get::Agency::offices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.offices#count
             * @methodOf lbServices.Agency.offices
             *
             * @description
             *
             * Counts offices of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.offices.count = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::count::Agency::offices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.offices#create
             * @methodOf lbServices.Agency.offices
             *
             * @description
             *
             * Creates a new instance in offices of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R.offices.create = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::create::Agency::offices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.offices#createMany
             * @methodOf lbServices.Agency.offices
             *
             * @description
             *
             * Creates a new instance in offices of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R.offices.createMany = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::createMany::Agency::offices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.offices#destroyAll
             * @methodOf lbServices.Agency.offices
             *
             * @description
             *
             * Deletes all offices of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.offices.destroyAll = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::delete::Agency::offices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.offices#destroyById
             * @methodOf lbServices.Agency.offices
             *
             * @description
             *
             * Delete a related item by id for offices.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for offices
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.offices.destroyById = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::destroyById::Agency::offices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.offices#findById
             * @methodOf lbServices.Agency.offices
             *
             * @description
             *
             * Find a related item by id for offices.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for offices
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R.offices.findById = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::findById::Agency::offices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.offices#updateById
             * @methodOf lbServices.Agency.offices
             *
             * @description
             *
             * Update a related item by id for offices.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for offices
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R.offices.updateById = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::updateById::Agency::offices"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.taskTemplates
     * @header lbServices.Agency.taskTemplates
     * @object
     * @description
     *
     * The object `Agency.taskTemplates` groups methods
     * manipulating `TaskTemplate` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#taskTemplates Agency.taskTemplates()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#taskTemplates
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries taskTemplates of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::get::Agency::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.taskTemplates#count
             * @methodOf lbServices.Agency.taskTemplates
             *
             * @description
             *
             * Counts taskTemplates of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.taskTemplates.count = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::count::Agency::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.taskTemplates#create
             * @methodOf lbServices.Agency.taskTemplates
             *
             * @description
             *
             * Creates a new instance in taskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates.create = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::create::Agency::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.taskTemplates#createMany
             * @methodOf lbServices.Agency.taskTemplates
             *
             * @description
             *
             * Creates a new instance in taskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates.createMany = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::createMany::Agency::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.taskTemplates#destroyAll
             * @methodOf lbServices.Agency.taskTemplates
             *
             * @description
             *
             * Deletes all taskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.taskTemplates.destroyAll = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::delete::Agency::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.taskTemplates#destroyById
             * @methodOf lbServices.Agency.taskTemplates
             *
             * @description
             *
             * Delete a related item by id for taskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for taskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.taskTemplates.destroyById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::destroyById::Agency::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.taskTemplates#findById
             * @methodOf lbServices.Agency.taskTemplates
             *
             * @description
             *
             * Find a related item by id for taskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for taskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates.findById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::findById::Agency::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.taskTemplates#updateById
             * @methodOf lbServices.Agency.taskTemplates
             *
             * @description
             *
             * Update a related item by id for taskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for taskTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates.updateById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::updateById::Agency::taskTemplates"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.emailTemplates
     * @header lbServices.Agency.emailTemplates
     * @object
     * @description
     *
     * The object `Agency.emailTemplates` groups methods
     * manipulating `EmailTemplate` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#emailTemplates Agency.emailTemplates()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#emailTemplates
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries emailTemplates of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::get::Agency::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.emailTemplates#count
             * @methodOf lbServices.Agency.emailTemplates
             *
             * @description
             *
             * Counts emailTemplates of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.emailTemplates.count = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::count::Agency::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.emailTemplates#create
             * @methodOf lbServices.Agency.emailTemplates
             *
             * @description
             *
             * Creates a new instance in emailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates.create = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::create::Agency::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.emailTemplates#createMany
             * @methodOf lbServices.Agency.emailTemplates
             *
             * @description
             *
             * Creates a new instance in emailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates.createMany = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::createMany::Agency::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.emailTemplates#destroyAll
             * @methodOf lbServices.Agency.emailTemplates
             *
             * @description
             *
             * Deletes all emailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.emailTemplates.destroyAll = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::delete::Agency::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.emailTemplates#destroyById
             * @methodOf lbServices.Agency.emailTemplates
             *
             * @description
             *
             * Delete a related item by id for emailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for emailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.emailTemplates.destroyById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::destroyById::Agency::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.emailTemplates#findById
             * @methodOf lbServices.Agency.emailTemplates
             *
             * @description
             *
             * Find a related item by id for emailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for emailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates.findById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::findById::Agency::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.emailTemplates#updateById
             * @methodOf lbServices.Agency.emailTemplates
             *
             * @description
             *
             * Update a related item by id for emailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for emailTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates.updateById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::updateById::Agency::emailTemplates"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.owners
     * @header lbServices.Agency.owners
     * @object
     * @description
     *
     * The object `Agency.owners` groups methods
     * manipulating `AgencyOwner` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#owners Agency.owners()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#owners
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries owners of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::get::Agency::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.owners#count
             * @methodOf lbServices.Agency.owners
             *
             * @description
             *
             * Counts owners of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.owners.count = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::count::Agency::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.owners#create
             * @methodOf lbServices.Agency.owners
             *
             * @description
             *
             * Creates a new instance in owners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners.create = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::create::Agency::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.owners#createMany
             * @methodOf lbServices.Agency.owners
             *
             * @description
             *
             * Creates a new instance in owners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners.createMany = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::createMany::Agency::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.owners#destroyAll
             * @methodOf lbServices.Agency.owners
             *
             * @description
             *
             * Deletes all owners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.owners.destroyAll = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::delete::Agency::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.owners#destroyById
             * @methodOf lbServices.Agency.owners
             *
             * @description
             *
             * Delete a related item by id for owners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for owners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.owners.destroyById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::destroyById::Agency::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.owners#findById
             * @methodOf lbServices.Agency.owners
             *
             * @description
             *
             * Find a related item by id for owners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for owners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners.findById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::findById::Agency::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.owners#updateById
             * @methodOf lbServices.Agency.owners
             *
             * @description
             *
             * Update a related item by id for owners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for owners
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners.updateById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::updateById::Agency::owners"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.members
     * @header lbServices.Agency.members
     * @object
     * @description
     *
     * The object `Agency.members` groups methods
     * manipulating `AgencyMember` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#members Agency.members()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#members
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries members of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::get::Agency::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.members#count
             * @methodOf lbServices.Agency.members
             *
             * @description
             *
             * Counts members of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.members.count = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::count::Agency::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.members#create
             * @methodOf lbServices.Agency.members
             *
             * @description
             *
             * Creates a new instance in members of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members.create = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::create::Agency::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.members#createMany
             * @methodOf lbServices.Agency.members
             *
             * @description
             *
             * Creates a new instance in members of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members.createMany = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::createMany::Agency::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.members#destroyAll
             * @methodOf lbServices.Agency.members
             *
             * @description
             *
             * Deletes all members of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.members.destroyAll = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::delete::Agency::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.members#destroyById
             * @methodOf lbServices.Agency.members
             *
             * @description
             *
             * Delete a related item by id for members.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for members
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.members.destroyById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::destroyById::Agency::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.members#findById
             * @methodOf lbServices.Agency.members
             *
             * @description
             *
             * Find a related item by id for members.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for members
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members.findById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::findById::Agency::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.members#updateById
             * @methodOf lbServices.Agency.members
             *
             * @description
             *
             * Update a related item by id for members.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for members
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members.updateById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::updateById::Agency::members"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.agencyMembers
     * @header lbServices.Agency.agencyMembers
     * @object
     * @description
     *
     * The object `Agency.agencyMembers` groups methods
     * manipulating `SbUser` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#agencyMembers Agency.agencyMembers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#agencyMembers
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries agencyMembers of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyMembers = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#count
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Counts agencyMembers of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.agencyMembers.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#create
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Creates a new instance in agencyMembers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyMembers.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#createMany
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Creates a new instance in agencyMembers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyMembers.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#destroyAll
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Deletes all agencyMembers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyMembers.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#destroyById
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Delete a related item by id for agencyMembers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyMembers.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#exists
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Check the existence of agencyMembers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyMembers.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#findById
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Find a related item by id for agencyMembers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyMembers.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#link
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Add a related item by id for agencyMembers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyMembers.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#unlink
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Remove the agencyMembers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyMembers.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyMembers#updateById
             * @methodOf lbServices.Agency.agencyMembers
             *
             * @description
             *
             * Update a related item by id for agencyMembers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyMembers.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Agency::agencyMembers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.agencyOwners
     * @header lbServices.Agency.agencyOwners
     * @object
     * @description
     *
     * The object `Agency.agencyOwners` groups methods
     * manipulating `SbUser` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#agencyOwners Agency.agencyOwners()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#agencyOwners
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries agencyOwners of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyOwners = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#count
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Counts agencyOwners of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.agencyOwners.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#create
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Creates a new instance in agencyOwners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyOwners.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#createMany
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Creates a new instance in agencyOwners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyOwners.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#destroyAll
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Deletes all agencyOwners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyOwners.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#destroyById
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Delete a related item by id for agencyOwners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyOwners.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#exists
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Check the existence of agencyOwners relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyOwners.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#findById
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Find a related item by id for agencyOwners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyOwners.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#link
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Add a related item by id for agencyOwners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyOwners.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#unlink
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Remove the agencyOwners relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyOwners.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyOwners#updateById
             * @methodOf lbServices.Agency.agencyOwners
             *
             * @description
             *
             * Update a related item by id for agencyOwners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyOwners.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Agency::agencyOwners"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.agencyAuditors
     * @header lbServices.Agency.agencyAuditors
     * @object
     * @description
     *
     * The object `Agency.agencyAuditors` groups methods
     * manipulating `SbUser` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#agencyAuditors Agency.agencyAuditors()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#agencyAuditors
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries agencyAuditors of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyAuditors = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#count
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Counts agencyAuditors of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.agencyAuditors.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#create
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Creates a new instance in agencyAuditors of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyAuditors.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#createMany
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Creates a new instance in agencyAuditors of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyAuditors.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#destroyAll
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Deletes all agencyAuditors of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyAuditors.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#destroyById
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Delete a related item by id for agencyAuditors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyAuditors
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyAuditors.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#exists
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Check the existence of agencyAuditors relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyAuditors
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyAuditors.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#findById
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Find a related item by id for agencyAuditors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyAuditors
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyAuditors.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#link
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Add a related item by id for agencyAuditors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyAuditors
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyAuditors.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#unlink
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Remove the agencyAuditors relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyAuditors
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyAuditors.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyAuditors#updateById
             * @methodOf lbServices.Agency.agencyAuditors
             *
             * @description
             *
             * Update a related item by id for agencyAuditors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyAuditors
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyAuditors.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Agency::agencyAuditors"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.agencyNotaries
     * @header lbServices.Agency.agencyNotaries
     * @object
     * @description
     *
     * The object `Agency.agencyNotaries` groups methods
     * manipulating `SbUser` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#agencyNotaries Agency.agencyNotaries()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#agencyNotaries
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries agencyNotaries of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyNotaries = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#count
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Counts agencyNotaries of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.agencyNotaries.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#create
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Creates a new instance in agencyNotaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyNotaries.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#createMany
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Creates a new instance in agencyNotaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyNotaries.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#destroyAll
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Deletes all agencyNotaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyNotaries.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#destroyById
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Delete a related item by id for agencyNotaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyNotaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyNotaries.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#exists
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Check the existence of agencyNotaries relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyNotaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyNotaries.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#findById
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Find a related item by id for agencyNotaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyNotaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyNotaries.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#link
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Add a related item by id for agencyNotaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyNotaries
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyNotaries.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#unlink
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Remove the agencyNotaries relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyNotaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyNotaries.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyNotaries#updateById
             * @methodOf lbServices.Agency.agencyNotaries
             *
             * @description
             *
             * Update a related item by id for agencyNotaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyNotaries
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyNotaries.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Agency::agencyNotaries"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.brokers
     * @header lbServices.Agency.brokers
     * @object
     * @description
     *
     * The object `Agency.brokers` groups methods
     * manipulating `Broker` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#brokers Agency.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#brokers
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries brokers of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Agency::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.brokers#count
             * @methodOf lbServices.Agency.brokers
             *
             * @description
             *
             * Counts brokers of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::Agency::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.brokers#create
             * @methodOf lbServices.Agency.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::Agency::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.brokers#createMany
             * @methodOf lbServices.Agency.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::Agency::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.brokers#destroyAll
             * @methodOf lbServices.Agency.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::Agency::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.brokers#destroyById
             * @methodOf lbServices.Agency.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::Agency::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.brokers#findById
             * @methodOf lbServices.Agency.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::Agency::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.brokers#updateById
             * @methodOf lbServices.Agency.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::Agency::brokers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.agencyBrokers
     * @header lbServices.Agency.agencyBrokers
     * @object
     * @description
     *
     * The object `Agency.agencyBrokers` groups methods
     * manipulating `SbUser` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#agencyBrokers Agency.agencyBrokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#agencyBrokers
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Queries agencyBrokers of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyBrokers = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#count
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Counts agencyBrokers of Agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.agencyBrokers.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#create
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Creates a new instance in agencyBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyBrokers.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#createMany
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Creates a new instance in agencyBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyBrokers.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#destroyAll
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Deletes all agencyBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyBrokers.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#destroyById
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Delete a related item by id for agencyBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyBrokers.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#exists
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Check the existence of agencyBrokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyBrokers.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#findById
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Find a related item by id for agencyBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyBrokers.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#link
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Add a related item by id for agencyBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyBrokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyBrokers.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#unlink
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Remove the agencyBrokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyBrokers.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.agencyBrokers#updateById
             * @methodOf lbServices.Agency.agencyBrokers
             *
             * @description
             *
             * Update a related item by id for agencyBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `fk` – `{*}` - Foreign key for agencyBrokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.agencyBrokers.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Agency::agencyBrokers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Agency.subscriptions
     * @header lbServices.Agency.subscriptions
     * @object
     * @description
     *
     * The object `Agency.subscriptions` groups methods
     * manipulating `Subscription` instances related to `Agency`.
     *
     * Call {@link lbServices.Agency#subscriptions Agency.subscriptions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Agency#subscriptions
             * @methodOf lbServices.Agency
             *
             * @description
             *
             * Fetches hasOne relation subscriptions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R.subscriptions = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::get::Agency::subscriptions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.subscriptions#create
             * @methodOf lbServices.Agency.subscriptions
             *
             * @description
             *
             * Creates a new instance in subscriptions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R.subscriptions.create = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::create::Agency::subscriptions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.subscriptions#createMany
             * @methodOf lbServices.Agency.subscriptions
             *
             * @description
             *
             * Creates a new instance in subscriptions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R.subscriptions.createMany = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::createMany::Agency::subscriptions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.subscriptions#destroy
             * @methodOf lbServices.Agency.subscriptions
             *
             * @description
             *
             * Deletes subscriptions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.subscriptions.destroy = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::destroy::Agency::subscriptions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Agency.subscriptions#update
             * @methodOf lbServices.Agency.subscriptions
             *
             * @description
             *
             * Update subscriptions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Agency id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R.subscriptions.update = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::update::Agency::subscriptions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Visit
 * @header lbServices.Visit
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Visit` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Visit",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Visits/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Visit.agencyOwner() instead.
            "prototype$__get__agencyOwner": {
              url: urlBase + "/Visits/:id/agencyOwner",
              method: "GET",
            },

            // INTERNAL. Use Visit.agencyMember() instead.
            "prototype$__get__agencyMember": {
              url: urlBase + "/Visits/:id/agencyMember",
              method: "GET",
            },

            // INTERNAL. Use Visit.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/Visits/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use Visit.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/Visits/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Visit.users.findById() instead.
            "prototype$__findById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Visit.users.destroyById() instead.
            "prototype$__destroyById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.users.updateById() instead.
            "prototype$__updateById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.users.link() instead.
            "prototype$__link__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.users.unlink() instead.
            "prototype$__unlink__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.users.exists() instead.
            "prototype$__exists__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Visit.usersVisit.findById() instead.
            "prototype$__findById__usersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/usersVisit/:fk",
              method: "GET",
            },

            // INTERNAL. Use Visit.usersVisit.destroyById() instead.
            "prototype$__destroyById__usersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/usersVisit/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.usersVisit.updateById() instead.
            "prototype$__updateById__usersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/usersVisit/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.coBuyers.findById() instead.
            "prototype$__findById__coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyers.destroyById() instead.
            "prototype$__destroyById__coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyers.updateById() instead.
            "prototype$__updateById__coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.coBuyers.link() instead.
            "prototype$__link__coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.coBuyers.unlink() instead.
            "prototype$__unlink__coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyers.exists() instead.
            "prototype$__exists__coBuyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Visit.coBuyersVisit.findById() instead.
            "prototype$__findById__coBuyersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyersVisit/:fk",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyersVisit.destroyById() instead.
            "prototype$__destroyById__coBuyersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyersVisit/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyersVisit.updateById() instead.
            "prototype$__updateById__coBuyersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyersVisit/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.users() instead.
            "prototype$__get__users": {
              isArray: true,
              url: urlBase + "/Visits/:id/users",
              method: "GET",
            },

            // INTERNAL. Use Visit.users.create() instead.
            "prototype$__create__users": {
              url: urlBase + "/Visits/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Visit.users.destroyAll() instead.
            "prototype$__delete__users": {
              url: urlBase + "/Visits/:id/users",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.users.count() instead.
            "prototype$__count__users": {
              url: urlBase + "/Visits/:id/users/count",
              method: "GET",
            },

            // INTERNAL. Use Visit.usersVisit() instead.
            "prototype$__get__usersVisit": {
              isArray: true,
              url: urlBase + "/Visits/:id/usersVisit",
              method: "GET",
            },

            // INTERNAL. Use Visit.usersVisit.create() instead.
            "prototype$__create__usersVisit": {
              url: urlBase + "/Visits/:id/usersVisit",
              method: "POST",
            },

            // INTERNAL. Use Visit.usersVisit.destroyAll() instead.
            "prototype$__delete__usersVisit": {
              url: urlBase + "/Visits/:id/usersVisit",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.usersVisit.count() instead.
            "prototype$__count__usersVisit": {
              url: urlBase + "/Visits/:id/usersVisit/count",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyers() instead.
            "prototype$__get__coBuyers": {
              isArray: true,
              url: urlBase + "/Visits/:id/coBuyers",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyers.create() instead.
            "prototype$__create__coBuyers": {
              url: urlBase + "/Visits/:id/coBuyers",
              method: "POST",
            },

            // INTERNAL. Use Visit.coBuyers.destroyAll() instead.
            "prototype$__delete__coBuyers": {
              url: urlBase + "/Visits/:id/coBuyers",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyers.count() instead.
            "prototype$__count__coBuyers": {
              url: urlBase + "/Visits/:id/coBuyers/count",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyersVisit() instead.
            "prototype$__get__coBuyersVisit": {
              isArray: true,
              url: urlBase + "/Visits/:id/coBuyersVisit",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyersVisit.create() instead.
            "prototype$__create__coBuyersVisit": {
              url: urlBase + "/Visits/:id/coBuyersVisit",
              method: "POST",
            },

            // INTERNAL. Use Visit.coBuyersVisit.destroyAll() instead.
            "prototype$__delete__coBuyersVisit": {
              url: urlBase + "/Visits/:id/coBuyersVisit",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyersVisit.count() instead.
            "prototype$__count__coBuyersVisit": {
              url: urlBase + "/Visits/:id/coBuyersVisit/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#create
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Visits",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#patchOrCreate
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Visits",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#replaceOrCreate
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Visits/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#upsertWithWhere
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Visits/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#exists
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Visits/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#findById
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Visits/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#replaceById
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Visits/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#find
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Visits",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#findOne
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Visits/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#updateAll
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Visits/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#deleteById
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Visits/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#count
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Visits/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#prototype$patchAttributes
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Visits/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#createChangeStream
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Visits/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#cancelVisit
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Destroys a visit and sends email to all participants informing them.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `reason` – `{string=}` -
             *
             *  - `updateGoogleEvent` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "cancelVisit": {
              url: urlBase + "/Visits/:id/cancel",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#linkAndUnlink
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `toLink` – `{*=}` -
             *
             *  - `toUnlink` – `{*=}` -
             *
             *  - `toCoLink` – `{*=}` -
             *
             *  - `toCoUnlink` – `{*=}` -
             *
             *  - `notifyRecipients` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "linkAndUnlink": {
              url: urlBase + "/Visits/:id/link-and-unlink",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#getPublicVisit
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Gets a visit based on its secret(hash value).
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `secret` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * VisitSbUser or VisitCoBuyer object
             */
            "getPublicVisit": {
              url: urlBase + "/Visits/getPublicVisit",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Visit#createMany
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Visits",
              method: "POST",
            },

            // INTERNAL. Use Buyer.visits.findById() instead.
            "::findById::Buyer::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.visits.destroyById() instead.
            "::destroyById::Buyer::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.visits.updateById() instead.
            "::updateById::Buyer::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.visits.link() instead.
            "::link::Buyer::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.visits.unlink() instead.
            "::unlink::Buyer::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.visits.exists() instead.
            "::exists::Buyer::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/visits/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.visits() instead.
            "::get::Buyer::visits": {
              isArray: true,
              url: urlBase + "/Buyers/:id/visits",
              method: "GET",
            },

            // INTERNAL. Use Buyer.visits.create() instead.
            "::create::Buyer::visits": {
              url: urlBase + "/Buyers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Buyer.visits.createMany() instead.
            "::createMany::Buyer::visits": {
              isArray: true,
              url: urlBase + "/Buyers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Buyer.visits.destroyAll() instead.
            "::delete::Buyer::visits": {
              url: urlBase + "/Buyers/:id/visits",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.visits.count() instead.
            "::count::Buyer::visits": {
              url: urlBase + "/Buyers/:id/visits/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.appointments.findById() instead.
            "::findById::AgencyOwner::appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/appointments/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.appointments.destroyById() instead.
            "::destroyById::AgencyOwner::appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/appointments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.appointments.updateById() instead.
            "::updateById::AgencyOwner::appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/appointments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.appointments() instead.
            "::get::AgencyOwner::appointments": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/appointments",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.appointments.create() instead.
            "::create::AgencyOwner::appointments": {
              url: urlBase + "/AgencyOwners/:id/appointments",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.appointments.createMany() instead.
            "::createMany::AgencyOwner::appointments": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/appointments",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.appointments.destroyAll() instead.
            "::delete::AgencyOwner::appointments": {
              url: urlBase + "/AgencyOwners/:id/appointments",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.appointments.count() instead.
            "::count::AgencyOwner::appointments": {
              url: urlBase + "/AgencyOwners/:id/appointments/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.appointments.findById() instead.
            "::findById::AgencyMember::appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/appointments/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.appointments.destroyById() instead.
            "::destroyById::AgencyMember::appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/appointments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.appointments.updateById() instead.
            "::updateById::AgencyMember::appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/appointments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.appointments() instead.
            "::get::AgencyMember::appointments": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/appointments",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.appointments.create() instead.
            "::create::AgencyMember::appointments": {
              url: urlBase + "/AgencyMembers/:id/appointments",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.appointments.createMany() instead.
            "::createMany::AgencyMember::appointments": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/appointments",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.appointments.destroyAll() instead.
            "::delete::AgencyMember::appointments": {
              url: urlBase + "/AgencyMembers/:id/appointments",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.appointments.count() instead.
            "::count::AgencyMember::appointments": {
              url: urlBase + "/AgencyMembers/:id/appointments/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.visits.findById() instead.
            "::findById::SbUser::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.visits.destroyById() instead.
            "::destroyById::SbUser::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.visits.updateById() instead.
            "::updateById::SbUser::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.visits.link() instead.
            "::link::SbUser::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.visits.unlink() instead.
            "::unlink::SbUser::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.visits.exists() instead.
            "::exists::SbUser::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.visits() instead.
            "::get::SbUser::visits": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/visits",
              method: "GET",
            },

            // INTERNAL. Use SbUser.visits.create() instead.
            "::create::SbUser::visits": {
              url: urlBase + "/SbUsers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use SbUser.visits.createMany() instead.
            "::createMany::SbUser::visits": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use SbUser.visits.destroyAll() instead.
            "::delete::SbUser::visits": {
              url: urlBase + "/SbUsers/:id/visits",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.visits.count() instead.
            "::count::SbUser::visits": {
              url: urlBase + "/SbUsers/:id/visits/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.visits.findById() instead.
            "::findById::Broker::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/visits/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.visits.destroyById() instead.
            "::destroyById::Broker::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/visits/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.visits.updateById() instead.
            "::updateById::Broker::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/visits/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.visits() instead.
            "::get::Broker::visits": {
              isArray: true,
              url: urlBase + "/Brokers/:id/visits",
              method: "GET",
            },

            // INTERNAL. Use Broker.visits.create() instead.
            "::create::Broker::visits": {
              url: urlBase + "/Brokers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Broker.visits.createMany() instead.
            "::createMany::Broker::visits": {
              isArray: true,
              url: urlBase + "/Brokers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Broker.visits.destroyAll() instead.
            "::delete::Broker::visits": {
              url: urlBase + "/Brokers/:id/visits",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.visits.count() instead.
            "::count::Broker::visits": {
              url: urlBase + "/Brokers/:id/visits/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.visits.findById() instead.
            "::findById::Listing::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/visits/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.visits.destroyById() instead.
            "::destroyById::Listing::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/visits/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.visits.updateById() instead.
            "::updateById::Listing::visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/visits/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.visits() instead.
            "::get::Listing::visits": {
              isArray: true,
              url: urlBase + "/Listings/:id/visits",
              method: "GET",
            },

            // INTERNAL. Use Listing.visits.create() instead.
            "::create::Listing::visits": {
              url: urlBase + "/Listings/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Listing.visits.createMany() instead.
            "::createMany::Listing::visits": {
              isArray: true,
              url: urlBase + "/Listings/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Listing.visits.destroyAll() instead.
            "::delete::Listing::visits": {
              url: urlBase + "/Listings/:id/visits",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.visits.count() instead.
            "::count::Listing::visits": {
              url: urlBase + "/Listings/:id/visits/count",
              method: "GET",
            },

            // INTERNAL. Use VisitSbUser.visit() instead.
            "::get::VisitSbUser::visit": {
              url: urlBase + "/VisitSbUsers/:id/visit",
              method: "GET",
            },

            // INTERNAL. Use VisitCoBuyer.visit() instead.
            "::get::VisitCoBuyer::visit": {
              url: urlBase + "/VisitCoBuyers/:id/visit",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Visit#upsert
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Visit#updateOrCreate
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Visit#patchOrCreateWithWhere
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Visit#update
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Visit#destroyById
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Visit#removeById
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Visit#prototype$updateAttributes
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Visit#modelName
        * @propertyOf lbServices.Visit
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Visit`.
        */
        R.modelName = "Visit";


            /**
             * @ngdoc method
             * @name lbServices.Visit#agencyOwner
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Fetches belongsTo relation agencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwner = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::get::Visit::agencyOwner"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit#agencyMember
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Fetches belongsTo relation agencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMember = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::get::Visit::agencyMember"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit#broker
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Visit::broker"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit#listing
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Visit::listing"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Visit.users
     * @header lbServices.Visit.users
     * @object
     * @description
     *
     * The object `Visit.users` groups methods
     * manipulating `SbUser` instances related to `Visit`.
     *
     * Call {@link lbServices.Visit#users Visit.users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Visit#users
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Queries users of Visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#count
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Counts users of Visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.users.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#create
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#createMany
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#destroyAll
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Deletes all users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#destroyById
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Delete a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#exists
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Check the existence of users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#findById
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Find a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#link
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Add a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#unlink
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Remove the users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Visit::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.users#updateById
             * @methodOf lbServices.Visit.users
             *
             * @description
             *
             * Update a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Visit::users"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Visit.usersVisit
     * @header lbServices.Visit.usersVisit
     * @object
     * @description
     *
     * The object `Visit.usersVisit` groups methods
     * manipulating `VisitSbUser` instances related to `Visit`.
     *
     * Call {@link lbServices.Visit#usersVisit Visit.usersVisit()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Visit#usersVisit
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Queries usersVisit of Visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R.usersVisit = function() {
          var TargetResource = $injector.get("VisitSbUser");
          var action = TargetResource["::get::Visit::usersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.usersVisit#count
             * @methodOf lbServices.Visit.usersVisit
             *
             * @description
             *
             * Counts usersVisit of Visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.usersVisit.count = function() {
          var TargetResource = $injector.get("VisitSbUser");
          var action = TargetResource["::count::Visit::usersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.usersVisit#create
             * @methodOf lbServices.Visit.usersVisit
             *
             * @description
             *
             * Creates a new instance in usersVisit of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R.usersVisit.create = function() {
          var TargetResource = $injector.get("VisitSbUser");
          var action = TargetResource["::create::Visit::usersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.usersVisit#createMany
             * @methodOf lbServices.Visit.usersVisit
             *
             * @description
             *
             * Creates a new instance in usersVisit of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R.usersVisit.createMany = function() {
          var TargetResource = $injector.get("VisitSbUser");
          var action = TargetResource["::createMany::Visit::usersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.usersVisit#destroyAll
             * @methodOf lbServices.Visit.usersVisit
             *
             * @description
             *
             * Deletes all usersVisit of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.usersVisit.destroyAll = function() {
          var TargetResource = $injector.get("VisitSbUser");
          var action = TargetResource["::delete::Visit::usersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.usersVisit#destroyById
             * @methodOf lbServices.Visit.usersVisit
             *
             * @description
             *
             * Delete a related item by id for usersVisit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for usersVisit
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.usersVisit.destroyById = function() {
          var TargetResource = $injector.get("VisitSbUser");
          var action = TargetResource["::destroyById::Visit::usersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.usersVisit#findById
             * @methodOf lbServices.Visit.usersVisit
             *
             * @description
             *
             * Find a related item by id for usersVisit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for usersVisit
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R.usersVisit.findById = function() {
          var TargetResource = $injector.get("VisitSbUser");
          var action = TargetResource["::findById::Visit::usersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.usersVisit#updateById
             * @methodOf lbServices.Visit.usersVisit
             *
             * @description
             *
             * Update a related item by id for usersVisit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `fk` – `{*}` - Foreign key for usersVisit
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R.usersVisit.updateById = function() {
          var TargetResource = $injector.get("VisitSbUser");
          var action = TargetResource["::updateById::Visit::usersVisit"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Visit.coBuyers
     * @header lbServices.Visit.coBuyers
     * @object
     * @description
     *
     * The object `Visit.coBuyers` groups methods
     * manipulating `Buyer` instances related to `Visit`.
     *
     * Call {@link lbServices.Visit#coBuyers Visit.coBuyers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Visit#coBuyers
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Queries coBuyers of Visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.coBuyers = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#count
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Counts coBuyers of Visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.coBuyers.count = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::count::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#create
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Creates a new instance in coBuyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.coBuyers.create = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::create::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#createMany
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Creates a new instance in coBuyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.coBuyers.createMany = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::createMany::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#destroyAll
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Deletes all coBuyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.coBuyers.destroyAll = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::delete::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#destroyById
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Delete a related item by id for coBuyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for coBuyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.coBuyers.destroyById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::destroyById::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#exists
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Check the existence of coBuyers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for coBuyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.coBuyers.exists = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::exists::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#findById
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Find a related item by id for coBuyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for coBuyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.coBuyers.findById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::findById::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#link
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Add a related item by id for coBuyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `fk` – `{*}` - Foreign key for coBuyers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.coBuyers.link = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::link::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#unlink
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Remove the coBuyers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for coBuyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.coBuyers.unlink = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::unlink::Visit::coBuyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyers#updateById
             * @methodOf lbServices.Visit.coBuyers
             *
             * @description
             *
             * Update a related item by id for coBuyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `fk` – `{*}` - Foreign key for coBuyers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.coBuyers.updateById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::updateById::Visit::coBuyers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Visit.coBuyersVisit
     * @header lbServices.Visit.coBuyersVisit
     * @object
     * @description
     *
     * The object `Visit.coBuyersVisit` groups methods
     * manipulating `VisitCoBuyer` instances related to `Visit`.
     *
     * Call {@link lbServices.Visit#coBuyersVisit Visit.coBuyersVisit()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Visit#coBuyersVisit
             * @methodOf lbServices.Visit
             *
             * @description
             *
             * Queries coBuyersVisit of Visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R.coBuyersVisit = function() {
          var TargetResource = $injector.get("VisitCoBuyer");
          var action = TargetResource["::get::Visit::coBuyersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyersVisit#count
             * @methodOf lbServices.Visit.coBuyersVisit
             *
             * @description
             *
             * Counts coBuyersVisit of Visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.coBuyersVisit.count = function() {
          var TargetResource = $injector.get("VisitCoBuyer");
          var action = TargetResource["::count::Visit::coBuyersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyersVisit#create
             * @methodOf lbServices.Visit.coBuyersVisit
             *
             * @description
             *
             * Creates a new instance in coBuyersVisit of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R.coBuyersVisit.create = function() {
          var TargetResource = $injector.get("VisitCoBuyer");
          var action = TargetResource["::create::Visit::coBuyersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyersVisit#createMany
             * @methodOf lbServices.Visit.coBuyersVisit
             *
             * @description
             *
             * Creates a new instance in coBuyersVisit of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R.coBuyersVisit.createMany = function() {
          var TargetResource = $injector.get("VisitCoBuyer");
          var action = TargetResource["::createMany::Visit::coBuyersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyersVisit#destroyAll
             * @methodOf lbServices.Visit.coBuyersVisit
             *
             * @description
             *
             * Deletes all coBuyersVisit of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.coBuyersVisit.destroyAll = function() {
          var TargetResource = $injector.get("VisitCoBuyer");
          var action = TargetResource["::delete::Visit::coBuyersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyersVisit#destroyById
             * @methodOf lbServices.Visit.coBuyersVisit
             *
             * @description
             *
             * Delete a related item by id for coBuyersVisit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for coBuyersVisit
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.coBuyersVisit.destroyById = function() {
          var TargetResource = $injector.get("VisitCoBuyer");
          var action = TargetResource["::destroyById::Visit::coBuyersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyersVisit#findById
             * @methodOf lbServices.Visit.coBuyersVisit
             *
             * @description
             *
             * Find a related item by id for coBuyersVisit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for coBuyersVisit
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R.coBuyersVisit.findById = function() {
          var TargetResource = $injector.get("VisitCoBuyer");
          var action = TargetResource["::findById::Visit::coBuyersVisit"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Visit.coBuyersVisit#updateById
             * @methodOf lbServices.Visit.coBuyersVisit
             *
             * @description
             *
             * Update a related item by id for coBuyersVisit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Visit id
             *
             *  - `fk` – `{*}` - Foreign key for coBuyersVisit
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R.coBuyersVisit.updateById = function() {
          var TargetResource = $injector.get("VisitCoBuyer");
          var action = TargetResource["::updateById::Visit::coBuyersVisit"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Property
 * @header lbServices.Property
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Property` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Property",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Properties/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Property#prototype$__get__address
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Fetches hasOne relation address.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "prototype$__get__address": {
              url: urlBase + "/Properties/:id/address",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#prototype$__create__address
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Creates a new instance in address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "prototype$__create__address": {
              url: urlBase + "/Properties/:id/address",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#prototype$__update__address
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Update address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "prototype$__update__address": {
              url: urlBase + "/Properties/:id/address",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#prototype$__destroy__address
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Deletes address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroy__address": {
              url: urlBase + "/Properties/:id/address",
              method: "DELETE",
            },

            // INTERNAL. Use Property.dimensionsMU() instead.
            "prototype$__get__dimensionsMU": {
              url: urlBase + "/Properties/:id/dimensionsMU",
              method: "GET",
            },

            // INTERNAL. Use Property.areasMU() instead.
            "prototype$__get__areasMU": {
              url: urlBase + "/Properties/:id/areasMU",
              method: "GET",
            },

            // INTERNAL. Use Property.lotAreaMU() instead.
            "prototype$__get__lotAreaMU": {
              url: urlBase + "/Properties/:id/lotAreaMU",
              method: "GET",
            },

            // INTERNAL. Use Property.lotDimensionMU() instead.
            "prototype$__get__lotDimensionMU": {
              url: urlBase + "/Properties/:id/lotDimensionMU",
              method: "GET",
            },

            // INTERNAL. Use Property.styles.findById() instead.
            "prototype$__findById__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.styles.destroyById() instead.
            "prototype$__destroyById__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.styles.updateById() instead.
            "prototype$__updateById__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.styles.link() instead.
            "prototype$__link__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.styles.unlink() instead.
            "prototype$__unlink__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.styles.exists() instead.
            "prototype$__exists__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Property.type() instead.
            "prototype$__get__type": {
              url: urlBase + "/Properties/:id/type",
              method: "GET",
            },

            // INTERNAL. Use Property.buildingType() instead.
            "prototype$__get__buildingType": {
              url: urlBase + "/Properties/:id/buildingType",
              method: "GET",
            },

            // INTERNAL. Use Property.costs.findById() instead.
            "prototype$__findById__costs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/costs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.costs.destroyById() instead.
            "prototype$__destroyById__costs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/costs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.costs.updateById() instead.
            "prototype$__updateById__costs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/costs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.characteristics.findById() instead.
            "prototype$__findById__characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/characteristics/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.characteristics.destroyById() instead.
            "prototype$__destroyById__characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/characteristics/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.characteristics.updateById() instead.
            "prototype$__updateById__characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/characteristics/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.rooms.findById() instead.
            "prototype$__findById__rooms": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/rooms/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.rooms.destroyById() instead.
            "prototype$__destroyById__rooms": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/rooms/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.rooms.updateById() instead.
            "prototype$__updateById__rooms": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/rooms/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.features.findById() instead.
            "prototype$__findById__features": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/features/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.features.destroyById() instead.
            "prototype$__destroyById__features": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/features/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.features.updateById() instead.
            "prototype$__updateById__features": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/features/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/Properties/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Property.propertyCodes.findById() instead.
            "prototype$__findById__propertyCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/propertyCodes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.propertyCodes.destroyById() instead.
            "prototype$__destroyById__propertyCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/propertyCodes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.propertyCodes.updateById() instead.
            "prototype$__updateById__propertyCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/propertyCodes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.codes.findById() instead.
            "prototype$__findById__codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.codes.destroyById() instead.
            "prototype$__destroyById__codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.codes.updateById() instead.
            "prototype$__updateById__codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.codes.link() instead.
            "prototype$__link__codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.codes.unlink() instead.
            "prototype$__unlink__codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.codes.exists() instead.
            "prototype$__exists__codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Property.additionalBuildings.findById() instead.
            "prototype$__findById__additionalBuildings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/additionalBuildings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.additionalBuildings.destroyById() instead.
            "prototype$__destroyById__additionalBuildings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/additionalBuildings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.additionalBuildings.updateById() instead.
            "prototype$__updateById__additionalBuildings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/additionalBuildings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.styles() instead.
            "prototype$__get__styles": {
              isArray: true,
              url: urlBase + "/Properties/:id/styles",
              method: "GET",
            },

            // INTERNAL. Use Property.styles.create() instead.
            "prototype$__create__styles": {
              url: urlBase + "/Properties/:id/styles",
              method: "POST",
            },

            // INTERNAL. Use Property.styles.destroyAll() instead.
            "prototype$__delete__styles": {
              url: urlBase + "/Properties/:id/styles",
              method: "DELETE",
            },

            // INTERNAL. Use Property.styles.count() instead.
            "prototype$__count__styles": {
              url: urlBase + "/Properties/:id/styles/count",
              method: "GET",
            },

            // INTERNAL. Use Property.costs() instead.
            "prototype$__get__costs": {
              isArray: true,
              url: urlBase + "/Properties/:id/costs",
              method: "GET",
            },

            // INTERNAL. Use Property.costs.create() instead.
            "prototype$__create__costs": {
              url: urlBase + "/Properties/:id/costs",
              method: "POST",
            },

            // INTERNAL. Use Property.costs.destroyAll() instead.
            "prototype$__delete__costs": {
              url: urlBase + "/Properties/:id/costs",
              method: "DELETE",
            },

            // INTERNAL. Use Property.costs.count() instead.
            "prototype$__count__costs": {
              url: urlBase + "/Properties/:id/costs/count",
              method: "GET",
            },

            // INTERNAL. Use Property.characteristics() instead.
            "prototype$__get__characteristics": {
              isArray: true,
              url: urlBase + "/Properties/:id/characteristics",
              method: "GET",
            },

            // INTERNAL. Use Property.characteristics.create() instead.
            "prototype$__create__characteristics": {
              url: urlBase + "/Properties/:id/characteristics",
              method: "POST",
            },

            // INTERNAL. Use Property.characteristics.destroyAll() instead.
            "prototype$__delete__characteristics": {
              url: urlBase + "/Properties/:id/characteristics",
              method: "DELETE",
            },

            // INTERNAL. Use Property.characteristics.count() instead.
            "prototype$__count__characteristics": {
              url: urlBase + "/Properties/:id/characteristics/count",
              method: "GET",
            },

            // INTERNAL. Use Property.rooms() instead.
            "prototype$__get__rooms": {
              isArray: true,
              url: urlBase + "/Properties/:id/rooms",
              method: "GET",
            },

            // INTERNAL. Use Property.rooms.create() instead.
            "prototype$__create__rooms": {
              url: urlBase + "/Properties/:id/rooms",
              method: "POST",
            },

            // INTERNAL. Use Property.rooms.destroyAll() instead.
            "prototype$__delete__rooms": {
              url: urlBase + "/Properties/:id/rooms",
              method: "DELETE",
            },

            // INTERNAL. Use Property.rooms.count() instead.
            "prototype$__count__rooms": {
              url: urlBase + "/Properties/:id/rooms/count",
              method: "GET",
            },

            // INTERNAL. Use Property.features() instead.
            "prototype$__get__features": {
              isArray: true,
              url: urlBase + "/Properties/:id/features",
              method: "GET",
            },

            // INTERNAL. Use Property.features.create() instead.
            "prototype$__create__features": {
              url: urlBase + "/Properties/:id/features",
              method: "POST",
            },

            // INTERNAL. Use Property.features.destroyAll() instead.
            "prototype$__delete__features": {
              url: urlBase + "/Properties/:id/features",
              method: "DELETE",
            },

            // INTERNAL. Use Property.features.count() instead.
            "prototype$__count__features": {
              url: urlBase + "/Properties/:id/features/count",
              method: "GET",
            },

            // INTERNAL. Use Property.propertyCodes() instead.
            "prototype$__get__propertyCodes": {
              isArray: true,
              url: urlBase + "/Properties/:id/propertyCodes",
              method: "GET",
            },

            // INTERNAL. Use Property.propertyCodes.create() instead.
            "prototype$__create__propertyCodes": {
              url: urlBase + "/Properties/:id/propertyCodes",
              method: "POST",
            },

            // INTERNAL. Use Property.propertyCodes.destroyAll() instead.
            "prototype$__delete__propertyCodes": {
              url: urlBase + "/Properties/:id/propertyCodes",
              method: "DELETE",
            },

            // INTERNAL. Use Property.propertyCodes.count() instead.
            "prototype$__count__propertyCodes": {
              url: urlBase + "/Properties/:id/propertyCodes/count",
              method: "GET",
            },

            // INTERNAL. Use Property.codes() instead.
            "prototype$__get__codes": {
              isArray: true,
              url: urlBase + "/Properties/:id/codes",
              method: "GET",
            },

            // INTERNAL. Use Property.codes.create() instead.
            "prototype$__create__codes": {
              url: urlBase + "/Properties/:id/codes",
              method: "POST",
            },

            // INTERNAL. Use Property.codes.destroyAll() instead.
            "prototype$__delete__codes": {
              url: urlBase + "/Properties/:id/codes",
              method: "DELETE",
            },

            // INTERNAL. Use Property.codes.count() instead.
            "prototype$__count__codes": {
              url: urlBase + "/Properties/:id/codes/count",
              method: "GET",
            },

            // INTERNAL. Use Property.additionalBuildings() instead.
            "prototype$__get__additionalBuildings": {
              isArray: true,
              url: urlBase + "/Properties/:id/additionalBuildings",
              method: "GET",
            },

            // INTERNAL. Use Property.additionalBuildings.create() instead.
            "prototype$__create__additionalBuildings": {
              url: urlBase + "/Properties/:id/additionalBuildings",
              method: "POST",
            },

            // INTERNAL. Use Property.additionalBuildings.destroyAll() instead.
            "prototype$__delete__additionalBuildings": {
              url: urlBase + "/Properties/:id/additionalBuildings",
              method: "DELETE",
            },

            // INTERNAL. Use Property.additionalBuildings.count() instead.
            "prototype$__count__additionalBuildings": {
              url: urlBase + "/Properties/:id/additionalBuildings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#create
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Properties",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#patchOrCreate
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Properties",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#replaceOrCreate
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Properties/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#upsertWithWhere
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Properties/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#exists
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Properties/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#findById
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Properties/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#replaceById
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Properties/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#find
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Properties",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#findOne
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Properties/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#updateAll
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Properties/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#deleteById
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Properties/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#count
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Properties/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#prototype$patchAttributes
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Properties/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#createChangeStream
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Properties/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Property#createMany
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Properties",
              method: "POST",
            },

            // INTERNAL. Use Code.properties.findById() instead.
            "::findById::Code::properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/:fk",
              method: "GET",
            },

            // INTERNAL. Use Code.properties.destroyById() instead.
            "::destroyById::Code::properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Code.properties.updateById() instead.
            "::updateById::Code::properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Code.properties.link() instead.
            "::link::Code::properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Code.properties.unlink() instead.
            "::unlink::Code::properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Code.properties.exists() instead.
            "::exists::Code::properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Code.properties() instead.
            "::get::Code::properties": {
              isArray: true,
              url: urlBase + "/Codes/:id/properties",
              method: "GET",
            },

            // INTERNAL. Use Code.properties.create() instead.
            "::create::Code::properties": {
              url: urlBase + "/Codes/:id/properties",
              method: "POST",
            },

            // INTERNAL. Use Code.properties.createMany() instead.
            "::createMany::Code::properties": {
              isArray: true,
              url: urlBase + "/Codes/:id/properties",
              method: "POST",
            },

            // INTERNAL. Use Code.properties.destroyAll() instead.
            "::delete::Code::properties": {
              url: urlBase + "/Codes/:id/properties",
              method: "DELETE",
            },

            // INTERNAL. Use Code.properties.count() instead.
            "::count::Code::properties": {
              url: urlBase + "/Codes/:id/properties/count",
              method: "GET",
            },

            // INTERNAL. Use Cost.property() instead.
            "::get::Cost::property": {
              url: urlBase + "/Costs/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Characteristic.property() instead.
            "::get::Characteristic::property": {
              url: urlBase + "/Characteristics/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Room.property() instead.
            "::get::Room::property": {
              url: urlBase + "/Rooms/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Feature.property() instead.
            "::get::Feature::property": {
              url: urlBase + "/Features/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Listing.property() instead.
            "::get::Listing::property": {
              url: urlBase + "/Listings/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Listing.property.create() instead.
            "::create::Listing::property": {
              url: urlBase + "/Listings/:id/property",
              method: "POST",
            },

            // INTERNAL. Use Listing.property.createMany() instead.
            "::createMany::Listing::property": {
              isArray: true,
              url: urlBase + "/Listings/:id/property",
              method: "POST",
            },

            // INTERNAL. Use Listing.property.update() instead.
            "::update::Listing::property": {
              url: urlBase + "/Listings/:id/property",
              method: "PUT",
            },

            // INTERNAL. Use Listing.property.destroy() instead.
            "::destroy::Listing::property": {
              url: urlBase + "/Listings/:id/property",
              method: "DELETE",
            },

            // INTERNAL. Use PropertyCode.property() instead.
            "::get::PropertyCode::property": {
              url: urlBase + "/PropertyCodes/:id/property",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.property() instead.
            "::get::AdditionalBuilding::property": {
              url: urlBase + "/AdditionalBuildings/:id/property",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Property#upsert
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Property#updateOrCreate
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Property#patchOrCreateWithWhere
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Property#update
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Property#destroyById
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Property#removeById
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Property#prototype$updateAttributes
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Property#modelName
        * @propertyOf lbServices.Property
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Property`.
        */
        R.modelName = "Property";


            /**
             * @ngdoc method
             * @name lbServices.Property#dimensionsMU
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Fetches belongsTo relation dimensionsMU.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.dimensionsMU = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Property::dimensionsMU"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property#areasMU
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Fetches belongsTo relation areasMU.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.areasMU = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Property::areasMU"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property#lotAreaMU
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Fetches belongsTo relation lotAreaMU.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.lotAreaMU = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Property::lotAreaMU"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property#lotDimensionMU
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Fetches belongsTo relation lotDimensionMU.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.lotDimensionMU = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Property::lotDimensionMU"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Property.styles
     * @header lbServices.Property.styles
     * @object
     * @description
     *
     * The object `Property.styles` groups methods
     * manipulating `Code` instances related to `Property`.
     *
     * Call {@link lbServices.Property#styles Property.styles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Property#styles
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Queries styles of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#count
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Counts styles of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.styles.count = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::count::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#create
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Creates a new instance in styles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.create = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::create::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#createMany
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Creates a new instance in styles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.createMany = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::createMany::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#destroyAll
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Deletes all styles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.styles.destroyAll = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::delete::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#destroyById
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Delete a related item by id for styles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.styles.destroyById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::destroyById::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#exists
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Check the existence of styles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.exists = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::exists::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#findById
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Find a related item by id for styles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.findById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::findById::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#link
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Add a related item by id for styles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.link = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::link::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#unlink
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Remove the styles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.styles.unlink = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::unlink::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.styles#updateById
             * @methodOf lbServices.Property.styles
             *
             * @description
             *
             * Update a related item by id for styles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.updateById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::updateById::Property::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property#type
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Fetches belongsTo relation type.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.type = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Property::type"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property#buildingType
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Fetches belongsTo relation buildingType.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.buildingType = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Property::buildingType"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Property.costs
     * @header lbServices.Property.costs
     * @object
     * @description
     *
     * The object `Property.costs` groups methods
     * manipulating `Cost` instances related to `Property`.
     *
     * Call {@link lbServices.Property#costs Property.costs()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Property#costs
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Queries costs of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R.costs = function() {
          var TargetResource = $injector.get("Cost");
          var action = TargetResource["::get::Property::costs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.costs#count
             * @methodOf lbServices.Property.costs
             *
             * @description
             *
             * Counts costs of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.costs.count = function() {
          var TargetResource = $injector.get("Cost");
          var action = TargetResource["::count::Property::costs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.costs#create
             * @methodOf lbServices.Property.costs
             *
             * @description
             *
             * Creates a new instance in costs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R.costs.create = function() {
          var TargetResource = $injector.get("Cost");
          var action = TargetResource["::create::Property::costs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.costs#createMany
             * @methodOf lbServices.Property.costs
             *
             * @description
             *
             * Creates a new instance in costs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R.costs.createMany = function() {
          var TargetResource = $injector.get("Cost");
          var action = TargetResource["::createMany::Property::costs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.costs#destroyAll
             * @methodOf lbServices.Property.costs
             *
             * @description
             *
             * Deletes all costs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.costs.destroyAll = function() {
          var TargetResource = $injector.get("Cost");
          var action = TargetResource["::delete::Property::costs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.costs#destroyById
             * @methodOf lbServices.Property.costs
             *
             * @description
             *
             * Delete a related item by id for costs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for costs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.costs.destroyById = function() {
          var TargetResource = $injector.get("Cost");
          var action = TargetResource["::destroyById::Property::costs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.costs#findById
             * @methodOf lbServices.Property.costs
             *
             * @description
             *
             * Find a related item by id for costs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for costs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R.costs.findById = function() {
          var TargetResource = $injector.get("Cost");
          var action = TargetResource["::findById::Property::costs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.costs#updateById
             * @methodOf lbServices.Property.costs
             *
             * @description
             *
             * Update a related item by id for costs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for costs
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R.costs.updateById = function() {
          var TargetResource = $injector.get("Cost");
          var action = TargetResource["::updateById::Property::costs"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Property.characteristics
     * @header lbServices.Property.characteristics
     * @object
     * @description
     *
     * The object `Property.characteristics` groups methods
     * manipulating `Characteristic` instances related to `Property`.
     *
     * Call {@link lbServices.Property#characteristics Property.characteristics()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Property#characteristics
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Queries characteristics of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::get::Property::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.characteristics#count
             * @methodOf lbServices.Property.characteristics
             *
             * @description
             *
             * Counts characteristics of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.characteristics.count = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::count::Property::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.characteristics#create
             * @methodOf lbServices.Property.characteristics
             *
             * @description
             *
             * Creates a new instance in characteristics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics.create = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::create::Property::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.characteristics#createMany
             * @methodOf lbServices.Property.characteristics
             *
             * @description
             *
             * Creates a new instance in characteristics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics.createMany = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::createMany::Property::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.characteristics#destroyAll
             * @methodOf lbServices.Property.characteristics
             *
             * @description
             *
             * Deletes all characteristics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.characteristics.destroyAll = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::delete::Property::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.characteristics#destroyById
             * @methodOf lbServices.Property.characteristics
             *
             * @description
             *
             * Delete a related item by id for characteristics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for characteristics
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.characteristics.destroyById = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::destroyById::Property::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.characteristics#findById
             * @methodOf lbServices.Property.characteristics
             *
             * @description
             *
             * Find a related item by id for characteristics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for characteristics
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics.findById = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::findById::Property::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.characteristics#updateById
             * @methodOf lbServices.Property.characteristics
             *
             * @description
             *
             * Update a related item by id for characteristics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for characteristics
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics.updateById = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::updateById::Property::characteristics"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Property.rooms
     * @header lbServices.Property.rooms
     * @object
     * @description
     *
     * The object `Property.rooms` groups methods
     * manipulating `Room` instances related to `Property`.
     *
     * Call {@link lbServices.Property#rooms Property.rooms()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Property#rooms
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Queries rooms of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R.rooms = function() {
          var TargetResource = $injector.get("Room");
          var action = TargetResource["::get::Property::rooms"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.rooms#count
             * @methodOf lbServices.Property.rooms
             *
             * @description
             *
             * Counts rooms of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.rooms.count = function() {
          var TargetResource = $injector.get("Room");
          var action = TargetResource["::count::Property::rooms"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.rooms#create
             * @methodOf lbServices.Property.rooms
             *
             * @description
             *
             * Creates a new instance in rooms of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R.rooms.create = function() {
          var TargetResource = $injector.get("Room");
          var action = TargetResource["::create::Property::rooms"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.rooms#createMany
             * @methodOf lbServices.Property.rooms
             *
             * @description
             *
             * Creates a new instance in rooms of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R.rooms.createMany = function() {
          var TargetResource = $injector.get("Room");
          var action = TargetResource["::createMany::Property::rooms"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.rooms#destroyAll
             * @methodOf lbServices.Property.rooms
             *
             * @description
             *
             * Deletes all rooms of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.rooms.destroyAll = function() {
          var TargetResource = $injector.get("Room");
          var action = TargetResource["::delete::Property::rooms"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.rooms#destroyById
             * @methodOf lbServices.Property.rooms
             *
             * @description
             *
             * Delete a related item by id for rooms.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for rooms
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.rooms.destroyById = function() {
          var TargetResource = $injector.get("Room");
          var action = TargetResource["::destroyById::Property::rooms"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.rooms#findById
             * @methodOf lbServices.Property.rooms
             *
             * @description
             *
             * Find a related item by id for rooms.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for rooms
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R.rooms.findById = function() {
          var TargetResource = $injector.get("Room");
          var action = TargetResource["::findById::Property::rooms"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.rooms#updateById
             * @methodOf lbServices.Property.rooms
             *
             * @description
             *
             * Update a related item by id for rooms.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for rooms
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R.rooms.updateById = function() {
          var TargetResource = $injector.get("Room");
          var action = TargetResource["::updateById::Property::rooms"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Property.features
     * @header lbServices.Property.features
     * @object
     * @description
     *
     * The object `Property.features` groups methods
     * manipulating `Feature` instances related to `Property`.
     *
     * Call {@link lbServices.Property#features Property.features()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Property#features
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Queries features of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R.features = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::get::Property::features"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.features#count
             * @methodOf lbServices.Property.features
             *
             * @description
             *
             * Counts features of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.features.count = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::count::Property::features"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.features#create
             * @methodOf lbServices.Property.features
             *
             * @description
             *
             * Creates a new instance in features of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R.features.create = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::create::Property::features"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.features#createMany
             * @methodOf lbServices.Property.features
             *
             * @description
             *
             * Creates a new instance in features of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R.features.createMany = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::createMany::Property::features"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.features#destroyAll
             * @methodOf lbServices.Property.features
             *
             * @description
             *
             * Deletes all features of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.features.destroyAll = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::delete::Property::features"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.features#destroyById
             * @methodOf lbServices.Property.features
             *
             * @description
             *
             * Delete a related item by id for features.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for features
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.features.destroyById = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::destroyById::Property::features"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.features#findById
             * @methodOf lbServices.Property.features
             *
             * @description
             *
             * Find a related item by id for features.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for features
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R.features.findById = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::findById::Property::features"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.features#updateById
             * @methodOf lbServices.Property.features
             *
             * @description
             *
             * Update a related item by id for features.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for features
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R.features.updateById = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::updateById::Property::features"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property#listing
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Property::listing"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Property.propertyCodes
     * @header lbServices.Property.propertyCodes
     * @object
     * @description
     *
     * The object `Property.propertyCodes` groups methods
     * manipulating `PropertyCode` instances related to `Property`.
     *
     * Call {@link lbServices.Property#propertyCodes Property.propertyCodes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Property#propertyCodes
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Queries propertyCodes of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R.propertyCodes = function() {
          var TargetResource = $injector.get("PropertyCode");
          var action = TargetResource["::get::Property::propertyCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.propertyCodes#count
             * @methodOf lbServices.Property.propertyCodes
             *
             * @description
             *
             * Counts propertyCodes of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.propertyCodes.count = function() {
          var TargetResource = $injector.get("PropertyCode");
          var action = TargetResource["::count::Property::propertyCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.propertyCodes#create
             * @methodOf lbServices.Property.propertyCodes
             *
             * @description
             *
             * Creates a new instance in propertyCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R.propertyCodes.create = function() {
          var TargetResource = $injector.get("PropertyCode");
          var action = TargetResource["::create::Property::propertyCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.propertyCodes#createMany
             * @methodOf lbServices.Property.propertyCodes
             *
             * @description
             *
             * Creates a new instance in propertyCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R.propertyCodes.createMany = function() {
          var TargetResource = $injector.get("PropertyCode");
          var action = TargetResource["::createMany::Property::propertyCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.propertyCodes#destroyAll
             * @methodOf lbServices.Property.propertyCodes
             *
             * @description
             *
             * Deletes all propertyCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.propertyCodes.destroyAll = function() {
          var TargetResource = $injector.get("PropertyCode");
          var action = TargetResource["::delete::Property::propertyCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.propertyCodes#destroyById
             * @methodOf lbServices.Property.propertyCodes
             *
             * @description
             *
             * Delete a related item by id for propertyCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for propertyCodes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.propertyCodes.destroyById = function() {
          var TargetResource = $injector.get("PropertyCode");
          var action = TargetResource["::destroyById::Property::propertyCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.propertyCodes#findById
             * @methodOf lbServices.Property.propertyCodes
             *
             * @description
             *
             * Find a related item by id for propertyCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for propertyCodes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R.propertyCodes.findById = function() {
          var TargetResource = $injector.get("PropertyCode");
          var action = TargetResource["::findById::Property::propertyCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.propertyCodes#updateById
             * @methodOf lbServices.Property.propertyCodes
             *
             * @description
             *
             * Update a related item by id for propertyCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for propertyCodes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R.propertyCodes.updateById = function() {
          var TargetResource = $injector.get("PropertyCode");
          var action = TargetResource["::updateById::Property::propertyCodes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Property.codes
     * @header lbServices.Property.codes
     * @object
     * @description
     *
     * The object `Property.codes` groups methods
     * manipulating `Code` instances related to `Property`.
     *
     * Call {@link lbServices.Property#codes Property.codes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Property#codes
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Queries codes of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.codes = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#count
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Counts codes of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.codes.count = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::count::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#create
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Creates a new instance in codes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.codes.create = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::create::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#createMany
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Creates a new instance in codes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.codes.createMany = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::createMany::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#destroyAll
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Deletes all codes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.codes.destroyAll = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::delete::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#destroyById
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Delete a related item by id for codes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for codes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.codes.destroyById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::destroyById::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#exists
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Check the existence of codes relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for codes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.codes.exists = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::exists::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#findById
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Find a related item by id for codes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for codes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.codes.findById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::findById::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#link
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Add a related item by id for codes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for codes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.codes.link = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::link::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#unlink
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Remove the codes relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for codes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.codes.unlink = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::unlink::Property::codes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.codes#updateById
             * @methodOf lbServices.Property.codes
             *
             * @description
             *
             * Update a related item by id for codes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for codes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.codes.updateById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::updateById::Property::codes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Property.additionalBuildings
     * @header lbServices.Property.additionalBuildings
     * @object
     * @description
     *
     * The object `Property.additionalBuildings` groups methods
     * manipulating `AdditionalBuilding` instances related to `Property`.
     *
     * Call {@link lbServices.Property#additionalBuildings Property.additionalBuildings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Property#additionalBuildings
             * @methodOf lbServices.Property
             *
             * @description
             *
             * Queries additionalBuildings of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R.additionalBuildings = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::get::Property::additionalBuildings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.additionalBuildings#count
             * @methodOf lbServices.Property.additionalBuildings
             *
             * @description
             *
             * Counts additionalBuildings of Property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.additionalBuildings.count = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::count::Property::additionalBuildings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.additionalBuildings#create
             * @methodOf lbServices.Property.additionalBuildings
             *
             * @description
             *
             * Creates a new instance in additionalBuildings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R.additionalBuildings.create = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::create::Property::additionalBuildings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.additionalBuildings#createMany
             * @methodOf lbServices.Property.additionalBuildings
             *
             * @description
             *
             * Creates a new instance in additionalBuildings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R.additionalBuildings.createMany = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::createMany::Property::additionalBuildings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.additionalBuildings#destroyAll
             * @methodOf lbServices.Property.additionalBuildings
             *
             * @description
             *
             * Deletes all additionalBuildings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.additionalBuildings.destroyAll = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::delete::Property::additionalBuildings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.additionalBuildings#destroyById
             * @methodOf lbServices.Property.additionalBuildings
             *
             * @description
             *
             * Delete a related item by id for additionalBuildings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for additionalBuildings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.additionalBuildings.destroyById = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::destroyById::Property::additionalBuildings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.additionalBuildings#findById
             * @methodOf lbServices.Property.additionalBuildings
             *
             * @description
             *
             * Find a related item by id for additionalBuildings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for additionalBuildings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R.additionalBuildings.findById = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::findById::Property::additionalBuildings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Property.additionalBuildings#updateById
             * @methodOf lbServices.Property.additionalBuildings
             *
             * @description
             *
             * Update a related item by id for additionalBuildings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Property id
             *
             *  - `fk` – `{*}` - Foreign key for additionalBuildings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R.additionalBuildings.updateById = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::updateById::Property::additionalBuildings"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Code
 * @header lbServices.Code
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Code` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Code",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Codes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Code.properties.findById() instead.
            "prototype$__findById__properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/:fk",
              method: "GET",
            },

            // INTERNAL. Use Code.properties.destroyById() instead.
            "prototype$__destroyById__properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Code.properties.updateById() instead.
            "prototype$__updateById__properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Code.properties.link() instead.
            "prototype$__link__properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Code.properties.unlink() instead.
            "prototype$__unlink__properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Code.properties.exists() instead.
            "prototype$__exists__properties": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Codes/:id/properties/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Code.properties() instead.
            "prototype$__get__properties": {
              isArray: true,
              url: urlBase + "/Codes/:id/properties",
              method: "GET",
            },

            // INTERNAL. Use Code.properties.create() instead.
            "prototype$__create__properties": {
              url: urlBase + "/Codes/:id/properties",
              method: "POST",
            },

            // INTERNAL. Use Code.properties.destroyAll() instead.
            "prototype$__delete__properties": {
              url: urlBase + "/Codes/:id/properties",
              method: "DELETE",
            },

            // INTERNAL. Use Code.properties.count() instead.
            "prototype$__count__properties": {
              url: urlBase + "/Codes/:id/properties/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#create
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Codes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#patchOrCreate
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Codes",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#replaceOrCreate
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Codes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#upsertWithWhere
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Codes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#exists
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Codes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#findById
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Codes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#replaceById
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Codes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#find
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Codes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#findOne
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Codes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#updateAll
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Codes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#deleteById
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Codes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#count
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Codes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#prototype$patchAttributes
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Codes/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#createChangeStream
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Codes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Code#createMany
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Codes",
              method: "POST",
            },

            // INTERNAL. Use Buyer.propertyTypes.findById() instead.
            "::findById::Buyer::propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.propertyTypes.destroyById() instead.
            "::destroyById::Buyer::propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.propertyTypes.updateById() instead.
            "::updateById::Buyer::propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.propertyTypes.link() instead.
            "::link::Buyer::propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.propertyTypes.unlink() instead.
            "::unlink::Buyer::propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.propertyTypes.exists() instead.
            "::exists::Buyer::propertyTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/propertyTypes/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.propertyTypes() instead.
            "::get::Buyer::propertyTypes": {
              isArray: true,
              url: urlBase + "/Buyers/:id/propertyTypes",
              method: "GET",
            },

            // INTERNAL. Use Buyer.propertyTypes.create() instead.
            "::create::Buyer::propertyTypes": {
              url: urlBase + "/Buyers/:id/propertyTypes",
              method: "POST",
            },

            // INTERNAL. Use Buyer.propertyTypes.createMany() instead.
            "::createMany::Buyer::propertyTypes": {
              isArray: true,
              url: urlBase + "/Buyers/:id/propertyTypes",
              method: "POST",
            },

            // INTERNAL. Use Buyer.propertyTypes.destroyAll() instead.
            "::delete::Buyer::propertyTypes": {
              url: urlBase + "/Buyers/:id/propertyTypes",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.propertyTypes.count() instead.
            "::count::Buyer::propertyTypes": {
              url: urlBase + "/Buyers/:id/propertyTypes/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.certificateType() instead.
            "::get::Agency::certificateType": {
              url: urlBase + "/Agencies/:id/certificateType",
              method: "GET",
            },

            // INTERNAL. Use Property.dimensionsMU() instead.
            "::get::Property::dimensionsMU": {
              url: urlBase + "/Properties/:id/dimensionsMU",
              method: "GET",
            },

            // INTERNAL. Use Property.areasMU() instead.
            "::get::Property::areasMU": {
              url: urlBase + "/Properties/:id/areasMU",
              method: "GET",
            },

            // INTERNAL. Use Property.lotAreaMU() instead.
            "::get::Property::lotAreaMU": {
              url: urlBase + "/Properties/:id/lotAreaMU",
              method: "GET",
            },

            // INTERNAL. Use Property.lotDimensionMU() instead.
            "::get::Property::lotDimensionMU": {
              url: urlBase + "/Properties/:id/lotDimensionMU",
              method: "GET",
            },

            // INTERNAL. Use Property.styles.findById() instead.
            "::findById::Property::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.styles.destroyById() instead.
            "::destroyById::Property::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.styles.updateById() instead.
            "::updateById::Property::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.styles.link() instead.
            "::link::Property::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.styles.unlink() instead.
            "::unlink::Property::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.styles.exists() instead.
            "::exists::Property::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/styles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Property.type() instead.
            "::get::Property::type": {
              url: urlBase + "/Properties/:id/type",
              method: "GET",
            },

            // INTERNAL. Use Property.buildingType() instead.
            "::get::Property::buildingType": {
              url: urlBase + "/Properties/:id/buildingType",
              method: "GET",
            },

            // INTERNAL. Use Property.codes.findById() instead.
            "::findById::Property::codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.codes.destroyById() instead.
            "::destroyById::Property::codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.codes.updateById() instead.
            "::updateById::Property::codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.codes.link() instead.
            "::link::Property::codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.codes.unlink() instead.
            "::unlink::Property::codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.codes.exists() instead.
            "::exists::Property::codes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/codes/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Property.styles() instead.
            "::get::Property::styles": {
              isArray: true,
              url: urlBase + "/Properties/:id/styles",
              method: "GET",
            },

            // INTERNAL. Use Property.styles.create() instead.
            "::create::Property::styles": {
              url: urlBase + "/Properties/:id/styles",
              method: "POST",
            },

            // INTERNAL. Use Property.styles.createMany() instead.
            "::createMany::Property::styles": {
              isArray: true,
              url: urlBase + "/Properties/:id/styles",
              method: "POST",
            },

            // INTERNAL. Use Property.styles.destroyAll() instead.
            "::delete::Property::styles": {
              url: urlBase + "/Properties/:id/styles",
              method: "DELETE",
            },

            // INTERNAL. Use Property.styles.count() instead.
            "::count::Property::styles": {
              url: urlBase + "/Properties/:id/styles/count",
              method: "GET",
            },

            // INTERNAL. Use Property.codes() instead.
            "::get::Property::codes": {
              isArray: true,
              url: urlBase + "/Properties/:id/codes",
              method: "GET",
            },

            // INTERNAL. Use Property.codes.create() instead.
            "::create::Property::codes": {
              url: urlBase + "/Properties/:id/codes",
              method: "POST",
            },

            // INTERNAL. Use Property.codes.createMany() instead.
            "::createMany::Property::codes": {
              isArray: true,
              url: urlBase + "/Properties/:id/codes",
              method: "POST",
            },

            // INTERNAL. Use Property.codes.destroyAll() instead.
            "::delete::Property::codes": {
              url: urlBase + "/Properties/:id/codes",
              method: "DELETE",
            },

            // INTERNAL. Use Property.codes.count() instead.
            "::count::Property::codes": {
              url: urlBase + "/Properties/:id/codes/count",
              method: "GET",
            },

            // INTERNAL. Use Cost.type() instead.
            "::get::Cost::type": {
              url: urlBase + "/Costs/:id/type",
              method: "GET",
            },

            // INTERNAL. Use Room.type() instead.
            "::get::Room::type": {
              url: urlBase + "/Rooms/:id/type",
              method: "GET",
            },

            // INTERNAL. Use Room.floor() instead.
            "::get::Room::floor": {
              url: urlBase + "/Rooms/:id/floor",
              method: "GET",
            },

            // INTERNAL. Use Room.floorCover() instead.
            "::get::Room::floorCover": {
              url: urlBase + "/Rooms/:id/floorCover",
              method: "GET",
            },

            // INTERNAL. Use Feature.type() instead.
            "::get::Feature::type": {
              url: urlBase + "/Features/:id/type",
              method: "GET",
            },

            // INTERNAL. Use Feature.value() instead.
            "::get::Feature::value": {
              url: urlBase + "/Features/:id/value",
              method: "GET",
            },

            // INTERNAL. Use Supplier.industry() instead.
            "::get::Supplier::industry": {
              url: urlBase + "/Suppliers/:id/industry",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.documentType() instead.
            "::get::ListingDocument::documentType": {
              url: urlBase + "/ListingDocuments/:id/documentType",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.agencyRoleCode() instead.
            "::get::AgencyMember::agencyRoleCode": {
              url: urlBase + "/AgencyMembers/:id/agencyRoleCode",
              method: "GET",
            },

            // INTERNAL. Use Broker.licenseType() instead.
            "::get::Broker::licenseType": {
              url: urlBase + "/Brokers/:id/licenseType",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentTypes.findById() instead.
            "::findById::Listing::documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentTypes.destroyById() instead.
            "::destroyById::Listing::documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentTypes.updateById() instead.
            "::updateById::Listing::documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.documentTypes.link() instead.
            "::link::Listing::documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.documentTypes.unlink() instead.
            "::unlink::Listing::documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentTypes.exists() instead.
            "::exists::Listing::documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.documentTypes() instead.
            "::get::Listing::documentTypes": {
              isArray: true,
              url: urlBase + "/Listings/:id/documentTypes",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentTypes.create() instead.
            "::create::Listing::documentTypes": {
              url: urlBase + "/Listings/:id/documentTypes",
              method: "POST",
            },

            // INTERNAL. Use Listing.documentTypes.createMany() instead.
            "::createMany::Listing::documentTypes": {
              isArray: true,
              url: urlBase + "/Listings/:id/documentTypes",
              method: "POST",
            },

            // INTERNAL. Use Listing.documentTypes.destroyAll() instead.
            "::delete::Listing::documentTypes": {
              url: urlBase + "/Listings/:id/documentTypes",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentTypes.count() instead.
            "::count::Listing::documentTypes": {
              url: urlBase + "/Listings/:id/documentTypes/count",
              method: "GET",
            },

            // INTERNAL. Use PropertyCode.code() instead.
            "::get::PropertyCode::code": {
              url: urlBase + "/PropertyCodes/:id/code",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.buildingType() instead.
            "::get::AdditionalBuilding::buildingType": {
              url: urlBase + "/AdditionalBuildings/:id/buildingType",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.styles.findById() instead.
            "::findById::AdditionalBuilding::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/:fk",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.styles.destroyById() instead.
            "::destroyById::AdditionalBuilding::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.styles.updateById() instead.
            "::updateById::AdditionalBuilding::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AdditionalBuilding.styles.link() instead.
            "::link::AdditionalBuilding::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AdditionalBuilding.styles.unlink() instead.
            "::unlink::AdditionalBuilding::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.styles.exists() instead.
            "::exists::AdditionalBuilding::styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use AdditionalBuilding.styles() instead.
            "::get::AdditionalBuilding::styles": {
              isArray: true,
              url: urlBase + "/AdditionalBuildings/:id/styles",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.styles.create() instead.
            "::create::AdditionalBuilding::styles": {
              url: urlBase + "/AdditionalBuildings/:id/styles",
              method: "POST",
            },

            // INTERNAL. Use AdditionalBuilding.styles.createMany() instead.
            "::createMany::AdditionalBuilding::styles": {
              isArray: true,
              url: urlBase + "/AdditionalBuildings/:id/styles",
              method: "POST",
            },

            // INTERNAL. Use AdditionalBuilding.styles.destroyAll() instead.
            "::delete::AdditionalBuilding::styles": {
              url: urlBase + "/AdditionalBuildings/:id/styles",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.styles.count() instead.
            "::count::AdditionalBuilding::styles": {
              url: urlBase + "/AdditionalBuildings/:id/styles/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Code#upsert
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Code#updateOrCreate
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Code#patchOrCreateWithWhere
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Code#update
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Code#destroyById
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Code#removeById
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Code#prototype$updateAttributes
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Code#modelName
        * @propertyOf lbServices.Code
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Code`.
        */
        R.modelName = "Code";

    /**
     * @ngdoc object
     * @name lbServices.Code.properties
     * @header lbServices.Code.properties
     * @object
     * @description
     *
     * The object `Code.properties` groups methods
     * manipulating `Property` instances related to `Code`.
     *
     * Call {@link lbServices.Code#properties Code.properties()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Code#properties
             * @methodOf lbServices.Code
             *
             * @description
             *
             * Queries properties of Code.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.properties = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::get::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#count
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Counts properties of Code.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.properties.count = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::count::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#create
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Creates a new instance in properties of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.properties.create = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::create::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#createMany
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Creates a new instance in properties of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.properties.createMany = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::createMany::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#destroyAll
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Deletes all properties of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.properties.destroyAll = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::delete::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#destroyById
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Delete a related item by id for properties.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for properties
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.properties.destroyById = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::destroyById::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#exists
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Check the existence of properties relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for properties
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.properties.exists = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::exists::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#findById
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Find a related item by id for properties.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for properties
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.properties.findById = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::findById::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#link
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Add a related item by id for properties.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `fk` – `{*}` - Foreign key for properties
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.properties.link = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::link::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#unlink
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Remove the properties relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for properties
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.properties.unlink = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::unlink::Code::properties"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Code.properties#updateById
             * @methodOf lbServices.Code.properties
             *
             * @description
             *
             * Update a related item by id for properties.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Code id
             *
             *  - `fk` – `{*}` - Foreign key for properties
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.properties.updateById = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::updateById::Code::properties"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Task
 * @header lbServices.Task
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Task` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Task",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Tasks/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Task#prototype$__get__assignedTo
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Fetches belongsTo relation assignedTo.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "prototype$__get__assignedTo": {
              url: urlBase + "/Tasks/:id/assignedTo",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#prototype$__get__about
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Fetches belongsTo relation about.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "prototype$__get__about": {
              url: urlBase + "/Tasks/:id/about",
              method: "GET",
            },

            // INTERNAL. Use Task.model() instead.
            "prototype$__get__model": {
              url: urlBase + "/Tasks/:id/model",
              method: "GET",
            },

            // INTERNAL. Use Task.suppliers.findById() instead.
            "prototype$__findById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Task.suppliers.destroyById() instead.
            "prototype$__destroyById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Task.suppliers.updateById() instead.
            "prototype$__updateById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Task.suppliers.link() instead.
            "prototype$__link__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Task.suppliers.unlink() instead.
            "prototype$__unlink__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Task.suppliers.exists() instead.
            "prototype$__exists__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Task.comments.findById() instead.
            "prototype$__findById__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/comments/:fk",
              method: "GET",
            },

            // INTERNAL. Use Task.comments.destroyById() instead.
            "prototype$__destroyById__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/comments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Task.comments.updateById() instead.
            "prototype$__updateById__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/comments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Task.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Task.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Task.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Task.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/Tasks/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Task.suppliers() instead.
            "prototype$__get__suppliers": {
              isArray: true,
              url: urlBase + "/Tasks/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Task.suppliers.create() instead.
            "prototype$__create__suppliers": {
              url: urlBase + "/Tasks/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Task.suppliers.destroyAll() instead.
            "prototype$__delete__suppliers": {
              url: urlBase + "/Tasks/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Task.suppliers.count() instead.
            "prototype$__count__suppliers": {
              url: urlBase + "/Tasks/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Task.comments() instead.
            "prototype$__get__comments": {
              isArray: true,
              url: urlBase + "/Tasks/:id/comments",
              method: "GET",
            },

            // INTERNAL. Use Task.comments.create() instead.
            "prototype$__create__comments": {
              url: urlBase + "/Tasks/:id/comments",
              method: "POST",
            },

            // INTERNAL. Use Task.comments.destroyAll() instead.
            "prototype$__delete__comments": {
              url: urlBase + "/Tasks/:id/comments",
              method: "DELETE",
            },

            // INTERNAL. Use Task.comments.count() instead.
            "prototype$__count__comments": {
              url: urlBase + "/Tasks/:id/comments/count",
              method: "GET",
            },

            // INTERNAL. Use Task.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Tasks/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Task.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Tasks/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Task.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Tasks/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Task.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Tasks/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#create
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Tasks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#patchOrCreate
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Tasks",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#replaceOrCreate
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Tasks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#upsertWithWhere
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Tasks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#exists
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Tasks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#findById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Tasks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#replaceById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Tasks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#find
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Tasks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#findOne
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Tasks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#updateAll
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Tasks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#deleteById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Tasks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#count
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Tasks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#prototype$patchAttributes
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Tasks/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#createChangeStream
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Tasks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#uploadFile
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Uploads a file to a task
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "uploadFile": {
              url: urlBase + "/Tasks/:id/uploadFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#downloadFiles
             * @methodOf lbServices.Task
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fileIds` – `{string}` -
             *
             *  - `access_token` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/Tasks/:id/downloadFiles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#downloadCommentFiles
             * @methodOf lbServices.Task
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `commentId` – `{string}` -
             *
             *  - `fileIds` – `{string}` -
             *
             *  - `access_token` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadCommentFiles": {
              url: urlBase + "/Tasks/:id/comments/:commentId/downloadFiles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#downloadFile
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/Tasks/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#downloadFiles
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/Tasks/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#createMany
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Tasks",
              method: "POST",
            },

            // INTERNAL. Use Seller.tasksAbout.findById() instead.
            "::findById::Seller::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.tasksAbout.destroyById() instead.
            "::destroyById::Seller::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.tasksAbout.updateById() instead.
            "::updateById::Seller::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.tasksAbout() instead.
            "::get::Seller::tasksAbout": {
              isArray: true,
              url: urlBase + "/Sellers/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use Seller.tasksAbout.create() instead.
            "::create::Seller::tasksAbout": {
              url: urlBase + "/Sellers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Seller.tasksAbout.createMany() instead.
            "::createMany::Seller::tasksAbout": {
              isArray: true,
              url: urlBase + "/Sellers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Seller.tasksAbout.destroyAll() instead.
            "::delete::Seller::tasksAbout": {
              url: urlBase + "/Sellers/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.tasksAbout.count() instead.
            "::count::Seller::tasksAbout": {
              url: urlBase + "/Sellers/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.tasksAbout.findById() instead.
            "::findById::Buyer::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.tasksAbout.destroyById() instead.
            "::destroyById::Buyer::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.tasksAbout.updateById() instead.
            "::updateById::Buyer::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.tasksAbout() instead.
            "::get::Buyer::tasksAbout": {
              isArray: true,
              url: urlBase + "/Buyers/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use Buyer.tasksAbout.create() instead.
            "::create::Buyer::tasksAbout": {
              url: urlBase + "/Buyers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Buyer.tasksAbout.createMany() instead.
            "::createMany::Buyer::tasksAbout": {
              isArray: true,
              url: urlBase + "/Buyers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Buyer.tasksAbout.destroyAll() instead.
            "::delete::Buyer::tasksAbout": {
              url: urlBase + "/Buyers/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.tasksAbout.count() instead.
            "::count::Buyer::tasksAbout": {
              url: urlBase + "/Buyers/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.tasksAbout.findById() instead.
            "::findById::Supplier::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.tasksAbout.destroyById() instead.
            "::destroyById::Supplier::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.tasksAbout.updateById() instead.
            "::updateById::Supplier::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.tasksAbout() instead.
            "::get::Supplier::tasksAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use Supplier.tasksAbout.create() instead.
            "::create::Supplier::tasksAbout": {
              url: urlBase + "/Suppliers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.tasksAbout.createMany() instead.
            "::createMany::Supplier::tasksAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.tasksAbout.destroyAll() instead.
            "::delete::Supplier::tasksAbout": {
              url: urlBase + "/Suppliers/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.tasksAbout.count() instead.
            "::count::Supplier::tasksAbout": {
              url: urlBase + "/Suppliers/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Comment.task() instead.
            "::get::Comment::task": {
              url: urlBase + "/Comments/:id/task",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.findById() instead.
            "::findById::AgencyOwner::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.destroyById() instead.
            "::destroyById::AgencyOwner::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.updateById() instead.
            "::updateById::AgencyOwner::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.tasks.findById() instead.
            "::findById::AgencyOwner::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasks.destroyById() instead.
            "::destroyById::AgencyOwner::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.tasks.updateById() instead.
            "::updateById::AgencyOwner::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout() instead.
            "::get::AgencyOwner::tasksAbout": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.create() instead.
            "::create::AgencyOwner::tasksAbout": {
              url: urlBase + "/AgencyOwners/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.createMany() instead.
            "::createMany::AgencyOwner::tasksAbout": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.destroyAll() instead.
            "::delete::AgencyOwner::tasksAbout": {
              url: urlBase + "/AgencyOwners/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.count() instead.
            "::count::AgencyOwner::tasksAbout": {
              url: urlBase + "/AgencyOwners/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasks() instead.
            "::get::AgencyOwner::tasks": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasks.create() instead.
            "::create::AgencyOwner::tasks": {
              url: urlBase + "/AgencyOwners/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.tasks.createMany() instead.
            "::createMany::AgencyOwner::tasks": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.tasks.destroyAll() instead.
            "::delete::AgencyOwner::tasks": {
              url: urlBase + "/AgencyOwners/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.tasks.count() instead.
            "::count::AgencyOwner::tasks": {
              url: urlBase + "/AgencyOwners/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasks.findById() instead.
            "::findById::AgencyMember::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasks.destroyById() instead.
            "::destroyById::AgencyMember::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.tasks.updateById() instead.
            "::updateById::AgencyMember::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.findById() instead.
            "::findById::AgencyMember::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.destroyById() instead.
            "::destroyById::AgencyMember::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.updateById() instead.
            "::updateById::AgencyMember::tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.tasks() instead.
            "::get::AgencyMember::tasks": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasks.create() instead.
            "::create::AgencyMember::tasks": {
              url: urlBase + "/AgencyMembers/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.tasks.createMany() instead.
            "::createMany::AgencyMember::tasks": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.tasks.destroyAll() instead.
            "::delete::AgencyMember::tasks": {
              url: urlBase + "/AgencyMembers/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.tasks.count() instead.
            "::count::AgencyMember::tasks": {
              url: urlBase + "/AgencyMembers/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasksAbout() instead.
            "::get::AgencyMember::tasksAbout": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.create() instead.
            "::create::AgencyMember::tasksAbout": {
              url: urlBase + "/AgencyMembers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.createMany() instead.
            "::createMany::AgencyMember::tasksAbout": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.destroyAll() instead.
            "::delete::AgencyMember::tasksAbout": {
              url: urlBase + "/AgencyMembers/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.count() instead.
            "::count::AgencyMember::tasksAbout": {
              url: urlBase + "/AgencyMembers/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.tasks.findById() instead.
            "::findById::Broker::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.tasks.destroyById() instead.
            "::destroyById::Broker::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.tasks.updateById() instead.
            "::updateById::Broker::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.personalTasks.findById() instead.
            "::findById::Broker::personalTasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/personalTasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.personalTasks.destroyById() instead.
            "::destroyById::Broker::personalTasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/personalTasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.personalTasks.updateById() instead.
            "::updateById::Broker::personalTasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/personalTasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.tasks() instead.
            "::get::Broker::tasks": {
              isArray: true,
              url: urlBase + "/Brokers/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use Broker.tasks.create() instead.
            "::create::Broker::tasks": {
              url: urlBase + "/Brokers/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Broker.tasks.createMany() instead.
            "::createMany::Broker::tasks": {
              isArray: true,
              url: urlBase + "/Brokers/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Broker.tasks.destroyAll() instead.
            "::delete::Broker::tasks": {
              url: urlBase + "/Brokers/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.tasks.count() instead.
            "::count::Broker::tasks": {
              url: urlBase + "/Brokers/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.personalTasks() instead.
            "::get::Broker::personalTasks": {
              isArray: true,
              url: urlBase + "/Brokers/:id/personalTasks",
              method: "GET",
            },

            // INTERNAL. Use Broker.personalTasks.create() instead.
            "::create::Broker::personalTasks": {
              url: urlBase + "/Brokers/:id/personalTasks",
              method: "POST",
            },

            // INTERNAL. Use Broker.personalTasks.createMany() instead.
            "::createMany::Broker::personalTasks": {
              isArray: true,
              url: urlBase + "/Brokers/:id/personalTasks",
              method: "POST",
            },

            // INTERNAL. Use Broker.personalTasks.destroyAll() instead.
            "::delete::Broker::personalTasks": {
              url: urlBase + "/Brokers/:id/personalTasks",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.personalTasks.count() instead.
            "::count::Broker::personalTasks": {
              url: urlBase + "/Brokers/:id/personalTasks/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.tasks.findById() instead.
            "::findById::Listing::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.tasks.destroyById() instead.
            "::destroyById::Listing::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.tasks.updateById() instead.
            "::updateById::Listing::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.tasks() instead.
            "::get::Listing::tasks": {
              isArray: true,
              url: urlBase + "/Listings/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use Listing.tasks.create() instead.
            "::create::Listing::tasks": {
              url: urlBase + "/Listings/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Listing.tasks.createMany() instead.
            "::createMany::Listing::tasks": {
              isArray: true,
              url: urlBase + "/Listings/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Listing.tasks.destroyAll() instead.
            "::delete::Listing::tasks": {
              url: urlBase + "/Listings/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.tasks.count() instead.
            "::count::Listing::tasks": {
              url: urlBase + "/Listings/:id/tasks/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Task#upsert
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Task#updateOrCreate
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Task#patchOrCreateWithWhere
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Task#update
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Task#destroyById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Task#removeById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Task#prototype$updateAttributes
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Task#modelName
        * @propertyOf lbServices.Task
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Task`.
        */
        R.modelName = "Task";


            /**
             * @ngdoc method
             * @name lbServices.Task#model
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Fetches belongsTo relation model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskModel` object.)
             * </em>
             */
        R.model = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::get::Task::model"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Task.suppliers
     * @header lbServices.Task.suppliers
     * @object
     * @description
     *
     * The object `Task.suppliers` groups methods
     * manipulating `Supplier` instances related to `Task`.
     *
     * Call {@link lbServices.Task#suppliers Task.suppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Task#suppliers
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Queries suppliers of Task.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#count
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Counts suppliers of Task.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.suppliers.count = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::count::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#create
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.create = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::create::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#createMany
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.createMany = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::createMany::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#destroyAll
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Deletes all suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyAll = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::delete::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#destroyById
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Delete a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::destroyById::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#exists
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Check the existence of suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.exists = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::exists::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#findById
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Find a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.findById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::findById::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#link
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Add a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.link = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::link::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#unlink
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Remove the suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.unlink = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::unlink::Task::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.suppliers#updateById
             * @methodOf lbServices.Task.suppliers
             *
             * @description
             *
             * Update a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.updateById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::updateById::Task::suppliers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Task.comments
     * @header lbServices.Task.comments
     * @object
     * @description
     *
     * The object `Task.comments` groups methods
     * manipulating `Comment` instances related to `Task`.
     *
     * Call {@link lbServices.Task#comments Task.comments()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Task#comments
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Queries comments of Task.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::get::Task::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.comments#count
             * @methodOf lbServices.Task.comments
             *
             * @description
             *
             * Counts comments of Task.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.comments.count = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::count::Task::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.comments#create
             * @methodOf lbServices.Task.comments
             *
             * @description
             *
             * Creates a new instance in comments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments.create = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::create::Task::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.comments#createMany
             * @methodOf lbServices.Task.comments
             *
             * @description
             *
             * Creates a new instance in comments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments.createMany = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::createMany::Task::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.comments#destroyAll
             * @methodOf lbServices.Task.comments
             *
             * @description
             *
             * Deletes all comments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.comments.destroyAll = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::delete::Task::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.comments#destroyById
             * @methodOf lbServices.Task.comments
             *
             * @description
             *
             * Delete a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.comments.destroyById = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::destroyById::Task::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.comments#findById
             * @methodOf lbServices.Task.comments
             *
             * @description
             *
             * Find a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments.findById = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::findById::Task::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.comments#updateById
             * @methodOf lbServices.Task.comments
             *
             * @description
             *
             * Update a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments.updateById = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::updateById::Task::comments"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Task.files
     * @header lbServices.Task.files
     * @object
     * @description
     *
     * The object `Task.files` groups methods
     * manipulating `SmartFile` instances related to `Task`.
     *
     * Call {@link lbServices.Task#files Task.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Task#files
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Queries files of Task.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Task::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.files#count
             * @methodOf lbServices.Task.files
             *
             * @description
             *
             * Counts files of Task.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Task::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.files#create
             * @methodOf lbServices.Task.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Task::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.files#createMany
             * @methodOf lbServices.Task.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Task::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.files#destroyAll
             * @methodOf lbServices.Task.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Task::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.files#destroyById
             * @methodOf lbServices.Task.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Task::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.files#findById
             * @methodOf lbServices.Task.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Task::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task.files#updateById
             * @methodOf lbServices.Task.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Task::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task#listing
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Task::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Cost
 * @header lbServices.Cost
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Cost` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Cost",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Costs/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Cost.property() instead.
            "prototype$__get__property": {
              url: urlBase + "/Costs/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Cost.type() instead.
            "prototype$__get__type": {
              url: urlBase + "/Costs/:id/type",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#create
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Costs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#patchOrCreate
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Costs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#replaceOrCreate
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Costs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#upsertWithWhere
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Costs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#exists
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Costs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#findById
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Costs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#replaceById
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Costs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#find
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Costs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#findOne
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Costs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#updateAll
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Costs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#deleteById
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Costs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#count
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Costs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#prototype$patchAttributes
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Cost id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Costs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#createChangeStream
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Costs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cost#createMany
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Costs",
              method: "POST",
            },

            // INTERNAL. Use Property.costs.findById() instead.
            "::findById::Property::costs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/costs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.costs.destroyById() instead.
            "::destroyById::Property::costs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/costs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.costs.updateById() instead.
            "::updateById::Property::costs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/costs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.costs() instead.
            "::get::Property::costs": {
              isArray: true,
              url: urlBase + "/Properties/:id/costs",
              method: "GET",
            },

            // INTERNAL. Use Property.costs.create() instead.
            "::create::Property::costs": {
              url: urlBase + "/Properties/:id/costs",
              method: "POST",
            },

            // INTERNAL. Use Property.costs.createMany() instead.
            "::createMany::Property::costs": {
              isArray: true,
              url: urlBase + "/Properties/:id/costs",
              method: "POST",
            },

            // INTERNAL. Use Property.costs.destroyAll() instead.
            "::delete::Property::costs": {
              url: urlBase + "/Properties/:id/costs",
              method: "DELETE",
            },

            // INTERNAL. Use Property.costs.count() instead.
            "::count::Property::costs": {
              url: urlBase + "/Properties/:id/costs/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Cost#upsert
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Cost#updateOrCreate
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Cost#patchOrCreateWithWhere
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Cost#update
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Cost#destroyById
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Cost#removeById
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Cost#prototype$updateAttributes
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Cost id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cost` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Cost#modelName
        * @propertyOf lbServices.Cost
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Cost`.
        */
        R.modelName = "Cost";


            /**
             * @ngdoc method
             * @name lbServices.Cost#property
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Fetches belongsTo relation property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Cost id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::get::Cost::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Cost#type
             * @methodOf lbServices.Cost
             *
             * @description
             *
             * Fetches belongsTo relation type.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Cost id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.type = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Cost::type"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Characteristic
 * @header lbServices.Characteristic
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Characteristic` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Characteristic",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Characteristics/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Characteristic.property() instead.
            "prototype$__get__property": {
              url: urlBase + "/Characteristics/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Characteristic.language() instead.
            "prototype$__get__language": {
              url: urlBase + "/Characteristics/:id/language",
              method: "GET",
            },

            // INTERNAL. Use Characteristic.additionalBuilding() instead.
            "prototype$__get__additionalBuilding": {
              url: urlBase + "/Characteristics/:id/additionalBuilding",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#create
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Characteristics",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#patchOrCreate
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Characteristics",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#replaceOrCreate
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Characteristics/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#upsertWithWhere
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Characteristics/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#exists
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Characteristics/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#findById
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Characteristics/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#replaceById
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Characteristics/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#find
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Characteristics",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#findOne
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Characteristics/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#updateAll
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Characteristics/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#deleteById
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Characteristics/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#count
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Characteristics/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#prototype$patchAttributes
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Characteristic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Characteristics/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#createChangeStream
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Characteristics/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#createMany
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Characteristics",
              method: "POST",
            },

            // INTERNAL. Use Property.characteristics.findById() instead.
            "::findById::Property::characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/characteristics/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.characteristics.destroyById() instead.
            "::destroyById::Property::characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/characteristics/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.characteristics.updateById() instead.
            "::updateById::Property::characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/characteristics/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.characteristics() instead.
            "::get::Property::characteristics": {
              isArray: true,
              url: urlBase + "/Properties/:id/characteristics",
              method: "GET",
            },

            // INTERNAL. Use Property.characteristics.create() instead.
            "::create::Property::characteristics": {
              url: urlBase + "/Properties/:id/characteristics",
              method: "POST",
            },

            // INTERNAL. Use Property.characteristics.createMany() instead.
            "::createMany::Property::characteristics": {
              isArray: true,
              url: urlBase + "/Properties/:id/characteristics",
              method: "POST",
            },

            // INTERNAL. Use Property.characteristics.destroyAll() instead.
            "::delete::Property::characteristics": {
              url: urlBase + "/Properties/:id/characteristics",
              method: "DELETE",
            },

            // INTERNAL. Use Property.characteristics.count() instead.
            "::count::Property::characteristics": {
              url: urlBase + "/Properties/:id/characteristics/count",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.findById() instead.
            "::findById::AdditionalBuilding::characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/characteristics/:fk",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.destroyById() instead.
            "::destroyById::AdditionalBuilding::characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/characteristics/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.updateById() instead.
            "::updateById::AdditionalBuilding::characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/characteristics/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics() instead.
            "::get::AdditionalBuilding::characteristics": {
              isArray: true,
              url: urlBase + "/AdditionalBuildings/:id/characteristics",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.create() instead.
            "::create::AdditionalBuilding::characteristics": {
              url: urlBase + "/AdditionalBuildings/:id/characteristics",
              method: "POST",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.createMany() instead.
            "::createMany::AdditionalBuilding::characteristics": {
              isArray: true,
              url: urlBase + "/AdditionalBuildings/:id/characteristics",
              method: "POST",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.destroyAll() instead.
            "::delete::AdditionalBuilding::characteristics": {
              url: urlBase + "/AdditionalBuildings/:id/characteristics",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.count() instead.
            "::count::AdditionalBuilding::characteristics": {
              url: urlBase + "/AdditionalBuildings/:id/characteristics/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Characteristic#upsert
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#updateOrCreate
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#patchOrCreateWithWhere
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#update
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#destroyById
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#removeById
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#prototype$updateAttributes
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Characteristic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Characteristic#modelName
        * @propertyOf lbServices.Characteristic
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Characteristic`.
        */
        R.modelName = "Characteristic";


            /**
             * @ngdoc method
             * @name lbServices.Characteristic#property
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Fetches belongsTo relation property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Characteristic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::get::Characteristic::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#language
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Fetches belongsTo relation language.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Characteristic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.language = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::get::Characteristic::language"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Characteristic#additionalBuilding
             * @methodOf lbServices.Characteristic
             *
             * @description
             *
             * Fetches belongsTo relation additionalBuilding.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Characteristic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R.additionalBuilding = function() {
          var TargetResource = $injector.get("AdditionalBuilding");
          var action = TargetResource["::get::Characteristic::additionalBuilding"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Room
 * @header lbServices.Room
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Room` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Room",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Rooms/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Room.property() instead.
            "prototype$__get__property": {
              url: urlBase + "/Rooms/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Room.type() instead.
            "prototype$__get__type": {
              url: urlBase + "/Rooms/:id/type",
              method: "GET",
            },

            // INTERNAL. Use Room.floor() instead.
            "prototype$__get__floor": {
              url: urlBase + "/Rooms/:id/floor",
              method: "GET",
            },

            // INTERNAL. Use Room.floorCover() instead.
            "prototype$__get__floorCover": {
              url: urlBase + "/Rooms/:id/floorCover",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#create
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Rooms",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#patchOrCreate
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Rooms",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#replaceOrCreate
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Rooms/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#upsertWithWhere
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Rooms/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#exists
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Rooms/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#findById
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Rooms/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#replaceById
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Rooms/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#find
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Rooms",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#findOne
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Rooms/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#updateAll
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Rooms/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#deleteById
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Rooms/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#count
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Rooms/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#prototype$patchAttributes
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Room id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Rooms/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#createChangeStream
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Rooms/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Room#createMany
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Rooms",
              method: "POST",
            },

            // INTERNAL. Use Property.rooms.findById() instead.
            "::findById::Property::rooms": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/rooms/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.rooms.destroyById() instead.
            "::destroyById::Property::rooms": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/rooms/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.rooms.updateById() instead.
            "::updateById::Property::rooms": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/rooms/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.rooms() instead.
            "::get::Property::rooms": {
              isArray: true,
              url: urlBase + "/Properties/:id/rooms",
              method: "GET",
            },

            // INTERNAL. Use Property.rooms.create() instead.
            "::create::Property::rooms": {
              url: urlBase + "/Properties/:id/rooms",
              method: "POST",
            },

            // INTERNAL. Use Property.rooms.createMany() instead.
            "::createMany::Property::rooms": {
              isArray: true,
              url: urlBase + "/Properties/:id/rooms",
              method: "POST",
            },

            // INTERNAL. Use Property.rooms.destroyAll() instead.
            "::delete::Property::rooms": {
              url: urlBase + "/Properties/:id/rooms",
              method: "DELETE",
            },

            // INTERNAL. Use Property.rooms.count() instead.
            "::count::Property::rooms": {
              url: urlBase + "/Properties/:id/rooms/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Room#upsert
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Room#updateOrCreate
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Room#patchOrCreateWithWhere
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Room#update
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Room#destroyById
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Room#removeById
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Room#prototype$updateAttributes
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Room id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Room` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Room#modelName
        * @propertyOf lbServices.Room
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Room`.
        */
        R.modelName = "Room";


            /**
             * @ngdoc method
             * @name lbServices.Room#property
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Fetches belongsTo relation property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Room id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::get::Room::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Room#type
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Fetches belongsTo relation type.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Room id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.type = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Room::type"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Room#floor
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Fetches belongsTo relation floor.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Room id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.floor = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Room::floor"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Room#floorCover
             * @methodOf lbServices.Room
             *
             * @description
             *
             * Fetches belongsTo relation floorCover.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Room id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.floorCover = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Room::floorCover"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Feature
 * @header lbServices.Feature
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Feature` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Feature",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Features/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Feature.property() instead.
            "prototype$__get__property": {
              url: urlBase + "/Features/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Feature.type() instead.
            "prototype$__get__type": {
              url: urlBase + "/Features/:id/type",
              method: "GET",
            },

            // INTERNAL. Use Feature.value() instead.
            "prototype$__get__value": {
              url: urlBase + "/Features/:id/value",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#create
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Features",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#patchOrCreate
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Features",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#replaceOrCreate
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Features/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#upsertWithWhere
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Features/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#exists
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Features/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#findById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Features/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#replaceById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Features/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#find
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Features",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#findOne
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Features/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#updateAll
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Features/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#deleteById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Features/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#count
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Features/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#prototype$patchAttributes
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Features/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#createChangeStream
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Features/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#createMany
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Features",
              method: "POST",
            },

            // INTERNAL. Use Property.features.findById() instead.
            "::findById::Property::features": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/features/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.features.destroyById() instead.
            "::destroyById::Property::features": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/features/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.features.updateById() instead.
            "::updateById::Property::features": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/features/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.features() instead.
            "::get::Property::features": {
              isArray: true,
              url: urlBase + "/Properties/:id/features",
              method: "GET",
            },

            // INTERNAL. Use Property.features.create() instead.
            "::create::Property::features": {
              url: urlBase + "/Properties/:id/features",
              method: "POST",
            },

            // INTERNAL. Use Property.features.createMany() instead.
            "::createMany::Property::features": {
              isArray: true,
              url: urlBase + "/Properties/:id/features",
              method: "POST",
            },

            // INTERNAL. Use Property.features.destroyAll() instead.
            "::delete::Property::features": {
              url: urlBase + "/Properties/:id/features",
              method: "DELETE",
            },

            // INTERNAL. Use Property.features.count() instead.
            "::count::Property::features": {
              url: urlBase + "/Properties/:id/features/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Feature#upsert
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#updateOrCreate
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#patchOrCreateWithWhere
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#update
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#destroyById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#removeById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#prototype$updateAttributes
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Feature#modelName
        * @propertyOf lbServices.Feature
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Feature`.
        */
        R.modelName = "Feature";


            /**
             * @ngdoc method
             * @name lbServices.Feature#property
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Fetches belongsTo relation property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::get::Feature::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature#type
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Fetches belongsTo relation type.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.type = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Feature::type"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature#value
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Fetches belongsTo relation value.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.value = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Feature::value"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Files
 * @header lbServices.Files
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Files` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Files",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Files/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Files#getSignedUrlWithBucket
             * @methodOf lbServices.Files
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `bucket` – `{string=}` -
             *
             *  - `key` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "getSignedUrlWithBucket": {
              url: urlBase + "/Files/:bucket/:key",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Files#getSignedUrl
             * @methodOf lbServices.Files
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `key` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "getSignedUrl": {
              url: urlBase + "/Files/:key",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Files#doBulkUrlSigning
             * @methodOf lbServices.Files
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `keys` – `{*=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "doBulkUrlSigning": {
              url: urlBase + "/Files/bulk",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Files#modelName
        * @propertyOf lbServices.Files
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Files`.
        */
        R.modelName = "Files";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.TaskModel
 * @header lbServices.TaskModel
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `TaskModel` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "TaskModel",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/TaskModels/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.TaskModel#prototype$__get__assignedTo
             * @methodOf lbServices.TaskModel
             *
             * @description
             *
             * Fetches belongsTo relation assignedTo.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskModel id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskModel` object.)
             * </em>
             */
            "prototype$__get__assignedTo": {
              url: urlBase + "/TaskModels/:id/assignedTo",
              method: "GET",
            },

            // INTERNAL. Use Task.model() instead.
            "::get::Task::model": {
              url: urlBase + "/Tasks/:id/model",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.taskList.findById() instead.
            "::findById::TaskTemplate::taskList": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/TaskTemplates/:id/taskList/:fk",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.taskList.destroyById() instead.
            "::destroyById::TaskTemplate::taskList": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/TaskTemplates/:id/taskList/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use TaskTemplate.taskList.updateById() instead.
            "::updateById::TaskTemplate::taskList": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/TaskTemplates/:id/taskList/:fk",
              method: "PUT",
            },

            // INTERNAL. Use TaskTemplate.taskList() instead.
            "::get::TaskTemplate::taskList": {
              isArray: true,
              url: urlBase + "/TaskTemplates/:id/taskList",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.taskList.create() instead.
            "::create::TaskTemplate::taskList": {
              url: urlBase + "/TaskTemplates/:id/taskList",
              method: "POST",
            },

            // INTERNAL. Use TaskTemplate.taskList.createMany() instead.
            "::createMany::TaskTemplate::taskList": {
              isArray: true,
              url: urlBase + "/TaskTemplates/:id/taskList",
              method: "POST",
            },

            // INTERNAL. Use TaskTemplate.taskList.destroyAll() instead.
            "::delete::TaskTemplate::taskList": {
              url: urlBase + "/TaskTemplates/:id/taskList",
              method: "DELETE",
            },

            // INTERNAL. Use TaskTemplate.taskList.count() instead.
            "::count::TaskTemplate::taskList": {
              url: urlBase + "/TaskTemplates/:id/taskList/count",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.TaskModel#modelName
        * @propertyOf lbServices.TaskModel
        * @description
        * The name of the model represented by this $resource,
        * i.e. `TaskModel`.
        */
        R.modelName = "TaskModel";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Supplier
 * @header lbServices.Supplier
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Supplier` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Supplier",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Suppliers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Supplier.industry() instead.
            "prototype$__get__industry": {
              url: urlBase + "/Suppliers/:id/industry",
              method: "GET",
            },

            // INTERNAL. Use Supplier.notesAbout.findById() instead.
            "prototype$__findById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/notesAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.notesAbout.destroyById() instead.
            "prototype$__destroyById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/notesAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.notesAbout.updateById() instead.
            "prototype$__updateById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/notesAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.tasksAbout.findById() instead.
            "prototype$__findById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.tasksAbout.destroyById() instead.
            "prototype$__destroyById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.tasksAbout.updateById() instead.
            "prototype$__updateById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.logsAbout.findById() instead.
            "prototype$__findById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.logsAbout.destroyById() instead.
            "prototype$__destroyById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.logsAbout.updateById() instead.
            "prototype$__updateById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.supplierBrokers.findById() instead.
            "prototype$__findById__supplierBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierBrokers.destroyById() instead.
            "prototype$__destroyById__supplierBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.supplierBrokers.updateById() instead.
            "prototype$__updateById__supplierBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.owner() instead.
            "prototype$__get__owner": {
              url: urlBase + "/Suppliers/:id/owner",
              method: "GET",
            },

            // INTERNAL. Use Supplier.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Suppliers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Supplier.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.brokers.link() instead.
            "prototype$__link__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.brokers.unlink() instead.
            "prototype$__unlink__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.brokers.exists() instead.
            "prototype$__exists__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Supplier.supplierAgencies.findById() instead.
            "prototype$__findById__supplierAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierAgencies/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierAgencies.destroyById() instead.
            "prototype$__destroyById__supplierAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierAgencies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.supplierAgencies.updateById() instead.
            "prototype$__updateById__supplierAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierAgencies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.listingSuppliers.findById() instead.
            "prototype$__findById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/listingSuppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.listingSuppliers.destroyById() instead.
            "prototype$__destroyById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/listingSuppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.listingSuppliers.updateById() instead.
            "prototype$__updateById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/listingSuppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.buyers.findById() instead.
            "prototype$__findById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.buyers.destroyById() instead.
            "prototype$__destroyById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.buyers.updateById() instead.
            "prototype$__updateById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.buyers.link() instead.
            "prototype$__link__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.buyers.unlink() instead.
            "prototype$__unlink__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.buyers.exists() instead.
            "prototype$__exists__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/buyers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Supplier.sellers.findById() instead.
            "prototype$__findById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.sellers.destroyById() instead.
            "prototype$__destroyById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.sellers.updateById() instead.
            "prototype$__updateById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.sellers.link() instead.
            "prototype$__link__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.sellers.unlink() instead.
            "prototype$__unlink__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.sellers.exists() instead.
            "prototype$__exists__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/sellers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Supplier.notesAbout() instead.
            "prototype$__get__notesAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/notesAbout",
              method: "GET",
            },

            // INTERNAL. Use Supplier.notesAbout.create() instead.
            "prototype$__create__notesAbout": {
              url: urlBase + "/Suppliers/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.notesAbout.destroyAll() instead.
            "prototype$__delete__notesAbout": {
              url: urlBase + "/Suppliers/:id/notesAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.notesAbout.count() instead.
            "prototype$__count__notesAbout": {
              url: urlBase + "/Suppliers/:id/notesAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.tasksAbout() instead.
            "prototype$__get__tasksAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use Supplier.tasksAbout.create() instead.
            "prototype$__create__tasksAbout": {
              url: urlBase + "/Suppliers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.tasksAbout.destroyAll() instead.
            "prototype$__delete__tasksAbout": {
              url: urlBase + "/Suppliers/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.tasksAbout.count() instead.
            "prototype$__count__tasksAbout": {
              url: urlBase + "/Suppliers/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.logsAbout() instead.
            "prototype$__get__logsAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use Supplier.logsAbout.create() instead.
            "prototype$__create__logsAbout": {
              url: urlBase + "/Suppliers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.logsAbout.destroyAll() instead.
            "prototype$__delete__logsAbout": {
              url: urlBase + "/Suppliers/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.logsAbout.count() instead.
            "prototype$__count__logsAbout": {
              url: urlBase + "/Suppliers/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierBrokers() instead.
            "prototype$__get__supplierBrokers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/supplierBrokers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierBrokers.create() instead.
            "prototype$__create__supplierBrokers": {
              url: urlBase + "/Suppliers/:id/supplierBrokers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.supplierBrokers.destroyAll() instead.
            "prototype$__delete__supplierBrokers": {
              url: urlBase + "/Suppliers/:id/supplierBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.supplierBrokers.count() instead.
            "prototype$__count__supplierBrokers": {
              url: urlBase + "/Suppliers/:id/supplierBrokers/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/Suppliers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/Suppliers/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/Suppliers/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierAgencies() instead.
            "prototype$__get__supplierAgencies": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/supplierAgencies",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierAgencies.create() instead.
            "prototype$__create__supplierAgencies": {
              url: urlBase + "/Suppliers/:id/supplierAgencies",
              method: "POST",
            },

            // INTERNAL. Use Supplier.supplierAgencies.destroyAll() instead.
            "prototype$__delete__supplierAgencies": {
              url: urlBase + "/Suppliers/:id/supplierAgencies",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.supplierAgencies.count() instead.
            "prototype$__count__supplierAgencies": {
              url: urlBase + "/Suppliers/:id/supplierAgencies/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.listingSuppliers() instead.
            "prototype$__get__listingSuppliers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/listingSuppliers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.listingSuppliers.create() instead.
            "prototype$__create__listingSuppliers": {
              url: urlBase + "/Suppliers/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.listingSuppliers.destroyAll() instead.
            "prototype$__delete__listingSuppliers": {
              url: urlBase + "/Suppliers/:id/listingSuppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.listingSuppliers.count() instead.
            "prototype$__count__listingSuppliers": {
              url: urlBase + "/Suppliers/:id/listingSuppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.buyers() instead.
            "prototype$__get__buyers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/buyers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.buyers.create() instead.
            "prototype$__create__buyers": {
              url: urlBase + "/Suppliers/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.buyers.destroyAll() instead.
            "prototype$__delete__buyers": {
              url: urlBase + "/Suppliers/:id/buyers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.buyers.count() instead.
            "prototype$__count__buyers": {
              url: urlBase + "/Suppliers/:id/buyers/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.sellers() instead.
            "prototype$__get__sellers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/sellers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.sellers.create() instead.
            "prototype$__create__sellers": {
              url: urlBase + "/Suppliers/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.sellers.destroyAll() instead.
            "prototype$__delete__sellers": {
              url: urlBase + "/Suppliers/:id/sellers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.sellers.count() instead.
            "prototype$__count__sellers": {
              url: urlBase + "/Suppliers/:id/sellers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#create
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Suppliers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#patchOrCreate
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Suppliers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#replaceOrCreate
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Suppliers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#upsertWithWhere
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Suppliers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#exists
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Suppliers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#findById
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Suppliers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#replaceById
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Suppliers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#find
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Suppliers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#findOne
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Suppliers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#updateAll
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Suppliers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#deleteById
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Suppliers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#count
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Suppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#prototype$patchAttributes
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Suppliers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#createChangeStream
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Suppliers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#getPublicImage
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "getPublicImage": {
              url: urlBase + "/Suppliers/:id/public/image",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#getVisits
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Gets the visits related to a model
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "getVisits": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/all-visits",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#getAppointments
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Gets the appointments related to a model
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "getAppointments": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/appointments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#sentEmails
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Gets the emails sent by the buyer
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "sentEmails": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/sent-emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Supplier#createMany
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Suppliers",
              method: "POST",
            },

            // INTERNAL. Use Seller.suppliers.findById() instead.
            "::findById::Seller::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.suppliers.destroyById() instead.
            "::destroyById::Seller::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.suppliers.updateById() instead.
            "::updateById::Seller::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.suppliers.link() instead.
            "::link::Seller::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.suppliers.unlink() instead.
            "::unlink::Seller::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.suppliers.exists() instead.
            "::exists::Seller::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Seller.suppliers() instead.
            "::get::Seller::suppliers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Seller.suppliers.create() instead.
            "::create::Seller::suppliers": {
              url: urlBase + "/Sellers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Seller.suppliers.createMany() instead.
            "::createMany::Seller::suppliers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Seller.suppliers.destroyAll() instead.
            "::delete::Seller::suppliers": {
              url: urlBase + "/Sellers/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.suppliers.count() instead.
            "::count::Seller::suppliers": {
              url: urlBase + "/Sellers/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.suppliers.findById() instead.
            "::findById::Buyer::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.suppliers.destroyById() instead.
            "::destroyById::Buyer::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.suppliers.updateById() instead.
            "::updateById::Buyer::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.suppliers.link() instead.
            "::link::Buyer::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.suppliers.unlink() instead.
            "::unlink::Buyer::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.suppliers.exists() instead.
            "::exists::Buyer::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.suppliers() instead.
            "::get::Buyer::suppliers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Buyer.suppliers.create() instead.
            "::create::Buyer::suppliers": {
              url: urlBase + "/Buyers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.suppliers.createMany() instead.
            "::createMany::Buyer::suppliers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.suppliers.destroyAll() instead.
            "::delete::Buyer::suppliers": {
              url: urlBase + "/Buyers/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.suppliers.count() instead.
            "::count::Buyer::suppliers": {
              url: urlBase + "/Buyers/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Task.suppliers.findById() instead.
            "::findById::Task::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Task.suppliers.destroyById() instead.
            "::destroyById::Task::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Task.suppliers.updateById() instead.
            "::updateById::Task::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Task.suppliers.link() instead.
            "::link::Task::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Task.suppliers.unlink() instead.
            "::unlink::Task::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Task.suppliers.exists() instead.
            "::exists::Task::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Task.suppliers() instead.
            "::get::Task::suppliers": {
              isArray: true,
              url: urlBase + "/Tasks/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Task.suppliers.create() instead.
            "::create::Task::suppliers": {
              url: urlBase + "/Tasks/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Task.suppliers.createMany() instead.
            "::createMany::Task::suppliers": {
              isArray: true,
              url: urlBase + "/Tasks/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Task.suppliers.destroyAll() instead.
            "::delete::Task::suppliers": {
              url: urlBase + "/Tasks/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Task.suppliers.count() instead.
            "::count::Task::suppliers": {
              url: urlBase + "/Tasks/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.supplier() instead.
            "::get::SupplierBroker::supplier": {
              url: urlBase + "/SupplierBrokers/:id/supplier",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.suppliers.findById() instead.
            "::findById::AgencyOwner::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.suppliers.destroyById() instead.
            "::destroyById::AgencyOwner::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.suppliers.updateById() instead.
            "::updateById::AgencyOwner::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.suppliers.link() instead.
            "::link::AgencyOwner::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.suppliers.unlink() instead.
            "::unlink::AgencyOwner::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.suppliers.exists() instead.
            "::exists::AgencyOwner::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use AgencyOwner.suppliers() instead.
            "::get::AgencyOwner::suppliers": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.suppliers.create() instead.
            "::create::AgencyOwner::suppliers": {
              url: urlBase + "/AgencyOwners/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.suppliers.createMany() instead.
            "::createMany::AgencyOwner::suppliers": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.suppliers.destroyAll() instead.
            "::delete::AgencyOwner::suppliers": {
              url: urlBase + "/AgencyOwners/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.suppliers.count() instead.
            "::count::AgencyOwner::suppliers": {
              url: urlBase + "/AgencyOwners/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.suppliers.findById() instead.
            "::findById::AgencyMember::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.suppliers.destroyById() instead.
            "::destroyById::AgencyMember::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.suppliers.updateById() instead.
            "::updateById::AgencyMember::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.suppliers.link() instead.
            "::link::AgencyMember::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.suppliers.unlink() instead.
            "::unlink::AgencyMember::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.suppliers.exists() instead.
            "::exists::AgencyMember::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use AgencyMember.suppliers() instead.
            "::get::AgencyMember::suppliers": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.suppliers.create() instead.
            "::create::AgencyMember::suppliers": {
              url: urlBase + "/AgencyMembers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.suppliers.createMany() instead.
            "::createMany::AgencyMember::suppliers": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.suppliers.destroyAll() instead.
            "::delete::AgencyMember::suppliers": {
              url: urlBase + "/AgencyMembers/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.suppliers.count() instead.
            "::count::AgencyMember::suppliers": {
              url: urlBase + "/AgencyMembers/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.findById() instead.
            "::findById::SbUser::ownedSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedSuppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.destroyById() instead.
            "::destroyById::SbUser::ownedSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedSuppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.updateById() instead.
            "::updateById::SbUser::ownedSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedSuppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.ownedSuppliers() instead.
            "::get::SbUser::ownedSuppliers": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/ownedSuppliers",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.create() instead.
            "::create::SbUser::ownedSuppliers": {
              url: urlBase + "/SbUsers/:id/ownedSuppliers",
              method: "POST",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.createMany() instead.
            "::createMany::SbUser::ownedSuppliers": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/ownedSuppliers",
              method: "POST",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.destroyAll() instead.
            "::delete::SbUser::ownedSuppliers": {
              url: urlBase + "/SbUsers/:id/ownedSuppliers",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.count() instead.
            "::count::SbUser::ownedSuppliers": {
              url: urlBase + "/SbUsers/:id/ownedSuppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.suppliers.findById() instead.
            "::findById::Broker::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.suppliers.destroyById() instead.
            "::destroyById::Broker::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.suppliers.updateById() instead.
            "::updateById::Broker::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.suppliers.link() instead.
            "::link::Broker::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.suppliers.unlink() instead.
            "::unlink::Broker::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.suppliers.exists() instead.
            "::exists::Broker::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.suppliers() instead.
            "::get::Broker::suppliers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Broker.suppliers.create() instead.
            "::create::Broker::suppliers": {
              url: urlBase + "/Brokers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Broker.suppliers.createMany() instead.
            "::createMany::Broker::suppliers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Broker.suppliers.destroyAll() instead.
            "::delete::Broker::suppliers": {
              url: urlBase + "/Brokers/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.suppliers.count() instead.
            "::count::Broker::suppliers": {
              url: urlBase + "/Brokers/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.suppliers.findById() instead.
            "::findById::Listing::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.suppliers.destroyById() instead.
            "::destroyById::Listing::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.suppliers.updateById() instead.
            "::updateById::Listing::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.suppliers.link() instead.
            "::link::Listing::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.suppliers.unlink() instead.
            "::unlink::Listing::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.suppliers.exists() instead.
            "::exists::Listing::suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.suppliers() instead.
            "::get::Listing::suppliers": {
              isArray: true,
              url: urlBase + "/Listings/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Listing.suppliers.create() instead.
            "::create::Listing::suppliers": {
              url: urlBase + "/Listings/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Listing.suppliers.createMany() instead.
            "::createMany::Listing::suppliers": {
              isArray: true,
              url: urlBase + "/Listings/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Listing.suppliers.destroyAll() instead.
            "::delete::Listing::suppliers": {
              url: urlBase + "/Listings/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.suppliers.count() instead.
            "::count::Listing::suppliers": {
              url: urlBase + "/Listings/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use AgencySupplier.supplier() instead.
            "::get::AgencySupplier::supplier": {
              url: urlBase + "/AgencySuppliers/:id/supplier",
              method: "GET",
            },

            // INTERNAL. Use ListingSupplier.supplier() instead.
            "::get::ListingSupplier::supplier": {
              url: urlBase + "/ListingSuppliers/:id/supplier",
              method: "GET",
            },

            // INTERNAL. Use BuyerSupplier.supplier() instead.
            "::get::BuyerSupplier::supplier": {
              url: urlBase + "/BuyerSuppliers/:id/supplier",
              method: "GET",
            },

            // INTERNAL. Use SellerSupplier.supplier() instead.
            "::get::SellerSupplier::supplier": {
              url: urlBase + "/SellerSuppliers/:id/supplier",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Supplier#upsert
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Supplier#updateOrCreate
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Supplier#patchOrCreateWithWhere
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Supplier#update
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Supplier#destroyById
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Supplier#removeById
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Supplier#prototype$updateAttributes
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Supplier#modelName
        * @propertyOf lbServices.Supplier
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Supplier`.
        */
        R.modelName = "Supplier";


            /**
             * @ngdoc method
             * @name lbServices.Supplier#industry
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Fetches belongsTo relation industry.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.industry = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Supplier::industry"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.notesAbout
     * @header lbServices.Supplier.notesAbout
     * @object
     * @description
     *
     * The object `Supplier.notesAbout` groups methods
     * manipulating `Note` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#notesAbout Supplier.notesAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#notesAbout
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries notesAbout of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::get::Supplier::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.notesAbout#count
             * @methodOf lbServices.Supplier.notesAbout
             *
             * @description
             *
             * Counts notesAbout of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notesAbout.count = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::count::Supplier::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.notesAbout#create
             * @methodOf lbServices.Supplier.notesAbout
             *
             * @description
             *
             * Creates a new instance in notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.create = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::create::Supplier::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.notesAbout#createMany
             * @methodOf lbServices.Supplier.notesAbout
             *
             * @description
             *
             * Creates a new instance in notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.createMany = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::createMany::Supplier::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.notesAbout#destroyAll
             * @methodOf lbServices.Supplier.notesAbout
             *
             * @description
             *
             * Deletes all notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notesAbout.destroyAll = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::delete::Supplier::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.notesAbout#destroyById
             * @methodOf lbServices.Supplier.notesAbout
             *
             * @description
             *
             * Delete a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notesAbout.destroyById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::destroyById::Supplier::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.notesAbout#findById
             * @methodOf lbServices.Supplier.notesAbout
             *
             * @description
             *
             * Find a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.findById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::findById::Supplier::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.notesAbout#updateById
             * @methodOf lbServices.Supplier.notesAbout
             *
             * @description
             *
             * Update a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.updateById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::updateById::Supplier::notesAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.tasksAbout
     * @header lbServices.Supplier.tasksAbout
     * @object
     * @description
     *
     * The object `Supplier.tasksAbout` groups methods
     * manipulating `Task` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#tasksAbout Supplier.tasksAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#tasksAbout
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries tasksAbout of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Supplier::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.tasksAbout#count
             * @methodOf lbServices.Supplier.tasksAbout
             *
             * @description
             *
             * Counts tasksAbout of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasksAbout.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::Supplier::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.tasksAbout#create
             * @methodOf lbServices.Supplier.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::Supplier::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.tasksAbout#createMany
             * @methodOf lbServices.Supplier.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::Supplier::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.tasksAbout#destroyAll
             * @methodOf lbServices.Supplier.tasksAbout
             *
             * @description
             *
             * Deletes all tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::Supplier::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.tasksAbout#destroyById
             * @methodOf lbServices.Supplier.tasksAbout
             *
             * @description
             *
             * Delete a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::Supplier::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.tasksAbout#findById
             * @methodOf lbServices.Supplier.tasksAbout
             *
             * @description
             *
             * Find a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::Supplier::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.tasksAbout#updateById
             * @methodOf lbServices.Supplier.tasksAbout
             *
             * @description
             *
             * Update a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::Supplier::tasksAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.logsAbout
     * @header lbServices.Supplier.logsAbout
     * @object
     * @description
     *
     * The object `Supplier.logsAbout` groups methods
     * manipulating `Log` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#logsAbout Supplier.logsAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#logsAbout
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries logsAbout of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::get::Supplier::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.logsAbout#count
             * @methodOf lbServices.Supplier.logsAbout
             *
             * @description
             *
             * Counts logsAbout of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.logsAbout.count = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::count::Supplier::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.logsAbout#create
             * @methodOf lbServices.Supplier.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.create = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::create::Supplier::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.logsAbout#createMany
             * @methodOf lbServices.Supplier.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.createMany = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::createMany::Supplier::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.logsAbout#destroyAll
             * @methodOf lbServices.Supplier.logsAbout
             *
             * @description
             *
             * Deletes all logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyAll = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::delete::Supplier::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.logsAbout#destroyById
             * @methodOf lbServices.Supplier.logsAbout
             *
             * @description
             *
             * Delete a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::destroyById::Supplier::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.logsAbout#findById
             * @methodOf lbServices.Supplier.logsAbout
             *
             * @description
             *
             * Find a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.findById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::findById::Supplier::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.logsAbout#updateById
             * @methodOf lbServices.Supplier.logsAbout
             *
             * @description
             *
             * Update a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.updateById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::updateById::Supplier::logsAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.supplierBrokers
     * @header lbServices.Supplier.supplierBrokers
     * @object
     * @description
     *
     * The object `Supplier.supplierBrokers` groups methods
     * manipulating `SupplierBroker` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#supplierBrokers Supplier.supplierBrokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#supplierBrokers
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries supplierBrokers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R.supplierBrokers = function() {
          var TargetResource = $injector.get("SupplierBroker");
          var action = TargetResource["::get::Supplier::supplierBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierBrokers#count
             * @methodOf lbServices.Supplier.supplierBrokers
             *
             * @description
             *
             * Counts supplierBrokers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.supplierBrokers.count = function() {
          var TargetResource = $injector.get("SupplierBroker");
          var action = TargetResource["::count::Supplier::supplierBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierBrokers#create
             * @methodOf lbServices.Supplier.supplierBrokers
             *
             * @description
             *
             * Creates a new instance in supplierBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R.supplierBrokers.create = function() {
          var TargetResource = $injector.get("SupplierBroker");
          var action = TargetResource["::create::Supplier::supplierBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierBrokers#createMany
             * @methodOf lbServices.Supplier.supplierBrokers
             *
             * @description
             *
             * Creates a new instance in supplierBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R.supplierBrokers.createMany = function() {
          var TargetResource = $injector.get("SupplierBroker");
          var action = TargetResource["::createMany::Supplier::supplierBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierBrokers#destroyAll
             * @methodOf lbServices.Supplier.supplierBrokers
             *
             * @description
             *
             * Deletes all supplierBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.supplierBrokers.destroyAll = function() {
          var TargetResource = $injector.get("SupplierBroker");
          var action = TargetResource["::delete::Supplier::supplierBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierBrokers#destroyById
             * @methodOf lbServices.Supplier.supplierBrokers
             *
             * @description
             *
             * Delete a related item by id for supplierBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for supplierBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.supplierBrokers.destroyById = function() {
          var TargetResource = $injector.get("SupplierBroker");
          var action = TargetResource["::destroyById::Supplier::supplierBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierBrokers#findById
             * @methodOf lbServices.Supplier.supplierBrokers
             *
             * @description
             *
             * Find a related item by id for supplierBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for supplierBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R.supplierBrokers.findById = function() {
          var TargetResource = $injector.get("SupplierBroker");
          var action = TargetResource["::findById::Supplier::supplierBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierBrokers#updateById
             * @methodOf lbServices.Supplier.supplierBrokers
             *
             * @description
             *
             * Update a related item by id for supplierBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for supplierBrokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R.supplierBrokers.updateById = function() {
          var TargetResource = $injector.get("SupplierBroker");
          var action = TargetResource["::updateById::Supplier::supplierBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier#owner
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Fetches belongsTo relation owner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.owner = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Supplier::owner"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier#user
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Supplier::user"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.brokers
     * @header lbServices.Supplier.brokers
     * @object
     * @description
     *
     * The object `Supplier.brokers` groups methods
     * manipulating `Broker` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#brokers Supplier.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#brokers
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries brokers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#count
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Counts brokers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#create
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#createMany
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#destroyAll
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#destroyById
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#exists
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Check the existence of brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#findById
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#link
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Add a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#unlink
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Remove the brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::Supplier::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.brokers#updateById
             * @methodOf lbServices.Supplier.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::Supplier::brokers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.supplierAgencies
     * @header lbServices.Supplier.supplierAgencies
     * @object
     * @description
     *
     * The object `Supplier.supplierAgencies` groups methods
     * manipulating `AgencySupplier` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#supplierAgencies Supplier.supplierAgencies()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#supplierAgencies
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries supplierAgencies of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R.supplierAgencies = function() {
          var TargetResource = $injector.get("AgencySupplier");
          var action = TargetResource["::get::Supplier::supplierAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierAgencies#count
             * @methodOf lbServices.Supplier.supplierAgencies
             *
             * @description
             *
             * Counts supplierAgencies of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.supplierAgencies.count = function() {
          var TargetResource = $injector.get("AgencySupplier");
          var action = TargetResource["::count::Supplier::supplierAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierAgencies#create
             * @methodOf lbServices.Supplier.supplierAgencies
             *
             * @description
             *
             * Creates a new instance in supplierAgencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R.supplierAgencies.create = function() {
          var TargetResource = $injector.get("AgencySupplier");
          var action = TargetResource["::create::Supplier::supplierAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierAgencies#createMany
             * @methodOf lbServices.Supplier.supplierAgencies
             *
             * @description
             *
             * Creates a new instance in supplierAgencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R.supplierAgencies.createMany = function() {
          var TargetResource = $injector.get("AgencySupplier");
          var action = TargetResource["::createMany::Supplier::supplierAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierAgencies#destroyAll
             * @methodOf lbServices.Supplier.supplierAgencies
             *
             * @description
             *
             * Deletes all supplierAgencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.supplierAgencies.destroyAll = function() {
          var TargetResource = $injector.get("AgencySupplier");
          var action = TargetResource["::delete::Supplier::supplierAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierAgencies#destroyById
             * @methodOf lbServices.Supplier.supplierAgencies
             *
             * @description
             *
             * Delete a related item by id for supplierAgencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for supplierAgencies
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.supplierAgencies.destroyById = function() {
          var TargetResource = $injector.get("AgencySupplier");
          var action = TargetResource["::destroyById::Supplier::supplierAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierAgencies#findById
             * @methodOf lbServices.Supplier.supplierAgencies
             *
             * @description
             *
             * Find a related item by id for supplierAgencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for supplierAgencies
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R.supplierAgencies.findById = function() {
          var TargetResource = $injector.get("AgencySupplier");
          var action = TargetResource["::findById::Supplier::supplierAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.supplierAgencies#updateById
             * @methodOf lbServices.Supplier.supplierAgencies
             *
             * @description
             *
             * Update a related item by id for supplierAgencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for supplierAgencies
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R.supplierAgencies.updateById = function() {
          var TargetResource = $injector.get("AgencySupplier");
          var action = TargetResource["::updateById::Supplier::supplierAgencies"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.listingSuppliers
     * @header lbServices.Supplier.listingSuppliers
     * @object
     * @description
     *
     * The object `Supplier.listingSuppliers` groups methods
     * manipulating `ListingSupplier` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#listingSuppliers Supplier.listingSuppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#listingSuppliers
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries listingSuppliers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::get::Supplier::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.listingSuppliers#count
             * @methodOf lbServices.Supplier.listingSuppliers
             *
             * @description
             *
             * Counts listingSuppliers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listingSuppliers.count = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::count::Supplier::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.listingSuppliers#create
             * @methodOf lbServices.Supplier.listingSuppliers
             *
             * @description
             *
             * Creates a new instance in listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.create = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::create::Supplier::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.listingSuppliers#createMany
             * @methodOf lbServices.Supplier.listingSuppliers
             *
             * @description
             *
             * Creates a new instance in listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.createMany = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::createMany::Supplier::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.listingSuppliers#destroyAll
             * @methodOf lbServices.Supplier.listingSuppliers
             *
             * @description
             *
             * Deletes all listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingSuppliers.destroyAll = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::delete::Supplier::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.listingSuppliers#destroyById
             * @methodOf lbServices.Supplier.listingSuppliers
             *
             * @description
             *
             * Delete a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingSuppliers.destroyById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::destroyById::Supplier::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.listingSuppliers#findById
             * @methodOf lbServices.Supplier.listingSuppliers
             *
             * @description
             *
             * Find a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.findById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::findById::Supplier::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.listingSuppliers#updateById
             * @methodOf lbServices.Supplier.listingSuppliers
             *
             * @description
             *
             * Update a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.updateById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::updateById::Supplier::listingSuppliers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.buyers
     * @header lbServices.Supplier.buyers
     * @object
     * @description
     *
     * The object `Supplier.buyers` groups methods
     * manipulating `Buyer` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#buyers Supplier.buyers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#buyers
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries buyers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#count
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Counts buyers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.buyers.count = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::count::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#create
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Creates a new instance in buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.create = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::create::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#createMany
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Creates a new instance in buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.createMany = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::createMany::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#destroyAll
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Deletes all buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.destroyAll = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::delete::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#destroyById
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Delete a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.destroyById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::destroyById::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#exists
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Check the existence of buyers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.exists = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::exists::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#findById
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Find a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.findById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::findById::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#link
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Add a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.link = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::link::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#unlink
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Remove the buyers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.unlink = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::unlink::Supplier::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.buyers#updateById
             * @methodOf lbServices.Supplier.buyers
             *
             * @description
             *
             * Update a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.updateById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::updateById::Supplier::buyers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Supplier.sellers
     * @header lbServices.Supplier.sellers
     * @object
     * @description
     *
     * The object `Supplier.sellers` groups methods
     * manipulating `Seller` instances related to `Supplier`.
     *
     * Call {@link lbServices.Supplier#sellers Supplier.sellers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Supplier#sellers
             * @methodOf lbServices.Supplier
             *
             * @description
             *
             * Queries sellers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::get::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#count
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Counts sellers of Supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sellers.count = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::count::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#create
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Creates a new instance in sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.create = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::create::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#createMany
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Creates a new instance in sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.createMany = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::createMany::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#destroyAll
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Deletes all sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.destroyAll = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::delete::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#destroyById
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Delete a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.destroyById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::destroyById::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#exists
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Check the existence of sellers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.exists = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::exists::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#findById
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Find a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.findById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::findById::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#link
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Add a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.link = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::link::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#unlink
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Remove the sellers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.unlink = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::unlink::Supplier::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Supplier.sellers#updateById
             * @methodOf lbServices.Supplier.sellers
             *
             * @description
             *
             * Update a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Supplier id
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.updateById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::updateById::Supplier::sellers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.EmailReceipt
 * @header lbServices.EmailReceipt
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `EmailReceipt` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "EmailReceipt",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/EmailReceipts/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#create
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/EmailReceipts",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#patchOrCreate
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/EmailReceipts",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#replaceOrCreate
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/EmailReceipts/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#upsertWithWhere
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/EmailReceipts/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#exists
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/EmailReceipts/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#findById
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/EmailReceipts/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#replaceById
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/EmailReceipts/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#find
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/EmailReceipts",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#findOne
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/EmailReceipts/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#updateAll
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/EmailReceipts/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#deleteById
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/EmailReceipts/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#count
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/EmailReceipts/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#prototype$patchAttributes
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailReceipt id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/EmailReceipts/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#createChangeStream
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/EmailReceipts/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#createMany
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/EmailReceipts",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#upsert
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#updateOrCreate
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#patchOrCreateWithWhere
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#update
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#destroyById
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#removeById
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EmailReceipt#prototype$updateAttributes
             * @methodOf lbServices.EmailReceipt
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailReceipt id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailReceipt` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.EmailReceipt#modelName
        * @propertyOf lbServices.EmailReceipt
        * @description
        * The name of the model represented by this $resource,
        * i.e. `EmailReceipt`.
        */
        R.modelName = "EmailReceipt";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Email
 * @header lbServices.Email
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Email` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Email",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Emails/:id",
          { 'id': '@id' },
          {
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Email#modelName
        * @propertyOf lbServices.Email
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Email`.
        */
        R.modelName = "Email";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Log
 * @header lbServices.Log
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Log` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Log",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Logs/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Log#prototype$__get__about
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Fetches belongsTo relation about.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Log id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "prototype$__get__about": {
              url: urlBase + "/Logs/:id/about",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#prototype$__get__related
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Fetches belongsTo relation related.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Log id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "prototype$__get__related": {
              url: urlBase + "/Logs/:id/related",
              method: "GET",
            },

            // INTERNAL. Use Log.relatedAgency() instead.
            "prototype$__get__relatedAgency": {
              url: urlBase + "/Logs/:id/relatedAgency",
              method: "GET",
            },

            // INTERNAL. Use Log.admin() instead.
            "prototype$__get__admin": {
              url: urlBase + "/Logs/:id/admin",
              method: "GET",
            },

            // INTERNAL. Use Log.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Logs/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#create
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Logs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#patchOrCreate
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Logs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#replaceOrCreate
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Logs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#upsertWithWhere
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Logs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#exists
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Logs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#findById
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Logs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#replaceById
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Logs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#find
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Logs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#findOne
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Logs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#updateAll
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Logs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#deleteById
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Logs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#count
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Logs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#prototype$patchAttributes
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Log id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Logs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#createChangeStream
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Logs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Log#createMany
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Logs",
              method: "POST",
            },

            // INTERNAL. Use Seller.logsAbout.findById() instead.
            "::findById::Seller::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.logsAbout.destroyById() instead.
            "::destroyById::Seller::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.logsAbout.updateById() instead.
            "::updateById::Seller::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.logsAbout() instead.
            "::get::Seller::logsAbout": {
              isArray: true,
              url: urlBase + "/Sellers/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use Seller.logsAbout.create() instead.
            "::create::Seller::logsAbout": {
              url: urlBase + "/Sellers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Seller.logsAbout.createMany() instead.
            "::createMany::Seller::logsAbout": {
              isArray: true,
              url: urlBase + "/Sellers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Seller.logsAbout.destroyAll() instead.
            "::delete::Seller::logsAbout": {
              url: urlBase + "/Sellers/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.logsAbout.count() instead.
            "::count::Seller::logsAbout": {
              url: urlBase + "/Sellers/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.logsAbout.findById() instead.
            "::findById::Buyer::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.logsAbout.destroyById() instead.
            "::destroyById::Buyer::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.logsAbout.updateById() instead.
            "::updateById::Buyer::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.logsAbout() instead.
            "::get::Buyer::logsAbout": {
              isArray: true,
              url: urlBase + "/Buyers/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use Buyer.logsAbout.create() instead.
            "::create::Buyer::logsAbout": {
              url: urlBase + "/Buyers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Buyer.logsAbout.createMany() instead.
            "::createMany::Buyer::logsAbout": {
              isArray: true,
              url: urlBase + "/Buyers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Buyer.logsAbout.destroyAll() instead.
            "::delete::Buyer::logsAbout": {
              url: urlBase + "/Buyers/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.logsAbout.count() instead.
            "::count::Buyer::logsAbout": {
              url: urlBase + "/Buyers/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.logsAbout.findById() instead.
            "::findById::Supplier::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.logsAbout.destroyById() instead.
            "::destroyById::Supplier::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.logsAbout.updateById() instead.
            "::updateById::Supplier::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.logsAbout() instead.
            "::get::Supplier::logsAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use Supplier.logsAbout.create() instead.
            "::create::Supplier::logsAbout": {
              url: urlBase + "/Suppliers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.logsAbout.createMany() instead.
            "::createMany::Supplier::logsAbout": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Supplier.logsAbout.destroyAll() instead.
            "::delete::Supplier::logsAbout": {
              url: urlBase + "/Suppliers/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.logsAbout.count() instead.
            "::count::Supplier::logsAbout": {
              url: urlBase + "/Suppliers/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Admin.logs.findById() instead.
            "::findById::Admin::logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Admins/:id/logs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Admin.logs.destroyById() instead.
            "::destroyById::Admin::logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Admins/:id/logs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Admin.logs.updateById() instead.
            "::updateById::Admin::logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Admins/:id/logs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Admin.logs() instead.
            "::get::Admin::logs": {
              isArray: true,
              url: urlBase + "/Admins/:id/logs",
              method: "GET",
            },

            // INTERNAL. Use Admin.logs.create() instead.
            "::create::Admin::logs": {
              url: urlBase + "/Admins/:id/logs",
              method: "POST",
            },

            // INTERNAL. Use Admin.logs.createMany() instead.
            "::createMany::Admin::logs": {
              isArray: true,
              url: urlBase + "/Admins/:id/logs",
              method: "POST",
            },

            // INTERNAL. Use Admin.logs.destroyAll() instead.
            "::delete::Admin::logs": {
              url: urlBase + "/Admins/:id/logs",
              method: "DELETE",
            },

            // INTERNAL. Use Admin.logs.count() instead.
            "::count::Admin::logs": {
              url: urlBase + "/Admins/:id/logs/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logs.findById() instead.
            "::findById::SbUser::logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logs/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logs.destroyById() instead.
            "::destroyById::SbUser::logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.logs.updateById() instead.
            "::updateById::SbUser::logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.logsAbout.findById() instead.
            "::findById::SbUser::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logsAbout.destroyById() instead.
            "::destroyById::SbUser::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.logsAbout.updateById() instead.
            "::updateById::SbUser::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.logs() instead.
            "::get::SbUser::logs": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/logs",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logs.create() instead.
            "::create::SbUser::logs": {
              url: urlBase + "/SbUsers/:id/logs",
              method: "POST",
            },

            // INTERNAL. Use SbUser.logs.createMany() instead.
            "::createMany::SbUser::logs": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/logs",
              method: "POST",
            },

            // INTERNAL. Use SbUser.logs.destroyAll() instead.
            "::delete::SbUser::logs": {
              url: urlBase + "/SbUsers/:id/logs",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.logs.count() instead.
            "::count::SbUser::logs": {
              url: urlBase + "/SbUsers/:id/logs/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logsAbout() instead.
            "::get::SbUser::logsAbout": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logsAbout.create() instead.
            "::create::SbUser::logsAbout": {
              url: urlBase + "/SbUsers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use SbUser.logsAbout.createMany() instead.
            "::createMany::SbUser::logsAbout": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use SbUser.logsAbout.destroyAll() instead.
            "::delete::SbUser::logsAbout": {
              url: urlBase + "/SbUsers/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.logsAbout.count() instead.
            "::count::SbUser::logsAbout": {
              url: urlBase + "/SbUsers/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.logsAbout.findById() instead.
            "::findById::Listing::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.logsAbout.destroyById() instead.
            "::destroyById::Listing::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.logsAbout.updateById() instead.
            "::updateById::Listing::logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.logsAbout() instead.
            "::get::Listing::logsAbout": {
              isArray: true,
              url: urlBase + "/Listings/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use Listing.logsAbout.create() instead.
            "::create::Listing::logsAbout": {
              url: urlBase + "/Listings/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Listing.logsAbout.createMany() instead.
            "::createMany::Listing::logsAbout": {
              isArray: true,
              url: urlBase + "/Listings/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Listing.logsAbout.destroyAll() instead.
            "::delete::Listing::logsAbout": {
              url: urlBase + "/Listings/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.logsAbout.count() instead.
            "::count::Listing::logsAbout": {
              url: urlBase + "/Listings/:id/logsAbout/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Log#upsert
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Log#updateOrCreate
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Log#patchOrCreateWithWhere
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Log#update
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Log#destroyById
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Log#removeById
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Log#prototype$updateAttributes
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Log id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Log#modelName
        * @propertyOf lbServices.Log
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Log`.
        */
        R.modelName = "Log";


            /**
             * @ngdoc method
             * @name lbServices.Log#relatedAgency
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Fetches belongsTo relation relatedAgency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Log id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.relatedAgency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Log::relatedAgency"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Log#admin
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Fetches belongsTo relation admin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Log id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R.admin = function() {
          var TargetResource = $injector.get("Admin");
          var action = TargetResource["::get::Log::admin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Log#user
             * @methodOf lbServices.Log
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Log id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Log::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Comment
 * @header lbServices.Comment
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Comment` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Comment",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Comments/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Comment.task() instead.
            "prototype$__get__task": {
              url: urlBase + "/Comments/:id/task",
              method: "GET",
            },

            // INTERNAL. Use Comment.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Comment.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Comment.owner() instead.
            "prototype$__get__owner": {
              url: urlBase + "/Comments/:id/owner",
              method: "GET",
            },

            // INTERNAL. Use Comment.notified.findById() instead.
            "prototype$__findById__notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/:fk",
              method: "GET",
            },

            // INTERNAL. Use Comment.notified.destroyById() instead.
            "prototype$__destroyById__notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.notified.updateById() instead.
            "prototype$__updateById__notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Comment.notified.link() instead.
            "prototype$__link__notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Comment.notified.unlink() instead.
            "prototype$__unlink__notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.notified.exists() instead.
            "prototype$__exists__notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Comment.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Comments/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Comment.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Comments/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Comment.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Comments/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Comments/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Comment.notified() instead.
            "prototype$__get__notified": {
              isArray: true,
              url: urlBase + "/Comments/:id/notified",
              method: "GET",
            },

            // INTERNAL. Use Comment.notified.create() instead.
            "prototype$__create__notified": {
              url: urlBase + "/Comments/:id/notified",
              method: "POST",
            },

            // INTERNAL. Use Comment.notified.destroyAll() instead.
            "prototype$__delete__notified": {
              url: urlBase + "/Comments/:id/notified",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.notified.count() instead.
            "prototype$__count__notified": {
              url: urlBase + "/Comments/:id/notified/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#create
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Comments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#patchOrCreate
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Comments",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#replaceOrCreate
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Comments/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#upsertWithWhere
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Comments/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#exists
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Comments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#findById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Comments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#replaceById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Comments/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#find
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Comments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#findOne
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Comments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#updateAll
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Comments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#deleteById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Comments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#count
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Comments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#prototype$patchAttributes
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Comments/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#createChangeStream
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Comments/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#downloadFile
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/Comments/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#downloadFiles
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/Comments/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#createMany
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Comments",
              method: "POST",
            },

            // INTERNAL. Use Task.comments.findById() instead.
            "::findById::Task::comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/comments/:fk",
              method: "GET",
            },

            // INTERNAL. Use Task.comments.destroyById() instead.
            "::destroyById::Task::comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/comments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Task.comments.updateById() instead.
            "::updateById::Task::comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/comments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Task.comments() instead.
            "::get::Task::comments": {
              isArray: true,
              url: urlBase + "/Tasks/:id/comments",
              method: "GET",
            },

            // INTERNAL. Use Task.comments.create() instead.
            "::create::Task::comments": {
              url: urlBase + "/Tasks/:id/comments",
              method: "POST",
            },

            // INTERNAL. Use Task.comments.createMany() instead.
            "::createMany::Task::comments": {
              isArray: true,
              url: urlBase + "/Tasks/:id/comments",
              method: "POST",
            },

            // INTERNAL. Use Task.comments.destroyAll() instead.
            "::delete::Task::comments": {
              url: urlBase + "/Tasks/:id/comments",
              method: "DELETE",
            },

            // INTERNAL. Use Task.comments.count() instead.
            "::count::Task::comments": {
              url: urlBase + "/Tasks/:id/comments/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Comment#upsert
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#updateOrCreate
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#patchOrCreateWithWhere
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#update
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#destroyById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#removeById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#prototype$updateAttributes
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Comment#modelName
        * @propertyOf lbServices.Comment
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Comment`.
        */
        R.modelName = "Comment";


            /**
             * @ngdoc method
             * @name lbServices.Comment#task
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Fetches belongsTo relation task.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.task = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Comment::task"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Comment.files
     * @header lbServices.Comment.files
     * @object
     * @description
     *
     * The object `Comment.files` groups methods
     * manipulating `SmartFile` instances related to `Comment`.
     *
     * Call {@link lbServices.Comment#files Comment.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Comment#files
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Queries files of Comment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Comment::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.files#count
             * @methodOf lbServices.Comment.files
             *
             * @description
             *
             * Counts files of Comment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Comment::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.files#create
             * @methodOf lbServices.Comment.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Comment::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.files#createMany
             * @methodOf lbServices.Comment.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Comment::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.files#destroyAll
             * @methodOf lbServices.Comment.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Comment::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.files#destroyById
             * @methodOf lbServices.Comment.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Comment::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.files#findById
             * @methodOf lbServices.Comment.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Comment::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.files#updateById
             * @methodOf lbServices.Comment.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Comment::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment#owner
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Fetches belongsTo relation owner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.owner = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Comment::owner"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Comment.notified
     * @header lbServices.Comment.notified
     * @object
     * @description
     *
     * The object `Comment.notified` groups methods
     * manipulating `SbUser` instances related to `Comment`.
     *
     * Call {@link lbServices.Comment#notified Comment.notified()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Comment#notified
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Queries notified of Comment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.notified = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#count
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Counts notified of Comment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notified.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#create
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Creates a new instance in notified of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.notified.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#createMany
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Creates a new instance in notified of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.notified.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#destroyAll
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Deletes all notified of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notified.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#destroyById
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Delete a related item by id for notified.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notified
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notified.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#exists
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Check the existence of notified relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notified
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.notified.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#findById
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Find a related item by id for notified.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notified
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.notified.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#link
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Add a related item by id for notified.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `fk` – `{*}` - Foreign key for notified
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.notified.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#unlink
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Remove the notified relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notified
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notified.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Comment::notified"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Comment.notified#updateById
             * @methodOf lbServices.Comment.notified
             *
             * @description
             *
             * Update a related item by id for notified.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Comment id
             *
             *  - `fk` – `{*}` - Foreign key for notified
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.notified.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Comment::notified"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SmartFile
 * @header lbServices.SmartFile
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SmartFile` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SmartFile",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/smartFiles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SmartFile.thumbnail() instead.
            "prototype$__get__thumbnail": {
              url: urlBase + "/smartFiles/:id/thumbnail",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.folder() instead.
            "prototype$__get__folder": {
              url: urlBase + "/smartFiles/:id/folder",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.listingDocument() instead.
            "prototype$__get__listingDocument": {
              url: urlBase + "/smartFiles/:id/listingDocument",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartFile.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartFile.brokers.link() instead.
            "prototype$__link__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartFile.brokers.unlink() instead.
            "prototype$__unlink__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartFile.brokers.exists() instead.
            "prototype$__exists__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartFile.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/smartFiles/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/smartFiles/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/smartFiles/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use SmartFile.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/smartFiles/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use SmartFile.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/smartFiles/:id/brokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#create
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/smartFiles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#patchOrCreate
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/smartFiles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#replaceOrCreate
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/smartFiles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#upsertWithWhere
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/smartFiles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#exists
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/smartFiles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#findById
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/smartFiles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#replaceById
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/smartFiles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#find
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/smartFiles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#findOne
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/smartFiles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#updateAll
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/smartFiles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#deleteById
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/smartFiles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#count
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/smartFiles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#prototype$patchAttributes
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/smartFiles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#createChangeStream
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/smartFiles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#prototype$getEnvelope
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "prototype$getEnvelope": {
              url: urlBase + "/smartFiles/:id/docusign/envelope",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#prototype$updateFromWebhook
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{*}` - The envelope coming from the webhook.
             *
             *  - `req` – `{object=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "prototype$updateFromWebhook": {
              url: urlBase + "/smartFiles/:id/updateFromWebhook",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#prototype$createEnvelope
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Creates an envelope using the file
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `url` – `{string=}` -
             */
            "prototype$createEnvelope": {
              url: urlBase + "/smartFiles/:id/docusign/create-envelope",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#createMany
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/smartFiles",
              method: "POST",
            },

            // INTERNAL. Use Message.files.findById() instead.
            "::findById::Message::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Message.files.destroyById() instead.
            "::destroyById::Message::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Message.files.updateById() instead.
            "::updateById::Message::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Message.files.link() instead.
            "::link::Message::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Message.files.unlink() instead.
            "::unlink::Message::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Message.files.exists() instead.
            "::exists::Message::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Messages/:id/files/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Message.files() instead.
            "::get::Message::files": {
              isArray: true,
              url: urlBase + "/Messages/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Message.files.create() instead.
            "::create::Message::files": {
              url: urlBase + "/Messages/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Message.files.createMany() instead.
            "::createMany::Message::files": {
              isArray: true,
              url: urlBase + "/Messages/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Message.files.destroyAll() instead.
            "::delete::Message::files": {
              url: urlBase + "/Messages/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Message.files.count() instead.
            "::count::Message::files": {
              url: urlBase + "/Messages/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Task.files.findById() instead.
            "::findById::Task::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Task.files.destroyById() instead.
            "::destroyById::Task::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Task.files.updateById() instead.
            "::updateById::Task::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tasks/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Task.files() instead.
            "::get::Task::files": {
              isArray: true,
              url: urlBase + "/Tasks/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Task.files.create() instead.
            "::create::Task::files": {
              url: urlBase + "/Tasks/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Task.files.createMany() instead.
            "::createMany::Task::files": {
              isArray: true,
              url: urlBase + "/Tasks/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Task.files.destroyAll() instead.
            "::delete::Task::files": {
              url: urlBase + "/Tasks/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Task.files.count() instead.
            "::count::Task::files": {
              url: urlBase + "/Tasks/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Comment.files.findById() instead.
            "::findById::Comment::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Comment.files.destroyById() instead.
            "::destroyById::Comment::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.files.updateById() instead.
            "::updateById::Comment::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Comment.files() instead.
            "::get::Comment::files": {
              isArray: true,
              url: urlBase + "/Comments/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Comment.files.create() instead.
            "::create::Comment::files": {
              url: urlBase + "/Comments/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Comment.files.createMany() instead.
            "::createMany::Comment::files": {
              isArray: true,
              url: urlBase + "/Comments/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Comment.files.destroyAll() instead.
            "::delete::Comment::files": {
              url: urlBase + "/Comments/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.files.count() instead.
            "::count::Comment::files": {
              url: urlBase + "/Comments/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.thumbnail() instead.
            "::get::SmartFile::thumbnail": {
              url: urlBase + "/smartFiles/:id/thumbnail",
              method: "GET",
            },

            // INTERNAL. Use Folder.files.findById() instead.
            "::findById::Folder::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Folders/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Folder.files.destroyById() instead.
            "::destroyById::Folder::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Folders/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Folder.files.updateById() instead.
            "::updateById::Folder::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Folders/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Folder.files() instead.
            "::get::Folder::files": {
              isArray: true,
              url: urlBase + "/Folders/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Folder.files.create() instead.
            "::create::Folder::files": {
              url: urlBase + "/Folders/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Folder.files.createMany() instead.
            "::createMany::Folder::files": {
              isArray: true,
              url: urlBase + "/Folders/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Folder.files.destroyAll() instead.
            "::delete::Folder::files": {
              url: urlBase + "/Folders/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Folder.files.count() instead.
            "::count::Folder::files": {
              url: urlBase + "/Folders/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use ListingVideoImage.file() instead.
            "::get::ListingVideoImage::file": {
              url: urlBase + "/ListingVideoImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingVideoImage.smartFile() instead.
            "::get::ListingVideoImage::smartFile": {
              url: urlBase + "/ListingVideoImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use ListingCaravanImage.file() instead.
            "::get::ListingCaravanImage::file": {
              url: urlBase + "/ListingCaravanImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingCaravanImage.smartFile() instead.
            "::get::ListingCaravanImage::smartFile": {
              url: urlBase + "/ListingCaravanImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use ListingFeatureImage.file() instead.
            "::get::ListingFeatureImage::file": {
              url: urlBase + "/ListingFeatureImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingFeatureImage.smartFile() instead.
            "::get::ListingFeatureImage::smartFile": {
              url: urlBase + "/ListingFeatureImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use ListingWebsiteImage.file() instead.
            "::get::ListingWebsiteImage::file": {
              url: urlBase + "/ListingWebsiteImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingWebsiteImage.smartFile() instead.
            "::get::ListingWebsiteImage::smartFile": {
              url: urlBase + "/ListingWebsiteImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use Offer.files.findById() instead.
            "::findById::Offer::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offers/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Offer.files.destroyById() instead.
            "::destroyById::Offer::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offers/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Offer.files.updateById() instead.
            "::updateById::Offer::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offers/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Offer.files() instead.
            "::get::Offer::files": {
              isArray: true,
              url: urlBase + "/Offers/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Offer.files.create() instead.
            "::create::Offer::files": {
              url: urlBase + "/Offers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Offer.files.createMany() instead.
            "::createMany::Offer::files": {
              isArray: true,
              url: urlBase + "/Offers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Offer.files.destroyAll() instead.
            "::delete::Offer::files": {
              url: urlBase + "/Offers/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Offer.files.count() instead.
            "::count::Offer::files": {
              url: urlBase + "/Offers/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.files.findById() instead.
            "::findById::EmailTemplate::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.files.destroyById() instead.
            "::destroyById::EmailTemplate::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EmailTemplate.files.updateById() instead.
            "::updateById::EmailTemplate::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EmailTemplate.files.link() instead.
            "::link::EmailTemplate::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EmailTemplate.files.unlink() instead.
            "::unlink::EmailTemplate::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EmailTemplate.files.exists() instead.
            "::exists::EmailTemplate::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use EmailTemplate.files() instead.
            "::get::EmailTemplate::files": {
              isArray: true,
              url: urlBase + "/EmailTemplates/:id/files",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.files.create() instead.
            "::create::EmailTemplate::files": {
              url: urlBase + "/EmailTemplates/:id/files",
              method: "POST",
            },

            // INTERNAL. Use EmailTemplate.files.createMany() instead.
            "::createMany::EmailTemplate::files": {
              isArray: true,
              url: urlBase + "/EmailTemplates/:id/files",
              method: "POST",
            },

            // INTERNAL. Use EmailTemplate.files.destroyAll() instead.
            "::delete::EmailTemplate::files": {
              url: urlBase + "/EmailTemplates/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use EmailTemplate.files.count() instead.
            "::count::EmailTemplate::files": {
              url: urlBase + "/EmailTemplates/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.files.findById() instead.
            "::findById::ListingDocument::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingDocuments/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.files.destroyById() instead.
            "::destroyById::ListingDocument::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingDocuments/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use ListingDocument.files.updateById() instead.
            "::updateById::ListingDocument::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingDocuments/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use ListingDocument.files() instead.
            "::get::ListingDocument::files": {
              isArray: true,
              url: urlBase + "/ListingDocuments/:id/files",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.files.create() instead.
            "::create::ListingDocument::files": {
              url: urlBase + "/ListingDocuments/:id/files",
              method: "POST",
            },

            // INTERNAL. Use ListingDocument.files.createMany() instead.
            "::createMany::ListingDocument::files": {
              isArray: true,
              url: urlBase + "/ListingDocuments/:id/files",
              method: "POST",
            },

            // INTERNAL. Use ListingDocument.files.destroyAll() instead.
            "::delete::ListingDocument::files": {
              url: urlBase + "/ListingDocuments/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use ListingDocument.files.count() instead.
            "::count::ListingDocument::files": {
              url: urlBase + "/ListingDocuments/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use ListingMlsImage.file() instead.
            "::get::ListingMlsImage::file": {
              url: urlBase + "/ListingMlsImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingMlsImage.smartFile() instead.
            "::get::ListingMlsImage::smartFile": {
              url: urlBase + "/ListingMlsImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.files.findById() instead.
            "::findById::SbUser::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.files.destroyById() instead.
            "::destroyById::SbUser::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.files.updateById() instead.
            "::updateById::SbUser::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.files() instead.
            "::get::SbUser::files": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/files",
              method: "GET",
            },

            // INTERNAL. Use SbUser.files.create() instead.
            "::create::SbUser::files": {
              url: urlBase + "/SbUsers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use SbUser.files.createMany() instead.
            "::createMany::SbUser::files": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use SbUser.files.destroyAll() instead.
            "::delete::SbUser::files": {
              url: urlBase + "/SbUsers/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.files.count() instead.
            "::count::SbUser::files": {
              url: urlBase + "/SbUsers/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.reports.findById() instead.
            "::findById::Broker::reports": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/reports/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.reports.destroyById() instead.
            "::destroyById::Broker::reports": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/reports/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.reports.updateById() instead.
            "::updateById::Broker::reports": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/reports/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.files.findById() instead.
            "::findById::Broker::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.files.destroyById() instead.
            "::destroyById::Broker::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.files.updateById() instead.
            "::updateById::Broker::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.reports() instead.
            "::get::Broker::reports": {
              isArray: true,
              url: urlBase + "/Brokers/:id/reports",
              method: "GET",
            },

            // INTERNAL. Use Broker.reports.create() instead.
            "::create::Broker::reports": {
              url: urlBase + "/Brokers/:id/reports",
              method: "POST",
            },

            // INTERNAL. Use Broker.reports.createMany() instead.
            "::createMany::Broker::reports": {
              isArray: true,
              url: urlBase + "/Brokers/:id/reports",
              method: "POST",
            },

            // INTERNAL. Use Broker.reports.destroyAll() instead.
            "::delete::Broker::reports": {
              url: urlBase + "/Brokers/:id/reports",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.reports.count() instead.
            "::count::Broker::reports": {
              url: urlBase + "/Brokers/:id/reports/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.files() instead.
            "::get::Broker::files": {
              isArray: true,
              url: urlBase + "/Brokers/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Broker.files.create() instead.
            "::create::Broker::files": {
              url: urlBase + "/Brokers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Broker.files.createMany() instead.
            "::createMany::Broker::files": {
              isArray: true,
              url: urlBase + "/Brokers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Broker.files.destroyAll() instead.
            "::delete::Broker::files": {
              url: urlBase + "/Brokers/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.files.count() instead.
            "::count::Broker::files": {
              url: urlBase + "/Brokers/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.files.findById() instead.
            "::findById::Listing::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.files.destroyById() instead.
            "::destroyById::Listing::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.files.updateById() instead.
            "::updateById::Listing::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.videoImages.findById() instead.
            "::findById::Listing::videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoImages.destroyById() instead.
            "::destroyById::Listing::videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoImages.updateById() instead.
            "::updateById::Listing::videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.videoImages.link() instead.
            "::link::Listing::videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.videoImages.unlink() instead.
            "::unlink::Listing::videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoImages.exists() instead.
            "::exists::Listing::videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.caravanImages.findById() instead.
            "::findById::Listing::caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanImages.destroyById() instead.
            "::destroyById::Listing::caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanImages.updateById() instead.
            "::updateById::Listing::caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.caravanImages.link() instead.
            "::link::Listing::caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.caravanImages.unlink() instead.
            "::unlink::Listing::caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanImages.exists() instead.
            "::exists::Listing::caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.featureImages.findById() instead.
            "::findById::Listing::featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.featureImages.destroyById() instead.
            "::destroyById::Listing::featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featureImages.updateById() instead.
            "::updateById::Listing::featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.featureImages.link() instead.
            "::link::Listing::featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.featureImages.unlink() instead.
            "::unlink::Listing::featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featureImages.exists() instead.
            "::exists::Listing::featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.websiteImages.findById() instead.
            "::findById::Listing::websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.websiteImages.destroyById() instead.
            "::destroyById::Listing::websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websiteImages.updateById() instead.
            "::updateById::Listing::websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.websiteImages.link() instead.
            "::link::Listing::websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.websiteImages.unlink() instead.
            "::unlink::Listing::websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websiteImages.exists() instead.
            "::exists::Listing::websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.mlsImages.findById() instead.
            "::findById::Listing::mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsImages.destroyById() instead.
            "::destroyById::Listing::mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsImages.updateById() instead.
            "::updateById::Listing::mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.mlsImages.link() instead.
            "::link::Listing::mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.mlsImages.unlink() instead.
            "::unlink::Listing::mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsImages.exists() instead.
            "::exists::Listing::mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.featureSheet() instead.
            "::get::Listing::featureSheet": {
              url: urlBase + "/Listings/:id/featureSheet",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanBulletin() instead.
            "::get::Listing::caravanBulletin": {
              url: urlBase + "/Listings/:id/caravanBulletin",
              method: "GET",
            },

            // INTERNAL. Use Listing.detailedFeatureSheet() instead.
            "::get::Listing::detailedFeatureSheet": {
              url: urlBase + "/Listings/:id/detailedFeatureSheet",
              method: "GET",
            },

            // INTERNAL. Use Listing.mainPhoto() instead.
            "::get::Listing::mainPhoto": {
              url: urlBase + "/Listings/:id/mainPhoto",
              method: "GET",
            },

            // INTERNAL. Use Listing.files() instead.
            "::get::Listing::files": {
              isArray: true,
              url: urlBase + "/Listings/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Listing.files.create() instead.
            "::create::Listing::files": {
              url: urlBase + "/Listings/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Listing.files.createMany() instead.
            "::createMany::Listing::files": {
              isArray: true,
              url: urlBase + "/Listings/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Listing.files.destroyAll() instead.
            "::delete::Listing::files": {
              url: urlBase + "/Listings/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.files.count() instead.
            "::count::Listing::files": {
              url: urlBase + "/Listings/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoImages() instead.
            "::get::Listing::videoImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/videoImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoImages.create() instead.
            "::create::Listing::videoImages": {
              url: urlBase + "/Listings/:id/videoImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.videoImages.createMany() instead.
            "::createMany::Listing::videoImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/videoImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.videoImages.destroyAll() instead.
            "::delete::Listing::videoImages": {
              url: urlBase + "/Listings/:id/videoImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoImages.count() instead.
            "::count::Listing::videoImages": {
              url: urlBase + "/Listings/:id/videoImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanImages() instead.
            "::get::Listing::caravanImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/caravanImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanImages.create() instead.
            "::create::Listing::caravanImages": {
              url: urlBase + "/Listings/:id/caravanImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.caravanImages.createMany() instead.
            "::createMany::Listing::caravanImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/caravanImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.caravanImages.destroyAll() instead.
            "::delete::Listing::caravanImages": {
              url: urlBase + "/Listings/:id/caravanImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanImages.count() instead.
            "::count::Listing::caravanImages": {
              url: urlBase + "/Listings/:id/caravanImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.featureImages() instead.
            "::get::Listing::featureImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/featureImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.featureImages.create() instead.
            "::create::Listing::featureImages": {
              url: urlBase + "/Listings/:id/featureImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.featureImages.createMany() instead.
            "::createMany::Listing::featureImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/featureImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.featureImages.destroyAll() instead.
            "::delete::Listing::featureImages": {
              url: urlBase + "/Listings/:id/featureImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featureImages.count() instead.
            "::count::Listing::featureImages": {
              url: urlBase + "/Listings/:id/featureImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.websiteImages() instead.
            "::get::Listing::websiteImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/websiteImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.websiteImages.create() instead.
            "::create::Listing::websiteImages": {
              url: urlBase + "/Listings/:id/websiteImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.websiteImages.createMany() instead.
            "::createMany::Listing::websiteImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/websiteImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.websiteImages.destroyAll() instead.
            "::delete::Listing::websiteImages": {
              url: urlBase + "/Listings/:id/websiteImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websiteImages.count() instead.
            "::count::Listing::websiteImages": {
              url: urlBase + "/Listings/:id/websiteImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsImages() instead.
            "::get::Listing::mlsImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/mlsImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsImages.create() instead.
            "::create::Listing::mlsImages": {
              url: urlBase + "/Listings/:id/mlsImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.mlsImages.createMany() instead.
            "::createMany::Listing::mlsImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/mlsImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.mlsImages.destroyAll() instead.
            "::delete::Listing::mlsImages": {
              url: urlBase + "/Listings/:id/mlsImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsImages.count() instead.
            "::count::Listing::mlsImages": {
              url: urlBase + "/Listings/:id/mlsImages/count",
              method: "GET",
            },

            // INTERNAL. Use Subscription.invoices.findById() instead.
            "::findById::Subscription::invoices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Subscriptions/:id/invoices/:fk",
              method: "GET",
            },

            // INTERNAL. Use Subscription.invoices.destroyById() instead.
            "::destroyById::Subscription::invoices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Subscriptions/:id/invoices/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Subscription.invoices.updateById() instead.
            "::updateById::Subscription::invoices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Subscriptions/:id/invoices/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Subscription.invoices() instead.
            "::get::Subscription::invoices": {
              isArray: true,
              url: urlBase + "/Subscriptions/:id/invoices",
              method: "GET",
            },

            // INTERNAL. Use Subscription.invoices.create() instead.
            "::create::Subscription::invoices": {
              url: urlBase + "/Subscriptions/:id/invoices",
              method: "POST",
            },

            // INTERNAL. Use Subscription.invoices.createMany() instead.
            "::createMany::Subscription::invoices": {
              isArray: true,
              url: urlBase + "/Subscriptions/:id/invoices",
              method: "POST",
            },

            // INTERNAL. Use Subscription.invoices.destroyAll() instead.
            "::delete::Subscription::invoices": {
              url: urlBase + "/Subscriptions/:id/invoices",
              method: "DELETE",
            },

            // INTERNAL. Use Subscription.invoices.count() instead.
            "::count::Subscription::invoices": {
              url: urlBase + "/Subscriptions/:id/invoices/count",
              method: "GET",
            },

            // INTERNAL. Use Conversation.files.findById() instead.
            "::findById::Conversation::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Conversation.files.destroyById() instead.
            "::destroyById::Conversation::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.files.updateById() instead.
            "::updateById::Conversation::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.files.link() instead.
            "::link::Conversation::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.files.unlink() instead.
            "::unlink::Conversation::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.files.exists() instead.
            "::exists::Conversation::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Conversation.files() instead.
            "::get::Conversation::files": {
              isArray: true,
              url: urlBase + "/Conversations/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Conversation.files.create() instead.
            "::create::Conversation::files": {
              url: urlBase + "/Conversations/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Conversation.files.createMany() instead.
            "::createMany::Conversation::files": {
              isArray: true,
              url: urlBase + "/Conversations/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Conversation.files.destroyAll() instead.
            "::delete::Conversation::files": {
              url: urlBase + "/Conversations/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.files.count() instead.
            "::count::Conversation::files": {
              url: urlBase + "/Conversations/:id/files/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SmartFile#upsert
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#updateOrCreate
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#patchOrCreateWithWhere
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#update
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#destroyById
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#removeById
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#prototype$updateAttributes
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SmartFile#modelName
        * @propertyOf lbServices.SmartFile
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SmartFile`.
        */
        R.modelName = "SmartFile";


            /**
             * @ngdoc method
             * @name lbServices.SmartFile#thumbnail
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Fetches belongsTo relation thumbnail.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.thumbnail = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::SmartFile::thumbnail"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#folder
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Fetches belongsTo relation folder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folder = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::SmartFile::folder"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#listingDocument
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Fetches belongsTo relation listingDocument.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R.listingDocument = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::get::SmartFile::listingDocument"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SmartFile.brokers
     * @header lbServices.SmartFile.brokers
     * @object
     * @description
     *
     * The object `SmartFile.brokers` groups methods
     * manipulating `Broker` instances related to `SmartFile`.
     *
     * Call {@link lbServices.SmartFile#brokers SmartFile.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SmartFile#brokers
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Queries brokers of SmartFile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#count
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Counts brokers of SmartFile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#create
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#createMany
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#destroyAll
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#destroyById
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#exists
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Check the existence of brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#findById
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#link
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Add a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#unlink
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Remove the brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile.brokers#updateById
             * @methodOf lbServices.SmartFile.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::SmartFile::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartFile#listing
             * @methodOf lbServices.SmartFile
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartFile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::SmartFile::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Container
 * @header lbServices.Container
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Container` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Container",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Containers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Container#getContainers
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getContainers": {
              isArray: true,
              url: urlBase + "/Containers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#createContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "createContainer": {
              url: urlBase + "/Containers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#destroyContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "destroyContainer": {
              url: urlBase + "/Containers/:container",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getContainer": {
              url: urlBase + "/Containers/:container",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getFiles
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getFiles": {
              isArray: true,
              url: urlBase + "/Containers/:container/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getFile
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             *  - `file` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getFile": {
              url: urlBase + "/Containers/:container/files/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#removeFile
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             *  - `file` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "removeFile": {
              url: urlBase + "/Containers/:container/files/:file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#upload
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `result` – `{object=}` -
             */
            "upload": {
              url: urlBase + "/Containers/:container/upload",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#download
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             *  - `file` – `{string}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "download": {
              url: urlBase + "/Containers/:container/download/:file",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Container#modelName
        * @propertyOf lbServices.Container
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Container`.
        */
        R.modelName = "Container";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Folder
 * @header lbServices.Folder
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Folder` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Folder",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Folders/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Folder.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Folders/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Folder.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Folders/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Folder.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Folders/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Folder.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Folders/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Folder.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Folders/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Folder.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/Folders/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use Folder.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/Folders/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Folder.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Folders/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Folder.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Folders/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Folder.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Folders/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Folder.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Folders/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#create
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Folders",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#patchOrCreate
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Folders",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#replaceOrCreate
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Folders/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#upsertWithWhere
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Folders/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#exists
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Folders/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#findById
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Folders/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#replaceById
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Folders/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#find
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Folders",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#findOne
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Folders/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#updateAll
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Folders/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#deleteById
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Folders/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#count
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Folders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#prototype$patchAttributes
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Folders/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#createChangeStream
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Folders/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#uploadFile
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Uploads an image for smartbrokr
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "uploadFile": {
              url: urlBase + "/Folders/:id/uploadFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#downloadFile
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/Folders/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#downloadFiles
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/Folders/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Folder#createMany
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Folders",
              method: "POST",
            },

            // INTERNAL. Use Agency.folders.findById() instead.
            "::findById::Agency::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.folders.destroyById() instead.
            "::destroyById::Agency::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.folders.updateById() instead.
            "::updateById::Agency::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.folders() instead.
            "::get::Agency::folders": {
              isArray: true,
              url: urlBase + "/Agencies/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use Agency.folders.create() instead.
            "::create::Agency::folders": {
              url: urlBase + "/Agencies/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Agency.folders.createMany() instead.
            "::createMany::Agency::folders": {
              isArray: true,
              url: urlBase + "/Agencies/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Agency.folders.destroyAll() instead.
            "::delete::Agency::folders": {
              url: urlBase + "/Agencies/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.folders.count() instead.
            "::count::Agency::folders": {
              url: urlBase + "/Agencies/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.folder() instead.
            "::get::SmartFile::folder": {
              url: urlBase + "/smartFiles/:id/folder",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.folders.findById() instead.
            "::findById::BuyerBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/BuyerBrokers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.folders.destroyById() instead.
            "::destroyById::BuyerBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/BuyerBrokers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use BuyerBroker.folders.updateById() instead.
            "::updateById::BuyerBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/BuyerBrokers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use BuyerBroker.folders() instead.
            "::get::BuyerBroker::folders": {
              isArray: true,
              url: urlBase + "/BuyerBrokers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.folders.create() instead.
            "::create::BuyerBroker::folders": {
              url: urlBase + "/BuyerBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use BuyerBroker.folders.createMany() instead.
            "::createMany::BuyerBroker::folders": {
              isArray: true,
              url: urlBase + "/BuyerBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use BuyerBroker.folders.destroyAll() instead.
            "::delete::BuyerBroker::folders": {
              url: urlBase + "/BuyerBrokers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use BuyerBroker.folders.count() instead.
            "::count::BuyerBroker::folders": {
              url: urlBase + "/BuyerBrokers/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.folders.findById() instead.
            "::findById::SellerBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SellerBrokers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.folders.destroyById() instead.
            "::destroyById::SellerBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SellerBrokers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SellerBroker.folders.updateById() instead.
            "::updateById::SellerBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SellerBrokers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SellerBroker.folders() instead.
            "::get::SellerBroker::folders": {
              isArray: true,
              url: urlBase + "/SellerBrokers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.folders.create() instead.
            "::create::SellerBroker::folders": {
              url: urlBase + "/SellerBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SellerBroker.folders.createMany() instead.
            "::createMany::SellerBroker::folders": {
              isArray: true,
              url: urlBase + "/SellerBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SellerBroker.folders.destroyAll() instead.
            "::delete::SellerBroker::folders": {
              url: urlBase + "/SellerBrokers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use SellerBroker.folders.count() instead.
            "::count::SellerBroker::folders": {
              url: urlBase + "/SellerBrokers/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.folders.findById() instead.
            "::findById::SupplierBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SupplierBrokers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.folders.destroyById() instead.
            "::destroyById::SupplierBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SupplierBrokers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SupplierBroker.folders.updateById() instead.
            "::updateById::SupplierBroker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SupplierBrokers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SupplierBroker.folders() instead.
            "::get::SupplierBroker::folders": {
              isArray: true,
              url: urlBase + "/SupplierBrokers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.folders.create() instead.
            "::create::SupplierBroker::folders": {
              url: urlBase + "/SupplierBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SupplierBroker.folders.createMany() instead.
            "::createMany::SupplierBroker::folders": {
              isArray: true,
              url: urlBase + "/SupplierBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SupplierBroker.folders.destroyAll() instead.
            "::delete::SupplierBroker::folders": {
              url: urlBase + "/SupplierBrokers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use SupplierBroker.folders.count() instead.
            "::count::SupplierBroker::folders": {
              url: urlBase + "/SupplierBrokers/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.folders.findById() instead.
            "::findById::SbUser::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.folders.destroyById() instead.
            "::destroyById::SbUser::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.folders.updateById() instead.
            "::updateById::SbUser::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.folders() instead.
            "::get::SbUser::folders": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use SbUser.folders.create() instead.
            "::create::SbUser::folders": {
              url: urlBase + "/SbUsers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SbUser.folders.createMany() instead.
            "::createMany::SbUser::folders": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SbUser.folders.destroyAll() instead.
            "::delete::SbUser::folders": {
              url: urlBase + "/SbUsers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.folders.count() instead.
            "::count::SbUser::folders": {
              url: urlBase + "/SbUsers/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.folders.findById() instead.
            "::findById::Broker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.folders.destroyById() instead.
            "::destroyById::Broker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.folders.updateById() instead.
            "::updateById::Broker::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.folders() instead.
            "::get::Broker::folders": {
              isArray: true,
              url: urlBase + "/Brokers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use Broker.folders.create() instead.
            "::create::Broker::folders": {
              url: urlBase + "/Brokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Broker.folders.createMany() instead.
            "::createMany::Broker::folders": {
              isArray: true,
              url: urlBase + "/Brokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Broker.folders.destroyAll() instead.
            "::delete::Broker::folders": {
              url: urlBase + "/Brokers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.folders.count() instead.
            "::count::Broker::folders": {
              url: urlBase + "/Brokers/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.folders.findById() instead.
            "::findById::Listing::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.folders.destroyById() instead.
            "::destroyById::Listing::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.folders.updateById() instead.
            "::updateById::Listing::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.folders() instead.
            "::get::Listing::folders": {
              isArray: true,
              url: urlBase + "/Listings/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use Listing.folders.create() instead.
            "::create::Listing::folders": {
              url: urlBase + "/Listings/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Listing.folders.createMany() instead.
            "::createMany::Listing::folders": {
              isArray: true,
              url: urlBase + "/Listings/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Listing.folders.destroyAll() instead.
            "::delete::Listing::folders": {
              url: urlBase + "/Listings/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.folders.count() instead.
            "::count::Listing::folders": {
              url: urlBase + "/Listings/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use AgencySupplier.folders.findById() instead.
            "::findById::AgencySupplier::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencySuppliers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencySupplier.folders.destroyById() instead.
            "::destroyById::AgencySupplier::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencySuppliers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencySupplier.folders.updateById() instead.
            "::updateById::AgencySupplier::folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencySuppliers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencySupplier.folders() instead.
            "::get::AgencySupplier::folders": {
              isArray: true,
              url: urlBase + "/AgencySuppliers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use AgencySupplier.folders.create() instead.
            "::create::AgencySupplier::folders": {
              url: urlBase + "/AgencySuppliers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use AgencySupplier.folders.createMany() instead.
            "::createMany::AgencySupplier::folders": {
              isArray: true,
              url: urlBase + "/AgencySuppliers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use AgencySupplier.folders.destroyAll() instead.
            "::delete::AgencySupplier::folders": {
              url: urlBase + "/AgencySuppliers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use AgencySupplier.folders.count() instead.
            "::count::AgencySupplier::folders": {
              url: urlBase + "/AgencySuppliers/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use HelpTopic.folder() instead.
            "::get::HelpTopic::folder": {
              url: urlBase + "/HelpTopics/:id/folder",
              method: "GET",
            },

            // INTERNAL. Use HelpTopic.folder.create() instead.
            "::create::HelpTopic::folder": {
              url: urlBase + "/HelpTopics/:id/folder",
              method: "POST",
            },

            // INTERNAL. Use HelpTopic.folder.createMany() instead.
            "::createMany::HelpTopic::folder": {
              isArray: true,
              url: urlBase + "/HelpTopics/:id/folder",
              method: "POST",
            },

            // INTERNAL. Use HelpTopic.folder.update() instead.
            "::update::HelpTopic::folder": {
              url: urlBase + "/HelpTopics/:id/folder",
              method: "PUT",
            },

            // INTERNAL. Use HelpTopic.folder.destroy() instead.
            "::destroy::HelpTopic::folder": {
              url: urlBase + "/HelpTopics/:id/folder",
              method: "DELETE",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Folder#upsert
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Folder#updateOrCreate
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Folder#patchOrCreateWithWhere
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Folder#update
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Folder#destroyById
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Folder#removeById
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Folder#prototype$updateAttributes
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Folder#modelName
        * @propertyOf lbServices.Folder
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Folder`.
        */
        R.modelName = "Folder";


            /**
             * @ngdoc method
             * @name lbServices.Folder#agency
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Folder::agency"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Folder.files
     * @header lbServices.Folder.files
     * @object
     * @description
     *
     * The object `Folder.files` groups methods
     * manipulating `SmartFile` instances related to `Folder`.
     *
     * Call {@link lbServices.Folder#files Folder.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Folder#files
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Queries files of Folder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Folder::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder.files#count
             * @methodOf lbServices.Folder.files
             *
             * @description
             *
             * Counts files of Folder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Folder::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder.files#create
             * @methodOf lbServices.Folder.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Folder::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder.files#createMany
             * @methodOf lbServices.Folder.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Folder::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder.files#destroyAll
             * @methodOf lbServices.Folder.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Folder::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder.files#destroyById
             * @methodOf lbServices.Folder.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Folder::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder.files#findById
             * @methodOf lbServices.Folder.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Folder::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder.files#updateById
             * @methodOf lbServices.Folder.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Folder::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder#user
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Folder::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder#broker
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Folder::broker"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Folder#listing
             * @methodOf lbServices.Folder
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Folder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Folder::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingBroker
 * @header lbServices.ListingBroker
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingBroker` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingBroker",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingBrokers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingBroker.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/ListingBrokers/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use ListingBroker.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingBrokers/:id/listing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#create
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingBrokers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#patchOrCreate
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingBrokers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#replaceOrCreate
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingBrokers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#upsertWithWhere
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingBrokers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#exists
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingBrokers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#findById
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingBrokers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#replaceById
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingBrokers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#find
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingBrokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#findOne
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingBrokers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#updateAll
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingBrokers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#deleteById
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingBrokers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#count
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingBrokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#prototype$patchAttributes
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingBrokers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#createChangeStream
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingBrokers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#createMany
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingBrokers",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingBrokers.findById() instead.
            "::findById::Listing::listingBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingBrokers.destroyById() instead.
            "::destroyById::Listing::listingBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingBrokers.updateById() instead.
            "::updateById::Listing::listingBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.listingBrokers() instead.
            "::get::Listing::listingBrokers": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingBrokers",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingBrokers.create() instead.
            "::create::Listing::listingBrokers": {
              url: urlBase + "/Listings/:id/listingBrokers",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingBrokers.createMany() instead.
            "::createMany::Listing::listingBrokers": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingBrokers",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingBrokers.destroyAll() instead.
            "::delete::Listing::listingBrokers": {
              url: urlBase + "/Listings/:id/listingBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingBrokers.count() instead.
            "::count::Listing::listingBrokers": {
              url: urlBase + "/Listings/:id/listingBrokers/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#upsert
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#updateOrCreate
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#patchOrCreateWithWhere
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#update
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#destroyById
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#removeById
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#prototype$updateAttributes
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingBroker#modelName
        * @propertyOf lbServices.ListingBroker
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingBroker`.
        */
        R.modelName = "ListingBroker";


            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#broker
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::ListingBroker::broker"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingBroker#listing
             * @methodOf lbServices.ListingBroker
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingBroker::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.BuyerBroker
 * @header lbServices.BuyerBroker
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `BuyerBroker` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "BuyerBroker",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/BuyerBrokers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use BuyerBroker.buyer() instead.
            "prototype$__get__buyer": {
              url: urlBase + "/BuyerBrokers/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.folders.findById() instead.
            "prototype$__findById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/BuyerBrokers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.folders.destroyById() instead.
            "prototype$__destroyById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/BuyerBrokers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use BuyerBroker.folders.updateById() instead.
            "prototype$__updateById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/BuyerBrokers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use BuyerBroker.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/BuyerBrokers/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.folders() instead.
            "prototype$__get__folders": {
              isArray: true,
              url: urlBase + "/BuyerBrokers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.folders.create() instead.
            "prototype$__create__folders": {
              url: urlBase + "/BuyerBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use BuyerBroker.folders.destroyAll() instead.
            "prototype$__delete__folders": {
              url: urlBase + "/BuyerBrokers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use BuyerBroker.folders.count() instead.
            "prototype$__count__folders": {
              url: urlBase + "/BuyerBrokers/:id/folders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#create
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/BuyerBrokers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#patchOrCreate
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/BuyerBrokers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#replaceOrCreate
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/BuyerBrokers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#upsertWithWhere
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/BuyerBrokers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#exists
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/BuyerBrokers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#findById
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/BuyerBrokers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#replaceById
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/BuyerBrokers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#find
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/BuyerBrokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#findOne
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/BuyerBrokers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#updateAll
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/BuyerBrokers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#deleteById
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/BuyerBrokers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#count
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/BuyerBrokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#prototype$patchAttributes
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/BuyerBrokers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#createChangeStream
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/BuyerBrokers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#createMany
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/BuyerBrokers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerBrokers.findById() instead.
            "::findById::Buyer::buyerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerBrokers.destroyById() instead.
            "::destroyById::Buyer::buyerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerBrokers.updateById() instead.
            "::updateById::Buyer::buyerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.buyerBrokers() instead.
            "::get::Buyer::buyerBrokers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerBrokers",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerBrokers.create() instead.
            "::create::Buyer::buyerBrokers": {
              url: urlBase + "/Buyers/:id/buyerBrokers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerBrokers.createMany() instead.
            "::createMany::Buyer::buyerBrokers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerBrokers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerBrokers.destroyAll() instead.
            "::delete::Buyer::buyerBrokers": {
              url: urlBase + "/Buyers/:id/buyerBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerBrokers.count() instead.
            "::count::Buyer::buyerBrokers": {
              url: urlBase + "/Buyers/:id/buyerBrokers/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#upsert
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#updateOrCreate
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#patchOrCreateWithWhere
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#update
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#destroyById
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#removeById
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#prototype$updateAttributes
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerBroker` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.BuyerBroker#modelName
        * @propertyOf lbServices.BuyerBroker
        * @description
        * The name of the model represented by this $resource,
        * i.e. `BuyerBroker`.
        */
        R.modelName = "BuyerBroker";


            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#buyer
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Fetches belongsTo relation buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyer = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::BuyerBroker::buyer"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.BuyerBroker.folders
     * @header lbServices.BuyerBroker.folders
     * @object
     * @description
     *
     * The object `BuyerBroker.folders` groups methods
     * manipulating `Folder` instances related to `BuyerBroker`.
     *
     * Call {@link lbServices.BuyerBroker#folders BuyerBroker.folders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#folders
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Queries folders of BuyerBroker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::BuyerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker.folders#count
             * @methodOf lbServices.BuyerBroker.folders
             *
             * @description
             *
             * Counts folders of BuyerBroker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.folders.count = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::count::BuyerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker.folders#create
             * @methodOf lbServices.BuyerBroker.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::BuyerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker.folders#createMany
             * @methodOf lbServices.BuyerBroker.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::BuyerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker.folders#destroyAll
             * @methodOf lbServices.BuyerBroker.folders
             *
             * @description
             *
             * Deletes all folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyAll = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::delete::BuyerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker.folders#destroyById
             * @methodOf lbServices.BuyerBroker.folders
             *
             * @description
             *
             * Delete a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroyById::BuyerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker.folders#findById
             * @methodOf lbServices.BuyerBroker.folders
             *
             * @description
             *
             * Find a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.findById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::findById::BuyerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker.folders#updateById
             * @methodOf lbServices.BuyerBroker.folders
             *
             * @description
             *
             * Update a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.updateById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::updateById::BuyerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerBroker#broker
             * @methodOf lbServices.BuyerBroker
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::BuyerBroker::broker"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SellerBroker
 * @header lbServices.SellerBroker
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SellerBroker` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SellerBroker",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SellerBrokers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SellerBroker.seller() instead.
            "prototype$__get__seller": {
              url: urlBase + "/SellerBrokers/:id/seller",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.folders.findById() instead.
            "prototype$__findById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SellerBrokers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.folders.destroyById() instead.
            "prototype$__destroyById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SellerBrokers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SellerBroker.folders.updateById() instead.
            "prototype$__updateById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SellerBrokers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SellerBroker.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/SellerBrokers/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.folders() instead.
            "prototype$__get__folders": {
              isArray: true,
              url: urlBase + "/SellerBrokers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.folders.create() instead.
            "prototype$__create__folders": {
              url: urlBase + "/SellerBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SellerBroker.folders.destroyAll() instead.
            "prototype$__delete__folders": {
              url: urlBase + "/SellerBrokers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use SellerBroker.folders.count() instead.
            "prototype$__count__folders": {
              url: urlBase + "/SellerBrokers/:id/folders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#create
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SellerBrokers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#patchOrCreate
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SellerBrokers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#replaceOrCreate
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SellerBrokers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#upsertWithWhere
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SellerBrokers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#exists
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SellerBrokers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#findById
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SellerBrokers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#replaceById
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SellerBrokers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#find
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SellerBrokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#findOne
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SellerBrokers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#updateAll
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SellerBrokers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#deleteById
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SellerBrokers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#count
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SellerBrokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#prototype$patchAttributes
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SellerBrokers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#createChangeStream
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SellerBrokers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#createMany
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SellerBrokers",
              method: "POST",
            },

            // INTERNAL. Use Seller.sellerBrokers.findById() instead.
            "::findById::Seller::sellerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/sellerBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.sellerBrokers.destroyById() instead.
            "::destroyById::Seller::sellerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/sellerBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.sellerBrokers.updateById() instead.
            "::updateById::Seller::sellerBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/sellerBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.sellerBrokers() instead.
            "::get::Seller::sellerBrokers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/sellerBrokers",
              method: "GET",
            },

            // INTERNAL. Use Seller.sellerBrokers.create() instead.
            "::create::Seller::sellerBrokers": {
              url: urlBase + "/Sellers/:id/sellerBrokers",
              method: "POST",
            },

            // INTERNAL. Use Seller.sellerBrokers.createMany() instead.
            "::createMany::Seller::sellerBrokers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/sellerBrokers",
              method: "POST",
            },

            // INTERNAL. Use Seller.sellerBrokers.destroyAll() instead.
            "::delete::Seller::sellerBrokers": {
              url: urlBase + "/Sellers/:id/sellerBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.sellerBrokers.count() instead.
            "::count::Seller::sellerBrokers": {
              url: urlBase + "/Sellers/:id/sellerBrokers/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#upsert
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#updateOrCreate
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#patchOrCreateWithWhere
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#update
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#destroyById
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#removeById
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#prototype$updateAttributes
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerBroker` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SellerBroker#modelName
        * @propertyOf lbServices.SellerBroker
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SellerBroker`.
        */
        R.modelName = "SellerBroker";


            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#seller
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Fetches belongsTo relation seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.seller = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::get::SellerBroker::seller"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SellerBroker.folders
     * @header lbServices.SellerBroker.folders
     * @object
     * @description
     *
     * The object `SellerBroker.folders` groups methods
     * manipulating `Folder` instances related to `SellerBroker`.
     *
     * Call {@link lbServices.SellerBroker#folders SellerBroker.folders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#folders
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Queries folders of SellerBroker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::SellerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker.folders#count
             * @methodOf lbServices.SellerBroker.folders
             *
             * @description
             *
             * Counts folders of SellerBroker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.folders.count = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::count::SellerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker.folders#create
             * @methodOf lbServices.SellerBroker.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::SellerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker.folders#createMany
             * @methodOf lbServices.SellerBroker.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::SellerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker.folders#destroyAll
             * @methodOf lbServices.SellerBroker.folders
             *
             * @description
             *
             * Deletes all folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyAll = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::delete::SellerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker.folders#destroyById
             * @methodOf lbServices.SellerBroker.folders
             *
             * @description
             *
             * Delete a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroyById::SellerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker.folders#findById
             * @methodOf lbServices.SellerBroker.folders
             *
             * @description
             *
             * Find a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.findById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::findById::SellerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker.folders#updateById
             * @methodOf lbServices.SellerBroker.folders
             *
             * @description
             *
             * Update a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.updateById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::updateById::SellerBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerBroker#broker
             * @methodOf lbServices.SellerBroker
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::SellerBroker::broker"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SupplierBroker
 * @header lbServices.SupplierBroker
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SupplierBroker` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SupplierBroker",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SupplierBrokers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SupplierBroker.supplier() instead.
            "prototype$__get__supplier": {
              url: urlBase + "/SupplierBrokers/:id/supplier",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.folders.findById() instead.
            "prototype$__findById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SupplierBrokers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.folders.destroyById() instead.
            "prototype$__destroyById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SupplierBrokers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SupplierBroker.folders.updateById() instead.
            "prototype$__updateById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SupplierBrokers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SupplierBroker.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/SupplierBrokers/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.folders() instead.
            "prototype$__get__folders": {
              isArray: true,
              url: urlBase + "/SupplierBrokers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.folders.create() instead.
            "prototype$__create__folders": {
              url: urlBase + "/SupplierBrokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SupplierBroker.folders.destroyAll() instead.
            "prototype$__delete__folders": {
              url: urlBase + "/SupplierBrokers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use SupplierBroker.folders.count() instead.
            "prototype$__count__folders": {
              url: urlBase + "/SupplierBrokers/:id/folders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#create
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SupplierBrokers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#patchOrCreate
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SupplierBrokers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#replaceOrCreate
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SupplierBrokers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#upsertWithWhere
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SupplierBrokers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#exists
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SupplierBrokers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#findById
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SupplierBrokers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#replaceById
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SupplierBrokers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#find
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SupplierBrokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#findOne
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SupplierBrokers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#updateAll
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SupplierBrokers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#deleteById
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SupplierBrokers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#count
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SupplierBrokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#prototype$patchAttributes
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SupplierBrokers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#createChangeStream
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SupplierBrokers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#createMany
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SupplierBrokers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.supplierBrokers.findById() instead.
            "::findById::Supplier::supplierBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierBrokers.destroyById() instead.
            "::destroyById::Supplier::supplierBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.supplierBrokers.updateById() instead.
            "::updateById::Supplier::supplierBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.supplierBrokers() instead.
            "::get::Supplier::supplierBrokers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/supplierBrokers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierBrokers.create() instead.
            "::create::Supplier::supplierBrokers": {
              url: urlBase + "/Suppliers/:id/supplierBrokers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.supplierBrokers.createMany() instead.
            "::createMany::Supplier::supplierBrokers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/supplierBrokers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.supplierBrokers.destroyAll() instead.
            "::delete::Supplier::supplierBrokers": {
              url: urlBase + "/Suppliers/:id/supplierBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.supplierBrokers.count() instead.
            "::count::Supplier::supplierBrokers": {
              url: urlBase + "/Suppliers/:id/supplierBrokers/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#upsert
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#updateOrCreate
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#patchOrCreateWithWhere
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#update
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#destroyById
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#removeById
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#prototype$updateAttributes
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupplierBroker` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SupplierBroker#modelName
        * @propertyOf lbServices.SupplierBroker
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SupplierBroker`.
        */
        R.modelName = "SupplierBroker";


            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#supplier
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Fetches belongsTo relation supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.supplier = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::SupplierBroker::supplier"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SupplierBroker.folders
     * @header lbServices.SupplierBroker.folders
     * @object
     * @description
     *
     * The object `SupplierBroker.folders` groups methods
     * manipulating `Folder` instances related to `SupplierBroker`.
     *
     * Call {@link lbServices.SupplierBroker#folders SupplierBroker.folders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#folders
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Queries folders of SupplierBroker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::SupplierBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker.folders#count
             * @methodOf lbServices.SupplierBroker.folders
             *
             * @description
             *
             * Counts folders of SupplierBroker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.folders.count = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::count::SupplierBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker.folders#create
             * @methodOf lbServices.SupplierBroker.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::SupplierBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker.folders#createMany
             * @methodOf lbServices.SupplierBroker.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::SupplierBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker.folders#destroyAll
             * @methodOf lbServices.SupplierBroker.folders
             *
             * @description
             *
             * Deletes all folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyAll = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::delete::SupplierBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker.folders#destroyById
             * @methodOf lbServices.SupplierBroker.folders
             *
             * @description
             *
             * Delete a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroyById::SupplierBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker.folders#findById
             * @methodOf lbServices.SupplierBroker.folders
             *
             * @description
             *
             * Find a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.findById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::findById::SupplierBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker.folders#updateById
             * @methodOf lbServices.SupplierBroker.folders
             *
             * @description
             *
             * Update a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.updateById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::updateById::SupplierBroker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SupplierBroker#broker
             * @methodOf lbServices.SupplierBroker
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SupplierBroker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::SupplierBroker::broker"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingSeller
 * @header lbServices.ListingSeller
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingSeller` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingSeller",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingSellers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingSeller.seller() instead.
            "prototype$__get__seller": {
              url: urlBase + "/ListingSellers/:id/seller",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingSellers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.findById() instead.
            "prototype$__findById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingSellers/:id/listingSuppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.destroyById() instead.
            "prototype$__destroyById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingSellers/:id/listingSuppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.updateById() instead.
            "prototype$__updateById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingSellers/:id/listingSuppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers() instead.
            "prototype$__get__listingSuppliers": {
              isArray: true,
              url: urlBase + "/ListingSellers/:id/listingSuppliers",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.create() instead.
            "prototype$__create__listingSuppliers": {
              url: urlBase + "/ListingSellers/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.destroyAll() instead.
            "prototype$__delete__listingSuppliers": {
              url: urlBase + "/ListingSellers/:id/listingSuppliers",
              method: "DELETE",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.count() instead.
            "prototype$__count__listingSuppliers": {
              url: urlBase + "/ListingSellers/:id/listingSuppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#create
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingSellers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#patchOrCreate
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingSellers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#replaceOrCreate
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingSellers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#upsertWithWhere
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingSellers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#exists
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingSellers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#findById
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingSellers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#replaceById
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingSellers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#find
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingSellers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#findOne
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingSellers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#updateAll
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingSellers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#deleteById
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingSellers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#count
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingSellers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#prototype$patchAttributes
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingSellers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#createChangeStream
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingSellers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#deleteSupplier
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Gets suppliers related to a ListingSeller relation
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `supplierId` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "deleteSupplier": {
              url: urlBase + "/ListingSellers/deleteSupplier",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#getSuppliers
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Gets suppliers related to a ListingSeller relation
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `supplierId` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Array of suppliers that were created by the currently logged in seller
             */
            "getSuppliers": {
              isArray: true,
              url: urlBase + "/ListingSellers/getSuppliers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#loginSeller
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Seller login.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `token` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with Access token for seller login and seller profile
             */
            "loginSeller": {
              url: urlBase + "/ListingSellers/sellerLogin",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#logoutSeller
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Seller logout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result of the operation.
             */
            "logoutSeller": {
              url: urlBase + "/ListingSellers/sellerLogout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#saveSupplier
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Saves a supplier as related to this ListingSeller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `supplier` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result of the operation.
             */
            "saveSupplier": {
              url: urlBase + "/ListingSellers/saveSupplier",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#createMany
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingSellers",
              method: "POST",
            },

            // INTERNAL. Use ListingSupplier.listingSeller() instead.
            "::get::ListingSupplier::listingSeller": {
              url: urlBase + "/ListingSuppliers/:id/listingSeller",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#upsert
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#updateOrCreate
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#patchOrCreateWithWhere
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#update
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#destroyById
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#removeById
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#prototype$updateAttributes
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingSeller#modelName
        * @propertyOf lbServices.ListingSeller
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingSeller`.
        */
        R.modelName = "ListingSeller";


            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#seller
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Fetches belongsTo relation seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.seller = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::get::ListingSeller::seller"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#listing
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingSeller::listing"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.ListingSeller.listingSuppliers
     * @header lbServices.ListingSeller.listingSuppliers
     * @object
     * @description
     *
     * The object `ListingSeller.listingSuppliers` groups methods
     * manipulating `ListingSupplier` instances related to `ListingSeller`.
     *
     * Call {@link lbServices.ListingSeller#listingSuppliers ListingSeller.listingSuppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.ListingSeller#listingSuppliers
             * @methodOf lbServices.ListingSeller
             *
             * @description
             *
             * Queries listingSuppliers of ListingSeller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::get::ListingSeller::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller.listingSuppliers#count
             * @methodOf lbServices.ListingSeller.listingSuppliers
             *
             * @description
             *
             * Counts listingSuppliers of ListingSeller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listingSuppliers.count = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::count::ListingSeller::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller.listingSuppliers#create
             * @methodOf lbServices.ListingSeller.listingSuppliers
             *
             * @description
             *
             * Creates a new instance in listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.create = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::create::ListingSeller::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller.listingSuppliers#createMany
             * @methodOf lbServices.ListingSeller.listingSuppliers
             *
             * @description
             *
             * Creates a new instance in listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.createMany = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::createMany::ListingSeller::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller.listingSuppliers#destroyAll
             * @methodOf lbServices.ListingSeller.listingSuppliers
             *
             * @description
             *
             * Deletes all listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingSuppliers.destroyAll = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::delete::ListingSeller::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller.listingSuppliers#destroyById
             * @methodOf lbServices.ListingSeller.listingSuppliers
             *
             * @description
             *
             * Delete a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingSuppliers.destroyById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::destroyById::ListingSeller::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller.listingSuppliers#findById
             * @methodOf lbServices.ListingSeller.listingSuppliers
             *
             * @description
             *
             * Find a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.findById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::findById::ListingSeller::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSeller.listingSuppliers#updateById
             * @methodOf lbServices.ListingSeller.listingSuppliers
             *
             * @description
             *
             * Update a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSeller id
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.updateById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::updateById::ListingSeller::listingSuppliers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingNotary
 * @header lbServices.ListingNotary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingNotary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingNotary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingNotaries/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingNotary.notary() instead.
            "prototype$__get__notary": {
              url: urlBase + "/ListingNotaries/:id/notary",
              method: "GET",
            },

            // INTERNAL. Use ListingNotary.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingNotaries/:id/listing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#create
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingNotaries",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#patchOrCreate
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingNotaries",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#replaceOrCreate
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingNotaries/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#upsertWithWhere
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingNotaries/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#exists
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingNotaries/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#findById
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingNotaries/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#replaceById
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingNotaries/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#find
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingNotaries",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#findOne
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingNotaries/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#updateAll
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingNotaries/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#deleteById
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingNotaries/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#count
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingNotaries/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#prototype$patchAttributes
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingNotary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingNotaries/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#createChangeStream
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingNotaries/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#createMany
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingNotaries",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingNotaries.findById() instead.
            "::findById::Listing::listingNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingNotaries/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingNotaries.destroyById() instead.
            "::destroyById::Listing::listingNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingNotaries/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingNotaries.updateById() instead.
            "::updateById::Listing::listingNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingNotaries/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.listingNotaries() instead.
            "::get::Listing::listingNotaries": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingNotaries",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingNotaries.create() instead.
            "::create::Listing::listingNotaries": {
              url: urlBase + "/Listings/:id/listingNotaries",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingNotaries.createMany() instead.
            "::createMany::Listing::listingNotaries": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingNotaries",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingNotaries.destroyAll() instead.
            "::delete::Listing::listingNotaries": {
              url: urlBase + "/Listings/:id/listingNotaries",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingNotaries.count() instead.
            "::count::Listing::listingNotaries": {
              url: urlBase + "/Listings/:id/listingNotaries/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#upsert
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#updateOrCreate
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#patchOrCreateWithWhere
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#update
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#destroyById
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#removeById
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#prototype$updateAttributes
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingNotary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingNotary#modelName
        * @propertyOf lbServices.ListingNotary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingNotary`.
        */
        R.modelName = "ListingNotary";


            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#notary
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Fetches belongsTo relation notary.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingNotary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notary = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::get::ListingNotary::notary"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingNotary#listing
             * @methodOf lbServices.ListingNotary
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingNotary id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingNotary::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingVideoImage
 * @header lbServices.ListingVideoImage
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingVideoImage` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingVideoImage",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingVideoImages/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingVideoImage.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/ListingVideoImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingVideoImage.smartFile() instead.
            "prototype$__get__smartFile": {
              url: urlBase + "/ListingVideoImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use ListingVideoImage.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingVideoImages/:id/listing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#create
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingVideoImages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#patchOrCreate
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingVideoImages",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#replaceOrCreate
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingVideoImages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#upsertWithWhere
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingVideoImages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#exists
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingVideoImages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#findById
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingVideoImages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#replaceById
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingVideoImages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#find
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingVideoImages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#findOne
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingVideoImages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#updateAll
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingVideoImages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#deleteById
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingVideoImages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#count
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingVideoImages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#prototype$patchAttributes
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingVideoImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingVideoImages/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#createChangeStream
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingVideoImages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#createMany
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingVideoImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.videoPhotos.findById() instead.
            "::findById::Listing::videoPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoPhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoPhotos.destroyById() instead.
            "::destroyById::Listing::videoPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoPhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoPhotos.updateById() instead.
            "::updateById::Listing::videoPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoPhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.videoPhotos() instead.
            "::get::Listing::videoPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/videoPhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoPhotos.create() instead.
            "::create::Listing::videoPhotos": {
              url: urlBase + "/Listings/:id/videoPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.videoPhotos.createMany() instead.
            "::createMany::Listing::videoPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/videoPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.videoPhotos.destroyAll() instead.
            "::delete::Listing::videoPhotos": {
              url: urlBase + "/Listings/:id/videoPhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoPhotos.count() instead.
            "::count::Listing::videoPhotos": {
              url: urlBase + "/Listings/:id/videoPhotos/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#upsert
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#updateOrCreate
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#patchOrCreateWithWhere
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#update
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#destroyById
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#removeById
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#prototype$updateAttributes
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingVideoImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingVideoImage#modelName
        * @propertyOf lbServices.ListingVideoImage
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingVideoImage`.
        */
        R.modelName = "ListingVideoImage";


            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#file
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Fetches belongsTo relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingVideoImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingVideoImage::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#smartFile
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Fetches belongsTo relation smartFile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingVideoImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.smartFile = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingVideoImage::smartFile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingVideoImage#listing
             * @methodOf lbServices.ListingVideoImage
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingVideoImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingVideoImage::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingCaravanImage
 * @header lbServices.ListingCaravanImage
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingCaravanImage` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingCaravanImage",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingCaravanImages/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingCaravanImage.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/ListingCaravanImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingCaravanImage.smartFile() instead.
            "prototype$__get__smartFile": {
              url: urlBase + "/ListingCaravanImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use ListingCaravanImage.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingCaravanImages/:id/listing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#create
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingCaravanImages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#patchOrCreate
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingCaravanImages",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#replaceOrCreate
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingCaravanImages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#upsertWithWhere
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingCaravanImages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#exists
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingCaravanImages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#findById
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingCaravanImages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#replaceById
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingCaravanImages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#find
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingCaravanImages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#findOne
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingCaravanImages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#updateAll
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingCaravanImages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#deleteById
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingCaravanImages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#count
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingCaravanImages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#prototype$patchAttributes
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingCaravanImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingCaravanImages/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#createChangeStream
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingCaravanImages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#createMany
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingCaravanImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.caravanPhotos.findById() instead.
            "::findById::Listing::caravanPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanPhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanPhotos.destroyById() instead.
            "::destroyById::Listing::caravanPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanPhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanPhotos.updateById() instead.
            "::updateById::Listing::caravanPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanPhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.caravanPhotos() instead.
            "::get::Listing::caravanPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/caravanPhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanPhotos.create() instead.
            "::create::Listing::caravanPhotos": {
              url: urlBase + "/Listings/:id/caravanPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.caravanPhotos.createMany() instead.
            "::createMany::Listing::caravanPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/caravanPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.caravanPhotos.destroyAll() instead.
            "::delete::Listing::caravanPhotos": {
              url: urlBase + "/Listings/:id/caravanPhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanPhotos.count() instead.
            "::count::Listing::caravanPhotos": {
              url: urlBase + "/Listings/:id/caravanPhotos/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#upsert
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#updateOrCreate
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#patchOrCreateWithWhere
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#update
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#destroyById
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#removeById
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#prototype$updateAttributes
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingCaravanImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingCaravanImage#modelName
        * @propertyOf lbServices.ListingCaravanImage
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingCaravanImage`.
        */
        R.modelName = "ListingCaravanImage";


            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#file
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Fetches belongsTo relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingCaravanImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingCaravanImage::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#smartFile
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Fetches belongsTo relation smartFile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingCaravanImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.smartFile = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingCaravanImage::smartFile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingCaravanImage#listing
             * @methodOf lbServices.ListingCaravanImage
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingCaravanImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingCaravanImage::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingFeatureImage
 * @header lbServices.ListingFeatureImage
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingFeatureImage` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingFeatureImage",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingFeatureImages/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingFeatureImage.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/ListingFeatureImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingFeatureImage.smartFile() instead.
            "prototype$__get__smartFile": {
              url: urlBase + "/ListingFeatureImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use ListingFeatureImage.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingFeatureImages/:id/listing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#create
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingFeatureImages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#patchOrCreate
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingFeatureImages",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#replaceOrCreate
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingFeatureImages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#upsertWithWhere
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingFeatureImages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#exists
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingFeatureImages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#findById
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingFeatureImages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#replaceById
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingFeatureImages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#find
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingFeatureImages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#findOne
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingFeatureImages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#updateAll
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingFeatureImages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#deleteById
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingFeatureImages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#count
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingFeatureImages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#prototype$patchAttributes
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingFeatureImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingFeatureImages/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#createChangeStream
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingFeatureImages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#createMany
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingFeatureImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.featurePhotos.findById() instead.
            "::findById::Listing::featurePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featurePhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.featurePhotos.destroyById() instead.
            "::destroyById::Listing::featurePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featurePhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featurePhotos.updateById() instead.
            "::updateById::Listing::featurePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featurePhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.featurePhotos() instead.
            "::get::Listing::featurePhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/featurePhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.featurePhotos.create() instead.
            "::create::Listing::featurePhotos": {
              url: urlBase + "/Listings/:id/featurePhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.featurePhotos.createMany() instead.
            "::createMany::Listing::featurePhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/featurePhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.featurePhotos.destroyAll() instead.
            "::delete::Listing::featurePhotos": {
              url: urlBase + "/Listings/:id/featurePhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featurePhotos.count() instead.
            "::count::Listing::featurePhotos": {
              url: urlBase + "/Listings/:id/featurePhotos/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#upsert
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#updateOrCreate
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#patchOrCreateWithWhere
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#update
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#destroyById
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#removeById
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#prototype$updateAttributes
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingFeatureImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingFeatureImage#modelName
        * @propertyOf lbServices.ListingFeatureImage
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingFeatureImage`.
        */
        R.modelName = "ListingFeatureImage";


            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#file
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Fetches belongsTo relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingFeatureImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingFeatureImage::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#smartFile
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Fetches belongsTo relation smartFile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingFeatureImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.smartFile = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingFeatureImage::smartFile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingFeatureImage#listing
             * @methodOf lbServices.ListingFeatureImage
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingFeatureImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingFeatureImage::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingWebsiteImage
 * @header lbServices.ListingWebsiteImage
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingWebsiteImage` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingWebsiteImage",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingWebsiteImages/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingWebsiteImage.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/ListingWebsiteImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingWebsiteImage.smartFile() instead.
            "prototype$__get__smartFile": {
              url: urlBase + "/ListingWebsiteImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use ListingWebsiteImage.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingWebsiteImages/:id/listing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#create
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingWebsiteImages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#patchOrCreate
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingWebsiteImages",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#replaceOrCreate
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingWebsiteImages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#upsertWithWhere
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingWebsiteImages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#exists
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingWebsiteImages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#findById
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingWebsiteImages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#replaceById
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingWebsiteImages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#find
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingWebsiteImages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#findOne
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingWebsiteImages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#updateAll
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingWebsiteImages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#deleteById
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingWebsiteImages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#count
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingWebsiteImages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#prototype$patchAttributes
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingWebsiteImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingWebsiteImages/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#createChangeStream
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingWebsiteImages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#createMany
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingWebsiteImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.websitePhotos.findById() instead.
            "::findById::Listing::websitePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websitePhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.websitePhotos.destroyById() instead.
            "::destroyById::Listing::websitePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websitePhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websitePhotos.updateById() instead.
            "::updateById::Listing::websitePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websitePhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.websitePhotos() instead.
            "::get::Listing::websitePhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/websitePhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.websitePhotos.create() instead.
            "::create::Listing::websitePhotos": {
              url: urlBase + "/Listings/:id/websitePhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.websitePhotos.createMany() instead.
            "::createMany::Listing::websitePhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/websitePhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.websitePhotos.destroyAll() instead.
            "::delete::Listing::websitePhotos": {
              url: urlBase + "/Listings/:id/websitePhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websitePhotos.count() instead.
            "::count::Listing::websitePhotos": {
              url: urlBase + "/Listings/:id/websitePhotos/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#upsert
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#updateOrCreate
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#patchOrCreateWithWhere
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#update
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#destroyById
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#removeById
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#prototype$updateAttributes
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingWebsiteImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingWebsiteImage#modelName
        * @propertyOf lbServices.ListingWebsiteImage
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingWebsiteImage`.
        */
        R.modelName = "ListingWebsiteImage";


            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#file
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Fetches belongsTo relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingWebsiteImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingWebsiteImage::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#smartFile
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Fetches belongsTo relation smartFile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingWebsiteImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.smartFile = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingWebsiteImage::smartFile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingWebsiteImage#listing
             * @methodOf lbServices.ListingWebsiteImage
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingWebsiteImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingWebsiteImage::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Office
 * @header lbServices.Office
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Office` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Office",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Offices/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Office.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Offices/:id/agency",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#prototype$__get__address
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Fetches hasOne relation address.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "prototype$__get__address": {
              url: urlBase + "/Offices/:id/address",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#prototype$__create__address
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Creates a new instance in address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "prototype$__create__address": {
              url: urlBase + "/Offices/:id/address",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#prototype$__update__address
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Update address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "prototype$__update__address": {
              url: urlBase + "/Offices/:id/address",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#prototype$__destroy__address
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Deletes address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroy__address": {
              url: urlBase + "/Offices/:id/address",
              method: "DELETE",
            },

            // INTERNAL. Use Office.owners.findById() instead.
            "prototype$__findById__owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/owners/:fk",
              method: "GET",
            },

            // INTERNAL. Use Office.owners.destroyById() instead.
            "prototype$__destroyById__owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/owners/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Office.owners.updateById() instead.
            "prototype$__updateById__owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/owners/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Office.members.findById() instead.
            "prototype$__findById__members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/members/:fk",
              method: "GET",
            },

            // INTERNAL. Use Office.members.destroyById() instead.
            "prototype$__destroyById__members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/members/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Office.members.updateById() instead.
            "prototype$__updateById__members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/members/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Office.director() instead.
            "prototype$__get__director": {
              url: urlBase + "/Offices/:id/director",
              method: "GET",
            },

            // INTERNAL. Use Office.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Office.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Office.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Office.owners() instead.
            "prototype$__get__owners": {
              isArray: true,
              url: urlBase + "/Offices/:id/owners",
              method: "GET",
            },

            // INTERNAL. Use Office.owners.create() instead.
            "prototype$__create__owners": {
              url: urlBase + "/Offices/:id/owners",
              method: "POST",
            },

            // INTERNAL. Use Office.owners.destroyAll() instead.
            "prototype$__delete__owners": {
              url: urlBase + "/Offices/:id/owners",
              method: "DELETE",
            },

            // INTERNAL. Use Office.owners.count() instead.
            "prototype$__count__owners": {
              url: urlBase + "/Offices/:id/owners/count",
              method: "GET",
            },

            // INTERNAL. Use Office.members() instead.
            "prototype$__get__members": {
              isArray: true,
              url: urlBase + "/Offices/:id/members",
              method: "GET",
            },

            // INTERNAL. Use Office.members.create() instead.
            "prototype$__create__members": {
              url: urlBase + "/Offices/:id/members",
              method: "POST",
            },

            // INTERNAL. Use Office.members.destroyAll() instead.
            "prototype$__delete__members": {
              url: urlBase + "/Offices/:id/members",
              method: "DELETE",
            },

            // INTERNAL. Use Office.members.count() instead.
            "prototype$__count__members": {
              url: urlBase + "/Offices/:id/members/count",
              method: "GET",
            },

            // INTERNAL. Use Office.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/Offices/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Office.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/Offices/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Office.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/Offices/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Office.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/Offices/:id/brokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#create
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Offices",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#patchOrCreate
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Offices",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#replaceOrCreate
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Offices/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#upsertWithWhere
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Offices/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#exists
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Offices/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#findById
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Offices/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#replaceById
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Offices/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#find
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Offices",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#findOne
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Offices/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#updateAll
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Offices/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#deleteById
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Offices/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#count
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Offices/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#prototype$patchAttributes
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Offices/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#createChangeStream
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Offices/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Office#createMany
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Offices",
              method: "POST",
            },

            // INTERNAL. Use Agency.offices.findById() instead.
            "::findById::Agency::offices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/offices/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.offices.destroyById() instead.
            "::destroyById::Agency::offices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/offices/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.offices.updateById() instead.
            "::updateById::Agency::offices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/offices/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.offices() instead.
            "::get::Agency::offices": {
              isArray: true,
              url: urlBase + "/Agencies/:id/offices",
              method: "GET",
            },

            // INTERNAL. Use Agency.offices.create() instead.
            "::create::Agency::offices": {
              url: urlBase + "/Agencies/:id/offices",
              method: "POST",
            },

            // INTERNAL. Use Agency.offices.createMany() instead.
            "::createMany::Agency::offices": {
              isArray: true,
              url: urlBase + "/Agencies/:id/offices",
              method: "POST",
            },

            // INTERNAL. Use Agency.offices.destroyAll() instead.
            "::delete::Agency::offices": {
              url: urlBase + "/Agencies/:id/offices",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.offices.count() instead.
            "::count::Agency::offices": {
              url: urlBase + "/Agencies/:id/offices/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.office() instead.
            "::get::AgencyOwner::office": {
              url: urlBase + "/AgencyOwners/:id/office",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.office() instead.
            "::get::AgencyMember::office": {
              url: urlBase + "/AgencyMembers/:id/office",
              method: "GET",
            },

            // INTERNAL. Use Broker.office() instead.
            "::get::Broker::office": {
              url: urlBase + "/Brokers/:id/office",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Office#upsert
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Office#updateOrCreate
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Office#patchOrCreateWithWhere
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Office#update
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Office#destroyById
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Office#removeById
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Office#prototype$updateAttributes
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Office#modelName
        * @propertyOf lbServices.Office
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Office`.
        */
        R.modelName = "Office";


            /**
             * @ngdoc method
             * @name lbServices.Office#agency
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Office::agency"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Office.owners
     * @header lbServices.Office.owners
     * @object
     * @description
     *
     * The object `Office.owners` groups methods
     * manipulating `AgencyOwner` instances related to `Office`.
     *
     * Call {@link lbServices.Office#owners Office.owners()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Office#owners
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Queries owners of Office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::get::Office::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.owners#count
             * @methodOf lbServices.Office.owners
             *
             * @description
             *
             * Counts owners of Office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.owners.count = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::count::Office::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.owners#create
             * @methodOf lbServices.Office.owners
             *
             * @description
             *
             * Creates a new instance in owners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners.create = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::create::Office::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.owners#createMany
             * @methodOf lbServices.Office.owners
             *
             * @description
             *
             * Creates a new instance in owners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners.createMany = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::createMany::Office::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.owners#destroyAll
             * @methodOf lbServices.Office.owners
             *
             * @description
             *
             * Deletes all owners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.owners.destroyAll = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::delete::Office::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.owners#destroyById
             * @methodOf lbServices.Office.owners
             *
             * @description
             *
             * Delete a related item by id for owners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for owners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.owners.destroyById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::destroyById::Office::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.owners#findById
             * @methodOf lbServices.Office.owners
             *
             * @description
             *
             * Find a related item by id for owners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for owners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners.findById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::findById::Office::owners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.owners#updateById
             * @methodOf lbServices.Office.owners
             *
             * @description
             *
             * Update a related item by id for owners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `fk` – `{*}` - Foreign key for owners
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.owners.updateById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::updateById::Office::owners"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Office.members
     * @header lbServices.Office.members
     * @object
     * @description
     *
     * The object `Office.members` groups methods
     * manipulating `AgencyMember` instances related to `Office`.
     *
     * Call {@link lbServices.Office#members Office.members()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Office#members
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Queries members of Office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::get::Office::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.members#count
             * @methodOf lbServices.Office.members
             *
             * @description
             *
             * Counts members of Office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.members.count = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::count::Office::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.members#create
             * @methodOf lbServices.Office.members
             *
             * @description
             *
             * Creates a new instance in members of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members.create = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::create::Office::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.members#createMany
             * @methodOf lbServices.Office.members
             *
             * @description
             *
             * Creates a new instance in members of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members.createMany = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::createMany::Office::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.members#destroyAll
             * @methodOf lbServices.Office.members
             *
             * @description
             *
             * Deletes all members of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.members.destroyAll = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::delete::Office::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.members#destroyById
             * @methodOf lbServices.Office.members
             *
             * @description
             *
             * Delete a related item by id for members.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for members
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.members.destroyById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::destroyById::Office::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.members#findById
             * @methodOf lbServices.Office.members
             *
             * @description
             *
             * Find a related item by id for members.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for members
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members.findById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::findById::Office::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.members#updateById
             * @methodOf lbServices.Office.members
             *
             * @description
             *
             * Update a related item by id for members.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `fk` – `{*}` - Foreign key for members
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.members.updateById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::updateById::Office::members"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office#director
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Fetches belongsTo relation director.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.director = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Office::director"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Office.brokers
     * @header lbServices.Office.brokers
     * @object
     * @description
     *
     * The object `Office.brokers` groups methods
     * manipulating `Broker` instances related to `Office`.
     *
     * Call {@link lbServices.Office#brokers Office.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Office#brokers
             * @methodOf lbServices.Office
             *
             * @description
             *
             * Queries brokers of Office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Office::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.brokers#count
             * @methodOf lbServices.Office.brokers
             *
             * @description
             *
             * Counts brokers of Office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::Office::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.brokers#create
             * @methodOf lbServices.Office.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::Office::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.brokers#createMany
             * @methodOf lbServices.Office.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::Office::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.brokers#destroyAll
             * @methodOf lbServices.Office.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::Office::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.brokers#destroyById
             * @methodOf lbServices.Office.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::Office::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.brokers#findById
             * @methodOf lbServices.Office.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::Office::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Office.brokers#updateById
             * @methodOf lbServices.Office.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Office id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::Office::brokers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Offer
 * @header lbServices.Offer
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Offer` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Offer",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Offers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Offer.buyer() instead.
            "prototype$__get__buyer": {
              url: urlBase + "/Offers/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use Offer.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offers/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Offer.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offers/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Offer.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offers/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Offer.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/Offers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Offer.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Offers/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Offer.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Offers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Offer.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Offers/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Offer.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Offers/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#create
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Offers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#patchOrCreate
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Offers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#replaceOrCreate
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Offers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#upsertWithWhere
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Offers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#exists
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Offers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#findById
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Offers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#replaceById
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Offers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#find
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Offers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#findOne
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Offers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#updateAll
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Offers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#deleteById
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Offers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#count
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Offers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#prototype$patchAttributes
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Offers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#createChangeStream
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Offers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Offer#createMany
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Offers",
              method: "POST",
            },

            // INTERNAL. Use Listing.offers.findById() instead.
            "::findById::Listing::offers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/offers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.offers.destroyById() instead.
            "::destroyById::Listing::offers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/offers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.offers.updateById() instead.
            "::updateById::Listing::offers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/offers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.offers() instead.
            "::get::Listing::offers": {
              isArray: true,
              url: urlBase + "/Listings/:id/offers",
              method: "GET",
            },

            // INTERNAL. Use Listing.offers.create() instead.
            "::create::Listing::offers": {
              url: urlBase + "/Listings/:id/offers",
              method: "POST",
            },

            // INTERNAL. Use Listing.offers.createMany() instead.
            "::createMany::Listing::offers": {
              isArray: true,
              url: urlBase + "/Listings/:id/offers",
              method: "POST",
            },

            // INTERNAL. Use Listing.offers.destroyAll() instead.
            "::delete::Listing::offers": {
              url: urlBase + "/Listings/:id/offers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.offers.count() instead.
            "::count::Listing::offers": {
              url: urlBase + "/Listings/:id/offers/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Offer#upsert
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Offer#updateOrCreate
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Offer#patchOrCreateWithWhere
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Offer#update
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Offer#destroyById
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Offer#removeById
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Offer#prototype$updateAttributes
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Offer#modelName
        * @propertyOf lbServices.Offer
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Offer`.
        */
        R.modelName = "Offer";


            /**
             * @ngdoc method
             * @name lbServices.Offer#buyer
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Fetches belongsTo relation buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyer = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::Offer::buyer"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Offer.files
     * @header lbServices.Offer.files
     * @object
     * @description
     *
     * The object `Offer.files` groups methods
     * manipulating `SmartFile` instances related to `Offer`.
     *
     * Call {@link lbServices.Offer#files Offer.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Offer#files
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Queries files of Offer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Offer::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Offer.files#count
             * @methodOf lbServices.Offer.files
             *
             * @description
             *
             * Counts files of Offer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Offer::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Offer.files#create
             * @methodOf lbServices.Offer.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Offer::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Offer.files#createMany
             * @methodOf lbServices.Offer.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Offer::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Offer.files#destroyAll
             * @methodOf lbServices.Offer.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Offer::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Offer.files#destroyById
             * @methodOf lbServices.Offer.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Offer::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Offer.files#findById
             * @methodOf lbServices.Offer.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Offer::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Offer.files#updateById
             * @methodOf lbServices.Offer.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Offer::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Offer#listing
             * @methodOf lbServices.Offer
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Offer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Offer::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.TaskTemplate
 * @header lbServices.TaskTemplate
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `TaskTemplate` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "TaskTemplate",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/TaskTemplates/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use TaskTemplate.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/TaskTemplates/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.taskList.findById() instead.
            "prototype$__findById__taskList": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/TaskTemplates/:id/taskList/:fk",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.taskList.destroyById() instead.
            "prototype$__destroyById__taskList": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/TaskTemplates/:id/taskList/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use TaskTemplate.taskList.updateById() instead.
            "prototype$__updateById__taskList": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/TaskTemplates/:id/taskList/:fk",
              method: "PUT",
            },

            // INTERNAL. Use TaskTemplate.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/TaskTemplates/:id/user",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.taskList() instead.
            "prototype$__get__taskList": {
              isArray: true,
              url: urlBase + "/TaskTemplates/:id/taskList",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.taskList.create() instead.
            "prototype$__create__taskList": {
              url: urlBase + "/TaskTemplates/:id/taskList",
              method: "POST",
            },

            // INTERNAL. Use TaskTemplate.taskList.destroyAll() instead.
            "prototype$__delete__taskList": {
              url: urlBase + "/TaskTemplates/:id/taskList",
              method: "DELETE",
            },

            // INTERNAL. Use TaskTemplate.taskList.count() instead.
            "prototype$__count__taskList": {
              url: urlBase + "/TaskTemplates/:id/taskList/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#create
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/TaskTemplates",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#patchOrCreate
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/TaskTemplates",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#replaceOrCreate
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/TaskTemplates/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#upsertWithWhere
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/TaskTemplates/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#exists
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/TaskTemplates/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#findById
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/TaskTemplates/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#replaceById
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/TaskTemplates/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#find
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/TaskTemplates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#findOne
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/TaskTemplates/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#updateAll
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/TaskTemplates/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#deleteById
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/TaskTemplates/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#count
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/TaskTemplates/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#prototype$patchAttributes
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/TaskTemplates/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#createChangeStream
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/TaskTemplates/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#createMany
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/TaskTemplates",
              method: "POST",
            },

            // INTERNAL. Use Agency.taskTemplates.findById() instead.
            "::findById::Agency::taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/taskTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.taskTemplates.destroyById() instead.
            "::destroyById::Agency::taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/taskTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.taskTemplates.updateById() instead.
            "::updateById::Agency::taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/taskTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.taskTemplates() instead.
            "::get::Agency::taskTemplates": {
              isArray: true,
              url: urlBase + "/Agencies/:id/taskTemplates",
              method: "GET",
            },

            // INTERNAL. Use Agency.taskTemplates.create() instead.
            "::create::Agency::taskTemplates": {
              url: urlBase + "/Agencies/:id/taskTemplates",
              method: "POST",
            },

            // INTERNAL. Use Agency.taskTemplates.createMany() instead.
            "::createMany::Agency::taskTemplates": {
              isArray: true,
              url: urlBase + "/Agencies/:id/taskTemplates",
              method: "POST",
            },

            // INTERNAL. Use Agency.taskTemplates.destroyAll() instead.
            "::delete::Agency::taskTemplates": {
              url: urlBase + "/Agencies/:id/taskTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.taskTemplates.count() instead.
            "::count::Agency::taskTemplates": {
              url: urlBase + "/Agencies/:id/taskTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.taskTemplates.findById() instead.
            "::findById::SbUser::taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.taskTemplates.destroyById() instead.
            "::destroyById::SbUser::taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.taskTemplates.updateById() instead.
            "::updateById::SbUser::taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.findById() instead.
            "::findById::SbUser::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.destroyById() instead.
            "::destroyById::SbUser::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.updateById() instead.
            "::updateById::SbUser::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.link() instead.
            "::link::SbUser::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.unlink() instead.
            "::unlink::SbUser::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.exists() instead.
            "::exists::SbUser::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.taskTemplates() instead.
            "::get::SbUser::taskTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/taskTemplates",
              method: "GET",
            },

            // INTERNAL. Use SbUser.taskTemplates.create() instead.
            "::create::SbUser::taskTemplates": {
              url: urlBase + "/SbUsers/:id/taskTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.taskTemplates.createMany() instead.
            "::createMany::SbUser::taskTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/taskTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.taskTemplates.destroyAll() instead.
            "::delete::SbUser::taskTemplates": {
              url: urlBase + "/SbUsers/:id/taskTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.taskTemplates.count() instead.
            "::count::SbUser::taskTemplates": {
              url: urlBase + "/SbUsers/:id/taskTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates() instead.
            "::get::SbUser::sharedTaskTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.create() instead.
            "::create::SbUser::sharedTaskTemplates": {
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.createMany() instead.
            "::createMany::SbUser::sharedTaskTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.destroyAll() instead.
            "::delete::SbUser::sharedTaskTemplates": {
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.count() instead.
            "::count::SbUser::sharedTaskTemplates": {
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.findById() instead.
            "::findById::Broker::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.destroyById() instead.
            "::destroyById::Broker::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.updateById() instead.
            "::updateById::Broker::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.link() instead.
            "::link::Broker::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.unlink() instead.
            "::unlink::Broker::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.exists() instead.
            "::exists::Broker::sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates() instead.
            "::get::Broker::sharedTaskTemplates": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedTaskTemplates",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.create() instead.
            "::create::Broker::sharedTaskTemplates": {
              url: urlBase + "/Brokers/:id/sharedTaskTemplates",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.createMany() instead.
            "::createMany::Broker::sharedTaskTemplates": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedTaskTemplates",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.destroyAll() instead.
            "::delete::Broker::sharedTaskTemplates": {
              url: urlBase + "/Brokers/:id/sharedTaskTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.count() instead.
            "::count::Broker::sharedTaskTemplates": {
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SharedTaskTemplate.taskTemplate() instead.
            "::get::SharedTaskTemplate::taskTemplate": {
              url: urlBase + "/SharedTaskTemplates/:id/taskTemplate",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#upsert
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#updateOrCreate
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#patchOrCreateWithWhere
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#update
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#destroyById
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#removeById
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#prototype$updateAttributes
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.TaskTemplate#modelName
        * @propertyOf lbServices.TaskTemplate
        * @description
        * The name of the model represented by this $resource,
        * i.e. `TaskTemplate`.
        */
        R.modelName = "TaskTemplate";


            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#agency
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::TaskTemplate::agency"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.TaskTemplate.taskList
     * @header lbServices.TaskTemplate.taskList
     * @object
     * @description
     *
     * The object `TaskTemplate.taskList` groups methods
     * manipulating `TaskModel` instances related to `TaskTemplate`.
     *
     * Call {@link lbServices.TaskTemplate#taskList TaskTemplate.taskList()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#taskList
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Queries taskList of TaskTemplate.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskModel` object.)
             * </em>
             */
        R.taskList = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::get::TaskTemplate::taskList"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate.taskList#count
             * @methodOf lbServices.TaskTemplate.taskList
             *
             * @description
             *
             * Counts taskList of TaskTemplate.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.taskList.count = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::count::TaskTemplate::taskList"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate.taskList#create
             * @methodOf lbServices.TaskTemplate.taskList
             *
             * @description
             *
             * Creates a new instance in taskList of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskModel` object.)
             * </em>
             */
        R.taskList.create = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::create::TaskTemplate::taskList"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate.taskList#createMany
             * @methodOf lbServices.TaskTemplate.taskList
             *
             * @description
             *
             * Creates a new instance in taskList of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskModel` object.)
             * </em>
             */
        R.taskList.createMany = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::createMany::TaskTemplate::taskList"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate.taskList#destroyAll
             * @methodOf lbServices.TaskTemplate.taskList
             *
             * @description
             *
             * Deletes all taskList of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.taskList.destroyAll = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::delete::TaskTemplate::taskList"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate.taskList#destroyById
             * @methodOf lbServices.TaskTemplate.taskList
             *
             * @description
             *
             * Delete a related item by id for taskList.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for taskList
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.taskList.destroyById = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::destroyById::TaskTemplate::taskList"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate.taskList#findById
             * @methodOf lbServices.TaskTemplate.taskList
             *
             * @description
             *
             * Find a related item by id for taskList.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for taskList
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskModel` object.)
             * </em>
             */
        R.taskList.findById = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::findById::TaskTemplate::taskList"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate.taskList#updateById
             * @methodOf lbServices.TaskTemplate.taskList
             *
             * @description
             *
             * Update a related item by id for taskList.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `fk` – `{*}` - Foreign key for taskList
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskModel` object.)
             * </em>
             */
        R.taskList.updateById = function() {
          var TargetResource = $injector.get("TaskModel");
          var action = TargetResource["::updateById::TaskTemplate::taskList"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.TaskTemplate#user
             * @methodOf lbServices.TaskTemplate
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - TaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::TaskTemplate::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.EmailTemplate
 * @header lbServices.EmailTemplate
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `EmailTemplate` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "EmailTemplate",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/EmailTemplates/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use EmailTemplate.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/EmailTemplates/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EmailTemplate.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EmailTemplate.files.link() instead.
            "prototype$__link__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EmailTemplate.files.unlink() instead.
            "prototype$__unlink__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EmailTemplate.files.exists() instead.
            "prototype$__exists__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/EmailTemplates/:id/files/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use EmailTemplate.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/EmailTemplates/:id/user",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/EmailTemplates/:id/files",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/EmailTemplates/:id/files",
              method: "POST",
            },

            // INTERNAL. Use EmailTemplate.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/EmailTemplates/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use EmailTemplate.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/EmailTemplates/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#create
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/EmailTemplates",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#patchOrCreate
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/EmailTemplates",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#replaceOrCreate
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/EmailTemplates/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#upsertWithWhere
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/EmailTemplates/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#exists
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/EmailTemplates/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#findById
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/EmailTemplates/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#replaceById
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/EmailTemplates/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#find
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/EmailTemplates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#findOne
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/EmailTemplates/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#updateAll
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/EmailTemplates/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#deleteById
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/EmailTemplates/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#count
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/EmailTemplates/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#prototype$patchAttributes
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/EmailTemplates/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#createChangeStream
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/EmailTemplates/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#createMany
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/EmailTemplates",
              method: "POST",
            },

            // INTERNAL. Use Agency.emailTemplates.findById() instead.
            "::findById::Agency::emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/emailTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.emailTemplates.destroyById() instead.
            "::destroyById::Agency::emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/emailTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.emailTemplates.updateById() instead.
            "::updateById::Agency::emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/emailTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.emailTemplates() instead.
            "::get::Agency::emailTemplates": {
              isArray: true,
              url: urlBase + "/Agencies/:id/emailTemplates",
              method: "GET",
            },

            // INTERNAL. Use Agency.emailTemplates.create() instead.
            "::create::Agency::emailTemplates": {
              url: urlBase + "/Agencies/:id/emailTemplates",
              method: "POST",
            },

            // INTERNAL. Use Agency.emailTemplates.createMany() instead.
            "::createMany::Agency::emailTemplates": {
              isArray: true,
              url: urlBase + "/Agencies/:id/emailTemplates",
              method: "POST",
            },

            // INTERNAL. Use Agency.emailTemplates.destroyAll() instead.
            "::delete::Agency::emailTemplates": {
              url: urlBase + "/Agencies/:id/emailTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.emailTemplates.count() instead.
            "::count::Agency::emailTemplates": {
              url: urlBase + "/Agencies/:id/emailTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.emailTemplates.findById() instead.
            "::findById::SbUser::emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/emailTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.emailTemplates.destroyById() instead.
            "::destroyById::SbUser::emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/emailTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.emailTemplates.updateById() instead.
            "::updateById::SbUser::emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/emailTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.findById() instead.
            "::findById::SbUser::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.destroyById() instead.
            "::destroyById::SbUser::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.updateById() instead.
            "::updateById::SbUser::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.link() instead.
            "::link::SbUser::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.unlink() instead.
            "::unlink::SbUser::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.exists() instead.
            "::exists::SbUser::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.emailTemplates() instead.
            "::get::SbUser::emailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/emailTemplates",
              method: "GET",
            },

            // INTERNAL. Use SbUser.emailTemplates.create() instead.
            "::create::SbUser::emailTemplates": {
              url: urlBase + "/SbUsers/:id/emailTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.emailTemplates.createMany() instead.
            "::createMany::SbUser::emailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/emailTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.emailTemplates.destroyAll() instead.
            "::delete::SbUser::emailTemplates": {
              url: urlBase + "/SbUsers/:id/emailTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.emailTemplates.count() instead.
            "::count::SbUser::emailTemplates": {
              url: urlBase + "/SbUsers/:id/emailTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates() instead.
            "::get::SbUser::sharedEmailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.create() instead.
            "::create::SbUser::sharedEmailTemplates": {
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.createMany() instead.
            "::createMany::SbUser::sharedEmailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.destroyAll() instead.
            "::delete::SbUser::sharedEmailTemplates": {
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.count() instead.
            "::count::SbUser::sharedEmailTemplates": {
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.findById() instead.
            "::findById::Broker::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.destroyById() instead.
            "::destroyById::Broker::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.updateById() instead.
            "::updateById::Broker::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.link() instead.
            "::link::Broker::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.unlink() instead.
            "::unlink::Broker::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.exists() instead.
            "::exists::Broker::sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates() instead.
            "::get::Broker::sharedEmailTemplates": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedEmailTemplates",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.create() instead.
            "::create::Broker::sharedEmailTemplates": {
              url: urlBase + "/Brokers/:id/sharedEmailTemplates",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.createMany() instead.
            "::createMany::Broker::sharedEmailTemplates": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedEmailTemplates",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.destroyAll() instead.
            "::delete::Broker::sharedEmailTemplates": {
              url: urlBase + "/Brokers/:id/sharedEmailTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.count() instead.
            "::count::Broker::sharedEmailTemplates": {
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SharedEmailTemplate.emailTemplate() instead.
            "::get::SharedEmailTemplate::emailTemplate": {
              url: urlBase + "/SharedEmailTemplates/:id/emailTemplate",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#upsert
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#updateOrCreate
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#patchOrCreateWithWhere
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#update
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#destroyById
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#removeById
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#prototype$updateAttributes
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.EmailTemplate#modelName
        * @propertyOf lbServices.EmailTemplate
        * @description
        * The name of the model represented by this $resource,
        * i.e. `EmailTemplate`.
        */
        R.modelName = "EmailTemplate";


            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#agency
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::EmailTemplate::agency"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.EmailTemplate.files
     * @header lbServices.EmailTemplate.files
     * @object
     * @description
     *
     * The object `EmailTemplate.files` groups methods
     * manipulating `SmartFile` instances related to `EmailTemplate`.
     *
     * Call {@link lbServices.EmailTemplate#files EmailTemplate.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#files
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Queries files of EmailTemplate.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#count
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Counts files of EmailTemplate.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#create
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#createMany
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#destroyAll
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#destroyById
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#exists
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Check the existence of files relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.exists = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::exists::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#findById
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#link
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Add a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.link = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::link::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#unlink
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Remove the files relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.unlink = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::unlink::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate.files#updateById
             * @methodOf lbServices.EmailTemplate.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::EmailTemplate::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EmailTemplate#user
             * @methodOf lbServices.EmailTemplate
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::EmailTemplate::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingDocument
 * @header lbServices.ListingDocument
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingDocument` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingDocument",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingDocuments/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingDocument.documentType() instead.
            "prototype$__get__documentType": {
              url: urlBase + "/ListingDocuments/:id/documentType",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingDocuments/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingDocuments/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use ListingDocument.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingDocuments/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use ListingDocument.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingDocuments/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/ListingDocuments/:id/files",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/ListingDocuments/:id/files",
              method: "POST",
            },

            // INTERNAL. Use ListingDocument.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/ListingDocuments/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use ListingDocument.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/ListingDocuments/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#create
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingDocuments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#patchOrCreate
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingDocuments",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#replaceOrCreate
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingDocuments/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#upsertWithWhere
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingDocuments/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#exists
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingDocuments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#findById
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingDocuments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#replaceById
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingDocuments/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#find
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingDocuments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#findOne
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingDocuments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#updateAll
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingDocuments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#deleteById
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingDocuments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#count
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingDocuments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#prototype$patchAttributes
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingDocuments/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#createChangeStream
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingDocuments/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#downloadFile
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/ListingDocuments/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#downloadFiles
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/ListingDocuments/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#createMany
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingDocuments",
              method: "POST",
            },

            // INTERNAL. Use SmartFile.listingDocument() instead.
            "::get::SmartFile::listingDocument": {
              url: urlBase + "/smartFiles/:id/listingDocument",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentMetadata.findById() instead.
            "::findById::Listing::documentMetadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentMetadata/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentMetadata.destroyById() instead.
            "::destroyById::Listing::documentMetadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentMetadata/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentMetadata.updateById() instead.
            "::updateById::Listing::documentMetadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentMetadata/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.documentMetadata() instead.
            "::get::Listing::documentMetadata": {
              isArray: true,
              url: urlBase + "/Listings/:id/documentMetadata",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentMetadata.create() instead.
            "::create::Listing::documentMetadata": {
              url: urlBase + "/Listings/:id/documentMetadata",
              method: "POST",
            },

            // INTERNAL. Use Listing.documentMetadata.createMany() instead.
            "::createMany::Listing::documentMetadata": {
              isArray: true,
              url: urlBase + "/Listings/:id/documentMetadata",
              method: "POST",
            },

            // INTERNAL. Use Listing.documentMetadata.destroyAll() instead.
            "::delete::Listing::documentMetadata": {
              url: urlBase + "/Listings/:id/documentMetadata",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentMetadata.count() instead.
            "::count::Listing::documentMetadata": {
              url: urlBase + "/Listings/:id/documentMetadata/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#upsert
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#updateOrCreate
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#patchOrCreateWithWhere
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#update
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#destroyById
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#removeById
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#prototype$updateAttributes
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingDocument#modelName
        * @propertyOf lbServices.ListingDocument
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingDocument`.
        */
        R.modelName = "ListingDocument";


            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#documentType
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Fetches belongsTo relation documentType.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.documentType = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::ListingDocument::documentType"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.ListingDocument.files
     * @header lbServices.ListingDocument.files
     * @object
     * @description
     *
     * The object `ListingDocument.files` groups methods
     * manipulating `SmartFile` instances related to `ListingDocument`.
     *
     * Call {@link lbServices.ListingDocument#files ListingDocument.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#files
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Queries files of ListingDocument.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingDocument::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument.files#count
             * @methodOf lbServices.ListingDocument.files
             *
             * @description
             *
             * Counts files of ListingDocument.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::ListingDocument::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument.files#create
             * @methodOf lbServices.ListingDocument.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::ListingDocument::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument.files#createMany
             * @methodOf lbServices.ListingDocument.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::ListingDocument::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument.files#destroyAll
             * @methodOf lbServices.ListingDocument.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::ListingDocument::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument.files#destroyById
             * @methodOf lbServices.ListingDocument.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::ListingDocument::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument.files#findById
             * @methodOf lbServices.ListingDocument.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::ListingDocument::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument.files#updateById
             * @methodOf lbServices.ListingDocument.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::ListingDocument::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingDocument#listing
             * @methodOf lbServices.ListingDocument
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingDocument id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingDocument::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.CustomRole
 * @header lbServices.CustomRole
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `CustomRole` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "CustomRole",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/CustomRoles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use CustomRole.users.findById() instead.
            "prototype$__findById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/:fk",
              method: "GET",
            },

            // INTERNAL. Use CustomRole.users.destroyById() instead.
            "prototype$__destroyById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CustomRole.users.updateById() instead.
            "prototype$__updateById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CustomRole.users.link() instead.
            "prototype$__link__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CustomRole.users.unlink() instead.
            "prototype$__unlink__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CustomRole.users.exists() instead.
            "prototype$__exists__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use CustomRole.users() instead.
            "prototype$__get__users": {
              isArray: true,
              url: urlBase + "/CustomRoles/:id/users",
              method: "GET",
            },

            // INTERNAL. Use CustomRole.users.create() instead.
            "prototype$__create__users": {
              url: urlBase + "/CustomRoles/:id/users",
              method: "POST",
            },

            // INTERNAL. Use CustomRole.users.destroyAll() instead.
            "prototype$__delete__users": {
              url: urlBase + "/CustomRoles/:id/users",
              method: "DELETE",
            },

            // INTERNAL. Use CustomRole.users.count() instead.
            "prototype$__count__users": {
              url: urlBase + "/CustomRoles/:id/users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#create
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/CustomRoles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#patchOrCreate
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/CustomRoles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#replaceOrCreate
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/CustomRoles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#upsertWithWhere
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/CustomRoles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#exists
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/CustomRoles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#findById
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/CustomRoles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#replaceById
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/CustomRoles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#find
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/CustomRoles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#findOne
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/CustomRoles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#updateAll
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/CustomRoles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#deleteById
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/CustomRoles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#count
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/CustomRoles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#prototype$patchAttributes
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/CustomRoles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#createChangeStream
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/CustomRoles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#createMany
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/CustomRoles",
              method: "POST",
            },

            // INTERNAL. Use UserRole.customRole() instead.
            "::get::UserRole::customRole": {
              url: urlBase + "/UserRoles/:id/customRole",
              method: "GET",
            },

            // INTERNAL. Use SbUser.roles.findById() instead.
            "::findById::SbUser::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.roles.destroyById() instead.
            "::destroyById::SbUser::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.roles.updateById() instead.
            "::updateById::SbUser::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.roles.link() instead.
            "::link::SbUser::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.roles.unlink() instead.
            "::unlink::SbUser::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.roles.exists() instead.
            "::exists::SbUser::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.roles() instead.
            "::get::SbUser::roles": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/roles",
              method: "GET",
            },

            // INTERNAL. Use SbUser.roles.create() instead.
            "::create::SbUser::roles": {
              url: urlBase + "/SbUsers/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use SbUser.roles.createMany() instead.
            "::createMany::SbUser::roles": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use SbUser.roles.destroyAll() instead.
            "::delete::SbUser::roles": {
              url: urlBase + "/SbUsers/:id/roles",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.roles.count() instead.
            "::count::SbUser::roles": {
              url: urlBase + "/SbUsers/:id/roles/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.CustomRole#upsert
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#updateOrCreate
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#patchOrCreateWithWhere
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#update
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#destroyById
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#removeById
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.CustomRole#prototype$updateAttributes
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.CustomRole#modelName
        * @propertyOf lbServices.CustomRole
        * @description
        * The name of the model represented by this $resource,
        * i.e. `CustomRole`.
        */
        R.modelName = "CustomRole";

    /**
     * @ngdoc object
     * @name lbServices.CustomRole.users
     * @header lbServices.CustomRole.users
     * @object
     * @description
     *
     * The object `CustomRole.users` groups methods
     * manipulating `SbUser` instances related to `CustomRole`.
     *
     * Call {@link lbServices.CustomRole#users CustomRole.users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.CustomRole#users
             * @methodOf lbServices.CustomRole
             *
             * @description
             *
             * Queries users of CustomRole.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#count
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Counts users of CustomRole.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.users.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#create
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#createMany
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#destroyAll
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Deletes all users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#destroyById
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Delete a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#exists
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Check the existence of users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#findById
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Find a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#link
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Add a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#unlink
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Remove the users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::CustomRole::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CustomRole.users#updateById
             * @methodOf lbServices.CustomRole.users
             *
             * @description
             *
             * Update a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - CustomRole id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::CustomRole::users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.UserRole
 * @header lbServices.UserRole
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `UserRole` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "UserRole",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/UserRoles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use UserRole.customRole() instead.
            "prototype$__get__customRole": {
              url: urlBase + "/UserRoles/:id/customRole",
              method: "GET",
            },

            // INTERNAL. Use UserRole.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/UserRoles/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#create
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/UserRoles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#patchOrCreate
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/UserRoles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#replaceOrCreate
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/UserRoles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#upsertWithWhere
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/UserRoles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#exists
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/UserRoles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#findById
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/UserRoles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#replaceById
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/UserRoles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#find
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/UserRoles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#findOne
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/UserRoles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#updateAll
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/UserRoles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#deleteById
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/UserRoles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#count
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/UserRoles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#prototype$patchAttributes
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UserRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/UserRoles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#createChangeStream
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/UserRoles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UserRole#createMany
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/UserRoles",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.UserRole#upsert
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.UserRole#updateOrCreate
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.UserRole#patchOrCreateWithWhere
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.UserRole#update
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.UserRole#destroyById
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.UserRole#removeById
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.UserRole#prototype$updateAttributes
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UserRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UserRole` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.UserRole#modelName
        * @propertyOf lbServices.UserRole
        * @description
        * The name of the model represented by this $resource,
        * i.e. `UserRole`.
        */
        R.modelName = "UserRole";


            /**
             * @ngdoc method
             * @name lbServices.UserRole#customRole
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Fetches belongsTo relation customRole.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UserRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R.customRole = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::get::UserRole::customRole"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UserRole#user
             * @methodOf lbServices.UserRole
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UserRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::UserRole::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Manager
 * @header lbServices.Manager
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Manager` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Manager",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Managers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Manager.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Managers/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Manager.manager() instead.
            "prototype$__get__manager": {
              url: urlBase + "/Managers/:id/manager",
              method: "GET",
            },

            // INTERNAL. Use Manager.managing() instead.
            "prototype$__get__managing": {
              url: urlBase + "/Managers/:id/managing",
              method: "GET",
            },

            // INTERNAL. Use Manager.clients.findById() instead.
            "prototype$__findById__clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/:fk",
              method: "GET",
            },

            // INTERNAL. Use Manager.clients.destroyById() instead.
            "prototype$__destroyById__clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.clients.updateById() instead.
            "prototype$__updateById__clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manager.clients.link() instead.
            "prototype$__link__clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manager.clients.unlink() instead.
            "prototype$__unlink__clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.clients.exists() instead.
            "prototype$__exists__clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Manager.listings.findById() instead.
            "prototype$__findById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Manager.listings.destroyById() instead.
            "prototype$__destroyById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.listings.updateById() instead.
            "prototype$__updateById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manager.listings.link() instead.
            "prototype$__link__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manager.listings.unlink() instead.
            "prototype$__unlink__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.listings.exists() instead.
            "prototype$__exists__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Manager.clients() instead.
            "prototype$__get__clients": {
              isArray: true,
              url: urlBase + "/Managers/:id/clients",
              method: "GET",
            },

            // INTERNAL. Use Manager.clients.create() instead.
            "prototype$__create__clients": {
              url: urlBase + "/Managers/:id/clients",
              method: "POST",
            },

            // INTERNAL. Use Manager.clients.destroyAll() instead.
            "prototype$__delete__clients": {
              url: urlBase + "/Managers/:id/clients",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.clients.count() instead.
            "prototype$__count__clients": {
              url: urlBase + "/Managers/:id/clients/count",
              method: "GET",
            },

            // INTERNAL. Use Manager.listings() instead.
            "prototype$__get__listings": {
              isArray: true,
              url: urlBase + "/Managers/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Manager.listings.create() instead.
            "prototype$__create__listings": {
              url: urlBase + "/Managers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Manager.listings.destroyAll() instead.
            "prototype$__delete__listings": {
              url: urlBase + "/Managers/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.listings.count() instead.
            "prototype$__count__listings": {
              url: urlBase + "/Managers/:id/listings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#create
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Managers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#patchOrCreate
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Managers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#replaceOrCreate
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Managers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#upsertWithWhere
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Managers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#exists
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Managers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#findById
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Managers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#replaceById
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Managers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#find
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Managers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#findOne
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Managers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#updateAll
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Managers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#deleteById
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Managers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#count
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Managers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#prototype$patchAttributes
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Managers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#createChangeStream
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Managers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manager#createMany
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Managers",
              method: "POST",
            },

            // INTERNAL. Use SbUser.managers.findById() instead.
            "::findById::SbUser::managers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managers.destroyById() instead.
            "::destroyById::SbUser::managers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.managers.updateById() instead.
            "::updateById::SbUser::managers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.managerProfile.findById() instead.
            "::findById::SbUser::managerProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managerProfile/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managerProfile.destroyById() instead.
            "::destroyById::SbUser::managerProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managerProfile/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.managerProfile.updateById() instead.
            "::updateById::SbUser::managerProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managerProfile/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.managers() instead.
            "::get::SbUser::managers": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/managers",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managers.create() instead.
            "::create::SbUser::managers": {
              url: urlBase + "/SbUsers/:id/managers",
              method: "POST",
            },

            // INTERNAL. Use SbUser.managers.createMany() instead.
            "::createMany::SbUser::managers": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/managers",
              method: "POST",
            },

            // INTERNAL. Use SbUser.managers.destroyAll() instead.
            "::delete::SbUser::managers": {
              url: urlBase + "/SbUsers/:id/managers",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.managers.count() instead.
            "::count::SbUser::managers": {
              url: urlBase + "/SbUsers/:id/managers/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managerProfile() instead.
            "::get::SbUser::managerProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/managerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managerProfile.create() instead.
            "::create::SbUser::managerProfile": {
              url: urlBase + "/SbUsers/:id/managerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.managerProfile.createMany() instead.
            "::createMany::SbUser::managerProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/managerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.managerProfile.destroyAll() instead.
            "::delete::SbUser::managerProfile": {
              url: urlBase + "/SbUsers/:id/managerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.managerProfile.count() instead.
            "::count::SbUser::managerProfile": {
              url: urlBase + "/SbUsers/:id/managerProfile/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Manager#upsert
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Manager#updateOrCreate
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Manager#patchOrCreateWithWhere
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Manager#update
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Manager#destroyById
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Manager#removeById
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Manager#prototype$updateAttributes
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Manager#modelName
        * @propertyOf lbServices.Manager
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Manager`.
        */
        R.modelName = "Manager";


            /**
             * @ngdoc method
             * @name lbServices.Manager#agency
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Manager::agency"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager#manager
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Fetches belongsTo relation manager.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.manager = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Manager::manager"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager#managing
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Fetches belongsTo relation managing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.managing = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Manager::managing"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Manager.clients
     * @header lbServices.Manager.clients
     * @object
     * @description
     *
     * The object `Manager.clients` groups methods
     * manipulating `SbUser` instances related to `Manager`.
     *
     * Call {@link lbServices.Manager#clients Manager.clients()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Manager#clients
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Queries clients of Manager.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.clients = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#count
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Counts clients of Manager.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.clients.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#create
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Creates a new instance in clients of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.clients.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#createMany
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Creates a new instance in clients of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.clients.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#destroyAll
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Deletes all clients of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.clients.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#destroyById
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Delete a related item by id for clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for clients
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.clients.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#exists
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Check the existence of clients relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for clients
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.clients.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#findById
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Find a related item by id for clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for clients
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.clients.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#link
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Add a related item by id for clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `fk` – `{*}` - Foreign key for clients
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.clients.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#unlink
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Remove the clients relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for clients
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.clients.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Manager::clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.clients#updateById
             * @methodOf lbServices.Manager.clients
             *
             * @description
             *
             * Update a related item by id for clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `fk` – `{*}` - Foreign key for clients
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.clients.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Manager::clients"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Manager.listings
     * @header lbServices.Manager.listings
     * @object
     * @description
     *
     * The object `Manager.listings` groups methods
     * manipulating `Listing` instances related to `Manager`.
     *
     * Call {@link lbServices.Manager#listings Manager.listings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Manager#listings
             * @methodOf lbServices.Manager
             *
             * @description
             *
             * Queries listings of Manager.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#count
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Counts listings of Manager.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listings.count = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::count::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#create
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.create = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::create::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#createMany
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.createMany = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::createMany::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#destroyAll
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Deletes all listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyAll = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::delete::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#destroyById
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Delete a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::destroyById::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#exists
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Check the existence of listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.exists = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::exists::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#findById
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Find a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.findById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::findById::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#link
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Add a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.link = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::link::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#unlink
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Remove the listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.unlink = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::unlink::Manager::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manager.listings#updateById
             * @methodOf lbServices.Manager.listings
             *
             * @description
             *
             * Update a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Manager id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.updateById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::updateById::Manager::listings"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingMlsImage
 * @header lbServices.ListingMlsImage
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingMlsImage` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingMlsImage",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingMlsImages/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingMlsImage.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/ListingMlsImages/:id/file",
              method: "GET",
            },

            // INTERNAL. Use ListingMlsImage.smartFile() instead.
            "prototype$__get__smartFile": {
              url: urlBase + "/ListingMlsImages/:id/smartFile",
              method: "GET",
            },

            // INTERNAL. Use ListingMlsImage.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingMlsImages/:id/listing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#create
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingMlsImages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#patchOrCreate
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingMlsImages",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#replaceOrCreate
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingMlsImages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#upsertWithWhere
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingMlsImages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#exists
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingMlsImages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#findById
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingMlsImages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#replaceById
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingMlsImages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#find
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingMlsImages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#findOne
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingMlsImages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#updateAll
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingMlsImages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#deleteById
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingMlsImages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#count
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingMlsImages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#prototype$patchAttributes
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingMlsImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingMlsImages/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#createChangeStream
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingMlsImages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#createMany
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingMlsImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.mlsPhotos.findById() instead.
            "::findById::Listing::mlsPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsPhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsPhotos.destroyById() instead.
            "::destroyById::Listing::mlsPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsPhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsPhotos.updateById() instead.
            "::updateById::Listing::mlsPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsPhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.mlsPhotos() instead.
            "::get::Listing::mlsPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/mlsPhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsPhotos.create() instead.
            "::create::Listing::mlsPhotos": {
              url: urlBase + "/Listings/:id/mlsPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.mlsPhotos.createMany() instead.
            "::createMany::Listing::mlsPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/mlsPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.mlsPhotos.destroyAll() instead.
            "::delete::Listing::mlsPhotos": {
              url: urlBase + "/Listings/:id/mlsPhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsPhotos.count() instead.
            "::count::Listing::mlsPhotos": {
              url: urlBase + "/Listings/:id/mlsPhotos/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#upsert
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#updateOrCreate
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#patchOrCreateWithWhere
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#update
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#destroyById
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#removeById
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#prototype$updateAttributes
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingMlsImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingMlsImage#modelName
        * @propertyOf lbServices.ListingMlsImage
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingMlsImage`.
        */
        R.modelName = "ListingMlsImage";


            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#file
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Fetches belongsTo relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingMlsImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingMlsImage::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#smartFile
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Fetches belongsTo relation smartFile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingMlsImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.smartFile = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::ListingMlsImage::smartFile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingMlsImage#listing
             * @methodOf lbServices.ListingMlsImage
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingMlsImage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingMlsImage::listing"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Country
 * @header lbServices.Country
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Country` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Country",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Countries/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Country.provinces.findById() instead.
            "prototype$__findById__provinces": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Countries/:id/provinces/:fk",
              method: "GET",
            },

            // INTERNAL. Use Country.provinces.destroyById() instead.
            "prototype$__destroyById__provinces": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Countries/:id/provinces/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Country.provinces.updateById() instead.
            "prototype$__updateById__provinces": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Countries/:id/provinces/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Country.provinces() instead.
            "prototype$__get__provinces": {
              isArray: true,
              url: urlBase + "/Countries/:id/provinces",
              method: "GET",
            },

            // INTERNAL. Use Country.provinces.create() instead.
            "prototype$__create__provinces": {
              url: urlBase + "/Countries/:id/provinces",
              method: "POST",
            },

            // INTERNAL. Use Country.provinces.destroyAll() instead.
            "prototype$__delete__provinces": {
              url: urlBase + "/Countries/:id/provinces",
              method: "DELETE",
            },

            // INTERNAL. Use Country.provinces.count() instead.
            "prototype$__count__provinces": {
              url: urlBase + "/Countries/:id/provinces/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#create
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Countries",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#patchOrCreate
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Countries",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#replaceOrCreate
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Countries/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#upsertWithWhere
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Countries/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#exists
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Countries/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#findById
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Countries/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#replaceById
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Countries/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#find
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Countries",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#findOne
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Countries/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#updateAll
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Countries/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#deleteById
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Countries/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#count
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Countries/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#prototype$patchAttributes
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Countries/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#createChangeStream
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Countries/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Country#createMany
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Countries",
              method: "POST",
            },

            // INTERNAL. Use Province.country() instead.
            "::get::Province::country": {
              url: urlBase + "/Provinces/:id/country",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Country#upsert
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Country#updateOrCreate
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Country#patchOrCreateWithWhere
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Country#update
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Country#destroyById
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Country#removeById
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Country#prototype$updateAttributes
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Country` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Country#modelName
        * @propertyOf lbServices.Country
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Country`.
        */
        R.modelName = "Country";

    /**
     * @ngdoc object
     * @name lbServices.Country.provinces
     * @header lbServices.Country.provinces
     * @object
     * @description
     *
     * The object `Country.provinces` groups methods
     * manipulating `Province` instances related to `Country`.
     *
     * Call {@link lbServices.Country#provinces Country.provinces()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Country#provinces
             * @methodOf lbServices.Country
             *
             * @description
             *
             * Queries provinces of Country.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R.provinces = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::get::Country::provinces"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Country.provinces#count
             * @methodOf lbServices.Country.provinces
             *
             * @description
             *
             * Counts provinces of Country.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.provinces.count = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::count::Country::provinces"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Country.provinces#create
             * @methodOf lbServices.Country.provinces
             *
             * @description
             *
             * Creates a new instance in provinces of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R.provinces.create = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::create::Country::provinces"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Country.provinces#createMany
             * @methodOf lbServices.Country.provinces
             *
             * @description
             *
             * Creates a new instance in provinces of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R.provinces.createMany = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::createMany::Country::provinces"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Country.provinces#destroyAll
             * @methodOf lbServices.Country.provinces
             *
             * @description
             *
             * Deletes all provinces of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.provinces.destroyAll = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::delete::Country::provinces"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Country.provinces#destroyById
             * @methodOf lbServices.Country.provinces
             *
             * @description
             *
             * Delete a related item by id for provinces.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for provinces
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.provinces.destroyById = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::destroyById::Country::provinces"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Country.provinces#findById
             * @methodOf lbServices.Country.provinces
             *
             * @description
             *
             * Find a related item by id for provinces.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for provinces
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R.provinces.findById = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::findById::Country::provinces"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Country.provinces#updateById
             * @methodOf lbServices.Country.provinces
             *
             * @description
             *
             * Update a related item by id for provinces.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Country id
             *
             *  - `fk` – `{*}` - Foreign key for provinces
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Province` object.)
             * </em>
             */
        R.provinces.updateById = function() {
          var TargetResource = $injector.get("Province");
          var action = TargetResource["::updateById::Country::provinces"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PendingNotification
 * @header lbServices.PendingNotification
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PendingNotification` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PendingNotification",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PendingNotifications/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PendingNotification.fromUser() instead.
            "prototype$__get__fromUser": {
              url: urlBase + "/PendingNotifications/:id/fromUser",
              method: "GET",
            },

            // INTERNAL. Use PendingNotification.toUser() instead.
            "prototype$__get__toUser": {
              url: urlBase + "/PendingNotifications/:id/toUser",
              method: "GET",
            },

            // INTERNAL. Use PendingNotification.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/PendingNotifications/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#create
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PendingNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#patchOrCreate
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/PendingNotifications",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#replaceOrCreate
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PendingNotifications/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#upsertWithWhere
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PendingNotifications/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#exists
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PendingNotifications/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#findById
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PendingNotifications/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#replaceById
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PendingNotifications/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#find
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PendingNotifications",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#findOne
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PendingNotifications/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#updateAll
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PendingNotifications/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#deleteById
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PendingNotifications/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#count
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PendingNotifications/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#prototype$patchAttributes
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PendingNotification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/PendingNotifications/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#createChangeStream
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PendingNotifications/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#createMany
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PendingNotifications",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sentEmails.findById() instead.
            "::findById::SbUser::sentEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sentEmails/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sentEmails.destroyById() instead.
            "::destroyById::SbUser::sentEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sentEmails/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sentEmails.updateById() instead.
            "::updateById::SbUser::sentEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sentEmails/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.receivedEmails.findById() instead.
            "::findById::SbUser::receivedEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/receivedEmails/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.receivedEmails.destroyById() instead.
            "::destroyById::SbUser::receivedEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/receivedEmails/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.receivedEmails.updateById() instead.
            "::updateById::SbUser::receivedEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/receivedEmails/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sentEmails() instead.
            "::get::SbUser::sentEmails": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sentEmails",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sentEmails.create() instead.
            "::create::SbUser::sentEmails": {
              url: urlBase + "/SbUsers/:id/sentEmails",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sentEmails.createMany() instead.
            "::createMany::SbUser::sentEmails": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sentEmails",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sentEmails.destroyAll() instead.
            "::delete::SbUser::sentEmails": {
              url: urlBase + "/SbUsers/:id/sentEmails",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sentEmails.count() instead.
            "::count::SbUser::sentEmails": {
              url: urlBase + "/SbUsers/:id/sentEmails/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.receivedEmails() instead.
            "::get::SbUser::receivedEmails": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/receivedEmails",
              method: "GET",
            },

            // INTERNAL. Use SbUser.receivedEmails.create() instead.
            "::create::SbUser::receivedEmails": {
              url: urlBase + "/SbUsers/:id/receivedEmails",
              method: "POST",
            },

            // INTERNAL. Use SbUser.receivedEmails.createMany() instead.
            "::createMany::SbUser::receivedEmails": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/receivedEmails",
              method: "POST",
            },

            // INTERNAL. Use SbUser.receivedEmails.destroyAll() instead.
            "::delete::SbUser::receivedEmails": {
              url: urlBase + "/SbUsers/:id/receivedEmails",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.receivedEmails.count() instead.
            "::count::SbUser::receivedEmails": {
              url: urlBase + "/SbUsers/:id/receivedEmails/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#upsert
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#updateOrCreate
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#patchOrCreateWithWhere
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#update
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#destroyById
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#removeById
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#prototype$updateAttributes
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PendingNotification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PendingNotification#modelName
        * @propertyOf lbServices.PendingNotification
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PendingNotification`.
        */
        R.modelName = "PendingNotification";


            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#fromUser
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Fetches belongsTo relation fromUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PendingNotification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.fromUser = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::PendingNotification::fromUser"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#toUser
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Fetches belongsTo relation toUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PendingNotification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.toUser = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::PendingNotification::toUser"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PendingNotification#account
             * @methodOf lbServices.PendingNotification
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PendingNotification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::PendingNotification::account"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AgencyOwner
 * @header lbServices.AgencyOwner
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AgencyOwner` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AgencyOwner",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AgencyOwners/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use AgencyOwner.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/AgencyOwners/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.office() instead.
            "prototype$__get__office": {
              url: urlBase + "/AgencyOwners/:id/office",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.findById() instead.
            "prototype$__findById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.destroyById() instead.
            "prototype$__destroyById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.updateById() instead.
            "prototype$__updateById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.tasks.findById() instead.
            "prototype$__findById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasks.destroyById() instead.
            "prototype$__destroyById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.tasks.updateById() instead.
            "prototype$__updateById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.appointments.findById() instead.
            "prototype$__findById__appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/appointments/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.appointments.destroyById() instead.
            "prototype$__destroyById__appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/appointments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.appointments.updateById() instead.
            "prototype$__updateById__appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/appointments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/AgencyOwners/:id/user",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.suppliers.findById() instead.
            "prototype$__findById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.suppliers.destroyById() instead.
            "prototype$__destroyById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.suppliers.updateById() instead.
            "prototype$__updateById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.suppliers.link() instead.
            "prototype$__link__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyOwner.suppliers.unlink() instead.
            "prototype$__unlink__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.suppliers.exists() instead.
            "prototype$__exists__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyOwners/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout() instead.
            "prototype$__get__tasksAbout": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.create() instead.
            "prototype$__create__tasksAbout": {
              url: urlBase + "/AgencyOwners/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.destroyAll() instead.
            "prototype$__delete__tasksAbout": {
              url: urlBase + "/AgencyOwners/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.tasksAbout.count() instead.
            "prototype$__count__tasksAbout": {
              url: urlBase + "/AgencyOwners/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasks() instead.
            "prototype$__get__tasks": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.tasks.create() instead.
            "prototype$__create__tasks": {
              url: urlBase + "/AgencyOwners/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.tasks.destroyAll() instead.
            "prototype$__delete__tasks": {
              url: urlBase + "/AgencyOwners/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.tasks.count() instead.
            "prototype$__count__tasks": {
              url: urlBase + "/AgencyOwners/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.appointments() instead.
            "prototype$__get__appointments": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/appointments",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.appointments.create() instead.
            "prototype$__create__appointments": {
              url: urlBase + "/AgencyOwners/:id/appointments",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.appointments.destroyAll() instead.
            "prototype$__delete__appointments": {
              url: urlBase + "/AgencyOwners/:id/appointments",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.appointments.count() instead.
            "prototype$__count__appointments": {
              url: urlBase + "/AgencyOwners/:id/appointments/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.suppliers() instead.
            "prototype$__get__suppliers": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.suppliers.create() instead.
            "prototype$__create__suppliers": {
              url: urlBase + "/AgencyOwners/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use AgencyOwner.suppliers.destroyAll() instead.
            "prototype$__delete__suppliers": {
              url: urlBase + "/AgencyOwners/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyOwner.suppliers.count() instead.
            "prototype$__count__suppliers": {
              url: urlBase + "/AgencyOwners/:id/suppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#create
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AgencyOwners",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#patchOrCreate
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/AgencyOwners",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#replaceOrCreate
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AgencyOwners/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#upsertWithWhere
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AgencyOwners/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#exists
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AgencyOwners/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#findById
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AgencyOwners/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#replaceById
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AgencyOwners/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#find
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AgencyOwners",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#findOne
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AgencyOwners/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#updateAll
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AgencyOwners/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#deleteById
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AgencyOwners/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#count
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AgencyOwners/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#prototype$patchAttributes
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/AgencyOwners/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#createChangeStream
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AgencyOwners/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#getRelatedList
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `rel` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns list of related objects with fields required for a list view
             */
            "getRelatedList": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/getList/:rel",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetBroker
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Returns a brokers profile, including their visible listings
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `brokerId` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Broker profile
             */
            "wpGetBroker": {
              url: urlBase + "/AgencyOwners/:id/wp/broker/:brokerId/get",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetOfficesMinimal
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpGetOfficesMinimal": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/wp/getOfficesMinimal",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetBrokers
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Returns a list of agency brokers to show in the Wordpress agency plugin
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of brokers that belong to this agency owner's agency
             */
            "wpGetBrokers": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/wp/getBrokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetOfficeEmail
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `officeId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpGetOfficeEmail": {
              url: urlBase + "/AgencyOwners/:id/offices/:officeId/wp/email",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpOfficeDetails
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `officeId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpOfficeDetails": {
              url: urlBase + "/AgencyOwners/:id/offices/:officeId/wp/details",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetOwnerEmail
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpGetOwnerEmail": {
              url: urlBase + "/AgencyOwners/:id/email",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#sentEmails
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Gets the emails sent by the buyer
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "sentEmails": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/sent-emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#getBasicSearch
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `search` – `{string}` -
             *
             *  - `type` – `{string}` -
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * An array with the returned results
             */
            "getBasicSearch": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/getBasicSearch",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#getMenuSearchCount
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `search` – `{string}` -
             *
             *  - `type` – `{string=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with the total count and the ids returned
             */
            "getMenuSearchCount": {
              url: urlBase + "/AgencyOwners/:id/menuSearchCount",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#getMenuSearchDetails
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `results` – `{object}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * An array with the returned results
             */
            "getMenuSearchDetails": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/menuSearchDetails",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#getAllTasks
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `filter` – `{*=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "getAllTasks": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/tasks/all",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetAgencyOffices
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpGetAgencyOffices": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/wp/offices/all",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetOurBrokers
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `officeId` – `{string=}` -
             *
             *  - `limit` – `{number=}` -
             *
             *  - `skip` – `{number=}` -
             *
             *  - `country` – `{string=}` -
             *
             *  - `province` – `{string=}` -
             *
             *  - `city` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `brokers` – `{*=}` -
             *
             *  - `count` – `{number=}` -
             */
            "wpGetOurBrokers": {
              url: urlBase + "/AgencyOwners/:id/wp/brokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetBrokersMinimal
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpGetBrokersMinimal": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/wp/brokers/minimal",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetOfficesMinimal
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpGetOfficesMinimal": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/wp/offices/minimal",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetOffices
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `limit` – `{number=}` -
             *
             *  - `skip` – `{number=}` -
             *
             *  - `country` – `{string=}` -
             *
             *  - `province` – `{string=}` -
             *
             *  - `city` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `offices` – `{*=}` -
             *
             *  - `count` – `{number=}` -
             */
            "wpGetOffices": {
              url: urlBase + "/AgencyOwners/:id/wp/offices",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetAgencyName
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Returns the name of this person's agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Legal name of this person's agency
             */
            "wpGetAgencyName": {
              url: urlBase + "/AgencyOwners/:id/wp/getAgencyName",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetMyAddress
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Returns this person's address
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This person's address
             */
            "wpGetMyAddress": {
              url: urlBase + "/AgencyOwners/:id/wp/getMyAddress",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetOfficeBrokers
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `officeId` – `{string=}` -
             *
             *  - `name` – `{string=}` -
             *
             *  - `language` – `{string=}` -
             *
             *  - `location` – `{string=}` -
             *
             *  - `isDropdown` – `{boolean=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpGetOfficeBrokers": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/wp/brokers/all",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpGetAgencyBrokers
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `name` – `{string=}` -
             *
             *  - `language` – `{string=}` -
             *
             *  - `location` – `{string=}` -
             *
             *  - `isDropdown` – `{boolean=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "wpGetAgencyBrokers": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/wp/agency/brokers/all",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#bulkDeleteTasks
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Deletes a number of tasks based on their ID.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `aboutId` – `{string}` -
             *
             *  - `aboutType` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Number of deleted tasks
             */
            "bulkDeleteTasks": {
              url: urlBase + "/AgencyOwners/:id/bulkDeleteTasks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#linkAndUnlinkVisitors
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `toLink` – `{*=}` -
             *
             *  - `toUnlink` – `{*=}` -
             *
             *  - `toCoLink` – `{*=}` -
             *
             *  - `toCoUnlink` – `{*=}` -
             *
             *  - `notifyRecipients` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "linkAndUnlinkVisitors": {
              url: urlBase + "/AgencyOwners/:id/visits/:fk/link-and-unlink",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#wpListingSearch
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Looks for a brokers listings according to city, price and property type.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `priceMin` – `{number=}` -
             *
             *  - `priceMax` – `{number=}` -
             *
             *  - `type` – `{string=}` -
             *
             *  - `city` – `{string=}` -
             *
             *  - `province` – `{string=}` -
             *
             *  - `country` – `{string=}` -
             *
             *  - `broker` – `{string=}` -
             *
             *  - `office` – `{string=}` -
             *
             *  - `getNew` – `{boolean=}` -
             *
             *  - `getSold` – `{boolean=}` -
             *
             *  - `getPriceChanged` – `{boolean=}` -
             *
             *  - `getSmart` – `{boolean=}` -
             *
             *  - `getConditionalContract` – `{boolean=}` -
             *
             *  - `mls` – `{*=}` -
             *
             *  - `isDropdown` – `{boolean=}` -
             *
             *  - `limit` – `{number=}` -
             *
             *  - `sort` – `{string=}` -
             *
             *  - `searchType` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Visible listings that match a certain criteria.
             */
            "wpListingSearch": {
              isArray: true,
              url: urlBase + "/AgencyOwners/:id/wp/listingSearch",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#createMany
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AgencyOwners",
              method: "POST",
            },

            // INTERNAL. Use Agency.owners.findById() instead.
            "::findById::Agency::owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/owners/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.owners.destroyById() instead.
            "::destroyById::Agency::owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/owners/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.owners.updateById() instead.
            "::updateById::Agency::owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/owners/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.owners() instead.
            "::get::Agency::owners": {
              isArray: true,
              url: urlBase + "/Agencies/:id/owners",
              method: "GET",
            },

            // INTERNAL. Use Agency.owners.create() instead.
            "::create::Agency::owners": {
              url: urlBase + "/Agencies/:id/owners",
              method: "POST",
            },

            // INTERNAL. Use Agency.owners.createMany() instead.
            "::createMany::Agency::owners": {
              isArray: true,
              url: urlBase + "/Agencies/:id/owners",
              method: "POST",
            },

            // INTERNAL. Use Agency.owners.destroyAll() instead.
            "::delete::Agency::owners": {
              url: urlBase + "/Agencies/:id/owners",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.owners.count() instead.
            "::count::Agency::owners": {
              url: urlBase + "/Agencies/:id/owners/count",
              method: "GET",
            },

            // INTERNAL. Use Visit.agencyOwner() instead.
            "::get::Visit::agencyOwner": {
              url: urlBase + "/Visits/:id/agencyOwner",
              method: "GET",
            },

            // INTERNAL. Use Office.owners.findById() instead.
            "::findById::Office::owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/owners/:fk",
              method: "GET",
            },

            // INTERNAL. Use Office.owners.destroyById() instead.
            "::destroyById::Office::owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/owners/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Office.owners.updateById() instead.
            "::updateById::Office::owners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/owners/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Office.owners() instead.
            "::get::Office::owners": {
              isArray: true,
              url: urlBase + "/Offices/:id/owners",
              method: "GET",
            },

            // INTERNAL. Use Office.owners.create() instead.
            "::create::Office::owners": {
              url: urlBase + "/Offices/:id/owners",
              method: "POST",
            },

            // INTERNAL. Use Office.owners.createMany() instead.
            "::createMany::Office::owners": {
              isArray: true,
              url: urlBase + "/Offices/:id/owners",
              method: "POST",
            },

            // INTERNAL. Use Office.owners.destroyAll() instead.
            "::delete::Office::owners": {
              url: urlBase + "/Offices/:id/owners",
              method: "DELETE",
            },

            // INTERNAL. Use Office.owners.count() instead.
            "::count::Office::owners": {
              url: urlBase + "/Offices/:id/owners/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile() instead.
            "::get::SbUser::agencyOwnerProfile": {
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile.create() instead.
            "::create::SbUser::agencyOwnerProfile": {
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile.createMany() instead.
            "::createMany::SbUser::agencyOwnerProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile.update() instead.
            "::update::SbUser::agencyOwnerProfile": {
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile.destroy() instead.
            "::destroy::SbUser::agencyOwnerProfile": {
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.findById() instead.
            "::findById::SmartSearch::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.destroyById() instead.
            "::destroyById::SmartSearch::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.updateById() instead.
            "::updateById::SmartSearch::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.link() instead.
            "::link::SmartSearch::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.unlink() instead.
            "::unlink::SmartSearch::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.exists() instead.
            "::exists::SmartSearch::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartSearch.agencyOwners() instead.
            "::get::SmartSearch::agencyOwners": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencyOwners",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.create() instead.
            "::create::SmartSearch::agencyOwners": {
              url: urlBase + "/SmartSearches/:id/agencyOwners",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.createMany() instead.
            "::createMany::SmartSearch::agencyOwners": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencyOwners",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.destroyAll() instead.
            "::delete::SmartSearch::agencyOwners": {
              url: urlBase + "/SmartSearches/:id/agencyOwners",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.count() instead.
            "::count::SmartSearch::agencyOwners": {
              url: urlBase + "/SmartSearches/:id/agencyOwners/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#upsert
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#updateOrCreate
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#patchOrCreateWithWhere
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#update
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#destroyById
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#removeById
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#prototype$updateAttributes
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AgencyOwner#modelName
        * @propertyOf lbServices.AgencyOwner
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AgencyOwner`.
        */
        R.modelName = "AgencyOwner";


            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#agency
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::AgencyOwner::agency"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#office
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Fetches belongsTo relation office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R.office = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::get::AgencyOwner::office"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencyOwner.tasksAbout
     * @header lbServices.AgencyOwner.tasksAbout
     * @object
     * @description
     *
     * The object `AgencyOwner.tasksAbout` groups methods
     * manipulating `Task` instances related to `AgencyOwner`.
     *
     * Call {@link lbServices.AgencyOwner#tasksAbout AgencyOwner.tasksAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#tasksAbout
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Queries tasksAbout of AgencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::AgencyOwner::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasksAbout#count
             * @methodOf lbServices.AgencyOwner.tasksAbout
             *
             * @description
             *
             * Counts tasksAbout of AgencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasksAbout.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::AgencyOwner::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasksAbout#create
             * @methodOf lbServices.AgencyOwner.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::AgencyOwner::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasksAbout#createMany
             * @methodOf lbServices.AgencyOwner.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::AgencyOwner::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasksAbout#destroyAll
             * @methodOf lbServices.AgencyOwner.tasksAbout
             *
             * @description
             *
             * Deletes all tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::AgencyOwner::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasksAbout#destroyById
             * @methodOf lbServices.AgencyOwner.tasksAbout
             *
             * @description
             *
             * Delete a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::AgencyOwner::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasksAbout#findById
             * @methodOf lbServices.AgencyOwner.tasksAbout
             *
             * @description
             *
             * Find a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::AgencyOwner::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasksAbout#updateById
             * @methodOf lbServices.AgencyOwner.tasksAbout
             *
             * @description
             *
             * Update a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::AgencyOwner::tasksAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencyOwner.tasks
     * @header lbServices.AgencyOwner.tasks
     * @object
     * @description
     *
     * The object `AgencyOwner.tasks` groups methods
     * manipulating `Task` instances related to `AgencyOwner`.
     *
     * Call {@link lbServices.AgencyOwner#tasks AgencyOwner.tasks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#tasks
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Queries tasks of AgencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::AgencyOwner::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasks#count
             * @methodOf lbServices.AgencyOwner.tasks
             *
             * @description
             *
             * Counts tasks of AgencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasks.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::AgencyOwner::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasks#create
             * @methodOf lbServices.AgencyOwner.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::AgencyOwner::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasks#createMany
             * @methodOf lbServices.AgencyOwner.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::AgencyOwner::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasks#destroyAll
             * @methodOf lbServices.AgencyOwner.tasks
             *
             * @description
             *
             * Deletes all tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::AgencyOwner::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasks#destroyById
             * @methodOf lbServices.AgencyOwner.tasks
             *
             * @description
             *
             * Delete a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::AgencyOwner::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasks#findById
             * @methodOf lbServices.AgencyOwner.tasks
             *
             * @description
             *
             * Find a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::AgencyOwner::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.tasks#updateById
             * @methodOf lbServices.AgencyOwner.tasks
             *
             * @description
             *
             * Update a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::AgencyOwner::tasks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencyOwner.appointments
     * @header lbServices.AgencyOwner.appointments
     * @object
     * @description
     *
     * The object `AgencyOwner.appointments` groups methods
     * manipulating `Visit` instances related to `AgencyOwner`.
     *
     * Call {@link lbServices.AgencyOwner#appointments AgencyOwner.appointments()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#appointments
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Queries appointments of AgencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::get::AgencyOwner::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.appointments#count
             * @methodOf lbServices.AgencyOwner.appointments
             *
             * @description
             *
             * Counts appointments of AgencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.appointments.count = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::count::AgencyOwner::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.appointments#create
             * @methodOf lbServices.AgencyOwner.appointments
             *
             * @description
             *
             * Creates a new instance in appointments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments.create = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::create::AgencyOwner::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.appointments#createMany
             * @methodOf lbServices.AgencyOwner.appointments
             *
             * @description
             *
             * Creates a new instance in appointments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments.createMany = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::createMany::AgencyOwner::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.appointments#destroyAll
             * @methodOf lbServices.AgencyOwner.appointments
             *
             * @description
             *
             * Deletes all appointments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.appointments.destroyAll = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::delete::AgencyOwner::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.appointments#destroyById
             * @methodOf lbServices.AgencyOwner.appointments
             *
             * @description
             *
             * Delete a related item by id for appointments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for appointments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.appointments.destroyById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::destroyById::AgencyOwner::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.appointments#findById
             * @methodOf lbServices.AgencyOwner.appointments
             *
             * @description
             *
             * Find a related item by id for appointments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for appointments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments.findById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::findById::AgencyOwner::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.appointments#updateById
             * @methodOf lbServices.AgencyOwner.appointments
             *
             * @description
             *
             * Update a related item by id for appointments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `fk` – `{*}` - Foreign key for appointments
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments.updateById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::updateById::AgencyOwner::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#user
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::AgencyOwner::user"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencyOwner.suppliers
     * @header lbServices.AgencyOwner.suppliers
     * @object
     * @description
     *
     * The object `AgencyOwner.suppliers` groups methods
     * manipulating `Supplier` instances related to `AgencyOwner`.
     *
     * Call {@link lbServices.AgencyOwner#suppliers AgencyOwner.suppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner#suppliers
             * @methodOf lbServices.AgencyOwner
             *
             * @description
             *
             * Queries suppliers of AgencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#count
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Counts suppliers of AgencyOwner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.suppliers.count = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::count::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#create
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.create = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::create::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#createMany
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.createMany = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::createMany::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#destroyAll
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Deletes all suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyAll = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::delete::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#destroyById
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Delete a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::destroyById::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#exists
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Check the existence of suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.exists = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::exists::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#findById
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Find a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.findById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::findById::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#link
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Add a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.link = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::link::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#unlink
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Remove the suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.unlink = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::unlink::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyOwner.suppliers#updateById
             * @methodOf lbServices.AgencyOwner.suppliers
             *
             * @description
             *
             * Update a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyOwner id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.updateById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::updateById::AgencyOwner::suppliers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AgencyMember
 * @header lbServices.AgencyMember
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AgencyMember` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AgencyMember",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AgencyMembers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use AgencyMember.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/AgencyMembers/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.office() instead.
            "prototype$__get__office": {
              url: urlBase + "/AgencyMembers/:id/office",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasks.findById() instead.
            "prototype$__findById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasks.destroyById() instead.
            "prototype$__destroyById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.tasks.updateById() instead.
            "prototype$__updateById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.findById() instead.
            "prototype$__findById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasksAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.destroyById() instead.
            "prototype$__destroyById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasksAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.updateById() instead.
            "prototype$__updateById__tasksAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/tasksAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.appointments.findById() instead.
            "prototype$__findById__appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/appointments/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.appointments.destroyById() instead.
            "prototype$__destroyById__appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/appointments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.appointments.updateById() instead.
            "prototype$__updateById__appointments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/appointments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.agencyRoleCode() instead.
            "prototype$__get__agencyRoleCode": {
              url: urlBase + "/AgencyMembers/:id/agencyRoleCode",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/AgencyMembers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.suppliers.findById() instead.
            "prototype$__findById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.suppliers.destroyById() instead.
            "prototype$__destroyById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.suppliers.updateById() instead.
            "prototype$__updateById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.suppliers.link() instead.
            "prototype$__link__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencyMember.suppliers.unlink() instead.
            "prototype$__unlink__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.suppliers.exists() instead.
            "prototype$__exists__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencyMembers/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use AgencyMember.tasks() instead.
            "prototype$__get__tasks": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasks.create() instead.
            "prototype$__create__tasks": {
              url: urlBase + "/AgencyMembers/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.tasks.destroyAll() instead.
            "prototype$__delete__tasks": {
              url: urlBase + "/AgencyMembers/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.tasks.count() instead.
            "prototype$__count__tasks": {
              url: urlBase + "/AgencyMembers/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasksAbout() instead.
            "prototype$__get__tasksAbout": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/tasksAbout",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.create() instead.
            "prototype$__create__tasksAbout": {
              url: urlBase + "/AgencyMembers/:id/tasksAbout",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.destroyAll() instead.
            "prototype$__delete__tasksAbout": {
              url: urlBase + "/AgencyMembers/:id/tasksAbout",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.tasksAbout.count() instead.
            "prototype$__count__tasksAbout": {
              url: urlBase + "/AgencyMembers/:id/tasksAbout/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.appointments() instead.
            "prototype$__get__appointments": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/appointments",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.appointments.create() instead.
            "prototype$__create__appointments": {
              url: urlBase + "/AgencyMembers/:id/appointments",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.appointments.destroyAll() instead.
            "prototype$__delete__appointments": {
              url: urlBase + "/AgencyMembers/:id/appointments",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.appointments.count() instead.
            "prototype$__count__appointments": {
              url: urlBase + "/AgencyMembers/:id/appointments/count",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.suppliers() instead.
            "prototype$__get__suppliers": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.suppliers.create() instead.
            "prototype$__create__suppliers": {
              url: urlBase + "/AgencyMembers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use AgencyMember.suppliers.destroyAll() instead.
            "prototype$__delete__suppliers": {
              url: urlBase + "/AgencyMembers/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use AgencyMember.suppliers.count() instead.
            "prototype$__count__suppliers": {
              url: urlBase + "/AgencyMembers/:id/suppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#create
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AgencyMembers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#patchOrCreate
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/AgencyMembers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#replaceOrCreate
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AgencyMembers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#upsertWithWhere
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AgencyMembers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#exists
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AgencyMembers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#findById
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AgencyMembers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#replaceById
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AgencyMembers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#find
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AgencyMembers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#findOne
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AgencyMembers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#updateAll
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AgencyMembers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#deleteById
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AgencyMembers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#count
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AgencyMembers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#prototype$patchAttributes
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/AgencyMembers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#createChangeStream
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AgencyMembers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#getRelatedList
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `rel` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns list of related objects with fields required for a list view
             */
            "getRelatedList": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/getList/:rel",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#sentEmails
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Gets the emails sent by the buyer
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "sentEmails": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/sent-emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#getAllTasks
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `filter` – `{*=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "getAllTasks": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/tasks/all",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#bulkDeleteTasks
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Deletes a number of tasks based on their ID.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `aboutId` – `{string}` -
             *
             *  - `aboutType` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Number of deleted tasks
             */
            "bulkDeleteTasks": {
              url: urlBase + "/AgencyMembers/:id/bulkDeleteTasks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#getBasicSearch
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `search` – `{string}` -
             *
             *  - `type` – `{string}` -
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * An array with the returned results
             */
            "getBasicSearch": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/getBasicSearch",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#getMenuSearchCount
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `search` – `{string}` -
             *
             *  - `type` – `{string=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with the total count and the ids returned
             */
            "getMenuSearchCount": {
              url: urlBase + "/AgencyMembers/:id/menuSearchCount",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#getMenuSearchDetails
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `results` – `{object}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * An array with the returned results
             */
            "getMenuSearchDetails": {
              isArray: true,
              url: urlBase + "/AgencyMembers/:id/menuSearchDetails",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#linkAndUnlinkVisitors
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `toLink` – `{*=}` -
             *
             *  - `toUnlink` – `{*=}` -
             *
             *  - `toCoLink` – `{*=}` -
             *
             *  - `toCoUnlink` – `{*=}` -
             *
             *  - `notifyRecipients` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "linkAndUnlinkVisitors": {
              url: urlBase + "/AgencyMembers/:id/visits/:fk/link-and-unlink",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#createMany
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AgencyMembers",
              method: "POST",
            },

            // INTERNAL. Use Agency.members.findById() instead.
            "::findById::Agency::members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/members/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.members.destroyById() instead.
            "::destroyById::Agency::members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/members/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.members.updateById() instead.
            "::updateById::Agency::members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/members/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.members() instead.
            "::get::Agency::members": {
              isArray: true,
              url: urlBase + "/Agencies/:id/members",
              method: "GET",
            },

            // INTERNAL. Use Agency.members.create() instead.
            "::create::Agency::members": {
              url: urlBase + "/Agencies/:id/members",
              method: "POST",
            },

            // INTERNAL. Use Agency.members.createMany() instead.
            "::createMany::Agency::members": {
              isArray: true,
              url: urlBase + "/Agencies/:id/members",
              method: "POST",
            },

            // INTERNAL. Use Agency.members.destroyAll() instead.
            "::delete::Agency::members": {
              url: urlBase + "/Agencies/:id/members",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.members.count() instead.
            "::count::Agency::members": {
              url: urlBase + "/Agencies/:id/members/count",
              method: "GET",
            },

            // INTERNAL. Use Visit.agencyMember() instead.
            "::get::Visit::agencyMember": {
              url: urlBase + "/Visits/:id/agencyMember",
              method: "GET",
            },

            // INTERNAL. Use Office.members.findById() instead.
            "::findById::Office::members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/members/:fk",
              method: "GET",
            },

            // INTERNAL. Use Office.members.destroyById() instead.
            "::destroyById::Office::members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/members/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Office.members.updateById() instead.
            "::updateById::Office::members": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/members/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Office.members() instead.
            "::get::Office::members": {
              isArray: true,
              url: urlBase + "/Offices/:id/members",
              method: "GET",
            },

            // INTERNAL. Use Office.members.create() instead.
            "::create::Office::members": {
              url: urlBase + "/Offices/:id/members",
              method: "POST",
            },

            // INTERNAL. Use Office.members.createMany() instead.
            "::createMany::Office::members": {
              isArray: true,
              url: urlBase + "/Offices/:id/members",
              method: "POST",
            },

            // INTERNAL. Use Office.members.destroyAll() instead.
            "::delete::Office::members": {
              url: urlBase + "/Offices/:id/members",
              method: "DELETE",
            },

            // INTERNAL. Use Office.members.count() instead.
            "::count::Office::members": {
              url: urlBase + "/Offices/:id/members/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile() instead.
            "::get::SbUser::agencyMemberProfile": {
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile.create() instead.
            "::create::SbUser::agencyMemberProfile": {
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile.createMany() instead.
            "::createMany::SbUser::agencyMemberProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile.update() instead.
            "::update::SbUser::agencyMemberProfile": {
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile.destroy() instead.
            "::destroy::SbUser::agencyMemberProfile": {
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.findById() instead.
            "::findById::SmartSearch::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.destroyById() instead.
            "::destroyById::SmartSearch::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.updateById() instead.
            "::updateById::SmartSearch::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.link() instead.
            "::link::SmartSearch::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.unlink() instead.
            "::unlink::SmartSearch::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.exists() instead.
            "::exists::SmartSearch::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartSearch.agencyMembers() instead.
            "::get::SmartSearch::agencyMembers": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencyMembers",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.create() instead.
            "::create::SmartSearch::agencyMembers": {
              url: urlBase + "/SmartSearches/:id/agencyMembers",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.createMany() instead.
            "::createMany::SmartSearch::agencyMembers": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencyMembers",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.destroyAll() instead.
            "::delete::SmartSearch::agencyMembers": {
              url: urlBase + "/SmartSearches/:id/agencyMembers",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.count() instead.
            "::count::SmartSearch::agencyMembers": {
              url: urlBase + "/SmartSearches/:id/agencyMembers/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#upsert
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#updateOrCreate
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#patchOrCreateWithWhere
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#update
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#destroyById
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#removeById
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#prototype$updateAttributes
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AgencyMember#modelName
        * @propertyOf lbServices.AgencyMember
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AgencyMember`.
        */
        R.modelName = "AgencyMember";


            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#agency
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::AgencyMember::agency"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#office
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Fetches belongsTo relation office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R.office = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::get::AgencyMember::office"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencyMember.tasks
     * @header lbServices.AgencyMember.tasks
     * @object
     * @description
     *
     * The object `AgencyMember.tasks` groups methods
     * manipulating `Task` instances related to `AgencyMember`.
     *
     * Call {@link lbServices.AgencyMember#tasks AgencyMember.tasks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#tasks
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Queries tasks of AgencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::AgencyMember::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasks#count
             * @methodOf lbServices.AgencyMember.tasks
             *
             * @description
             *
             * Counts tasks of AgencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasks.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::AgencyMember::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasks#create
             * @methodOf lbServices.AgencyMember.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::AgencyMember::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasks#createMany
             * @methodOf lbServices.AgencyMember.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::AgencyMember::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasks#destroyAll
             * @methodOf lbServices.AgencyMember.tasks
             *
             * @description
             *
             * Deletes all tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::AgencyMember::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasks#destroyById
             * @methodOf lbServices.AgencyMember.tasks
             *
             * @description
             *
             * Delete a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::AgencyMember::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasks#findById
             * @methodOf lbServices.AgencyMember.tasks
             *
             * @description
             *
             * Find a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::AgencyMember::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasks#updateById
             * @methodOf lbServices.AgencyMember.tasks
             *
             * @description
             *
             * Update a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::AgencyMember::tasks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencyMember.tasksAbout
     * @header lbServices.AgencyMember.tasksAbout
     * @object
     * @description
     *
     * The object `AgencyMember.tasksAbout` groups methods
     * manipulating `Task` instances related to `AgencyMember`.
     *
     * Call {@link lbServices.AgencyMember#tasksAbout AgencyMember.tasksAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#tasksAbout
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Queries tasksAbout of AgencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::AgencyMember::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasksAbout#count
             * @methodOf lbServices.AgencyMember.tasksAbout
             *
             * @description
             *
             * Counts tasksAbout of AgencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasksAbout.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::AgencyMember::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasksAbout#create
             * @methodOf lbServices.AgencyMember.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::AgencyMember::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasksAbout#createMany
             * @methodOf lbServices.AgencyMember.tasksAbout
             *
             * @description
             *
             * Creates a new instance in tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::AgencyMember::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasksAbout#destroyAll
             * @methodOf lbServices.AgencyMember.tasksAbout
             *
             * @description
             *
             * Deletes all tasksAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::AgencyMember::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasksAbout#destroyById
             * @methodOf lbServices.AgencyMember.tasksAbout
             *
             * @description
             *
             * Delete a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasksAbout.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::AgencyMember::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasksAbout#findById
             * @methodOf lbServices.AgencyMember.tasksAbout
             *
             * @description
             *
             * Find a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::AgencyMember::tasksAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.tasksAbout#updateById
             * @methodOf lbServices.AgencyMember.tasksAbout
             *
             * @description
             *
             * Update a related item by id for tasksAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `fk` – `{*}` - Foreign key for tasksAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasksAbout.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::AgencyMember::tasksAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencyMember.appointments
     * @header lbServices.AgencyMember.appointments
     * @object
     * @description
     *
     * The object `AgencyMember.appointments` groups methods
     * manipulating `Visit` instances related to `AgencyMember`.
     *
     * Call {@link lbServices.AgencyMember#appointments AgencyMember.appointments()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#appointments
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Queries appointments of AgencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::get::AgencyMember::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.appointments#count
             * @methodOf lbServices.AgencyMember.appointments
             *
             * @description
             *
             * Counts appointments of AgencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.appointments.count = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::count::AgencyMember::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.appointments#create
             * @methodOf lbServices.AgencyMember.appointments
             *
             * @description
             *
             * Creates a new instance in appointments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments.create = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::create::AgencyMember::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.appointments#createMany
             * @methodOf lbServices.AgencyMember.appointments
             *
             * @description
             *
             * Creates a new instance in appointments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments.createMany = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::createMany::AgencyMember::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.appointments#destroyAll
             * @methodOf lbServices.AgencyMember.appointments
             *
             * @description
             *
             * Deletes all appointments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.appointments.destroyAll = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::delete::AgencyMember::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.appointments#destroyById
             * @methodOf lbServices.AgencyMember.appointments
             *
             * @description
             *
             * Delete a related item by id for appointments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for appointments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.appointments.destroyById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::destroyById::AgencyMember::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.appointments#findById
             * @methodOf lbServices.AgencyMember.appointments
             *
             * @description
             *
             * Find a related item by id for appointments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for appointments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments.findById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::findById::AgencyMember::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.appointments#updateById
             * @methodOf lbServices.AgencyMember.appointments
             *
             * @description
             *
             * Update a related item by id for appointments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `fk` – `{*}` - Foreign key for appointments
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.appointments.updateById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::updateById::AgencyMember::appointments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#agencyRoleCode
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Fetches belongsTo relation agencyRoleCode.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.agencyRoleCode = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::AgencyMember::agencyRoleCode"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#user
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::AgencyMember::user"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencyMember.suppliers
     * @header lbServices.AgencyMember.suppliers
     * @object
     * @description
     *
     * The object `AgencyMember.suppliers` groups methods
     * manipulating `Supplier` instances related to `AgencyMember`.
     *
     * Call {@link lbServices.AgencyMember#suppliers AgencyMember.suppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencyMember#suppliers
             * @methodOf lbServices.AgencyMember
             *
             * @description
             *
             * Queries suppliers of AgencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#count
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Counts suppliers of AgencyMember.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.suppliers.count = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::count::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#create
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.create = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::create::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#createMany
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.createMany = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::createMany::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#destroyAll
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Deletes all suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyAll = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::delete::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#destroyById
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Delete a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::destroyById::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#exists
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Check the existence of suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.exists = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::exists::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#findById
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Find a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.findById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::findById::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#link
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Add a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.link = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::link::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#unlink
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Remove the suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.unlink = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::unlink::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencyMember.suppliers#updateById
             * @methodOf lbServices.AgencyMember.suppliers
             *
             * @description
             *
             * Update a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencyMember id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.updateById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::updateById::AgencyMember::suppliers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Admin
 * @header lbServices.Admin
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Admin` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Admin",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Admins/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Admin.logs.findById() instead.
            "prototype$__findById__logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Admins/:id/logs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Admin.logs.destroyById() instead.
            "prototype$__destroyById__logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Admins/:id/logs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Admin.logs.updateById() instead.
            "prototype$__updateById__logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Admins/:id/logs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Admin.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Admins/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Admin.logs() instead.
            "prototype$__get__logs": {
              isArray: true,
              url: urlBase + "/Admins/:id/logs",
              method: "GET",
            },

            // INTERNAL. Use Admin.logs.create() instead.
            "prototype$__create__logs": {
              url: urlBase + "/Admins/:id/logs",
              method: "POST",
            },

            // INTERNAL. Use Admin.logs.destroyAll() instead.
            "prototype$__delete__logs": {
              url: urlBase + "/Admins/:id/logs",
              method: "DELETE",
            },

            // INTERNAL. Use Admin.logs.count() instead.
            "prototype$__count__logs": {
              url: urlBase + "/Admins/:id/logs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#create
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Admins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#patchOrCreate
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Admins",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#replaceOrCreate
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Admins/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#upsertWithWhere
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Admins/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#exists
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Admins/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#findById
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Admins/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#replaceById
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Admins/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#find
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Admins",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#findOne
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Admins/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#updateAll
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Admins/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#deleteById
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Admins/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#count
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Admins/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#prototype$patchAttributes
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Admins/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#createChangeStream
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Admins/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#saveAccount
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `account` – `{string}` -
             *
             *  - `sbUserId` – `{string}` -
             *
             *  - `agencyId` – `{string=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Updated Account object
             */
            "saveAccount": {
              url: urlBase + "/Admins/:id/saveAccount",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getWordpress
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `accountId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with information about user's Wordpress page
             */
            "getWordpress": {
              url: urlBase + "/Admins/:id/getWordpress/:accountId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#countLogs
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `startDate` – `{string=}` -
             *
             *  - `endDate` – `{string=}` -
             *
             *  - `userIds` – `{*=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` - Number of logs that match these parameters
             */
            "countLogs": {
              url: urlBase + "/Admins/:id/countLogs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#createCharge
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `charge` – `{object}` -
             *
             *  - `agencyId` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Log created by this action
             */
            "createCharge": {
              url: urlBase + "/Admins/:id/createCharge",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#deleteItem
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `model` – `{string}` -
             *
             *  - `modelId` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Deleted item
             */
            "deleteItem": {
              url: urlBase + "/Admins/:id/deleteItem",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#editSubscription
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `agencyId` – `{string}` -
             *
             *  - `subscriptionId` – `{string}` -
             *
             *  - `subItemId` – `{string=}` -
             *
             *  - `changes` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Updated subscription as returned from Stripe API
             */
            "editSubscription": {
              url: urlBase + "/Admins/:id/editSubscription",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#exportLogs
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `startDate` – `{string=}` -
             *
             *  - `endDate` – `{string=}` -
             *
             *  - `userIds` – `{string=}` -
             *
             *  - `response` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "exportLogs": {
              url: urlBase + "/Admins/:id/exportLogs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getActiveBrokers
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of all brokers with status SB_USER
             */
            "getActiveBrokers": {
              isArray: true,
              url: urlBase + "/Admins/:id/getActiveBrokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getAgencies
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of all Agencies in the system
             */
            "getAgencies": {
              isArray: true,
              url: urlBase + "/Admins/:id/getAgencies",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getAgency
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `agencyId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Agency object including subscriptions, agencyOwners
             */
            "getAgency": {
              url: urlBase + "/Admins/:id/getAgency/:agencyId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getAgencyAccounts
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `agencyId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Agency object with all related objects that currently have active accounts
             */
            "getAgencyAccounts": {
              url: urlBase + "/Admins/:id/getAgencyAccounts/:agencyId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getBrokers
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of all Brokers in the system
             */
            "getBrokers": {
              isArray: true,
              url: urlBase + "/Admins/:id/getBrokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getCards
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `customerId` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of cards in that customer's Stripe account
             */
            "getCards": {
              isArray: true,
              url: urlBase + "/Admins/:id/getCards",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getEmails
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of emails for all agency people in the system
             */
            "getEmails": {
              isArray: true,
              url: urlBase + "/Admins/:id/getEmails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getLogs
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `aboutId` – `{string=}` -
             *
             *  - `aboutType` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of all Logs created by this admin filtered by aboutId and aboutType
             */
            "getLogs": {
              isArray: true,
              url: urlBase + "/Admins/:id/getLogs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getListOfUsers
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of users that have accounts
             */
            "getListOfUsers": {
              isArray: true,
              url: urlBase + "/Admins/:id/getListOfUsers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getListings
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `type` – `{string=}` -
             *
             *  - `profileId` – `{string=}` -
             *
             *  - `role` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of Listings in the system according to type
             */
            "getListings": {
              isArray: true,
              url: urlBase + "/Admins/:id/getListings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getProfile
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `profileId` – `{string=}` -
             *
             *  - `type` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Profile object including user, user.account, agency
             */
            "getProfile": {
              url: urlBase + "/Admins/:id/getProfile/:profileId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#getProfileSentEmails
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `profileId` – `{string=}` -
             *
             *  - `type` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Emails sent to a profile
             */
            "getProfileSentEmails": {
              isArray: true,
              url: urlBase + "/Admins/:id/getProfile/:profileId/emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#menuSearch
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `term` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Array with results from search
             */
            "menuSearch": {
              isArray: true,
              url: urlBase + "/Admins/:id/menuSearch",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#refundCharge
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Log with information about the refund.
             */
            "refundCharge": {
              url: urlBase + "/Admins/:id/refundCharge",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#runCentris
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Message that Centris feed is running
             */
            "runCentris": {
              url: urlBase + "/Admins/:id/runCentris",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#runRealtor
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Message that Realtor feed is running
             */
            "runRealtor": {
              url: urlBase + "/Admins/:id/runRealtor",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#runRealtorPhotos
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Message that Realtor photos feed is running
             */
            "runRealtorPhotos": {
              url: urlBase + "/Admins/:id/runRealtorPhotos",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#saveInternal
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `entry` – `{object}` -
             *
             *  - `type` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Updated entry
             */
            "saveInternal": {
              url: urlBase + "/Admins/:id/saveInternal",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#saveProfile
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `profile` – `{string}` -
             *
             *  - `type` – `{string}` -
             *
             *  - `agencyId` – `{string=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Updated object
             */
            "saveProfile": {
              url: urlBase + "/Admins/:id/saveProfile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#toggleSubscription
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `subscriptionId` – `{string}` -
             *
             *  - `agencyId` – `{string}` -
             *
             *  - `isCancel` – `{boolean=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Updated Subscription object
             */
            "toggleSubscription": {
              url: urlBase + "/Admins/:id/toggleSubscription",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#undoAction
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `logId` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * New log about reverted action
             */
            "undoAction": {
              url: urlBase + "/Admins/:id/undoAction",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Admin#createMany
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Admins",
              method: "POST",
            },

            // INTERNAL. Use Log.admin() instead.
            "::get::Log::admin": {
              url: urlBase + "/Logs/:id/admin",
              method: "GET",
            },

            // INTERNAL. Use SbUser.adminProfile() instead.
            "::get::SbUser::adminProfile": {
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.adminProfile.create() instead.
            "::create::SbUser::adminProfile": {
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.adminProfile.createMany() instead.
            "::createMany::SbUser::adminProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.adminProfile.update() instead.
            "::update::SbUser::adminProfile": {
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.adminProfile.destroy() instead.
            "::destroy::SbUser::adminProfile": {
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "DELETE",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Admin#upsert
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Admin#updateOrCreate
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Admin#patchOrCreateWithWhere
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Admin#update
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Admin#destroyById
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Admin#removeById
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Admin#prototype$updateAttributes
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Admin#modelName
        * @propertyOf lbServices.Admin
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Admin`.
        */
        R.modelName = "Admin";

    /**
     * @ngdoc object
     * @name lbServices.Admin.logs
     * @header lbServices.Admin.logs
     * @object
     * @description
     *
     * The object `Admin.logs` groups methods
     * manipulating `Log` instances related to `Admin`.
     *
     * Call {@link lbServices.Admin#logs Admin.logs()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Admin#logs
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Queries logs of Admin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::get::Admin::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Admin.logs#count
             * @methodOf lbServices.Admin.logs
             *
             * @description
             *
             * Counts logs of Admin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.logs.count = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::count::Admin::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Admin.logs#create
             * @methodOf lbServices.Admin.logs
             *
             * @description
             *
             * Creates a new instance in logs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs.create = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::create::Admin::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Admin.logs#createMany
             * @methodOf lbServices.Admin.logs
             *
             * @description
             *
             * Creates a new instance in logs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs.createMany = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::createMany::Admin::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Admin.logs#destroyAll
             * @methodOf lbServices.Admin.logs
             *
             * @description
             *
             * Deletes all logs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logs.destroyAll = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::delete::Admin::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Admin.logs#destroyById
             * @methodOf lbServices.Admin.logs
             *
             * @description
             *
             * Delete a related item by id for logs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logs.destroyById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::destroyById::Admin::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Admin.logs#findById
             * @methodOf lbServices.Admin.logs
             *
             * @description
             *
             * Find a related item by id for logs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs.findById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::findById::Admin::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Admin.logs#updateById
             * @methodOf lbServices.Admin.logs
             *
             * @description
             *
             * Update a related item by id for logs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `fk` – `{*}` - Foreign key for logs
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs.updateById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::updateById::Admin::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Admin#user
             * @methodOf lbServices.Admin
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Admin id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Admin::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SbUser
 * @header lbServices.SbUser
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SbUser` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SbUser",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SbUsers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SbUser#prototype$__get__address
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Fetches hasOne relation address.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "prototype$__get__address": {
              url: urlBase + "/SbUsers/:id/address",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#prototype$__create__address
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Creates a new instance in address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "prototype$__create__address": {
              url: urlBase + "/SbUsers/:id/address",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#prototype$__update__address
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Update address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "prototype$__update__address": {
              url: urlBase + "/SbUsers/:id/address",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#prototype$__destroy__address
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Deletes address of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroy__address": {
              url: urlBase + "/SbUsers/:id/address",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.languages.findById() instead.
            "prototype$__findById__languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.languages.destroyById() instead.
            "prototype$__destroyById__languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.languages.updateById() instead.
            "prototype$__updateById__languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.languages.link() instead.
            "prototype$__link__languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.languages.unlink() instead.
            "prototype$__unlink__languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.languages.exists() instead.
            "prototype$__exists__languages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/languages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.adminProfile() instead.
            "prototype$__get__adminProfile": {
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.adminProfile.create() instead.
            "prototype$__create__adminProfile": {
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.adminProfile.update() instead.
            "prototype$__update__adminProfile": {
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.adminProfile.destroy() instead.
            "prototype$__destroy__adminProfile": {
              url: urlBase + "/SbUsers/:id/adminProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile() instead.
            "prototype$__get__agencyMemberProfile": {
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile.create() instead.
            "prototype$__create__agencyMemberProfile": {
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile.update() instead.
            "prototype$__update__agencyMemberProfile": {
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.agencyMemberProfile.destroy() instead.
            "prototype$__destroy__agencyMemberProfile": {
              url: urlBase + "/SbUsers/:id/agencyMemberProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile() instead.
            "prototype$__get__agencyOwnerProfile": {
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile.create() instead.
            "prototype$__create__agencyOwnerProfile": {
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile.update() instead.
            "prototype$__update__agencyOwnerProfile": {
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.agencyOwnerProfile.destroy() instead.
            "prototype$__destroy__agencyOwnerProfile": {
              url: urlBase + "/SbUsers/:id/agencyOwnerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.auditorProfile.findById() instead.
            "prototype$__findById__auditorProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/auditorProfile/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.auditorProfile.destroyById() instead.
            "prototype$__destroyById__auditorProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/auditorProfile/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.auditorProfile.updateById() instead.
            "prototype$__updateById__auditorProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/auditorProfile/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.notaryProfile.findById() instead.
            "prototype$__findById__notaryProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notaryProfile/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notaryProfile.destroyById() instead.
            "prototype$__destroyById__notaryProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notaryProfile/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notaryProfile.updateById() instead.
            "prototype$__updateById__notaryProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notaryProfile/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.managers.findById() instead.
            "prototype$__findById__managers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managers.destroyById() instead.
            "prototype$__destroyById__managers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.managers.updateById() instead.
            "prototype$__updateById__managers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.managerProfile.findById() instead.
            "prototype$__findById__managerProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managerProfile/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managerProfile.destroyById() instead.
            "prototype$__destroyById__managerProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managerProfile/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.managerProfile.updateById() instead.
            "prototype$__updateById__managerProfile": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/managerProfile/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sellerProfile() instead.
            "prototype$__get__sellerProfile": {
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sellerProfile.create() instead.
            "prototype$__create__sellerProfile": {
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sellerProfile.update() instead.
            "prototype$__update__sellerProfile": {
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sellerProfile.destroy() instead.
            "prototype$__destroy__sellerProfile": {
              url: urlBase + "/SbUsers/:id/sellerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.buyerProfile() instead.
            "prototype$__get__buyerProfile": {
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.buyerProfile.create() instead.
            "prototype$__create__buyerProfile": {
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.buyerProfile.update() instead.
            "prototype$__update__buyerProfile": {
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.buyerProfile.destroy() instead.
            "prototype$__destroy__buyerProfile": {
              url: urlBase + "/SbUsers/:id/buyerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.notes.findById() instead.
            "prototype$__findById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notes/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notes.destroyById() instead.
            "prototype$__destroyById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notes.updateById() instead.
            "prototype$__updateById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.bookmarks.findById() instead.
            "prototype$__findById__bookmarks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/bookmarks/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.bookmarks.destroyById() instead.
            "prototype$__destroyById__bookmarks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/bookmarks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.bookmarks.updateById() instead.
            "prototype$__updateById__bookmarks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/bookmarks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.findById() instead.
            "prototype$__findById__ownedSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedSuppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.destroyById() instead.
            "prototype$__destroyById__ownedSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedSuppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.updateById() instead.
            "prototype$__updateById__ownedSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedSuppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.ownedAgencies.findById() instead.
            "prototype$__findById__ownedAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedAgencies/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedAgencies.destroyById() instead.
            "prototype$__destroyById__ownedAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedAgencies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.ownedAgencies.updateById() instead.
            "prototype$__updateById__ownedAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/ownedAgencies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.logs.findById() instead.
            "prototype$__findById__logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logs/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logs.destroyById() instead.
            "prototype$__destroyById__logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.logs.updateById() instead.
            "prototype$__updateById__logs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.notesAbout.findById() instead.
            "prototype$__findById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notesAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notesAbout.destroyById() instead.
            "prototype$__destroyById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notesAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notesAbout.updateById() instead.
            "prototype$__updateById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notesAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.logsAbout.findById() instead.
            "prototype$__findById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logsAbout.destroyById() instead.
            "prototype$__destroyById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.logsAbout.updateById() instead.
            "prototype$__updateById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.taskTemplates.findById() instead.
            "prototype$__findById__taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.taskTemplates.destroyById() instead.
            "prototype$__destroyById__taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.taskTemplates.updateById() instead.
            "prototype$__updateById__taskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.emailTemplates.findById() instead.
            "prototype$__findById__emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/emailTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.emailTemplates.destroyById() instead.
            "prototype$__destroyById__emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/emailTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.emailTemplates.updateById() instead.
            "prototype$__updateById__emailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/emailTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.roles.findById() instead.
            "prototype$__findById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.roles.destroyById() instead.
            "prototype$__destroyById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.roles.updateById() instead.
            "prototype$__updateById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.roles.link() instead.
            "prototype$__link__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.roles.unlink() instead.
            "prototype$__unlink__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.roles.exists() instead.
            "prototype$__exists__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/roles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.folders.findById() instead.
            "prototype$__findById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.folders.destroyById() instead.
            "prototype$__destroyById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.folders.updateById() instead.
            "prototype$__updateById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sentEmails.findById() instead.
            "prototype$__findById__sentEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sentEmails/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sentEmails.destroyById() instead.
            "prototype$__destroyById__sentEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sentEmails/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sentEmails.updateById() instead.
            "prototype$__updateById__sentEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sentEmails/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.receivedEmails.findById() instead.
            "prototype$__findById__receivedEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/receivedEmails/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.receivedEmails.destroyById() instead.
            "prototype$__destroyById__receivedEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/receivedEmails/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.receivedEmails.updateById() instead.
            "prototype$__updateById__receivedEmails": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/receivedEmails/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.brokerProfile() instead.
            "prototype$__get__brokerProfile": {
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.brokerProfile.create() instead.
            "prototype$__create__brokerProfile": {
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.brokerProfile.update() instead.
            "prototype$__update__brokerProfile": {
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.brokerProfile.destroy() instead.
            "prototype$__destroy__brokerProfile": {
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/SbUsers/:id/account",
              method: "GET",
            },

            // INTERNAL. Use SbUser.visits.findById() instead.
            "prototype$__findById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.visits.destroyById() instead.
            "prototype$__destroyById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.visits.updateById() instead.
            "prototype$__updateById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.visits.link() instead.
            "prototype$__link__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.visits.unlink() instead.
            "prototype$__unlink__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.visits.exists() instead.
            "prototype$__exists__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/visits/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.conversations.findById() instead.
            "prototype$__findById__conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.conversations.destroyById() instead.
            "prototype$__destroyById__conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.conversations.updateById() instead.
            "prototype$__updateById__conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.conversations.link() instead.
            "prototype$__link__conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.conversations.unlink() instead.
            "prototype$__unlink__conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.conversations.exists() instead.
            "prototype$__exists__conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.events.findById() instead.
            "prototype$__findById__events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/events/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.events.destroyById() instead.
            "prototype$__destroyById__events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/events/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.events.updateById() instead.
            "prototype$__updateById__events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/events/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.notifications.findById() instead.
            "prototype$__findById__notifications": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notifications/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notifications.destroyById() instead.
            "prototype$__destroyById__notifications": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notifications/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notifications.updateById() instead.
            "prototype$__updateById__notifications": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notifications/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.findById() instead.
            "prototype$__findById__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.destroyById() instead.
            "prototype$__destroyById__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.updateById() instead.
            "prototype$__updateById__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.link() instead.
            "prototype$__link__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.unlink() instead.
            "prototype$__unlink__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.exists() instead.
            "prototype$__exists__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.findById() instead.
            "prototype$__findById__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.destroyById() instead.
            "prototype$__destroyById__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.updateById() instead.
            "prototype$__updateById__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.link() instead.
            "prototype$__link__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.unlink() instead.
            "prototype$__unlink__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.exists() instead.
            "prototype$__exists__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.languages() instead.
            "prototype$__get__languages": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/languages",
              method: "GET",
            },

            // INTERNAL. Use SbUser.languages.create() instead.
            "prototype$__create__languages": {
              url: urlBase + "/SbUsers/:id/languages",
              method: "POST",
            },

            // INTERNAL. Use SbUser.languages.destroyAll() instead.
            "prototype$__delete__languages": {
              url: urlBase + "/SbUsers/:id/languages",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.languages.count() instead.
            "prototype$__count__languages": {
              url: urlBase + "/SbUsers/:id/languages/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.auditorProfile() instead.
            "prototype$__get__auditorProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/auditorProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.auditorProfile.create() instead.
            "prototype$__create__auditorProfile": {
              url: urlBase + "/SbUsers/:id/auditorProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.auditorProfile.destroyAll() instead.
            "prototype$__delete__auditorProfile": {
              url: urlBase + "/SbUsers/:id/auditorProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.auditorProfile.count() instead.
            "prototype$__count__auditorProfile": {
              url: urlBase + "/SbUsers/:id/auditorProfile/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notaryProfile() instead.
            "prototype$__get__notaryProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notaryProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notaryProfile.create() instead.
            "prototype$__create__notaryProfile": {
              url: urlBase + "/SbUsers/:id/notaryProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notaryProfile.destroyAll() instead.
            "prototype$__delete__notaryProfile": {
              url: urlBase + "/SbUsers/:id/notaryProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notaryProfile.count() instead.
            "prototype$__count__notaryProfile": {
              url: urlBase + "/SbUsers/:id/notaryProfile/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managers() instead.
            "prototype$__get__managers": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/managers",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managers.create() instead.
            "prototype$__create__managers": {
              url: urlBase + "/SbUsers/:id/managers",
              method: "POST",
            },

            // INTERNAL. Use SbUser.managers.destroyAll() instead.
            "prototype$__delete__managers": {
              url: urlBase + "/SbUsers/:id/managers",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.managers.count() instead.
            "prototype$__count__managers": {
              url: urlBase + "/SbUsers/:id/managers/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managerProfile() instead.
            "prototype$__get__managerProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/managerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.managerProfile.create() instead.
            "prototype$__create__managerProfile": {
              url: urlBase + "/SbUsers/:id/managerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.managerProfile.destroyAll() instead.
            "prototype$__delete__managerProfile": {
              url: urlBase + "/SbUsers/:id/managerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.managerProfile.count() instead.
            "prototype$__count__managerProfile": {
              url: urlBase + "/SbUsers/:id/managerProfile/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/files",
              method: "GET",
            },

            // INTERNAL. Use SbUser.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/SbUsers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use SbUser.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/SbUsers/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/SbUsers/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notes() instead.
            "prototype$__get__notes": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notes",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notes.create() instead.
            "prototype$__create__notes": {
              url: urlBase + "/SbUsers/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notes.destroyAll() instead.
            "prototype$__delete__notes": {
              url: urlBase + "/SbUsers/:id/notes",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notes.count() instead.
            "prototype$__count__notes": {
              url: urlBase + "/SbUsers/:id/notes/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.bookmarks() instead.
            "prototype$__get__bookmarks": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/bookmarks",
              method: "GET",
            },

            // INTERNAL. Use SbUser.bookmarks.create() instead.
            "prototype$__create__bookmarks": {
              url: urlBase + "/SbUsers/:id/bookmarks",
              method: "POST",
            },

            // INTERNAL. Use SbUser.bookmarks.destroyAll() instead.
            "prototype$__delete__bookmarks": {
              url: urlBase + "/SbUsers/:id/bookmarks",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.bookmarks.count() instead.
            "prototype$__count__bookmarks": {
              url: urlBase + "/SbUsers/:id/bookmarks/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedSuppliers() instead.
            "prototype$__get__ownedSuppliers": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/ownedSuppliers",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.create() instead.
            "prototype$__create__ownedSuppliers": {
              url: urlBase + "/SbUsers/:id/ownedSuppliers",
              method: "POST",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.destroyAll() instead.
            "prototype$__delete__ownedSuppliers": {
              url: urlBase + "/SbUsers/:id/ownedSuppliers",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.ownedSuppliers.count() instead.
            "prototype$__count__ownedSuppliers": {
              url: urlBase + "/SbUsers/:id/ownedSuppliers/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedAgencies() instead.
            "prototype$__get__ownedAgencies": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/ownedAgencies",
              method: "GET",
            },

            // INTERNAL. Use SbUser.ownedAgencies.create() instead.
            "prototype$__create__ownedAgencies": {
              url: urlBase + "/SbUsers/:id/ownedAgencies",
              method: "POST",
            },

            // INTERNAL. Use SbUser.ownedAgencies.destroyAll() instead.
            "prototype$__delete__ownedAgencies": {
              url: urlBase + "/SbUsers/:id/ownedAgencies",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.ownedAgencies.count() instead.
            "prototype$__count__ownedAgencies": {
              url: urlBase + "/SbUsers/:id/ownedAgencies/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logs() instead.
            "prototype$__get__logs": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/logs",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logs.create() instead.
            "prototype$__create__logs": {
              url: urlBase + "/SbUsers/:id/logs",
              method: "POST",
            },

            // INTERNAL. Use SbUser.logs.destroyAll() instead.
            "prototype$__delete__logs": {
              url: urlBase + "/SbUsers/:id/logs",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.logs.count() instead.
            "prototype$__count__logs": {
              url: urlBase + "/SbUsers/:id/logs/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notesAbout() instead.
            "prototype$__get__notesAbout": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notesAbout",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notesAbout.create() instead.
            "prototype$__create__notesAbout": {
              url: urlBase + "/SbUsers/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notesAbout.destroyAll() instead.
            "prototype$__delete__notesAbout": {
              url: urlBase + "/SbUsers/:id/notesAbout",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notesAbout.count() instead.
            "prototype$__count__notesAbout": {
              url: urlBase + "/SbUsers/:id/notesAbout/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logsAbout() instead.
            "prototype$__get__logsAbout": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use SbUser.logsAbout.create() instead.
            "prototype$__create__logsAbout": {
              url: urlBase + "/SbUsers/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use SbUser.logsAbout.destroyAll() instead.
            "prototype$__delete__logsAbout": {
              url: urlBase + "/SbUsers/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.logsAbout.count() instead.
            "prototype$__count__logsAbout": {
              url: urlBase + "/SbUsers/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.taskTemplates() instead.
            "prototype$__get__taskTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/taskTemplates",
              method: "GET",
            },

            // INTERNAL. Use SbUser.taskTemplates.create() instead.
            "prototype$__create__taskTemplates": {
              url: urlBase + "/SbUsers/:id/taskTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.taskTemplates.destroyAll() instead.
            "prototype$__delete__taskTemplates": {
              url: urlBase + "/SbUsers/:id/taskTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.taskTemplates.count() instead.
            "prototype$__count__taskTemplates": {
              url: urlBase + "/SbUsers/:id/taskTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.emailTemplates() instead.
            "prototype$__get__emailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/emailTemplates",
              method: "GET",
            },

            // INTERNAL. Use SbUser.emailTemplates.create() instead.
            "prototype$__create__emailTemplates": {
              url: urlBase + "/SbUsers/:id/emailTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.emailTemplates.destroyAll() instead.
            "prototype$__delete__emailTemplates": {
              url: urlBase + "/SbUsers/:id/emailTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.emailTemplates.count() instead.
            "prototype$__count__emailTemplates": {
              url: urlBase + "/SbUsers/:id/emailTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.roles() instead.
            "prototype$__get__roles": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/roles",
              method: "GET",
            },

            // INTERNAL. Use SbUser.roles.create() instead.
            "prototype$__create__roles": {
              url: urlBase + "/SbUsers/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use SbUser.roles.destroyAll() instead.
            "prototype$__delete__roles": {
              url: urlBase + "/SbUsers/:id/roles",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.roles.count() instead.
            "prototype$__count__roles": {
              url: urlBase + "/SbUsers/:id/roles/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.folders() instead.
            "prototype$__get__folders": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use SbUser.folders.create() instead.
            "prototype$__create__folders": {
              url: urlBase + "/SbUsers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use SbUser.folders.destroyAll() instead.
            "prototype$__delete__folders": {
              url: urlBase + "/SbUsers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.folders.count() instead.
            "prototype$__count__folders": {
              url: urlBase + "/SbUsers/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sentEmails() instead.
            "prototype$__get__sentEmails": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sentEmails",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sentEmails.create() instead.
            "prototype$__create__sentEmails": {
              url: urlBase + "/SbUsers/:id/sentEmails",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sentEmails.destroyAll() instead.
            "prototype$__delete__sentEmails": {
              url: urlBase + "/SbUsers/:id/sentEmails",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sentEmails.count() instead.
            "prototype$__count__sentEmails": {
              url: urlBase + "/SbUsers/:id/sentEmails/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.receivedEmails() instead.
            "prototype$__get__receivedEmails": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/receivedEmails",
              method: "GET",
            },

            // INTERNAL. Use SbUser.receivedEmails.create() instead.
            "prototype$__create__receivedEmails": {
              url: urlBase + "/SbUsers/:id/receivedEmails",
              method: "POST",
            },

            // INTERNAL. Use SbUser.receivedEmails.destroyAll() instead.
            "prototype$__delete__receivedEmails": {
              url: urlBase + "/SbUsers/:id/receivedEmails",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.receivedEmails.count() instead.
            "prototype$__count__receivedEmails": {
              url: urlBase + "/SbUsers/:id/receivedEmails/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.visits() instead.
            "prototype$__get__visits": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/visits",
              method: "GET",
            },

            // INTERNAL. Use SbUser.visits.create() instead.
            "prototype$__create__visits": {
              url: urlBase + "/SbUsers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use SbUser.visits.destroyAll() instead.
            "prototype$__delete__visits": {
              url: urlBase + "/SbUsers/:id/visits",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.visits.count() instead.
            "prototype$__count__visits": {
              url: urlBase + "/SbUsers/:id/visits/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.conversations() instead.
            "prototype$__get__conversations": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/conversations",
              method: "GET",
            },

            // INTERNAL. Use SbUser.conversations.create() instead.
            "prototype$__create__conversations": {
              url: urlBase + "/SbUsers/:id/conversations",
              method: "POST",
            },

            // INTERNAL. Use SbUser.conversations.destroyAll() instead.
            "prototype$__delete__conversations": {
              url: urlBase + "/SbUsers/:id/conversations",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.conversations.count() instead.
            "prototype$__count__conversations": {
              url: urlBase + "/SbUsers/:id/conversations/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.events() instead.
            "prototype$__get__events": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/events",
              method: "GET",
            },

            // INTERNAL. Use SbUser.events.create() instead.
            "prototype$__create__events": {
              url: urlBase + "/SbUsers/:id/events",
              method: "POST",
            },

            // INTERNAL. Use SbUser.events.destroyAll() instead.
            "prototype$__delete__events": {
              url: urlBase + "/SbUsers/:id/events",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.events.count() instead.
            "prototype$__count__events": {
              url: urlBase + "/SbUsers/:id/events/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notifications() instead.
            "prototype$__get__notifications": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notifications",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notifications.create() instead.
            "prototype$__create__notifications": {
              url: urlBase + "/SbUsers/:id/notifications",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notifications.destroyAll() instead.
            "prototype$__delete__notifications": {
              url: urlBase + "/SbUsers/:id/notifications",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notifications.count() instead.
            "prototype$__count__notifications": {
              url: urlBase + "/SbUsers/:id/notifications/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates() instead.
            "prototype$__get__sharedTaskTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.create() instead.
            "prototype$__create__sharedTaskTemplates": {
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.destroyAll() instead.
            "prototype$__delete__sharedTaskTemplates": {
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedTaskTemplates.count() instead.
            "prototype$__count__sharedTaskTemplates": {
              url: urlBase + "/SbUsers/:id/sharedTaskTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates() instead.
            "prototype$__get__sharedEmailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates",
              method: "GET",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.create() instead.
            "prototype$__create__sharedEmailTemplates": {
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates",
              method: "POST",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.destroyAll() instead.
            "prototype$__delete__sharedEmailTemplates": {
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.sharedEmailTemplates.count() instead.
            "prototype$__count__sharedEmailTemplates": {
              url: urlBase + "/SbUsers/:id/sharedEmailTemplates/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#create
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SbUsers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#patchOrCreate
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SbUsers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#replaceOrCreate
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SbUsers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#upsertWithWhere
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SbUsers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#exists
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SbUsers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#findById
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SbUsers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#replaceById
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SbUsers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#find
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SbUsers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#findOne
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SbUsers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#updateAll
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SbUsers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#deleteById
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SbUsers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#count
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SbUsers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#prototype$patchAttributes
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SbUsers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#createChangeStream
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SbUsers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#uploadFile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Uploads an image for smartbrokr
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "uploadFile": {
              url: urlBase + "/SbUsers/:id/uploadFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#generateVideo
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns boolean on whether video was generated
             */
            "generateVideo": {
              url: urlBase + "/SbUsers/:id/generateVideo",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#uploadFileType
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Uploads a file for smartbrokr, specifying its container and or bucket based on type
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `type` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "uploadFileType": {
              url: urlBase + "/SbUsers/:id/uploadFile/:type",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#sendEmail
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Send an email to another SbUser(Broker/Agency/Buyer/Seller)
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Success flag.
             */
            "sendEmail": {
              url: urlBase + "/SbUsers/:id/sendEmail",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#sendEmails
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Send an email to another SbUser(Broker/Agency/Buyer/Seller)
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Success flag.
             */
            "sendEmails": {
              url: urlBase + "/SbUsers/:id/sendEmails",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getFoldersAndReports
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Returns folders and reports
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getFoldersAndReports": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/getFoldersAndReports",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getContacts
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Returns contacts
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getContacts": {
              url: urlBase + "/SbUsers/:id/getContacts",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#newConversationClient
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Creates a conversation between two users
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `message` – `{object=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "newConversationClient": {
              url: urlBase + "/SbUsers/:id/newConversation",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#newMessage
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Adds a message to a users conversation
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `message` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "newMessage": {
              url: urlBase + "/SbUsers/:id/newMessage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getUnreadCount
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Returns number of unread messages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getUnreadCount": {
              url: urlBase + "/SbUsers/:id/getUnreadCount",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getMyPeople
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Returns contacts
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getMyPeople": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/getMyPeople",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#bulkActionLog
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Creates a pdf file with selected logs and sends it by email
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `logIds` – `{*=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Success flag.
             */
            "bulkActionLog": {
              url: urlBase + "/SbUsers/:id/bulkActionLog",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#checkManyDuplicates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Checks for duplicates in an array of many strings
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{*=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The two scenarios
             */
            "checkManyDuplicates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/checkManyDuplicates",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#exportIncompleteTasks
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Gets a report with a person's incomplete tasks and generates a PDF file for it. Returns a file download.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `filters` – `{object=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns a generated PDF file with the incomplete tasks
             */
            "exportIncompleteTasks": {
              url: urlBase + "/SbUsers/:id/exportIncompleteTasks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getIncompleteTasks
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Gets a report with a person's incomplete tasks
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filters` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Report with all incomplete tasks.
             */
            "getIncompleteTasks": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/getIncompleteTasks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getNotifications
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Gets notifications for a user
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `types` – `{*=}` -
             *
             *  - `startDate` – `{date=}` -
             *
             *  - `endDate` – `{date=}` -
             *
             *  - `skip` – `{number=}` -
             *
             *  - `limit` – `{number=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Notifications that belong to this user.
             */
            "getNotifications": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/getNotifications",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getNotificationCount
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Gets the number of notifications that a user has
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `types` – `{*=}` -
             *
             *  - `startDate` – `{date=}` -
             *
             *  - `endDate` – `{date=}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `unread` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of notifications that the user has
             */
            "getNotificationCount": {
              url: urlBase + "/SbUsers/:id/getUnreadNotifications",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#readAllNotifications
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Marks all notifications as read
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of updated notifications
             */
            "readAllNotifications": {
              url: urlBase + "/SbUsers/:id/readAllNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#readNotification
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Marks one notification as read
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `nId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The result from Notification.save
             */
            "readNotification": {
              url: urlBase + "/SbUsers/:id/readNotification",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#deleteNotifications
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Deletes the selected notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `selected` – `{*}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of deleted notifications
             */
            "deleteNotifications": {
              url: urlBase + "/SbUsers/:id/deleteNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#assignFileToFolder
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             *  - `folderId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "assignFileToFolder": {
              url: urlBase + "/SbUsers/:id/files/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getGoogleEvents
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * IDK you figure it out
             */
            "getGoogleEvents": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/google-calendar",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getOAuthUrl
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `url` – `{string=}` -
             */
            "getOAuthUrl": {
              url: urlBase + "/SbUsers/:id/google-calendar/auth",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#OAuthCallback
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "OAuthCallback": {
              url: urlBase + "/SbUsers/oauth",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#downloadFile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/SbUsers/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#downloadFiles
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/SbUsers/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#updateTaskTemplate
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{*=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "updateTaskTemplate": {
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk/update",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#shareTaskTemplate
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `recipients` – `{*}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "shareTaskTemplate": {
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk/share",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#shareEmailTemplate
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `recipients` – `{*}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "shareEmailTemplate": {
              url: urlBase + "/SbUsers/:id/emailTemplates/:fk/share",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getSharedEmailTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getSharedEmailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/get-shared-email-templates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getEmailTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getEmailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/get-email-templates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getTaskTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{*=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getTaskTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/get-task-templates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getAllEmailTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getAllEmailTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/get-all-email-templates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getSharedTaskTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{*=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getSharedTaskTemplates": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/get-shared-task-templates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#getTaskTemplate
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             *  - `filter` – `{*=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "getTaskTemplate": {
              url: urlBase + "/SbUsers/:id/taskTemplates/:fk/get",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SbUser#createMany
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SbUsers",
              method: "POST",
            },

            // INTERNAL. Use Message.sender() instead.
            "::get::Message::sender": {
              url: urlBase + "/Messages/:id/sender",
              method: "GET",
            },

            // INTERNAL. Use Note.createdBy() instead.
            "::get::Note::createdBy": {
              url: urlBase + "/Notes/:id/createdBy",
              method: "GET",
            },

            // INTERNAL. Use Note.createManydBy() instead.
            "::get::Note::createManydBy": {
              isArray: true,
              url: urlBase + "/Notes/:id/createdBy",
              method: "GET",
            },

            // INTERNAL. Use Bookmark.user() instead.
            "::get::Bookmark::user": {
              url: urlBase + "/Bookmarks/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Auditor.user() instead.
            "::get::Auditor::user": {
              url: urlBase + "/Auditors/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Notary.user() instead.
            "::get::Notary::user": {
              url: urlBase + "/Notaries/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Seller.user() instead.
            "::get::Seller::user": {
              url: urlBase + "/Sellers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Buyer.user() instead.
            "::get::Buyer::user": {
              url: urlBase + "/Buyers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Buyer.coBuyer() instead.
            "::get::Buyer::coBuyer": {
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "GET",
            },

            // INTERNAL. Use Buyer.coBuyer.create() instead.
            "::create::Buyer::coBuyer": {
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "POST",
            },

            // INTERNAL. Use Buyer.coBuyer.createMany() instead.
            "::createMany::Buyer::coBuyer": {
              isArray: true,
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "POST",
            },

            // INTERNAL. Use Buyer.coBuyer.update() instead.
            "::update::Buyer::coBuyer": {
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.coBuyer.destroy() instead.
            "::destroy::Buyer::coBuyer": {
              url: urlBase + "/Buyers/:id/coBuyer",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyMembers.findById() instead.
            "::findById::Agency::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyMembers.destroyById() instead.
            "::destroyById::Agency::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyMembers.updateById() instead.
            "::updateById::Agency::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyMembers.link() instead.
            "::link::Agency::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyMembers.unlink() instead.
            "::unlink::Agency::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyMembers.exists() instead.
            "::exists::Agency::agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyMembers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.agencyOwners.findById() instead.
            "::findById::Agency::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyOwners.destroyById() instead.
            "::destroyById::Agency::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyOwners.updateById() instead.
            "::updateById::Agency::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyOwners.link() instead.
            "::link::Agency::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyOwners.unlink() instead.
            "::unlink::Agency::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyOwners.exists() instead.
            "::exists::Agency::agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyOwners/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.agencyAuditors.findById() instead.
            "::findById::Agency::agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyAuditors.destroyById() instead.
            "::destroyById::Agency::agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyAuditors.updateById() instead.
            "::updateById::Agency::agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyAuditors.link() instead.
            "::link::Agency::agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyAuditors.unlink() instead.
            "::unlink::Agency::agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyAuditors.exists() instead.
            "::exists::Agency::agencyAuditors": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyAuditors/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.agencyNotaries.findById() instead.
            "::findById::Agency::agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyNotaries.destroyById() instead.
            "::destroyById::Agency::agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyNotaries.updateById() instead.
            "::updateById::Agency::agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyNotaries.link() instead.
            "::link::Agency::agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyNotaries.unlink() instead.
            "::unlink::Agency::agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyNotaries.exists() instead.
            "::exists::Agency::agencyNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyNotaries/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.agencyBrokers.findById() instead.
            "::findById::Agency::agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyBrokers.destroyById() instead.
            "::destroyById::Agency::agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyBrokers.updateById() instead.
            "::updateById::Agency::agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyBrokers.link() instead.
            "::link::Agency::agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.agencyBrokers.unlink() instead.
            "::unlink::Agency::agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyBrokers.exists() instead.
            "::exists::Agency::agencyBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/agencyBrokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Agency.agencyMembers() instead.
            "::get::Agency::agencyMembers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyMembers",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyMembers.create() instead.
            "::create::Agency::agencyMembers": {
              url: urlBase + "/Agencies/:id/agencyMembers",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyMembers.createMany() instead.
            "::createMany::Agency::agencyMembers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyMembers",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyMembers.destroyAll() instead.
            "::delete::Agency::agencyMembers": {
              url: urlBase + "/Agencies/:id/agencyMembers",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyMembers.count() instead.
            "::count::Agency::agencyMembers": {
              url: urlBase + "/Agencies/:id/agencyMembers/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyOwners() instead.
            "::get::Agency::agencyOwners": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyOwners",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyOwners.create() instead.
            "::create::Agency::agencyOwners": {
              url: urlBase + "/Agencies/:id/agencyOwners",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyOwners.createMany() instead.
            "::createMany::Agency::agencyOwners": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyOwners",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyOwners.destroyAll() instead.
            "::delete::Agency::agencyOwners": {
              url: urlBase + "/Agencies/:id/agencyOwners",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyOwners.count() instead.
            "::count::Agency::agencyOwners": {
              url: urlBase + "/Agencies/:id/agencyOwners/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyAuditors() instead.
            "::get::Agency::agencyAuditors": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyAuditors",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyAuditors.create() instead.
            "::create::Agency::agencyAuditors": {
              url: urlBase + "/Agencies/:id/agencyAuditors",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyAuditors.createMany() instead.
            "::createMany::Agency::agencyAuditors": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyAuditors",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyAuditors.destroyAll() instead.
            "::delete::Agency::agencyAuditors": {
              url: urlBase + "/Agencies/:id/agencyAuditors",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyAuditors.count() instead.
            "::count::Agency::agencyAuditors": {
              url: urlBase + "/Agencies/:id/agencyAuditors/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyNotaries() instead.
            "::get::Agency::agencyNotaries": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyNotaries",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyNotaries.create() instead.
            "::create::Agency::agencyNotaries": {
              url: urlBase + "/Agencies/:id/agencyNotaries",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyNotaries.createMany() instead.
            "::createMany::Agency::agencyNotaries": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyNotaries",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyNotaries.destroyAll() instead.
            "::delete::Agency::agencyNotaries": {
              url: urlBase + "/Agencies/:id/agencyNotaries",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyNotaries.count() instead.
            "::count::Agency::agencyNotaries": {
              url: urlBase + "/Agencies/:id/agencyNotaries/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyBrokers() instead.
            "::get::Agency::agencyBrokers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyBrokers",
              method: "GET",
            },

            // INTERNAL. Use Agency.agencyBrokers.create() instead.
            "::create::Agency::agencyBrokers": {
              url: urlBase + "/Agencies/:id/agencyBrokers",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyBrokers.createMany() instead.
            "::createMany::Agency::agencyBrokers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/agencyBrokers",
              method: "POST",
            },

            // INTERNAL. Use Agency.agencyBrokers.destroyAll() instead.
            "::delete::Agency::agencyBrokers": {
              url: urlBase + "/Agencies/:id/agencyBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.agencyBrokers.count() instead.
            "::count::Agency::agencyBrokers": {
              url: urlBase + "/Agencies/:id/agencyBrokers/count",
              method: "GET",
            },

            // INTERNAL. Use Visit.users.findById() instead.
            "::findById::Visit::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Visit.users.destroyById() instead.
            "::destroyById::Visit::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.users.updateById() instead.
            "::updateById::Visit::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.users.link() instead.
            "::link::Visit::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.users.unlink() instead.
            "::unlink::Visit::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.users.exists() instead.
            "::exists::Visit::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Visit.users() instead.
            "::get::Visit::users": {
              isArray: true,
              url: urlBase + "/Visits/:id/users",
              method: "GET",
            },

            // INTERNAL. Use Visit.users.create() instead.
            "::create::Visit::users": {
              url: urlBase + "/Visits/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Visit.users.createMany() instead.
            "::createMany::Visit::users": {
              isArray: true,
              url: urlBase + "/Visits/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Visit.users.destroyAll() instead.
            "::delete::Visit::users": {
              url: urlBase + "/Visits/:id/users",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.users.count() instead.
            "::count::Visit::users": {
              url: urlBase + "/Visits/:id/users/count",
              method: "GET",
            },

            // INTERNAL. Use Supplier.owner() instead.
            "::get::Supplier::owner": {
              url: urlBase + "/Suppliers/:id/owner",
              method: "GET",
            },

            // INTERNAL. Use Supplier.user() instead.
            "::get::Supplier::user": {
              url: urlBase + "/Suppliers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Log.user() instead.
            "::get::Log::user": {
              url: urlBase + "/Logs/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Comment.owner() instead.
            "::get::Comment::owner": {
              url: urlBase + "/Comments/:id/owner",
              method: "GET",
            },

            // INTERNAL. Use Comment.notified.findById() instead.
            "::findById::Comment::notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/:fk",
              method: "GET",
            },

            // INTERNAL. Use Comment.notified.destroyById() instead.
            "::destroyById::Comment::notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.notified.updateById() instead.
            "::updateById::Comment::notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Comment.notified.link() instead.
            "::link::Comment::notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Comment.notified.unlink() instead.
            "::unlink::Comment::notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.notified.exists() instead.
            "::exists::Comment::notified": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Comments/:id/notified/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Comment.notified() instead.
            "::get::Comment::notified": {
              isArray: true,
              url: urlBase + "/Comments/:id/notified",
              method: "GET",
            },

            // INTERNAL. Use Comment.notified.create() instead.
            "::create::Comment::notified": {
              url: urlBase + "/Comments/:id/notified",
              method: "POST",
            },

            // INTERNAL. Use Comment.notified.createMany() instead.
            "::createMany::Comment::notified": {
              isArray: true,
              url: urlBase + "/Comments/:id/notified",
              method: "POST",
            },

            // INTERNAL. Use Comment.notified.destroyAll() instead.
            "::delete::Comment::notified": {
              url: urlBase + "/Comments/:id/notified",
              method: "DELETE",
            },

            // INTERNAL. Use Comment.notified.count() instead.
            "::count::Comment::notified": {
              url: urlBase + "/Comments/:id/notified/count",
              method: "GET",
            },

            // INTERNAL. Use Folder.user() instead.
            "::get::Folder::user": {
              url: urlBase + "/Folders/:id/user",
              method: "GET",
            },

            // INTERNAL. Use TaskTemplate.user() instead.
            "::get::TaskTemplate::user": {
              url: urlBase + "/TaskTemplates/:id/user",
              method: "GET",
            },

            // INTERNAL. Use EmailTemplate.user() instead.
            "::get::EmailTemplate::user": {
              url: urlBase + "/EmailTemplates/:id/user",
              method: "GET",
            },

            // INTERNAL. Use CustomRole.users.findById() instead.
            "::findById::CustomRole::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/:fk",
              method: "GET",
            },

            // INTERNAL. Use CustomRole.users.destroyById() instead.
            "::destroyById::CustomRole::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CustomRole.users.updateById() instead.
            "::updateById::CustomRole::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CustomRole.users.link() instead.
            "::link::CustomRole::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CustomRole.users.unlink() instead.
            "::unlink::CustomRole::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CustomRole.users.exists() instead.
            "::exists::CustomRole::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CustomRoles/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use CustomRole.users() instead.
            "::get::CustomRole::users": {
              isArray: true,
              url: urlBase + "/CustomRoles/:id/users",
              method: "GET",
            },

            // INTERNAL. Use CustomRole.users.create() instead.
            "::create::CustomRole::users": {
              url: urlBase + "/CustomRoles/:id/users",
              method: "POST",
            },

            // INTERNAL. Use CustomRole.users.createMany() instead.
            "::createMany::CustomRole::users": {
              isArray: true,
              url: urlBase + "/CustomRoles/:id/users",
              method: "POST",
            },

            // INTERNAL. Use CustomRole.users.destroyAll() instead.
            "::delete::CustomRole::users": {
              url: urlBase + "/CustomRoles/:id/users",
              method: "DELETE",
            },

            // INTERNAL. Use CustomRole.users.count() instead.
            "::count::CustomRole::users": {
              url: urlBase + "/CustomRoles/:id/users/count",
              method: "GET",
            },

            // INTERNAL. Use UserRole.user() instead.
            "::get::UserRole::user": {
              url: urlBase + "/UserRoles/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Manager.manager() instead.
            "::get::Manager::manager": {
              url: urlBase + "/Managers/:id/manager",
              method: "GET",
            },

            // INTERNAL. Use Manager.managing() instead.
            "::get::Manager::managing": {
              url: urlBase + "/Managers/:id/managing",
              method: "GET",
            },

            // INTERNAL. Use Manager.clients.findById() instead.
            "::findById::Manager::clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/:fk",
              method: "GET",
            },

            // INTERNAL. Use Manager.clients.destroyById() instead.
            "::destroyById::Manager::clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.clients.updateById() instead.
            "::updateById::Manager::clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manager.clients.link() instead.
            "::link::Manager::clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manager.clients.unlink() instead.
            "::unlink::Manager::clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.clients.exists() instead.
            "::exists::Manager::clients": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/clients/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Manager.clients() instead.
            "::get::Manager::clients": {
              isArray: true,
              url: urlBase + "/Managers/:id/clients",
              method: "GET",
            },

            // INTERNAL. Use Manager.clients.create() instead.
            "::create::Manager::clients": {
              url: urlBase + "/Managers/:id/clients",
              method: "POST",
            },

            // INTERNAL. Use Manager.clients.createMany() instead.
            "::createMany::Manager::clients": {
              isArray: true,
              url: urlBase + "/Managers/:id/clients",
              method: "POST",
            },

            // INTERNAL. Use Manager.clients.destroyAll() instead.
            "::delete::Manager::clients": {
              url: urlBase + "/Managers/:id/clients",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.clients.count() instead.
            "::count::Manager::clients": {
              url: urlBase + "/Managers/:id/clients/count",
              method: "GET",
            },

            // INTERNAL. Use PendingNotification.fromUser() instead.
            "::get::PendingNotification::fromUser": {
              url: urlBase + "/PendingNotifications/:id/fromUser",
              method: "GET",
            },

            // INTERNAL. Use PendingNotification.toUser() instead.
            "::get::PendingNotification::toUser": {
              url: urlBase + "/PendingNotifications/:id/toUser",
              method: "GET",
            },

            // INTERNAL. Use AgencyOwner.user() instead.
            "::get::AgencyOwner::user": {
              url: urlBase + "/AgencyOwners/:id/user",
              method: "GET",
            },

            // INTERNAL. Use AgencyMember.user() instead.
            "::get::AgencyMember::user": {
              url: urlBase + "/AgencyMembers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Admin.user() instead.
            "::get::Admin::user": {
              url: urlBase + "/Admins/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Broker.user() instead.
            "::get::Broker::user": {
              url: urlBase + "/Brokers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Account.user() instead.
            "::get::Account::user": {
              url: urlBase + "/Accounts/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Account.user.create() instead.
            "::create::Account::user": {
              url: urlBase + "/Accounts/:id/user",
              method: "POST",
            },

            // INTERNAL. Use Account.user.createMany() instead.
            "::createMany::Account::user": {
              isArray: true,
              url: urlBase + "/Accounts/:id/user",
              method: "POST",
            },

            // INTERNAL. Use Account.user.update() instead.
            "::update::Account::user": {
              url: urlBase + "/Accounts/:id/user",
              method: "PUT",
            },

            // INTERNAL. Use Account.user.destroy() instead.
            "::destroy::Account::user": {
              url: urlBase + "/Accounts/:id/user",
              method: "DELETE",
            },

            // INTERNAL. Use VisitSbUser.user() instead.
            "::get::VisitSbUser::user": {
              url: urlBase + "/VisitSbUsers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Conversation.users.findById() instead.
            "::findById::Conversation::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Conversation.users.destroyById() instead.
            "::destroyById::Conversation::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.users.updateById() instead.
            "::updateById::Conversation::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.users.link() instead.
            "::link::Conversation::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.users.unlink() instead.
            "::unlink::Conversation::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.users.exists() instead.
            "::exists::Conversation::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Conversation.users() instead.
            "::get::Conversation::users": {
              isArray: true,
              url: urlBase + "/Conversations/:id/users",
              method: "GET",
            },

            // INTERNAL. Use Conversation.users.create() instead.
            "::create::Conversation::users": {
              url: urlBase + "/Conversations/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Conversation.users.createMany() instead.
            "::createMany::Conversation::users": {
              isArray: true,
              url: urlBase + "/Conversations/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Conversation.users.destroyAll() instead.
            "::delete::Conversation::users": {
              url: urlBase + "/Conversations/:id/users",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.users.count() instead.
            "::count::Conversation::users": {
              url: urlBase + "/Conversations/:id/users/count",
              method: "GET",
            },

            // INTERNAL. Use ConversationSbUser.user() instead.
            "::get::ConversationSbUser::user": {
              url: urlBase + "/ConversationSbUsers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use PersonalEvent.user() instead.
            "::get::PersonalEvent::user": {
              url: urlBase + "/PersonalEvents/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Notification.user() instead.
            "::get::Notification::user": {
              url: urlBase + "/Notifications/:id/user",
              method: "GET",
            },

            // INTERNAL. Use SharedTaskTemplate.user() instead.
            "::get::SharedTaskTemplate::user": {
              url: urlBase + "/SharedTaskTemplates/:id/user",
              method: "GET",
            },

            // INTERNAL. Use SharedEmailTemplate.user() instead.
            "::get::SharedEmailTemplate::user": {
              url: urlBase + "/SharedEmailTemplates/:id/user",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SbUser#upsert
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SbUser#updateOrCreate
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SbUser#patchOrCreateWithWhere
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SbUser#update
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SbUser#destroyById
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SbUser#removeById
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SbUser#prototype$updateAttributes
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SbUser#modelName
        * @propertyOf lbServices.SbUser
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SbUser`.
        */
        R.modelName = "SbUser";

    /**
     * @ngdoc object
     * @name lbServices.SbUser.languages
     * @header lbServices.SbUser.languages
     * @object
     * @description
     *
     * The object `SbUser.languages` groups methods
     * manipulating `Language` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#languages SbUser.languages()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#languages
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries languages of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.languages = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::get::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#count
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Counts languages of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.languages.count = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::count::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#create
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Creates a new instance in languages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.languages.create = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::create::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#createMany
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Creates a new instance in languages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.languages.createMany = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::createMany::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#destroyAll
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Deletes all languages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.languages.destroyAll = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::delete::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#destroyById
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Delete a related item by id for languages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for languages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.languages.destroyById = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::destroyById::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#exists
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Check the existence of languages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for languages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.languages.exists = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::exists::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#findById
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Find a related item by id for languages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for languages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.languages.findById = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::findById::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#link
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Add a related item by id for languages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for languages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.languages.link = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::link::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#unlink
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Remove the languages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for languages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.languages.unlink = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::unlink::SbUser::languages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.languages#updateById
             * @methodOf lbServices.SbUser.languages
             *
             * @description
             *
             * Update a related item by id for languages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for languages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.languages.updateById = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::updateById::SbUser::languages"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.adminProfile
     * @header lbServices.SbUser.adminProfile
     * @object
     * @description
     *
     * The object `SbUser.adminProfile` groups methods
     * manipulating `Admin` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#adminProfile SbUser.adminProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#adminProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Fetches hasOne relation adminProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R.adminProfile = function() {
          var TargetResource = $injector.get("Admin");
          var action = TargetResource["::get::SbUser::adminProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.adminProfile#create
             * @methodOf lbServices.SbUser.adminProfile
             *
             * @description
             *
             * Creates a new instance in adminProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R.adminProfile.create = function() {
          var TargetResource = $injector.get("Admin");
          var action = TargetResource["::create::SbUser::adminProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.adminProfile#createMany
             * @methodOf lbServices.SbUser.adminProfile
             *
             * @description
             *
             * Creates a new instance in adminProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R.adminProfile.createMany = function() {
          var TargetResource = $injector.get("Admin");
          var action = TargetResource["::createMany::SbUser::adminProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.adminProfile#destroy
             * @methodOf lbServices.SbUser.adminProfile
             *
             * @description
             *
             * Deletes adminProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.adminProfile.destroy = function() {
          var TargetResource = $injector.get("Admin");
          var action = TargetResource["::destroy::SbUser::adminProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.adminProfile#update
             * @methodOf lbServices.SbUser.adminProfile
             *
             * @description
             *
             * Update adminProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Admin` object.)
             * </em>
             */
        R.adminProfile.update = function() {
          var TargetResource = $injector.get("Admin");
          var action = TargetResource["::update::SbUser::adminProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.agencyMemberProfile
     * @header lbServices.SbUser.agencyMemberProfile
     * @object
     * @description
     *
     * The object `SbUser.agencyMemberProfile` groups methods
     * manipulating `AgencyMember` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#agencyMemberProfile SbUser.agencyMemberProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#agencyMemberProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Fetches hasOne relation agencyMemberProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMemberProfile = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::get::SbUser::agencyMemberProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.agencyMemberProfile#create
             * @methodOf lbServices.SbUser.agencyMemberProfile
             *
             * @description
             *
             * Creates a new instance in agencyMemberProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMemberProfile.create = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::create::SbUser::agencyMemberProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.agencyMemberProfile#createMany
             * @methodOf lbServices.SbUser.agencyMemberProfile
             *
             * @description
             *
             * Creates a new instance in agencyMemberProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMemberProfile.createMany = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::createMany::SbUser::agencyMemberProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.agencyMemberProfile#destroy
             * @methodOf lbServices.SbUser.agencyMemberProfile
             *
             * @description
             *
             * Deletes agencyMemberProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyMemberProfile.destroy = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::destroy::SbUser::agencyMemberProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.agencyMemberProfile#update
             * @methodOf lbServices.SbUser.agencyMemberProfile
             *
             * @description
             *
             * Update agencyMemberProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMemberProfile.update = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::update::SbUser::agencyMemberProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.agencyOwnerProfile
     * @header lbServices.SbUser.agencyOwnerProfile
     * @object
     * @description
     *
     * The object `SbUser.agencyOwnerProfile` groups methods
     * manipulating `AgencyOwner` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#agencyOwnerProfile SbUser.agencyOwnerProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#agencyOwnerProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Fetches hasOne relation agencyOwnerProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwnerProfile = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::get::SbUser::agencyOwnerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.agencyOwnerProfile#create
             * @methodOf lbServices.SbUser.agencyOwnerProfile
             *
             * @description
             *
             * Creates a new instance in agencyOwnerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwnerProfile.create = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::create::SbUser::agencyOwnerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.agencyOwnerProfile#createMany
             * @methodOf lbServices.SbUser.agencyOwnerProfile
             *
             * @description
             *
             * Creates a new instance in agencyOwnerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwnerProfile.createMany = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::createMany::SbUser::agencyOwnerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.agencyOwnerProfile#destroy
             * @methodOf lbServices.SbUser.agencyOwnerProfile
             *
             * @description
             *
             * Deletes agencyOwnerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyOwnerProfile.destroy = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::destroy::SbUser::agencyOwnerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.agencyOwnerProfile#update
             * @methodOf lbServices.SbUser.agencyOwnerProfile
             *
             * @description
             *
             * Update agencyOwnerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwnerProfile.update = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::update::SbUser::agencyOwnerProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.auditorProfile
     * @header lbServices.SbUser.auditorProfile
     * @object
     * @description
     *
     * The object `SbUser.auditorProfile` groups methods
     * manipulating `Auditor` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#auditorProfile SbUser.auditorProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#auditorProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries auditorProfile of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditorProfile = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::get::SbUser::auditorProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.auditorProfile#count
             * @methodOf lbServices.SbUser.auditorProfile
             *
             * @description
             *
             * Counts auditorProfile of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.auditorProfile.count = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::count::SbUser::auditorProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.auditorProfile#create
             * @methodOf lbServices.SbUser.auditorProfile
             *
             * @description
             *
             * Creates a new instance in auditorProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditorProfile.create = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::create::SbUser::auditorProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.auditorProfile#createMany
             * @methodOf lbServices.SbUser.auditorProfile
             *
             * @description
             *
             * Creates a new instance in auditorProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditorProfile.createMany = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::createMany::SbUser::auditorProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.auditorProfile#destroyAll
             * @methodOf lbServices.SbUser.auditorProfile
             *
             * @description
             *
             * Deletes all auditorProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.auditorProfile.destroyAll = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::delete::SbUser::auditorProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.auditorProfile#destroyById
             * @methodOf lbServices.SbUser.auditorProfile
             *
             * @description
             *
             * Delete a related item by id for auditorProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for auditorProfile
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.auditorProfile.destroyById = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::destroyById::SbUser::auditorProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.auditorProfile#findById
             * @methodOf lbServices.SbUser.auditorProfile
             *
             * @description
             *
             * Find a related item by id for auditorProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for auditorProfile
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditorProfile.findById = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::findById::SbUser::auditorProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.auditorProfile#updateById
             * @methodOf lbServices.SbUser.auditorProfile
             *
             * @description
             *
             * Update a related item by id for auditorProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for auditorProfile
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Auditor` object.)
             * </em>
             */
        R.auditorProfile.updateById = function() {
          var TargetResource = $injector.get("Auditor");
          var action = TargetResource["::updateById::SbUser::auditorProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.notaryProfile
     * @header lbServices.SbUser.notaryProfile
     * @object
     * @description
     *
     * The object `SbUser.notaryProfile` groups methods
     * manipulating `Notary` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#notaryProfile SbUser.notaryProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#notaryProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries notaryProfile of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaryProfile = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::get::SbUser::notaryProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notaryProfile#count
             * @methodOf lbServices.SbUser.notaryProfile
             *
             * @description
             *
             * Counts notaryProfile of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notaryProfile.count = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::count::SbUser::notaryProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notaryProfile#create
             * @methodOf lbServices.SbUser.notaryProfile
             *
             * @description
             *
             * Creates a new instance in notaryProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaryProfile.create = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::create::SbUser::notaryProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notaryProfile#createMany
             * @methodOf lbServices.SbUser.notaryProfile
             *
             * @description
             *
             * Creates a new instance in notaryProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaryProfile.createMany = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::createMany::SbUser::notaryProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notaryProfile#destroyAll
             * @methodOf lbServices.SbUser.notaryProfile
             *
             * @description
             *
             * Deletes all notaryProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notaryProfile.destroyAll = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::delete::SbUser::notaryProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notaryProfile#destroyById
             * @methodOf lbServices.SbUser.notaryProfile
             *
             * @description
             *
             * Delete a related item by id for notaryProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notaryProfile
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notaryProfile.destroyById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::destroyById::SbUser::notaryProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notaryProfile#findById
             * @methodOf lbServices.SbUser.notaryProfile
             *
             * @description
             *
             * Find a related item by id for notaryProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notaryProfile
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaryProfile.findById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::findById::SbUser::notaryProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notaryProfile#updateById
             * @methodOf lbServices.SbUser.notaryProfile
             *
             * @description
             *
             * Update a related item by id for notaryProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for notaryProfile
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaryProfile.updateById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::updateById::SbUser::notaryProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.managers
     * @header lbServices.SbUser.managers
     * @object
     * @description
     *
     * The object `SbUser.managers` groups methods
     * manipulating `Manager` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#managers SbUser.managers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#managers
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries managers of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managers = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::get::SbUser::managers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managers#count
             * @methodOf lbServices.SbUser.managers
             *
             * @description
             *
             * Counts managers of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.managers.count = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::count::SbUser::managers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managers#create
             * @methodOf lbServices.SbUser.managers
             *
             * @description
             *
             * Creates a new instance in managers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managers.create = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::create::SbUser::managers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managers#createMany
             * @methodOf lbServices.SbUser.managers
             *
             * @description
             *
             * Creates a new instance in managers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managers.createMany = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::createMany::SbUser::managers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managers#destroyAll
             * @methodOf lbServices.SbUser.managers
             *
             * @description
             *
             * Deletes all managers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.managers.destroyAll = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::delete::SbUser::managers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managers#destroyById
             * @methodOf lbServices.SbUser.managers
             *
             * @description
             *
             * Delete a related item by id for managers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for managers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.managers.destroyById = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::destroyById::SbUser::managers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managers#findById
             * @methodOf lbServices.SbUser.managers
             *
             * @description
             *
             * Find a related item by id for managers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for managers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managers.findById = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::findById::SbUser::managers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managers#updateById
             * @methodOf lbServices.SbUser.managers
             *
             * @description
             *
             * Update a related item by id for managers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for managers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managers.updateById = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::updateById::SbUser::managers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.managerProfile
     * @header lbServices.SbUser.managerProfile
     * @object
     * @description
     *
     * The object `SbUser.managerProfile` groups methods
     * manipulating `Manager` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#managerProfile SbUser.managerProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#managerProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries managerProfile of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managerProfile = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::get::SbUser::managerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managerProfile#count
             * @methodOf lbServices.SbUser.managerProfile
             *
             * @description
             *
             * Counts managerProfile of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.managerProfile.count = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::count::SbUser::managerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managerProfile#create
             * @methodOf lbServices.SbUser.managerProfile
             *
             * @description
             *
             * Creates a new instance in managerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managerProfile.create = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::create::SbUser::managerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managerProfile#createMany
             * @methodOf lbServices.SbUser.managerProfile
             *
             * @description
             *
             * Creates a new instance in managerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managerProfile.createMany = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::createMany::SbUser::managerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managerProfile#destroyAll
             * @methodOf lbServices.SbUser.managerProfile
             *
             * @description
             *
             * Deletes all managerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.managerProfile.destroyAll = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::delete::SbUser::managerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managerProfile#destroyById
             * @methodOf lbServices.SbUser.managerProfile
             *
             * @description
             *
             * Delete a related item by id for managerProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for managerProfile
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.managerProfile.destroyById = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::destroyById::SbUser::managerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managerProfile#findById
             * @methodOf lbServices.SbUser.managerProfile
             *
             * @description
             *
             * Find a related item by id for managerProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for managerProfile
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managerProfile.findById = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::findById::SbUser::managerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.managerProfile#updateById
             * @methodOf lbServices.SbUser.managerProfile
             *
             * @description
             *
             * Update a related item by id for managerProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for managerProfile
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manager` object.)
             * </em>
             */
        R.managerProfile.updateById = function() {
          var TargetResource = $injector.get("Manager");
          var action = TargetResource["::updateById::SbUser::managerProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.sellerProfile
     * @header lbServices.SbUser.sellerProfile
     * @object
     * @description
     *
     * The object `SbUser.sellerProfile` groups methods
     * manipulating `Seller` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#sellerProfile SbUser.sellerProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#sellerProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Fetches hasOne relation sellerProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellerProfile = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::get::SbUser::sellerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sellerProfile#create
             * @methodOf lbServices.SbUser.sellerProfile
             *
             * @description
             *
             * Creates a new instance in sellerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellerProfile.create = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::create::SbUser::sellerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sellerProfile#createMany
             * @methodOf lbServices.SbUser.sellerProfile
             *
             * @description
             *
             * Creates a new instance in sellerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellerProfile.createMany = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::createMany::SbUser::sellerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sellerProfile#destroy
             * @methodOf lbServices.SbUser.sellerProfile
             *
             * @description
             *
             * Deletes sellerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellerProfile.destroy = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::destroy::SbUser::sellerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sellerProfile#update
             * @methodOf lbServices.SbUser.sellerProfile
             *
             * @description
             *
             * Update sellerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellerProfile.update = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::update::SbUser::sellerProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.buyerProfile
     * @header lbServices.SbUser.buyerProfile
     * @object
     * @description
     *
     * The object `SbUser.buyerProfile` groups methods
     * manipulating `Buyer` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#buyerProfile SbUser.buyerProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#buyerProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Fetches hasOne relation buyerProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyerProfile = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::SbUser::buyerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.buyerProfile#create
             * @methodOf lbServices.SbUser.buyerProfile
             *
             * @description
             *
             * Creates a new instance in buyerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyerProfile.create = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::create::SbUser::buyerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.buyerProfile#createMany
             * @methodOf lbServices.SbUser.buyerProfile
             *
             * @description
             *
             * Creates a new instance in buyerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyerProfile.createMany = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::createMany::SbUser::buyerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.buyerProfile#destroy
             * @methodOf lbServices.SbUser.buyerProfile
             *
             * @description
             *
             * Deletes buyerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyerProfile.destroy = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::destroy::SbUser::buyerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.buyerProfile#update
             * @methodOf lbServices.SbUser.buyerProfile
             *
             * @description
             *
             * Update buyerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyerProfile.update = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::update::SbUser::buyerProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.files
     * @header lbServices.SbUser.files
     * @object
     * @description
     *
     * The object `SbUser.files` groups methods
     * manipulating `SmartFile` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#files SbUser.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#files
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries files of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::SbUser::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.files#count
             * @methodOf lbServices.SbUser.files
             *
             * @description
             *
             * Counts files of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::SbUser::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.files#create
             * @methodOf lbServices.SbUser.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::SbUser::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.files#createMany
             * @methodOf lbServices.SbUser.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::SbUser::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.files#destroyAll
             * @methodOf lbServices.SbUser.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::SbUser::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.files#destroyById
             * @methodOf lbServices.SbUser.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::SbUser::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.files#findById
             * @methodOf lbServices.SbUser.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::SbUser::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.files#updateById
             * @methodOf lbServices.SbUser.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::SbUser::files"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.notes
     * @header lbServices.SbUser.notes
     * @object
     * @description
     *
     * The object `SbUser.notes` groups methods
     * manipulating `Note` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#notes SbUser.notes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#notes
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries notes of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::get::SbUser::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notes#count
             * @methodOf lbServices.SbUser.notes
             *
             * @description
             *
             * Counts notes of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notes.count = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::count::SbUser::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notes#create
             * @methodOf lbServices.SbUser.notes
             *
             * @description
             *
             * Creates a new instance in notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.create = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::create::SbUser::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notes#createMany
             * @methodOf lbServices.SbUser.notes
             *
             * @description
             *
             * Creates a new instance in notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.createMany = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::createMany::SbUser::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notes#destroyAll
             * @methodOf lbServices.SbUser.notes
             *
             * @description
             *
             * Deletes all notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notes.destroyAll = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::delete::SbUser::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notes#destroyById
             * @methodOf lbServices.SbUser.notes
             *
             * @description
             *
             * Delete a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notes.destroyById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::destroyById::SbUser::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notes#findById
             * @methodOf lbServices.SbUser.notes
             *
             * @description
             *
             * Find a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.findById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::findById::SbUser::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notes#updateById
             * @methodOf lbServices.SbUser.notes
             *
             * @description
             *
             * Update a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.updateById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::updateById::SbUser::notes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.bookmarks
     * @header lbServices.SbUser.bookmarks
     * @object
     * @description
     *
     * The object `SbUser.bookmarks` groups methods
     * manipulating `Bookmark` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#bookmarks SbUser.bookmarks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#bookmarks
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries bookmarks of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R.bookmarks = function() {
          var TargetResource = $injector.get("Bookmark");
          var action = TargetResource["::get::SbUser::bookmarks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.bookmarks#count
             * @methodOf lbServices.SbUser.bookmarks
             *
             * @description
             *
             * Counts bookmarks of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.bookmarks.count = function() {
          var TargetResource = $injector.get("Bookmark");
          var action = TargetResource["::count::SbUser::bookmarks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.bookmarks#create
             * @methodOf lbServices.SbUser.bookmarks
             *
             * @description
             *
             * Creates a new instance in bookmarks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R.bookmarks.create = function() {
          var TargetResource = $injector.get("Bookmark");
          var action = TargetResource["::create::SbUser::bookmarks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.bookmarks#createMany
             * @methodOf lbServices.SbUser.bookmarks
             *
             * @description
             *
             * Creates a new instance in bookmarks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R.bookmarks.createMany = function() {
          var TargetResource = $injector.get("Bookmark");
          var action = TargetResource["::createMany::SbUser::bookmarks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.bookmarks#destroyAll
             * @methodOf lbServices.SbUser.bookmarks
             *
             * @description
             *
             * Deletes all bookmarks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.bookmarks.destroyAll = function() {
          var TargetResource = $injector.get("Bookmark");
          var action = TargetResource["::delete::SbUser::bookmarks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.bookmarks#destroyById
             * @methodOf lbServices.SbUser.bookmarks
             *
             * @description
             *
             * Delete a related item by id for bookmarks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for bookmarks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.bookmarks.destroyById = function() {
          var TargetResource = $injector.get("Bookmark");
          var action = TargetResource["::destroyById::SbUser::bookmarks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.bookmarks#findById
             * @methodOf lbServices.SbUser.bookmarks
             *
             * @description
             *
             * Find a related item by id for bookmarks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for bookmarks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R.bookmarks.findById = function() {
          var TargetResource = $injector.get("Bookmark");
          var action = TargetResource["::findById::SbUser::bookmarks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.bookmarks#updateById
             * @methodOf lbServices.SbUser.bookmarks
             *
             * @description
             *
             * Update a related item by id for bookmarks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for bookmarks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Bookmark` object.)
             * </em>
             */
        R.bookmarks.updateById = function() {
          var TargetResource = $injector.get("Bookmark");
          var action = TargetResource["::updateById::SbUser::bookmarks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.ownedSuppliers
     * @header lbServices.SbUser.ownedSuppliers
     * @object
     * @description
     *
     * The object `SbUser.ownedSuppliers` groups methods
     * manipulating `Supplier` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#ownedSuppliers SbUser.ownedSuppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#ownedSuppliers
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries ownedSuppliers of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.ownedSuppliers = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::SbUser::ownedSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedSuppliers#count
             * @methodOf lbServices.SbUser.ownedSuppliers
             *
             * @description
             *
             * Counts ownedSuppliers of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.ownedSuppliers.count = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::count::SbUser::ownedSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedSuppliers#create
             * @methodOf lbServices.SbUser.ownedSuppliers
             *
             * @description
             *
             * Creates a new instance in ownedSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.ownedSuppliers.create = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::create::SbUser::ownedSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedSuppliers#createMany
             * @methodOf lbServices.SbUser.ownedSuppliers
             *
             * @description
             *
             * Creates a new instance in ownedSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.ownedSuppliers.createMany = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::createMany::SbUser::ownedSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedSuppliers#destroyAll
             * @methodOf lbServices.SbUser.ownedSuppliers
             *
             * @description
             *
             * Deletes all ownedSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ownedSuppliers.destroyAll = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::delete::SbUser::ownedSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedSuppliers#destroyById
             * @methodOf lbServices.SbUser.ownedSuppliers
             *
             * @description
             *
             * Delete a related item by id for ownedSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for ownedSuppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ownedSuppliers.destroyById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::destroyById::SbUser::ownedSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedSuppliers#findById
             * @methodOf lbServices.SbUser.ownedSuppliers
             *
             * @description
             *
             * Find a related item by id for ownedSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for ownedSuppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.ownedSuppliers.findById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::findById::SbUser::ownedSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedSuppliers#updateById
             * @methodOf lbServices.SbUser.ownedSuppliers
             *
             * @description
             *
             * Update a related item by id for ownedSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for ownedSuppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.ownedSuppliers.updateById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::updateById::SbUser::ownedSuppliers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.ownedAgencies
     * @header lbServices.SbUser.ownedAgencies
     * @object
     * @description
     *
     * The object `SbUser.ownedAgencies` groups methods
     * manipulating `Agency` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#ownedAgencies SbUser.ownedAgencies()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#ownedAgencies
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries ownedAgencies of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.ownedAgencies = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::SbUser::ownedAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedAgencies#count
             * @methodOf lbServices.SbUser.ownedAgencies
             *
             * @description
             *
             * Counts ownedAgencies of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.ownedAgencies.count = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::count::SbUser::ownedAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedAgencies#create
             * @methodOf lbServices.SbUser.ownedAgencies
             *
             * @description
             *
             * Creates a new instance in ownedAgencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.ownedAgencies.create = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::create::SbUser::ownedAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedAgencies#createMany
             * @methodOf lbServices.SbUser.ownedAgencies
             *
             * @description
             *
             * Creates a new instance in ownedAgencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.ownedAgencies.createMany = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::createMany::SbUser::ownedAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedAgencies#destroyAll
             * @methodOf lbServices.SbUser.ownedAgencies
             *
             * @description
             *
             * Deletes all ownedAgencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ownedAgencies.destroyAll = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::delete::SbUser::ownedAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedAgencies#destroyById
             * @methodOf lbServices.SbUser.ownedAgencies
             *
             * @description
             *
             * Delete a related item by id for ownedAgencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for ownedAgencies
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ownedAgencies.destroyById = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::destroyById::SbUser::ownedAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedAgencies#findById
             * @methodOf lbServices.SbUser.ownedAgencies
             *
             * @description
             *
             * Find a related item by id for ownedAgencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for ownedAgencies
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.ownedAgencies.findById = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::findById::SbUser::ownedAgencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.ownedAgencies#updateById
             * @methodOf lbServices.SbUser.ownedAgencies
             *
             * @description
             *
             * Update a related item by id for ownedAgencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for ownedAgencies
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.ownedAgencies.updateById = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::updateById::SbUser::ownedAgencies"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.logs
     * @header lbServices.SbUser.logs
     * @object
     * @description
     *
     * The object `SbUser.logs` groups methods
     * manipulating `Log` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#logs SbUser.logs()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#logs
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries logs of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::get::SbUser::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logs#count
             * @methodOf lbServices.SbUser.logs
             *
             * @description
             *
             * Counts logs of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.logs.count = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::count::SbUser::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logs#create
             * @methodOf lbServices.SbUser.logs
             *
             * @description
             *
             * Creates a new instance in logs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs.create = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::create::SbUser::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logs#createMany
             * @methodOf lbServices.SbUser.logs
             *
             * @description
             *
             * Creates a new instance in logs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs.createMany = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::createMany::SbUser::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logs#destroyAll
             * @methodOf lbServices.SbUser.logs
             *
             * @description
             *
             * Deletes all logs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logs.destroyAll = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::delete::SbUser::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logs#destroyById
             * @methodOf lbServices.SbUser.logs
             *
             * @description
             *
             * Delete a related item by id for logs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logs.destroyById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::destroyById::SbUser::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logs#findById
             * @methodOf lbServices.SbUser.logs
             *
             * @description
             *
             * Find a related item by id for logs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs.findById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::findById::SbUser::logs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logs#updateById
             * @methodOf lbServices.SbUser.logs
             *
             * @description
             *
             * Update a related item by id for logs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for logs
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logs.updateById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::updateById::SbUser::logs"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.notesAbout
     * @header lbServices.SbUser.notesAbout
     * @object
     * @description
     *
     * The object `SbUser.notesAbout` groups methods
     * manipulating `Note` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#notesAbout SbUser.notesAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#notesAbout
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries notesAbout of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::get::SbUser::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notesAbout#count
             * @methodOf lbServices.SbUser.notesAbout
             *
             * @description
             *
             * Counts notesAbout of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notesAbout.count = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::count::SbUser::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notesAbout#create
             * @methodOf lbServices.SbUser.notesAbout
             *
             * @description
             *
             * Creates a new instance in notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.create = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::create::SbUser::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notesAbout#createMany
             * @methodOf lbServices.SbUser.notesAbout
             *
             * @description
             *
             * Creates a new instance in notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.createMany = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::createMany::SbUser::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notesAbout#destroyAll
             * @methodOf lbServices.SbUser.notesAbout
             *
             * @description
             *
             * Deletes all notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notesAbout.destroyAll = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::delete::SbUser::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notesAbout#destroyById
             * @methodOf lbServices.SbUser.notesAbout
             *
             * @description
             *
             * Delete a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notesAbout.destroyById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::destroyById::SbUser::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notesAbout#findById
             * @methodOf lbServices.SbUser.notesAbout
             *
             * @description
             *
             * Find a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.findById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::findById::SbUser::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notesAbout#updateById
             * @methodOf lbServices.SbUser.notesAbout
             *
             * @description
             *
             * Update a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.updateById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::updateById::SbUser::notesAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.logsAbout
     * @header lbServices.SbUser.logsAbout
     * @object
     * @description
     *
     * The object `SbUser.logsAbout` groups methods
     * manipulating `Log` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#logsAbout SbUser.logsAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#logsAbout
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries logsAbout of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::get::SbUser::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logsAbout#count
             * @methodOf lbServices.SbUser.logsAbout
             *
             * @description
             *
             * Counts logsAbout of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.logsAbout.count = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::count::SbUser::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logsAbout#create
             * @methodOf lbServices.SbUser.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.create = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::create::SbUser::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logsAbout#createMany
             * @methodOf lbServices.SbUser.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.createMany = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::createMany::SbUser::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logsAbout#destroyAll
             * @methodOf lbServices.SbUser.logsAbout
             *
             * @description
             *
             * Deletes all logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyAll = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::delete::SbUser::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logsAbout#destroyById
             * @methodOf lbServices.SbUser.logsAbout
             *
             * @description
             *
             * Delete a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::destroyById::SbUser::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logsAbout#findById
             * @methodOf lbServices.SbUser.logsAbout
             *
             * @description
             *
             * Find a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.findById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::findById::SbUser::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.logsAbout#updateById
             * @methodOf lbServices.SbUser.logsAbout
             *
             * @description
             *
             * Update a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.updateById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::updateById::SbUser::logsAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.taskTemplates
     * @header lbServices.SbUser.taskTemplates
     * @object
     * @description
     *
     * The object `SbUser.taskTemplates` groups methods
     * manipulating `TaskTemplate` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#taskTemplates SbUser.taskTemplates()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#taskTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries taskTemplates of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::get::SbUser::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.taskTemplates#count
             * @methodOf lbServices.SbUser.taskTemplates
             *
             * @description
             *
             * Counts taskTemplates of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.taskTemplates.count = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::count::SbUser::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.taskTemplates#create
             * @methodOf lbServices.SbUser.taskTemplates
             *
             * @description
             *
             * Creates a new instance in taskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates.create = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::create::SbUser::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.taskTemplates#createMany
             * @methodOf lbServices.SbUser.taskTemplates
             *
             * @description
             *
             * Creates a new instance in taskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates.createMany = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::createMany::SbUser::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.taskTemplates#destroyAll
             * @methodOf lbServices.SbUser.taskTemplates
             *
             * @description
             *
             * Deletes all taskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.taskTemplates.destroyAll = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::delete::SbUser::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.taskTemplates#destroyById
             * @methodOf lbServices.SbUser.taskTemplates
             *
             * @description
             *
             * Delete a related item by id for taskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for taskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.taskTemplates.destroyById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::destroyById::SbUser::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.taskTemplates#findById
             * @methodOf lbServices.SbUser.taskTemplates
             *
             * @description
             *
             * Find a related item by id for taskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for taskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates.findById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::findById::SbUser::taskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.taskTemplates#updateById
             * @methodOf lbServices.SbUser.taskTemplates
             *
             * @description
             *
             * Update a related item by id for taskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for taskTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplates.updateById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::updateById::SbUser::taskTemplates"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.emailTemplates
     * @header lbServices.SbUser.emailTemplates
     * @object
     * @description
     *
     * The object `SbUser.emailTemplates` groups methods
     * manipulating `EmailTemplate` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#emailTemplates SbUser.emailTemplates()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#emailTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries emailTemplates of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::get::SbUser::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.emailTemplates#count
             * @methodOf lbServices.SbUser.emailTemplates
             *
             * @description
             *
             * Counts emailTemplates of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.emailTemplates.count = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::count::SbUser::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.emailTemplates#create
             * @methodOf lbServices.SbUser.emailTemplates
             *
             * @description
             *
             * Creates a new instance in emailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates.create = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::create::SbUser::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.emailTemplates#createMany
             * @methodOf lbServices.SbUser.emailTemplates
             *
             * @description
             *
             * Creates a new instance in emailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates.createMany = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::createMany::SbUser::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.emailTemplates#destroyAll
             * @methodOf lbServices.SbUser.emailTemplates
             *
             * @description
             *
             * Deletes all emailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.emailTemplates.destroyAll = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::delete::SbUser::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.emailTemplates#destroyById
             * @methodOf lbServices.SbUser.emailTemplates
             *
             * @description
             *
             * Delete a related item by id for emailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for emailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.emailTemplates.destroyById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::destroyById::SbUser::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.emailTemplates#findById
             * @methodOf lbServices.SbUser.emailTemplates
             *
             * @description
             *
             * Find a related item by id for emailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for emailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates.findById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::findById::SbUser::emailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.emailTemplates#updateById
             * @methodOf lbServices.SbUser.emailTemplates
             *
             * @description
             *
             * Update a related item by id for emailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for emailTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplates.updateById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::updateById::SbUser::emailTemplates"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.roles
     * @header lbServices.SbUser.roles
     * @object
     * @description
     *
     * The object `SbUser.roles` groups methods
     * manipulating `CustomRole` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#roles SbUser.roles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#roles
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries roles of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R.roles = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::get::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#count
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Counts roles of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.roles.count = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::count::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#create
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Creates a new instance in roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R.roles.create = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::create::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#createMany
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Creates a new instance in roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R.roles.createMany = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::createMany::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#destroyAll
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Deletes all roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.roles.destroyAll = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::delete::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#destroyById
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Delete a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.roles.destroyById = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::destroyById::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#exists
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Check the existence of roles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R.roles.exists = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::exists::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#findById
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Find a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R.roles.findById = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::findById::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#link
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Add a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R.roles.link = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::link::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#unlink
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Remove the roles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.roles.unlink = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::unlink::SbUser::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.roles#updateById
             * @methodOf lbServices.SbUser.roles
             *
             * @description
             *
             * Update a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CustomRole` object.)
             * </em>
             */
        R.roles.updateById = function() {
          var TargetResource = $injector.get("CustomRole");
          var action = TargetResource["::updateById::SbUser::roles"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.folders
     * @header lbServices.SbUser.folders
     * @object
     * @description
     *
     * The object `SbUser.folders` groups methods
     * manipulating `Folder` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#folders SbUser.folders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#folders
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries folders of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::SbUser::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.folders#count
             * @methodOf lbServices.SbUser.folders
             *
             * @description
             *
             * Counts folders of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.folders.count = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::count::SbUser::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.folders#create
             * @methodOf lbServices.SbUser.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::SbUser::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.folders#createMany
             * @methodOf lbServices.SbUser.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::SbUser::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.folders#destroyAll
             * @methodOf lbServices.SbUser.folders
             *
             * @description
             *
             * Deletes all folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyAll = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::delete::SbUser::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.folders#destroyById
             * @methodOf lbServices.SbUser.folders
             *
             * @description
             *
             * Delete a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroyById::SbUser::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.folders#findById
             * @methodOf lbServices.SbUser.folders
             *
             * @description
             *
             * Find a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.findById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::findById::SbUser::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.folders#updateById
             * @methodOf lbServices.SbUser.folders
             *
             * @description
             *
             * Update a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.updateById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::updateById::SbUser::folders"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.sentEmails
     * @header lbServices.SbUser.sentEmails
     * @object
     * @description
     *
     * The object `SbUser.sentEmails` groups methods
     * manipulating `PendingNotification` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#sentEmails SbUser.sentEmails()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#sentEmails
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries sentEmails of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.sentEmails = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::get::SbUser::sentEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sentEmails#count
             * @methodOf lbServices.SbUser.sentEmails
             *
             * @description
             *
             * Counts sentEmails of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sentEmails.count = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::count::SbUser::sentEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sentEmails#create
             * @methodOf lbServices.SbUser.sentEmails
             *
             * @description
             *
             * Creates a new instance in sentEmails of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.sentEmails.create = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::create::SbUser::sentEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sentEmails#createMany
             * @methodOf lbServices.SbUser.sentEmails
             *
             * @description
             *
             * Creates a new instance in sentEmails of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.sentEmails.createMany = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::createMany::SbUser::sentEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sentEmails#destroyAll
             * @methodOf lbServices.SbUser.sentEmails
             *
             * @description
             *
             * Deletes all sentEmails of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sentEmails.destroyAll = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::delete::SbUser::sentEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sentEmails#destroyById
             * @methodOf lbServices.SbUser.sentEmails
             *
             * @description
             *
             * Delete a related item by id for sentEmails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sentEmails
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sentEmails.destroyById = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::destroyById::SbUser::sentEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sentEmails#findById
             * @methodOf lbServices.SbUser.sentEmails
             *
             * @description
             *
             * Find a related item by id for sentEmails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sentEmails
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.sentEmails.findById = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::findById::SbUser::sentEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sentEmails#updateById
             * @methodOf lbServices.SbUser.sentEmails
             *
             * @description
             *
             * Update a related item by id for sentEmails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for sentEmails
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.sentEmails.updateById = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::updateById::SbUser::sentEmails"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.receivedEmails
     * @header lbServices.SbUser.receivedEmails
     * @object
     * @description
     *
     * The object `SbUser.receivedEmails` groups methods
     * manipulating `PendingNotification` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#receivedEmails SbUser.receivedEmails()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#receivedEmails
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries receivedEmails of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.receivedEmails = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::get::SbUser::receivedEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.receivedEmails#count
             * @methodOf lbServices.SbUser.receivedEmails
             *
             * @description
             *
             * Counts receivedEmails of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.receivedEmails.count = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::count::SbUser::receivedEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.receivedEmails#create
             * @methodOf lbServices.SbUser.receivedEmails
             *
             * @description
             *
             * Creates a new instance in receivedEmails of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.receivedEmails.create = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::create::SbUser::receivedEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.receivedEmails#createMany
             * @methodOf lbServices.SbUser.receivedEmails
             *
             * @description
             *
             * Creates a new instance in receivedEmails of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.receivedEmails.createMany = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::createMany::SbUser::receivedEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.receivedEmails#destroyAll
             * @methodOf lbServices.SbUser.receivedEmails
             *
             * @description
             *
             * Deletes all receivedEmails of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.receivedEmails.destroyAll = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::delete::SbUser::receivedEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.receivedEmails#destroyById
             * @methodOf lbServices.SbUser.receivedEmails
             *
             * @description
             *
             * Delete a related item by id for receivedEmails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for receivedEmails
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.receivedEmails.destroyById = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::destroyById::SbUser::receivedEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.receivedEmails#findById
             * @methodOf lbServices.SbUser.receivedEmails
             *
             * @description
             *
             * Find a related item by id for receivedEmails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for receivedEmails
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.receivedEmails.findById = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::findById::SbUser::receivedEmails"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.receivedEmails#updateById
             * @methodOf lbServices.SbUser.receivedEmails
             *
             * @description
             *
             * Update a related item by id for receivedEmails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for receivedEmails
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PendingNotification` object.)
             * </em>
             */
        R.receivedEmails.updateById = function() {
          var TargetResource = $injector.get("PendingNotification");
          var action = TargetResource["::updateById::SbUser::receivedEmails"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.brokerProfile
     * @header lbServices.SbUser.brokerProfile
     * @object
     * @description
     *
     * The object `SbUser.brokerProfile` groups methods
     * manipulating `Broker` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#brokerProfile SbUser.brokerProfile()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#brokerProfile
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Fetches hasOne relation brokerProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokerProfile = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::SbUser::brokerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.brokerProfile#create
             * @methodOf lbServices.SbUser.brokerProfile
             *
             * @description
             *
             * Creates a new instance in brokerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokerProfile.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::SbUser::brokerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.brokerProfile#createMany
             * @methodOf lbServices.SbUser.brokerProfile
             *
             * @description
             *
             * Creates a new instance in brokerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokerProfile.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::SbUser::brokerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.brokerProfile#destroy
             * @methodOf lbServices.SbUser.brokerProfile
             *
             * @description
             *
             * Deletes brokerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokerProfile.destroy = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroy::SbUser::brokerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.brokerProfile#update
             * @methodOf lbServices.SbUser.brokerProfile
             *
             * @description
             *
             * Update brokerProfile of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokerProfile.update = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::update::SbUser::brokerProfile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser#account
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::SbUser::account"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.visits
     * @header lbServices.SbUser.visits
     * @object
     * @description
     *
     * The object `SbUser.visits` groups methods
     * manipulating `Visit` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#visits SbUser.visits()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#visits
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries visits of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::get::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#count
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Counts visits of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.visits.count = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::count::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#create
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Creates a new instance in visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.create = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::create::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#createMany
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Creates a new instance in visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.createMany = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::createMany::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#destroyAll
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Deletes all visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.destroyAll = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::delete::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#destroyById
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Delete a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.destroyById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::destroyById::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#exists
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Check the existence of visits relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.exists = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::exists::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#findById
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Find a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.findById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::findById::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#link
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Add a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.link = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::link::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#unlink
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Remove the visits relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.unlink = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::unlink::SbUser::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.visits#updateById
             * @methodOf lbServices.SbUser.visits
             *
             * @description
             *
             * Update a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.updateById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::updateById::SbUser::visits"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.conversations
     * @header lbServices.SbUser.conversations
     * @object
     * @description
     *
     * The object `SbUser.conversations` groups methods
     * manipulating `Conversation` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#conversations SbUser.conversations()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#conversations
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries conversations of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversations = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::get::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#count
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Counts conversations of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.conversations.count = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::count::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#create
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Creates a new instance in conversations of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversations.create = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::create::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#createMany
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Creates a new instance in conversations of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversations.createMany = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::createMany::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#destroyAll
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Deletes all conversations of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.conversations.destroyAll = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::delete::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#destroyById
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Delete a related item by id for conversations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for conversations
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.conversations.destroyById = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::destroyById::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#exists
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Check the existence of conversations relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for conversations
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversations.exists = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::exists::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#findById
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Find a related item by id for conversations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for conversations
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversations.findById = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::findById::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#link
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Add a related item by id for conversations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for conversations
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversations.link = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::link::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#unlink
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Remove the conversations relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for conversations
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.conversations.unlink = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::unlink::SbUser::conversations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.conversations#updateById
             * @methodOf lbServices.SbUser.conversations
             *
             * @description
             *
             * Update a related item by id for conversations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for conversations
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversations.updateById = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::updateById::SbUser::conversations"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.events
     * @header lbServices.SbUser.events
     * @object
     * @description
     *
     * The object `SbUser.events` groups methods
     * manipulating `PersonalEvent` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#events SbUser.events()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#events
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries events of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R.events = function() {
          var TargetResource = $injector.get("PersonalEvent");
          var action = TargetResource["::get::SbUser::events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.events#count
             * @methodOf lbServices.SbUser.events
             *
             * @description
             *
             * Counts events of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.events.count = function() {
          var TargetResource = $injector.get("PersonalEvent");
          var action = TargetResource["::count::SbUser::events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.events#create
             * @methodOf lbServices.SbUser.events
             *
             * @description
             *
             * Creates a new instance in events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R.events.create = function() {
          var TargetResource = $injector.get("PersonalEvent");
          var action = TargetResource["::create::SbUser::events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.events#createMany
             * @methodOf lbServices.SbUser.events
             *
             * @description
             *
             * Creates a new instance in events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R.events.createMany = function() {
          var TargetResource = $injector.get("PersonalEvent");
          var action = TargetResource["::createMany::SbUser::events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.events#destroyAll
             * @methodOf lbServices.SbUser.events
             *
             * @description
             *
             * Deletes all events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.events.destroyAll = function() {
          var TargetResource = $injector.get("PersonalEvent");
          var action = TargetResource["::delete::SbUser::events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.events#destroyById
             * @methodOf lbServices.SbUser.events
             *
             * @description
             *
             * Delete a related item by id for events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for events
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.events.destroyById = function() {
          var TargetResource = $injector.get("PersonalEvent");
          var action = TargetResource["::destroyById::SbUser::events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.events#findById
             * @methodOf lbServices.SbUser.events
             *
             * @description
             *
             * Find a related item by id for events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for events
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R.events.findById = function() {
          var TargetResource = $injector.get("PersonalEvent");
          var action = TargetResource["::findById::SbUser::events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.events#updateById
             * @methodOf lbServices.SbUser.events
             *
             * @description
             *
             * Update a related item by id for events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for events
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R.events.updateById = function() {
          var TargetResource = $injector.get("PersonalEvent");
          var action = TargetResource["::updateById::SbUser::events"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.notifications
     * @header lbServices.SbUser.notifications
     * @object
     * @description
     *
     * The object `SbUser.notifications` groups methods
     * manipulating `Notification` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#notifications SbUser.notifications()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#notifications
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries notifications of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R.notifications = function() {
          var TargetResource = $injector.get("Notification");
          var action = TargetResource["::get::SbUser::notifications"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notifications#count
             * @methodOf lbServices.SbUser.notifications
             *
             * @description
             *
             * Counts notifications of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notifications.count = function() {
          var TargetResource = $injector.get("Notification");
          var action = TargetResource["::count::SbUser::notifications"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notifications#create
             * @methodOf lbServices.SbUser.notifications
             *
             * @description
             *
             * Creates a new instance in notifications of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R.notifications.create = function() {
          var TargetResource = $injector.get("Notification");
          var action = TargetResource["::create::SbUser::notifications"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notifications#createMany
             * @methodOf lbServices.SbUser.notifications
             *
             * @description
             *
             * Creates a new instance in notifications of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R.notifications.createMany = function() {
          var TargetResource = $injector.get("Notification");
          var action = TargetResource["::createMany::SbUser::notifications"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notifications#destroyAll
             * @methodOf lbServices.SbUser.notifications
             *
             * @description
             *
             * Deletes all notifications of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notifications.destroyAll = function() {
          var TargetResource = $injector.get("Notification");
          var action = TargetResource["::delete::SbUser::notifications"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notifications#destroyById
             * @methodOf lbServices.SbUser.notifications
             *
             * @description
             *
             * Delete a related item by id for notifications.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notifications
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notifications.destroyById = function() {
          var TargetResource = $injector.get("Notification");
          var action = TargetResource["::destroyById::SbUser::notifications"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notifications#findById
             * @methodOf lbServices.SbUser.notifications
             *
             * @description
             *
             * Find a related item by id for notifications.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notifications
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R.notifications.findById = function() {
          var TargetResource = $injector.get("Notification");
          var action = TargetResource["::findById::SbUser::notifications"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.notifications#updateById
             * @methodOf lbServices.SbUser.notifications
             *
             * @description
             *
             * Update a related item by id for notifications.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for notifications
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R.notifications.updateById = function() {
          var TargetResource = $injector.get("Notification");
          var action = TargetResource["::updateById::SbUser::notifications"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.sharedTaskTemplates
     * @header lbServices.SbUser.sharedTaskTemplates
     * @object
     * @description
     *
     * The object `SbUser.sharedTaskTemplates` groups methods
     * manipulating `TaskTemplate` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#sharedTaskTemplates SbUser.sharedTaskTemplates()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#sharedTaskTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries sharedTaskTemplates of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::get::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#count
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Counts sharedTaskTemplates of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sharedTaskTemplates.count = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::count::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#create
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Creates a new instance in sharedTaskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.create = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::create::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#createMany
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Creates a new instance in sharedTaskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.createMany = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::createMany::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#destroyAll
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Deletes all sharedTaskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedTaskTemplates.destroyAll = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::delete::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#destroyById
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Delete a related item by id for sharedTaskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedTaskTemplates.destroyById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::destroyById::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#exists
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Check the existence of sharedTaskTemplates relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.exists = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::exists::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#findById
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Find a related item by id for sharedTaskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.findById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::findById::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#link
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Add a related item by id for sharedTaskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.link = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::link::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#unlink
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Remove the sharedTaskTemplates relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedTaskTemplates.unlink = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::unlink::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedTaskTemplates#updateById
             * @methodOf lbServices.SbUser.sharedTaskTemplates
             *
             * @description
             *
             * Update a related item by id for sharedTaskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.updateById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::updateById::SbUser::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SbUser.sharedEmailTemplates
     * @header lbServices.SbUser.sharedEmailTemplates
     * @object
     * @description
     *
     * The object `SbUser.sharedEmailTemplates` groups methods
     * manipulating `EmailTemplate` instances related to `SbUser`.
     *
     * Call {@link lbServices.SbUser#sharedEmailTemplates SbUser.sharedEmailTemplates()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SbUser#sharedEmailTemplates
             * @methodOf lbServices.SbUser
             *
             * @description
             *
             * Queries sharedEmailTemplates of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::get::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#count
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Counts sharedEmailTemplates of SbUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sharedEmailTemplates.count = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::count::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#create
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Creates a new instance in sharedEmailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.create = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::create::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#createMany
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Creates a new instance in sharedEmailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.createMany = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::createMany::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#destroyAll
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Deletes all sharedEmailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedEmailTemplates.destroyAll = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::delete::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#destroyById
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Delete a related item by id for sharedEmailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedEmailTemplates.destroyById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::destroyById::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#exists
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Check the existence of sharedEmailTemplates relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.exists = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::exists::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#findById
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Find a related item by id for sharedEmailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.findById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::findById::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#link
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Add a related item by id for sharedEmailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.link = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::link::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#unlink
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Remove the sharedEmailTemplates relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedEmailTemplates.unlink = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::unlink::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SbUser.sharedEmailTemplates#updateById
             * @methodOf lbServices.SbUser.sharedEmailTemplates
             *
             * @description
             *
             * Update a related item by id for sharedEmailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SbUser id
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.updateById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::updateById::SbUser::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Broker
 * @header lbServices.Broker
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Broker` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Broker",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Brokers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Broker.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Brokers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Broker.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Brokers/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Broker.office() instead.
            "prototype$__get__office": {
              url: urlBase + "/Brokers/:id/office",
              method: "GET",
            },

            // INTERNAL. Use Broker.buyers.findById() instead.
            "prototype$__findById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.buyers.destroyById() instead.
            "prototype$__destroyById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.buyers.updateById() instead.
            "prototype$__updateById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.buyers.link() instead.
            "prototype$__link__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.buyers.unlink() instead.
            "prototype$__unlink__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.buyers.exists() instead.
            "prototype$__exists__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/buyers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.sellers.findById() instead.
            "prototype$__findById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sellers.destroyById() instead.
            "prototype$__destroyById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sellers.updateById() instead.
            "prototype$__updateById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sellers.link() instead.
            "prototype$__link__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sellers.unlink() instead.
            "prototype$__unlink__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sellers.exists() instead.
            "prototype$__exists__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sellers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.reports.findById() instead.
            "prototype$__findById__reports": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/reports/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.reports.destroyById() instead.
            "prototype$__destroyById__reports": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/reports/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.reports.updateById() instead.
            "prototype$__updateById__reports": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/reports/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.folders.findById() instead.
            "prototype$__findById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.folders.destroyById() instead.
            "prototype$__destroyById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.folders.updateById() instead.
            "prototype$__updateById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.managedBrokers.findById() instead.
            "prototype$__findById__managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.managedBrokers.destroyById() instead.
            "prototype$__destroyById__managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.managedBrokers.updateById() instead.
            "prototype$__updateById__managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.managedBrokers.link() instead.
            "prototype$__link__managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.managedBrokers.unlink() instead.
            "prototype$__unlink__managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.managedBrokers.exists() instead.
            "prototype$__exists__managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.licenseType() instead.
            "prototype$__get__licenseType": {
              url: urlBase + "/Brokers/:id/licenseType",
              method: "GET",
            },

            // INTERNAL. Use Broker.tasks.findById() instead.
            "prototype$__findById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.tasks.destroyById() instead.
            "prototype$__destroyById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.tasks.updateById() instead.
            "prototype$__updateById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.visits.findById() instead.
            "prototype$__findById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/visits/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.visits.destroyById() instead.
            "prototype$__destroyById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/visits/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.visits.updateById() instead.
            "prototype$__updateById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/visits/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.suppliers.findById() instead.
            "prototype$__findById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.suppliers.destroyById() instead.
            "prototype$__destroyById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.suppliers.updateById() instead.
            "prototype$__updateById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.suppliers.link() instead.
            "prototype$__link__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.suppliers.unlink() instead.
            "prototype$__unlink__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.suppliers.exists() instead.
            "prototype$__exists__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.personalTasks.findById() instead.
            "prototype$__findById__personalTasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/personalTasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.personalTasks.destroyById() instead.
            "prototype$__destroyById__personalTasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/personalTasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.personalTasks.updateById() instead.
            "prototype$__updateById__personalTasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/personalTasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.regionsCovered.findById() instead.
            "prototype$__findById__regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.regionsCovered.destroyById() instead.
            "prototype$__destroyById__regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.regionsCovered.updateById() instead.
            "prototype$__updateById__regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.regionsCovered.link() instead.
            "prototype$__link__regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.regionsCovered.unlink() instead.
            "prototype$__unlink__regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.regionsCovered.exists() instead.
            "prototype$__exists__regionsCovered": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/regionsCovered/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.listings.findById() instead.
            "prototype$__findById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.listings.destroyById() instead.
            "prototype$__destroyById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.listings.updateById() instead.
            "prototype$__updateById__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.listings.link() instead.
            "prototype$__link__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.listings.unlink() instead.
            "prototype$__unlink__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.listings.exists() instead.
            "prototype$__exists__listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.subscriptions() instead.
            "prototype$__get__subscriptions": {
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "GET",
            },

            // INTERNAL. Use Broker.subscriptions.create() instead.
            "prototype$__create__subscriptions": {
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "POST",
            },

            // INTERNAL. Use Broker.subscriptions.update() instead.
            "prototype$__update__subscriptions": {
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "PUT",
            },

            // INTERNAL. Use Broker.subscriptions.destroy() instead.
            "prototype$__destroy__subscriptions": {
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedListings.findById() instead.
            "prototype$__findById__sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedListings.destroyById() instead.
            "prototype$__destroyById__sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedListings.updateById() instead.
            "prototype$__updateById__sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedListings.link() instead.
            "prototype$__link__sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedListings.unlink() instead.
            "prototype$__unlink__sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedListings.exists() instead.
            "prototype$__exists__sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.sharedSmartListings.findById() instead.
            "prototype$__findById__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedSmartListings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedSmartListings.destroyById() instead.
            "prototype$__destroyById__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedSmartListings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedSmartListings.updateById() instead.
            "prototype$__updateById__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedSmartListings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.findById() instead.
            "prototype$__findById__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.destroyById() instead.
            "prototype$__destroyById__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.updateById() instead.
            "prototype$__updateById__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.link() instead.
            "prototype$__link__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.unlink() instead.
            "prototype$__unlink__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.exists() instead.
            "prototype$__exists__sharedTaskTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.findById() instead.
            "prototype$__findById__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.destroyById() instead.
            "prototype$__destroyById__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.updateById() instead.
            "prototype$__updateById__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.link() instead.
            "prototype$__link__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.unlink() instead.
            "prototype$__unlink__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.exists() instead.
            "prototype$__exists__sharedEmailTemplates": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.buyers() instead.
            "prototype$__get__buyers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/buyers",
              method: "GET",
            },

            // INTERNAL. Use Broker.buyers.create() instead.
            "prototype$__create__buyers": {
              url: urlBase + "/Brokers/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Broker.buyers.destroyAll() instead.
            "prototype$__delete__buyers": {
              url: urlBase + "/Brokers/:id/buyers",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.buyers.count() instead.
            "prototype$__count__buyers": {
              url: urlBase + "/Brokers/:id/buyers/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.sellers() instead.
            "prototype$__get__sellers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sellers",
              method: "GET",
            },

            // INTERNAL. Use Broker.sellers.create() instead.
            "prototype$__create__sellers": {
              url: urlBase + "/Brokers/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Broker.sellers.destroyAll() instead.
            "prototype$__delete__sellers": {
              url: urlBase + "/Brokers/:id/sellers",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sellers.count() instead.
            "prototype$__count__sellers": {
              url: urlBase + "/Brokers/:id/sellers/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.reports() instead.
            "prototype$__get__reports": {
              isArray: true,
              url: urlBase + "/Brokers/:id/reports",
              method: "GET",
            },

            // INTERNAL. Use Broker.reports.create() instead.
            "prototype$__create__reports": {
              url: urlBase + "/Brokers/:id/reports",
              method: "POST",
            },

            // INTERNAL. Use Broker.reports.destroyAll() instead.
            "prototype$__delete__reports": {
              url: urlBase + "/Brokers/:id/reports",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.reports.count() instead.
            "prototype$__count__reports": {
              url: urlBase + "/Brokers/:id/reports/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.folders() instead.
            "prototype$__get__folders": {
              isArray: true,
              url: urlBase + "/Brokers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use Broker.folders.create() instead.
            "prototype$__create__folders": {
              url: urlBase + "/Brokers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Broker.folders.destroyAll() instead.
            "prototype$__delete__folders": {
              url: urlBase + "/Brokers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.folders.count() instead.
            "prototype$__count__folders": {
              url: urlBase + "/Brokers/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.managedBrokers() instead.
            "prototype$__get__managedBrokers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/managedBrokers",
              method: "GET",
            },

            // INTERNAL. Use Broker.managedBrokers.create() instead.
            "prototype$__create__managedBrokers": {
              url: urlBase + "/Brokers/:id/managedBrokers",
              method: "POST",
            },

            // INTERNAL. Use Broker.managedBrokers.destroyAll() instead.
            "prototype$__delete__managedBrokers": {
              url: urlBase + "/Brokers/:id/managedBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.managedBrokers.count() instead.
            "prototype$__count__managedBrokers": {
              url: urlBase + "/Brokers/:id/managedBrokers/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.tasks() instead.
            "prototype$__get__tasks": {
              isArray: true,
              url: urlBase + "/Brokers/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use Broker.tasks.create() instead.
            "prototype$__create__tasks": {
              url: urlBase + "/Brokers/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Broker.tasks.destroyAll() instead.
            "prototype$__delete__tasks": {
              url: urlBase + "/Brokers/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.tasks.count() instead.
            "prototype$__count__tasks": {
              url: urlBase + "/Brokers/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.visits() instead.
            "prototype$__get__visits": {
              isArray: true,
              url: urlBase + "/Brokers/:id/visits",
              method: "GET",
            },

            // INTERNAL. Use Broker.visits.create() instead.
            "prototype$__create__visits": {
              url: urlBase + "/Brokers/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Broker.visits.destroyAll() instead.
            "prototype$__delete__visits": {
              url: urlBase + "/Brokers/:id/visits",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.visits.count() instead.
            "prototype$__count__visits": {
              url: urlBase + "/Brokers/:id/visits/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.suppliers() instead.
            "prototype$__get__suppliers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Broker.suppliers.create() instead.
            "prototype$__create__suppliers": {
              url: urlBase + "/Brokers/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Broker.suppliers.destroyAll() instead.
            "prototype$__delete__suppliers": {
              url: urlBase + "/Brokers/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.suppliers.count() instead.
            "prototype$__count__suppliers": {
              url: urlBase + "/Brokers/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Brokers/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Broker.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Brokers/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Broker.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Brokers/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Brokers/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.personalTasks() instead.
            "prototype$__get__personalTasks": {
              isArray: true,
              url: urlBase + "/Brokers/:id/personalTasks",
              method: "GET",
            },

            // INTERNAL. Use Broker.personalTasks.create() instead.
            "prototype$__create__personalTasks": {
              url: urlBase + "/Brokers/:id/personalTasks",
              method: "POST",
            },

            // INTERNAL. Use Broker.personalTasks.destroyAll() instead.
            "prototype$__delete__personalTasks": {
              url: urlBase + "/Brokers/:id/personalTasks",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.personalTasks.count() instead.
            "prototype$__count__personalTasks": {
              url: urlBase + "/Brokers/:id/personalTasks/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.regionsCovered() instead.
            "prototype$__get__regionsCovered": {
              isArray: true,
              url: urlBase + "/Brokers/:id/regionsCovered",
              method: "GET",
            },

            // INTERNAL. Use Broker.regionsCovered.create() instead.
            "prototype$__create__regionsCovered": {
              url: urlBase + "/Brokers/:id/regionsCovered",
              method: "POST",
            },

            // INTERNAL. Use Broker.regionsCovered.destroyAll() instead.
            "prototype$__delete__regionsCovered": {
              url: urlBase + "/Brokers/:id/regionsCovered",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.regionsCovered.count() instead.
            "prototype$__count__regionsCovered": {
              url: urlBase + "/Brokers/:id/regionsCovered/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.listings() instead.
            "prototype$__get__listings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Broker.listings.create() instead.
            "prototype$__create__listings": {
              url: urlBase + "/Brokers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Broker.listings.destroyAll() instead.
            "prototype$__delete__listings": {
              url: urlBase + "/Brokers/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.listings.count() instead.
            "prototype$__count__listings": {
              url: urlBase + "/Brokers/:id/listings/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedListings() instead.
            "prototype$__get__sharedListings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedListings",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedListings.create() instead.
            "prototype$__create__sharedListings": {
              url: urlBase + "/Brokers/:id/sharedListings",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedListings.destroyAll() instead.
            "prototype$__delete__sharedListings": {
              url: urlBase + "/Brokers/:id/sharedListings",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedListings.count() instead.
            "prototype$__count__sharedListings": {
              url: urlBase + "/Brokers/:id/sharedListings/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedSmartListings() instead.
            "prototype$__get__sharedSmartListings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedSmartListings",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedSmartListings.create() instead.
            "prototype$__create__sharedSmartListings": {
              url: urlBase + "/Brokers/:id/sharedSmartListings",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedSmartListings.destroyAll() instead.
            "prototype$__delete__sharedSmartListings": {
              url: urlBase + "/Brokers/:id/sharedSmartListings",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedSmartListings.count() instead.
            "prototype$__count__sharedSmartListings": {
              url: urlBase + "/Brokers/:id/sharedSmartListings/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates() instead.
            "prototype$__get__sharedTaskTemplates": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedTaskTemplates",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.create() instead.
            "prototype$__create__sharedTaskTemplates": {
              url: urlBase + "/Brokers/:id/sharedTaskTemplates",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.destroyAll() instead.
            "prototype$__delete__sharedTaskTemplates": {
              url: urlBase + "/Brokers/:id/sharedTaskTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedTaskTemplates.count() instead.
            "prototype$__count__sharedTaskTemplates": {
              url: urlBase + "/Brokers/:id/sharedTaskTemplates/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates() instead.
            "prototype$__get__sharedEmailTemplates": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedEmailTemplates",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.create() instead.
            "prototype$__create__sharedEmailTemplates": {
              url: urlBase + "/Brokers/:id/sharedEmailTemplates",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.destroyAll() instead.
            "prototype$__delete__sharedEmailTemplates": {
              url: urlBase + "/Brokers/:id/sharedEmailTemplates",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedEmailTemplates.count() instead.
            "prototype$__count__sharedEmailTemplates": {
              url: urlBase + "/Brokers/:id/sharedEmailTemplates/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#create
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Brokers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#patchOrCreate
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Brokers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#replaceOrCreate
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Brokers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#upsertWithWhere
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Brokers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#exists
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Brokers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#findById
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Brokers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#replaceById
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Brokers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#find
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Brokers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#findOne
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Brokers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#updateAll
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Brokers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#deleteById
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Brokers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#count
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Brokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#prototype$patchAttributes
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Brokers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#createChangeStream
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Brokers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getRelatedList
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `rel` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns list of related objects with fields required for a list view
             */
            "getRelatedList": {
              isArray: true,
              url: urlBase + "/Brokers/:id/getList/:rel",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getSearchFilters
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Gets the filters for advanced search.(max price of listing & list of brokers)
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "getSearchFilters": {
              url: urlBase + "/Brokers/:id/getSearchFilters",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#createDescription
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Creates listing & property and any submodels that are given.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `listing` – `{object}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Return created listing.
             */
            "createDescription": {
              url: urlBase + "/Brokers/:id/listings/createDesc",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getHitsReport
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Gets a report for hits in a brokers listings
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `startDate` – `{date}` -
             *
             *  - `endDate` – `{date}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Report with listing clicks information.
             */
            "getHitsReport": {
              isArray: true,
              url: urlBase + "/Brokers/:id/getHitsReport",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getListingsWithMissingDocuments
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of listings.
             */
            "getListingsWithMissingDocuments": {
              isArray: true,
              url: urlBase + "/Brokers/:id/missingDocuments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getListingsWithNoSeller
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Returns list of listings with no sellers attached to them.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of listings.
             */
            "getListingsWithNoSeller": {
              isArray: true,
              url: urlBase + "/Brokers/:id/NoSeller",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#isBrokerRegistered
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `code` – `{string}` -
             *
             *  - `isRealtor` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with boolean indicating whether broker is registered in the system or not
             */
            "isBrokerRegistered": {
              url: urlBase + "/Brokers/isBrokerRegistered",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#search
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Dashboard search service for brokers
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns array of buyers/listings/seller/suppliers.
             */
            "search": {
              isArray: true,
              url: urlBase + "/Brokers/:id/search",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#updateDescription
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Updates listing & property and any submodels that are given.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `listing` – `{object}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Return created listing.
             */
            "updateDescription": {
              url: urlBase + "/Brokers/:id/listings/:fk/updateDesc",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#wpListingMinimal
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Gets only MLS, status and address of brokers visible listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Visible listings with only MLS, status and address fields.
             */
            "wpListingMinimal": {
              isArray: true,
              url: urlBase + "/Brokers/:id/wp/listingMinimal",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getNewBuyersByPropertyType
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `startDate` – `{string=}` -
             *
             *  - `endDate` – `{string=}` -
             *
             *  - `propertyTypes` – `{*=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns created last month listings by broker report.
             */
            "getNewBuyersByPropertyType": {
              url: urlBase + "/Brokers/:id/newBuyersReport",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getAllTasks
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `filter` – `{*=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "getAllTasks": {
              isArray: true,
              url: urlBase + "/Brokers/:id/tasks/all",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#wpGetOwnerEmail
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "wpGetOwnerEmail": {
              url: urlBase + "/Brokers/:id/email",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#wpGetAgencyName
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Returns the name of this person's agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Legal name of this person's agency
             */
            "wpGetAgencyName": {
              url: urlBase + "/Brokers/:id/wp/getAgencyName",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#wpGetMyAddress
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Returns this person's address
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This person's address
             */
            "wpGetMyAddress": {
              url: urlBase + "/Brokers/:id/wp/getMyAddress",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#wpBrokerDetails
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "wpBrokerDetails": {
              url: urlBase + "/Brokers/:id/wp/details",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#sentEmails
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Gets the emails sent by the buyer
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "sentEmails": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sent-emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#bulkDeleteTasks
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Deletes a number of tasks based on their ID.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `aboutId` – `{string}` -
             *
             *  - `aboutType` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Number of deleted tasks
             */
            "bulkDeleteTasks": {
              url: urlBase + "/Brokers/:id/bulkDeleteTasks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#shareListings
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `listingIds` – `{*}` -
             *
             *  - `recipient` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "shareListings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/listings/share",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#shareListing
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `recipients` – `{*}` -
             *
             *  - `externalRecipients` – `{*=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "shareListing": {
              isArray: true,
              url: urlBase + "/Brokers/:id/listings/:fk/share",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getSharedListings
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{*=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "getSharedListings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/listings/shared",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#cancelSharedListing
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "cancelSharedListing": {
              url: urlBase + "/Brokers/:id/listings/:fk/cancel-sharing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#rejectSharedListing
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "rejectSharedListing": {
              url: urlBase + "/Brokers/:id/listings/:fk/reject-sharing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#acceptSharedListing
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "acceptSharedListing": {
              url: urlBase + "/Brokers/:id/listings/:fk/accept-sharing",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#downloadFile
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/Brokers/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#downloadFiles
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/Brokers/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getBasicSearch
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `search` – `{string}` -
             *
             *  - `type` – `{string}` -
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * An array with the returned results
             */
            "getBasicSearch": {
              isArray: true,
              url: urlBase + "/Brokers/:id/getBasicSearch",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getMenuSearchCount
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `search` – `{string}` -
             *
             *  - `type` – `{string=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with the total count and the ids returned
             */
            "getMenuSearchCount": {
              url: urlBase + "/Brokers/:id/menuSearchCount",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#getMenuSearchDetails
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `results` – `{object}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * An array with the returned results
             */
            "getMenuSearchDetails": {
              isArray: true,
              url: urlBase + "/Brokers/:id/menuSearchDetails",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#linkAndUnlinkVisitors
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `toLink` – `{*=}` -
             *
             *  - `toUnlink` – `{*=}` -
             *
             *  - `toCoLink` – `{*=}` -
             *
             *  - `toCoUnlink` – `{*=}` -
             *
             *  - `notifyRecipients` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "linkAndUnlinkVisitors": {
              url: urlBase + "/Brokers/:id/visits/:fk/link-and-unlink",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#wpListingSearch
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Looks for a brokers listings according to city, price and property type.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `priceMin` – `{number=}` -
             *
             *  - `priceMax` – `{number=}` -
             *
             *  - `type` – `{string=}` -
             *
             *  - `city` – `{string=}` -
             *
             *  - `province` – `{string=}` -
             *
             *  - `country` – `{string=}` -
             *
             *  - `broker` – `{string=}` -
             *
             *  - `office` – `{string=}` -
             *
             *  - `getNew` – `{boolean=}` -
             *
             *  - `getSold` – `{boolean=}` -
             *
             *  - `getPriceChanged` – `{boolean=}` -
             *
             *  - `getSmart` – `{boolean=}` -
             *
             *  - `getConditionalContract` – `{boolean=}` -
             *
             *  - `mls` – `{*=}` -
             *
             *  - `isDropdown` – `{boolean=}` -
             *
             *  - `limit` – `{number=}` -
             *
             *  - `sort` – `{string=}` -
             *
             *  - `searchType` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Visible listings that match a certain criteria.
             */
            "wpListingSearch": {
              isArray: true,
              url: urlBase + "/Brokers/:id/wp/listingSearch",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Broker#createMany
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Brokers",
              method: "POST",
            },

            // INTERNAL. Use Seller.brokers.findById() instead.
            "::findById::Seller::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.brokers.destroyById() instead.
            "::destroyById::Seller::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.brokers.updateById() instead.
            "::updateById::Seller::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.brokers.link() instead.
            "::link::Seller::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.brokers.unlink() instead.
            "::unlink::Seller::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.brokers.exists() instead.
            "::exists::Seller::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Seller.brokers() instead.
            "::get::Seller::brokers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Seller.brokers.create() instead.
            "::create::Seller::brokers": {
              url: urlBase + "/Sellers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Seller.brokers.createMany() instead.
            "::createMany::Seller::brokers": {
              isArray: true,
              url: urlBase + "/Sellers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Seller.brokers.destroyAll() instead.
            "::delete::Seller::brokers": {
              url: urlBase + "/Sellers/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.brokers.count() instead.
            "::count::Seller::brokers": {
              url: urlBase + "/Sellers/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.brokers.findById() instead.
            "::findById::Buyer::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.brokers.destroyById() instead.
            "::destroyById::Buyer::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.brokers.updateById() instead.
            "::updateById::Buyer::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.brokers.link() instead.
            "::link::Buyer::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.brokers.unlink() instead.
            "::unlink::Buyer::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.brokers.exists() instead.
            "::exists::Buyer::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.brokers() instead.
            "::get::Buyer::brokers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Buyer.brokers.create() instead.
            "::create::Buyer::brokers": {
              url: urlBase + "/Buyers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.brokers.createMany() instead.
            "::createMany::Buyer::brokers": {
              isArray: true,
              url: urlBase + "/Buyers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Buyer.brokers.destroyAll() instead.
            "::delete::Buyer::brokers": {
              url: urlBase + "/Buyers/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.brokers.count() instead.
            "::count::Buyer::brokers": {
              url: urlBase + "/Buyers/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Agency.brokers.findById() instead.
            "::findById::Agency::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Agency.brokers.destroyById() instead.
            "::destroyById::Agency::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.brokers.updateById() instead.
            "::updateById::Agency::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Agencies/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Agency.brokers() instead.
            "::get::Agency::brokers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Agency.brokers.create() instead.
            "::create::Agency::brokers": {
              url: urlBase + "/Agencies/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Agency.brokers.createMany() instead.
            "::createMany::Agency::brokers": {
              isArray: true,
              url: urlBase + "/Agencies/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Agency.brokers.destroyAll() instead.
            "::delete::Agency::brokers": {
              url: urlBase + "/Agencies/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Agency.brokers.count() instead.
            "::count::Agency::brokers": {
              url: urlBase + "/Agencies/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Visit.broker() instead.
            "::get::Visit::broker": {
              url: urlBase + "/Visits/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use Supplier.brokers.findById() instead.
            "::findById::Supplier::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.brokers.destroyById() instead.
            "::destroyById::Supplier::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.brokers.updateById() instead.
            "::updateById::Supplier::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.brokers.link() instead.
            "::link::Supplier::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.brokers.unlink() instead.
            "::unlink::Supplier::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.brokers.exists() instead.
            "::exists::Supplier::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Supplier.brokers() instead.
            "::get::Supplier::brokers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.brokers.create() instead.
            "::create::Supplier::brokers": {
              url: urlBase + "/Suppliers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.brokers.createMany() instead.
            "::createMany::Supplier::brokers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.brokers.destroyAll() instead.
            "::delete::Supplier::brokers": {
              url: urlBase + "/Suppliers/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.brokers.count() instead.
            "::count::Supplier::brokers": {
              url: urlBase + "/Suppliers/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.brokers.findById() instead.
            "::findById::SmartFile::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.brokers.destroyById() instead.
            "::destroyById::SmartFile::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartFile.brokers.updateById() instead.
            "::updateById::SmartFile::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartFile.brokers.link() instead.
            "::link::SmartFile::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartFile.brokers.unlink() instead.
            "::unlink::SmartFile::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartFile.brokers.exists() instead.
            "::exists::SmartFile::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/smartFiles/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartFile.brokers() instead.
            "::get::SmartFile::brokers": {
              isArray: true,
              url: urlBase + "/smartFiles/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.brokers.create() instead.
            "::create::SmartFile::brokers": {
              url: urlBase + "/smartFiles/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use SmartFile.brokers.createMany() instead.
            "::createMany::SmartFile::brokers": {
              isArray: true,
              url: urlBase + "/smartFiles/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use SmartFile.brokers.destroyAll() instead.
            "::delete::SmartFile::brokers": {
              url: urlBase + "/smartFiles/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use SmartFile.brokers.count() instead.
            "::count::SmartFile::brokers": {
              url: urlBase + "/smartFiles/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Folder.broker() instead.
            "::get::Folder::broker": {
              url: urlBase + "/Folders/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use ListingBroker.broker() instead.
            "::get::ListingBroker::broker": {
              url: urlBase + "/ListingBrokers/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use BuyerBroker.broker() instead.
            "::get::BuyerBroker::broker": {
              url: urlBase + "/BuyerBrokers/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use SellerBroker.broker() instead.
            "::get::SellerBroker::broker": {
              url: urlBase + "/SellerBrokers/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use SupplierBroker.broker() instead.
            "::get::SupplierBroker::broker": {
              url: urlBase + "/SupplierBrokers/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use Office.director() instead.
            "::get::Office::director": {
              url: urlBase + "/Offices/:id/director",
              method: "GET",
            },

            // INTERNAL. Use Office.brokers.findById() instead.
            "::findById::Office::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Office.brokers.destroyById() instead.
            "::destroyById::Office::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Office.brokers.updateById() instead.
            "::updateById::Office::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Offices/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Office.brokers() instead.
            "::get::Office::brokers": {
              isArray: true,
              url: urlBase + "/Offices/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Office.brokers.create() instead.
            "::create::Office::brokers": {
              url: urlBase + "/Offices/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Office.brokers.createMany() instead.
            "::createMany::Office::brokers": {
              isArray: true,
              url: urlBase + "/Offices/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Office.brokers.destroyAll() instead.
            "::delete::Office::brokers": {
              url: urlBase + "/Offices/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Office.brokers.count() instead.
            "::count::Office::brokers": {
              url: urlBase + "/Offices/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use SbUser.brokerProfile() instead.
            "::get::SbUser::brokerProfile": {
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "GET",
            },

            // INTERNAL. Use SbUser.brokerProfile.create() instead.
            "::create::SbUser::brokerProfile": {
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.brokerProfile.createMany() instead.
            "::createMany::SbUser::brokerProfile": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "POST",
            },

            // INTERNAL. Use SbUser.brokerProfile.update() instead.
            "::update::SbUser::brokerProfile": {
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.brokerProfile.destroy() instead.
            "::destroy::SbUser::brokerProfile": {
              url: urlBase + "/SbUsers/:id/brokerProfile",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.managedBrokers.findById() instead.
            "::findById::Broker::managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.managedBrokers.destroyById() instead.
            "::destroyById::Broker::managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.managedBrokers.updateById() instead.
            "::updateById::Broker::managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.managedBrokers.link() instead.
            "::link::Broker::managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.managedBrokers.unlink() instead.
            "::unlink::Broker::managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.managedBrokers.exists() instead.
            "::exists::Broker::managedBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/managedBrokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.managedBrokers() instead.
            "::get::Broker::managedBrokers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/managedBrokers",
              method: "GET",
            },

            // INTERNAL. Use Broker.managedBrokers.create() instead.
            "::create::Broker::managedBrokers": {
              url: urlBase + "/Brokers/:id/managedBrokers",
              method: "POST",
            },

            // INTERNAL. Use Broker.managedBrokers.createMany() instead.
            "::createMany::Broker::managedBrokers": {
              isArray: true,
              url: urlBase + "/Brokers/:id/managedBrokers",
              method: "POST",
            },

            // INTERNAL. Use Broker.managedBrokers.destroyAll() instead.
            "::delete::Broker::managedBrokers": {
              url: urlBase + "/Brokers/:id/managedBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.managedBrokers.count() instead.
            "::count::Broker::managedBrokers": {
              url: urlBase + "/Brokers/:id/managedBrokers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.brokers.findById() instead.
            "::findById::Listing::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.brokers.destroyById() instead.
            "::destroyById::Listing::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.brokers.updateById() instead.
            "::updateById::Listing::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.brokers.link() instead.
            "::link::Listing::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.brokers.unlink() instead.
            "::unlink::Listing::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.brokers.exists() instead.
            "::exists::Listing::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.sharedSmartListings.findById() instead.
            "::findById::Listing::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.sharedSmartListings.destroyById() instead.
            "::destroyById::Listing::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sharedSmartListings.updateById() instead.
            "::updateById::Listing::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sharedSmartListings.link() instead.
            "::link::Listing::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sharedSmartListings.unlink() instead.
            "::unlink::Listing::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sharedSmartListings.exists() instead.
            "::exists::Listing::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.brokers() instead.
            "::get::Listing::brokers": {
              isArray: true,
              url: urlBase + "/Listings/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Listing.brokers.create() instead.
            "::create::Listing::brokers": {
              url: urlBase + "/Listings/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Listing.brokers.createMany() instead.
            "::createMany::Listing::brokers": {
              isArray: true,
              url: urlBase + "/Listings/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Listing.brokers.destroyAll() instead.
            "::delete::Listing::brokers": {
              url: urlBase + "/Listings/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.brokers.count() instead.
            "::count::Listing::brokers": {
              url: urlBase + "/Listings/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.sharedSmartListings() instead.
            "::get::Listing::sharedSmartListings": {
              isArray: true,
              url: urlBase + "/Listings/:id/sharedSmartListings",
              method: "GET",
            },

            // INTERNAL. Use Listing.sharedSmartListings.create() instead.
            "::create::Listing::sharedSmartListings": {
              url: urlBase + "/Listings/:id/sharedSmartListings",
              method: "POST",
            },

            // INTERNAL. Use Listing.sharedSmartListings.createMany() instead.
            "::createMany::Listing::sharedSmartListings": {
              isArray: true,
              url: urlBase + "/Listings/:id/sharedSmartListings",
              method: "POST",
            },

            // INTERNAL. Use Listing.sharedSmartListings.destroyAll() instead.
            "::delete::Listing::sharedSmartListings": {
              url: urlBase + "/Listings/:id/sharedSmartListings",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sharedSmartListings.count() instead.
            "::count::Listing::sharedSmartListings": {
              url: urlBase + "/Listings/:id/sharedSmartListings/count",
              method: "GET",
            },

            // INTERNAL. Use ListingClick.broker() instead.
            "::get::ListingClick::broker": {
              url: urlBase + "/ListingClicks/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use Subscription.broker() instead.
            "::get::Subscription::broker": {
              url: urlBase + "/Subscriptions/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use AccountWordpress.brokers.findById() instead.
            "::findById::AccountWordpress::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use AccountWordpress.brokers.destroyById() instead.
            "::destroyById::AccountWordpress::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AccountWordpress.brokers.updateById() instead.
            "::updateById::AccountWordpress::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AccountWordpress.brokers.link() instead.
            "::link::AccountWordpress::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AccountWordpress.brokers.unlink() instead.
            "::unlink::AccountWordpress::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AccountWordpress.brokers.exists() instead.
            "::exists::AccountWordpress::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use AccountWordpress.brokers() instead.
            "::get::AccountWordpress::brokers": {
              isArray: true,
              url: urlBase + "/AccountWordpresses/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use AccountWordpress.brokers.create() instead.
            "::create::AccountWordpress::brokers": {
              url: urlBase + "/AccountWordpresses/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use AccountWordpress.brokers.createMany() instead.
            "::createMany::AccountWordpress::brokers": {
              isArray: true,
              url: urlBase + "/AccountWordpresses/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use AccountWordpress.brokers.destroyAll() instead.
            "::delete::AccountWordpress::brokers": {
              url: urlBase + "/AccountWordpresses/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use AccountWordpress.brokers.count() instead.
            "::count::AccountWordpress::brokers": {
              url: urlBase + "/AccountWordpresses/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.brokers.findById() instead.
            "::findById::SmartSearch::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.brokers.destroyById() instead.
            "::destroyById::SmartSearch::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.brokers.updateById() instead.
            "::updateById::SmartSearch::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.brokers.link() instead.
            "::link::SmartSearch::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.brokers.unlink() instead.
            "::unlink::SmartSearch::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.brokers.exists() instead.
            "::exists::SmartSearch::brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartSearch.brokers() instead.
            "::get::SmartSearch::brokers": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.brokers.create() instead.
            "::create::SmartSearch::brokers": {
              url: urlBase + "/SmartSearches/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.brokers.createMany() instead.
            "::createMany::SmartSearch::brokers": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.brokers.destroyAll() instead.
            "::delete::SmartSearch::brokers": {
              url: urlBase + "/SmartSearches/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.brokers.count() instead.
            "::count::SmartSearch::brokers": {
              url: urlBase + "/SmartSearches/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use SharedSmartListing.broker() instead.
            "::get::SharedSmartListing::broker": {
              url: urlBase + "/SharedSmartListings/:id/broker",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Broker#upsert
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Broker#updateOrCreate
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Broker#patchOrCreateWithWhere
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Broker#update
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Broker#destroyById
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Broker#removeById
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Broker#prototype$updateAttributes
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Broker#modelName
        * @propertyOf lbServices.Broker
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Broker`.
        */
        R.modelName = "Broker";


            /**
             * @ngdoc method
             * @name lbServices.Broker#user
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Broker::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker#agency
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Broker::agency"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker#office
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Fetches belongsTo relation office.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Office` object.)
             * </em>
             */
        R.office = function() {
          var TargetResource = $injector.get("Office");
          var action = TargetResource["::get::Broker::office"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.buyers
     * @header lbServices.Broker.buyers
     * @object
     * @description
     *
     * The object `Broker.buyers` groups methods
     * manipulating `Buyer` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#buyers Broker.buyers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#buyers
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries buyers of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#count
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Counts buyers of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.buyers.count = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::count::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#create
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Creates a new instance in buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.create = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::create::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#createMany
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Creates a new instance in buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.createMany = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::createMany::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#destroyAll
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Deletes all buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.destroyAll = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::delete::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#destroyById
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Delete a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.destroyById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::destroyById::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#exists
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Check the existence of buyers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.exists = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::exists::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#findById
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Find a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.findById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::findById::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#link
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Add a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.link = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::link::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#unlink
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Remove the buyers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.unlink = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::unlink::Broker::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.buyers#updateById
             * @methodOf lbServices.Broker.buyers
             *
             * @description
             *
             * Update a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.updateById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::updateById::Broker::buyers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.sellers
     * @header lbServices.Broker.sellers
     * @object
     * @description
     *
     * The object `Broker.sellers` groups methods
     * manipulating `Seller` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#sellers Broker.sellers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#sellers
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries sellers of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::get::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#count
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Counts sellers of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sellers.count = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::count::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#create
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Creates a new instance in sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.create = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::create::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#createMany
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Creates a new instance in sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.createMany = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::createMany::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#destroyAll
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Deletes all sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.destroyAll = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::delete::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#destroyById
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Delete a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.destroyById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::destroyById::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#exists
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Check the existence of sellers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.exists = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::exists::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#findById
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Find a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.findById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::findById::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#link
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Add a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.link = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::link::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#unlink
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Remove the sellers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.unlink = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::unlink::Broker::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sellers#updateById
             * @methodOf lbServices.Broker.sellers
             *
             * @description
             *
             * Update a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.updateById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::updateById::Broker::sellers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.reports
     * @header lbServices.Broker.reports
     * @object
     * @description
     *
     * The object `Broker.reports` groups methods
     * manipulating `SmartFile` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#reports Broker.reports()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#reports
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries reports of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.reports = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Broker::reports"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.reports#count
             * @methodOf lbServices.Broker.reports
             *
             * @description
             *
             * Counts reports of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.reports.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Broker::reports"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.reports#create
             * @methodOf lbServices.Broker.reports
             *
             * @description
             *
             * Creates a new instance in reports of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.reports.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Broker::reports"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.reports#createMany
             * @methodOf lbServices.Broker.reports
             *
             * @description
             *
             * Creates a new instance in reports of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.reports.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Broker::reports"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.reports#destroyAll
             * @methodOf lbServices.Broker.reports
             *
             * @description
             *
             * Deletes all reports of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.reports.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Broker::reports"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.reports#destroyById
             * @methodOf lbServices.Broker.reports
             *
             * @description
             *
             * Delete a related item by id for reports.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for reports
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.reports.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Broker::reports"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.reports#findById
             * @methodOf lbServices.Broker.reports
             *
             * @description
             *
             * Find a related item by id for reports.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for reports
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.reports.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Broker::reports"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.reports#updateById
             * @methodOf lbServices.Broker.reports
             *
             * @description
             *
             * Update a related item by id for reports.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for reports
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.reports.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Broker::reports"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.folders
     * @header lbServices.Broker.folders
     * @object
     * @description
     *
     * The object `Broker.folders` groups methods
     * manipulating `Folder` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#folders Broker.folders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#folders
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries folders of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::Broker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.folders#count
             * @methodOf lbServices.Broker.folders
             *
             * @description
             *
             * Counts folders of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.folders.count = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::count::Broker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.folders#create
             * @methodOf lbServices.Broker.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::Broker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.folders#createMany
             * @methodOf lbServices.Broker.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::Broker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.folders#destroyAll
             * @methodOf lbServices.Broker.folders
             *
             * @description
             *
             * Deletes all folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyAll = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::delete::Broker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.folders#destroyById
             * @methodOf lbServices.Broker.folders
             *
             * @description
             *
             * Delete a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroyById::Broker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.folders#findById
             * @methodOf lbServices.Broker.folders
             *
             * @description
             *
             * Find a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.findById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::findById::Broker::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.folders#updateById
             * @methodOf lbServices.Broker.folders
             *
             * @description
             *
             * Update a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.updateById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::updateById::Broker::folders"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.managedBrokers
     * @header lbServices.Broker.managedBrokers
     * @object
     * @description
     *
     * The object `Broker.managedBrokers` groups methods
     * manipulating `Broker` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#managedBrokers Broker.managedBrokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#managedBrokers
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries managedBrokers of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.managedBrokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#count
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Counts managedBrokers of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.managedBrokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#create
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Creates a new instance in managedBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.managedBrokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#createMany
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Creates a new instance in managedBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.managedBrokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#destroyAll
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Deletes all managedBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.managedBrokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#destroyById
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Delete a related item by id for managedBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for managedBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.managedBrokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#exists
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Check the existence of managedBrokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for managedBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.managedBrokers.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#findById
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Find a related item by id for managedBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for managedBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.managedBrokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#link
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Add a related item by id for managedBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for managedBrokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.managedBrokers.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#unlink
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Remove the managedBrokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for managedBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.managedBrokers.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.managedBrokers#updateById
             * @methodOf lbServices.Broker.managedBrokers
             *
             * @description
             *
             * Update a related item by id for managedBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for managedBrokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.managedBrokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::Broker::managedBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker#licenseType
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Fetches belongsTo relation licenseType.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.licenseType = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Broker::licenseType"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.tasks
     * @header lbServices.Broker.tasks
     * @object
     * @description
     *
     * The object `Broker.tasks` groups methods
     * manipulating `Task` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#tasks Broker.tasks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#tasks
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries tasks of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Broker::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.tasks#count
             * @methodOf lbServices.Broker.tasks
             *
             * @description
             *
             * Counts tasks of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasks.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::Broker::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.tasks#create
             * @methodOf lbServices.Broker.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::Broker::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.tasks#createMany
             * @methodOf lbServices.Broker.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::Broker::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.tasks#destroyAll
             * @methodOf lbServices.Broker.tasks
             *
             * @description
             *
             * Deletes all tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::Broker::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.tasks#destroyById
             * @methodOf lbServices.Broker.tasks
             *
             * @description
             *
             * Delete a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::Broker::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.tasks#findById
             * @methodOf lbServices.Broker.tasks
             *
             * @description
             *
             * Find a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::Broker::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.tasks#updateById
             * @methodOf lbServices.Broker.tasks
             *
             * @description
             *
             * Update a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::Broker::tasks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.visits
     * @header lbServices.Broker.visits
     * @object
     * @description
     *
     * The object `Broker.visits` groups methods
     * manipulating `Visit` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#visits Broker.visits()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#visits
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries visits of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::get::Broker::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.visits#count
             * @methodOf lbServices.Broker.visits
             *
             * @description
             *
             * Counts visits of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.visits.count = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::count::Broker::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.visits#create
             * @methodOf lbServices.Broker.visits
             *
             * @description
             *
             * Creates a new instance in visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.create = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::create::Broker::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.visits#createMany
             * @methodOf lbServices.Broker.visits
             *
             * @description
             *
             * Creates a new instance in visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.createMany = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::createMany::Broker::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.visits#destroyAll
             * @methodOf lbServices.Broker.visits
             *
             * @description
             *
             * Deletes all visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.destroyAll = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::delete::Broker::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.visits#destroyById
             * @methodOf lbServices.Broker.visits
             *
             * @description
             *
             * Delete a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.destroyById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::destroyById::Broker::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.visits#findById
             * @methodOf lbServices.Broker.visits
             *
             * @description
             *
             * Find a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.findById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::findById::Broker::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.visits#updateById
             * @methodOf lbServices.Broker.visits
             *
             * @description
             *
             * Update a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.updateById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::updateById::Broker::visits"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.suppliers
     * @header lbServices.Broker.suppliers
     * @object
     * @description
     *
     * The object `Broker.suppliers` groups methods
     * manipulating `Supplier` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#suppliers Broker.suppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#suppliers
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries suppliers of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#count
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Counts suppliers of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.suppliers.count = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::count::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#create
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.create = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::create::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#createMany
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.createMany = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::createMany::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#destroyAll
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Deletes all suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyAll = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::delete::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#destroyById
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Delete a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::destroyById::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#exists
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Check the existence of suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.exists = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::exists::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#findById
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Find a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.findById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::findById::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#link
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Add a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.link = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::link::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#unlink
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Remove the suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.unlink = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::unlink::Broker::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.suppliers#updateById
             * @methodOf lbServices.Broker.suppliers
             *
             * @description
             *
             * Update a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.updateById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::updateById::Broker::suppliers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.files
     * @header lbServices.Broker.files
     * @object
     * @description
     *
     * The object `Broker.files` groups methods
     * manipulating `SmartFile` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#files Broker.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#files
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries files of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Broker::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.files#count
             * @methodOf lbServices.Broker.files
             *
             * @description
             *
             * Counts files of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Broker::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.files#create
             * @methodOf lbServices.Broker.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Broker::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.files#createMany
             * @methodOf lbServices.Broker.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Broker::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.files#destroyAll
             * @methodOf lbServices.Broker.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Broker::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.files#destroyById
             * @methodOf lbServices.Broker.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Broker::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.files#findById
             * @methodOf lbServices.Broker.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Broker::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.files#updateById
             * @methodOf lbServices.Broker.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Broker::files"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.personalTasks
     * @header lbServices.Broker.personalTasks
     * @object
     * @description
     *
     * The object `Broker.personalTasks` groups methods
     * manipulating `Task` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#personalTasks Broker.personalTasks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#personalTasks
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries personalTasks of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.personalTasks = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Broker::personalTasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.personalTasks#count
             * @methodOf lbServices.Broker.personalTasks
             *
             * @description
             *
             * Counts personalTasks of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.personalTasks.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::Broker::personalTasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.personalTasks#create
             * @methodOf lbServices.Broker.personalTasks
             *
             * @description
             *
             * Creates a new instance in personalTasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.personalTasks.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::Broker::personalTasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.personalTasks#createMany
             * @methodOf lbServices.Broker.personalTasks
             *
             * @description
             *
             * Creates a new instance in personalTasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.personalTasks.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::Broker::personalTasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.personalTasks#destroyAll
             * @methodOf lbServices.Broker.personalTasks
             *
             * @description
             *
             * Deletes all personalTasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.personalTasks.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::Broker::personalTasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.personalTasks#destroyById
             * @methodOf lbServices.Broker.personalTasks
             *
             * @description
             *
             * Delete a related item by id for personalTasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for personalTasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.personalTasks.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::Broker::personalTasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.personalTasks#findById
             * @methodOf lbServices.Broker.personalTasks
             *
             * @description
             *
             * Find a related item by id for personalTasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for personalTasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.personalTasks.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::Broker::personalTasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.personalTasks#updateById
             * @methodOf lbServices.Broker.personalTasks
             *
             * @description
             *
             * Update a related item by id for personalTasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for personalTasks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.personalTasks.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::Broker::personalTasks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.regionsCovered
     * @header lbServices.Broker.regionsCovered
     * @object
     * @description
     *
     * The object `Broker.regionsCovered` groups methods
     * manipulating `City` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#regionsCovered Broker.regionsCovered()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#regionsCovered
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries regionsCovered of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.regionsCovered = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::get::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#count
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Counts regionsCovered of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.regionsCovered.count = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::count::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#create
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Creates a new instance in regionsCovered of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.regionsCovered.create = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::create::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#createMany
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Creates a new instance in regionsCovered of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.regionsCovered.createMany = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::createMany::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#destroyAll
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Deletes all regionsCovered of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.regionsCovered.destroyAll = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::delete::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#destroyById
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Delete a related item by id for regionsCovered.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for regionsCovered
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.regionsCovered.destroyById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::destroyById::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#exists
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Check the existence of regionsCovered relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for regionsCovered
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.regionsCovered.exists = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::exists::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#findById
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Find a related item by id for regionsCovered.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for regionsCovered
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.regionsCovered.findById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::findById::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#link
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Add a related item by id for regionsCovered.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for regionsCovered
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.regionsCovered.link = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::link::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#unlink
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Remove the regionsCovered relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for regionsCovered
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.regionsCovered.unlink = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::unlink::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.regionsCovered#updateById
             * @methodOf lbServices.Broker.regionsCovered
             *
             * @description
             *
             * Update a related item by id for regionsCovered.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for regionsCovered
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.regionsCovered.updateById = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::updateById::Broker::regionsCovered"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.listings
     * @header lbServices.Broker.listings
     * @object
     * @description
     *
     * The object `Broker.listings` groups methods
     * manipulating `Listing` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#listings Broker.listings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#listings
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries listings of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#count
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Counts listings of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listings.count = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::count::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#create
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.create = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::create::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#createMany
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Creates a new instance in listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.createMany = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::createMany::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#destroyAll
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Deletes all listings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyAll = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::delete::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#destroyById
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Delete a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.destroyById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::destroyById::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#exists
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Check the existence of listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.exists = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::exists::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#findById
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Find a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.findById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::findById::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#link
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Add a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.link = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::link::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#unlink
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Remove the listings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listings.unlink = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::unlink::Broker::listings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.listings#updateById
             * @methodOf lbServices.Broker.listings
             *
             * @description
             *
             * Update a related item by id for listings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for listings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listings.updateById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::updateById::Broker::listings"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.subscriptions
     * @header lbServices.Broker.subscriptions
     * @object
     * @description
     *
     * The object `Broker.subscriptions` groups methods
     * manipulating `Subscription` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#subscriptions Broker.subscriptions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#subscriptions
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Fetches hasOne relation subscriptions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R.subscriptions = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::get::Broker::subscriptions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.subscriptions#create
             * @methodOf lbServices.Broker.subscriptions
             *
             * @description
             *
             * Creates a new instance in subscriptions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R.subscriptions.create = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::create::Broker::subscriptions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.subscriptions#createMany
             * @methodOf lbServices.Broker.subscriptions
             *
             * @description
             *
             * Creates a new instance in subscriptions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R.subscriptions.createMany = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::createMany::Broker::subscriptions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.subscriptions#destroy
             * @methodOf lbServices.Broker.subscriptions
             *
             * @description
             *
             * Deletes subscriptions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.subscriptions.destroy = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::destroy::Broker::subscriptions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.subscriptions#update
             * @methodOf lbServices.Broker.subscriptions
             *
             * @description
             *
             * Update subscriptions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R.subscriptions.update = function() {
          var TargetResource = $injector.get("Subscription");
          var action = TargetResource["::update::Broker::subscriptions"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.sharedListings
     * @header lbServices.Broker.sharedListings
     * @object
     * @description
     *
     * The object `Broker.sharedListings` groups methods
     * manipulating `Listing` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#sharedListings Broker.sharedListings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#sharedListings
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries sharedListings of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.sharedListings = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#count
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Counts sharedListings of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sharedListings.count = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::count::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#create
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Creates a new instance in sharedListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.sharedListings.create = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::create::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#createMany
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Creates a new instance in sharedListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.sharedListings.createMany = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::createMany::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#destroyAll
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Deletes all sharedListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedListings.destroyAll = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::delete::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#destroyById
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Delete a related item by id for sharedListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedListings.destroyById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::destroyById::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#exists
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Check the existence of sharedListings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.sharedListings.exists = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::exists::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#findById
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Find a related item by id for sharedListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.sharedListings.findById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::findById::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#link
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Add a related item by id for sharedListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sharedListings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.sharedListings.link = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::link::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#unlink
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Remove the sharedListings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedListings.unlink = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::unlink::Broker::sharedListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedListings#updateById
             * @methodOf lbServices.Broker.sharedListings
             *
             * @description
             *
             * Update a related item by id for sharedListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sharedListings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.sharedListings.updateById = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::updateById::Broker::sharedListings"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.sharedSmartListings
     * @header lbServices.Broker.sharedSmartListings
     * @object
     * @description
     *
     * The object `Broker.sharedSmartListings` groups methods
     * manipulating `SharedSmartListing` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#sharedSmartListings Broker.sharedSmartListings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#sharedSmartListings
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries sharedSmartListings of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.sharedSmartListings = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::get::Broker::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedSmartListings#count
             * @methodOf lbServices.Broker.sharedSmartListings
             *
             * @description
             *
             * Counts sharedSmartListings of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sharedSmartListings.count = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::count::Broker::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedSmartListings#create
             * @methodOf lbServices.Broker.sharedSmartListings
             *
             * @description
             *
             * Creates a new instance in sharedSmartListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.sharedSmartListings.create = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::create::Broker::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedSmartListings#createMany
             * @methodOf lbServices.Broker.sharedSmartListings
             *
             * @description
             *
             * Creates a new instance in sharedSmartListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.sharedSmartListings.createMany = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::createMany::Broker::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedSmartListings#destroyAll
             * @methodOf lbServices.Broker.sharedSmartListings
             *
             * @description
             *
             * Deletes all sharedSmartListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedSmartListings.destroyAll = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::delete::Broker::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedSmartListings#destroyById
             * @methodOf lbServices.Broker.sharedSmartListings
             *
             * @description
             *
             * Delete a related item by id for sharedSmartListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedSmartListings.destroyById = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::destroyById::Broker::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedSmartListings#findById
             * @methodOf lbServices.Broker.sharedSmartListings
             *
             * @description
             *
             * Find a related item by id for sharedSmartListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.sharedSmartListings.findById = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::findById::Broker::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedSmartListings#updateById
             * @methodOf lbServices.Broker.sharedSmartListings
             *
             * @description
             *
             * Update a related item by id for sharedSmartListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.sharedSmartListings.updateById = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::updateById::Broker::sharedSmartListings"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.sharedTaskTemplates
     * @header lbServices.Broker.sharedTaskTemplates
     * @object
     * @description
     *
     * The object `Broker.sharedTaskTemplates` groups methods
     * manipulating `TaskTemplate` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#sharedTaskTemplates Broker.sharedTaskTemplates()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#sharedTaskTemplates
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries sharedTaskTemplates of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::get::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#count
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Counts sharedTaskTemplates of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sharedTaskTemplates.count = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::count::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#create
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Creates a new instance in sharedTaskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.create = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::create::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#createMany
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Creates a new instance in sharedTaskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.createMany = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::createMany::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#destroyAll
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Deletes all sharedTaskTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedTaskTemplates.destroyAll = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::delete::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#destroyById
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Delete a related item by id for sharedTaskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedTaskTemplates.destroyById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::destroyById::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#exists
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Check the existence of sharedTaskTemplates relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.exists = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::exists::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#findById
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Find a related item by id for sharedTaskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.findById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::findById::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#link
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Add a related item by id for sharedTaskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.link = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::link::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#unlink
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Remove the sharedTaskTemplates relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedTaskTemplates.unlink = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::unlink::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedTaskTemplates#updateById
             * @methodOf lbServices.Broker.sharedTaskTemplates
             *
             * @description
             *
             * Update a related item by id for sharedTaskTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sharedTaskTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.sharedTaskTemplates.updateById = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::updateById::Broker::sharedTaskTemplates"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Broker.sharedEmailTemplates
     * @header lbServices.Broker.sharedEmailTemplates
     * @object
     * @description
     *
     * The object `Broker.sharedEmailTemplates` groups methods
     * manipulating `EmailTemplate` instances related to `Broker`.
     *
     * Call {@link lbServices.Broker#sharedEmailTemplates Broker.sharedEmailTemplates()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Broker#sharedEmailTemplates
             * @methodOf lbServices.Broker
             *
             * @description
             *
             * Queries sharedEmailTemplates of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::get::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#count
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Counts sharedEmailTemplates of Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sharedEmailTemplates.count = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::count::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#create
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Creates a new instance in sharedEmailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.create = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::create::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#createMany
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Creates a new instance in sharedEmailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.createMany = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::createMany::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#destroyAll
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Deletes all sharedEmailTemplates of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedEmailTemplates.destroyAll = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::delete::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#destroyById
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Delete a related item by id for sharedEmailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedEmailTemplates.destroyById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::destroyById::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#exists
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Check the existence of sharedEmailTemplates relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.exists = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::exists::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#findById
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Find a related item by id for sharedEmailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.findById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::findById::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#link
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Add a related item by id for sharedEmailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.link = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::link::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#unlink
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Remove the sharedEmailTemplates relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedEmailTemplates.unlink = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::unlink::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Broker.sharedEmailTemplates#updateById
             * @methodOf lbServices.Broker.sharedEmailTemplates
             *
             * @description
             *
             * Update a related item by id for sharedEmailTemplates.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Broker id
             *
             *  - `fk` – `{*}` - Foreign key for sharedEmailTemplates
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.sharedEmailTemplates.updateById = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::updateById::Broker::sharedEmailTemplates"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Listing
 * @header lbServices.Listing
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Listing` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Listing",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Listings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Listing.visits.findById() instead.
            "prototype$__findById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/visits/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.visits.destroyById() instead.
            "prototype$__destroyById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/visits/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.visits.updateById() instead.
            "prototype$__updateById__visits": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/visits/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.tasks.findById() instead.
            "prototype$__findById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.tasks.destroyById() instead.
            "prototype$__destroyById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.tasks.updateById() instead.
            "prototype$__updateById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.property() instead.
            "prototype$__get__property": {
              url: urlBase + "/Listings/:id/property",
              method: "GET",
            },

            // INTERNAL. Use Listing.property.create() instead.
            "prototype$__create__property": {
              url: urlBase + "/Listings/:id/property",
              method: "POST",
            },

            // INTERNAL. Use Listing.property.update() instead.
            "prototype$__update__property": {
              url: urlBase + "/Listings/:id/property",
              method: "PUT",
            },

            // INTERNAL. Use Listing.property.destroy() instead.
            "prototype$__destroy__property": {
              url: urlBase + "/Listings/:id/property",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notaries.findById() instead.
            "prototype$__findById__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.notaries.destroyById() instead.
            "prototype$__destroyById__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notaries.updateById() instead.
            "prototype$__updateById__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.notaries.link() instead.
            "prototype$__link__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.notaries.unlink() instead.
            "prototype$__unlink__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notaries.exists() instead.
            "prototype$__exists__notaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notaries/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.listingNotaries.findById() instead.
            "prototype$__findById__listingNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingNotaries/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingNotaries.destroyById() instead.
            "prototype$__destroyById__listingNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingNotaries/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingNotaries.updateById() instead.
            "prototype$__updateById__listingNotaries": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingNotaries/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.brokers.link() instead.
            "prototype$__link__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.brokers.unlink() instead.
            "prototype$__unlink__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.brokers.exists() instead.
            "prototype$__exists__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.listingBrokers.findById() instead.
            "prototype$__findById__listingBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingBrokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingBrokers.destroyById() instead.
            "prototype$__destroyById__listingBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingBrokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingBrokers.updateById() instead.
            "prototype$__updateById__listingBrokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingBrokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sellers.findById() instead.
            "prototype$__findById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.sellers.destroyById() instead.
            "prototype$__destroyById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sellers.updateById() instead.
            "prototype$__updateById__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sellers.link() instead.
            "prototype$__link__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sellers.unlink() instead.
            "prototype$__unlink__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sellers.exists() instead.
            "prototype$__exists__sellers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sellers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.convertedTo() instead.
            "prototype$__get__convertedTo": {
              url: urlBase + "/Listings/:id/convertedTo",
              method: "GET",
            },

            // INTERNAL. Use Listing.folders.findById() instead.
            "prototype$__findById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.folders.destroyById() instead.
            "prototype$__destroyById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.folders.updateById() instead.
            "prototype$__updateById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.documentTypes.findById() instead.
            "prototype$__findById__documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentTypes.destroyById() instead.
            "prototype$__destroyById__documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentTypes.updateById() instead.
            "prototype$__updateById__documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.documentTypes.link() instead.
            "prototype$__link__documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.documentTypes.unlink() instead.
            "prototype$__unlink__documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentTypes.exists() instead.
            "prototype$__exists__documentTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentTypes/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.documentMetadata.findById() instead.
            "prototype$__findById__documentMetadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentMetadata/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentMetadata.destroyById() instead.
            "prototype$__destroyById__documentMetadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentMetadata/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentMetadata.updateById() instead.
            "prototype$__updateById__documentMetadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/documentMetadata/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.offers.findById() instead.
            "prototype$__findById__offers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/offers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.offers.destroyById() instead.
            "prototype$__destroyById__offers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/offers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.offers.updateById() instead.
            "prototype$__updateById__offers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/offers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.videoImages.findById() instead.
            "prototype$__findById__videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoImages.destroyById() instead.
            "prototype$__destroyById__videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoImages.updateById() instead.
            "prototype$__updateById__videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.videoImages.link() instead.
            "prototype$__link__videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.videoImages.unlink() instead.
            "prototype$__unlink__videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoImages.exists() instead.
            "prototype$__exists__videoImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.caravanImages.findById() instead.
            "prototype$__findById__caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanImages.destroyById() instead.
            "prototype$__destroyById__caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanImages.updateById() instead.
            "prototype$__updateById__caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.caravanImages.link() instead.
            "prototype$__link__caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.caravanImages.unlink() instead.
            "prototype$__unlink__caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanImages.exists() instead.
            "prototype$__exists__caravanImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.featureImages.findById() instead.
            "prototype$__findById__featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.featureImages.destroyById() instead.
            "prototype$__destroyById__featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featureImages.updateById() instead.
            "prototype$__updateById__featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.featureImages.link() instead.
            "prototype$__link__featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.featureImages.unlink() instead.
            "prototype$__unlink__featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featureImages.exists() instead.
            "prototype$__exists__featureImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featureImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.websiteImages.findById() instead.
            "prototype$__findById__websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.websiteImages.destroyById() instead.
            "prototype$__destroyById__websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websiteImages.updateById() instead.
            "prototype$__updateById__websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.websiteImages.link() instead.
            "prototype$__link__websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.websiteImages.unlink() instead.
            "prototype$__unlink__websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websiteImages.exists() instead.
            "prototype$__exists__websiteImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websiteImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.mlsImages.findById() instead.
            "prototype$__findById__mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsImages.destroyById() instead.
            "prototype$__destroyById__mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsImages.updateById() instead.
            "prototype$__updateById__mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.mlsImages.link() instead.
            "prototype$__link__mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.mlsImages.unlink() instead.
            "prototype$__unlink__mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsImages.exists() instead.
            "prototype$__exists__mlsImages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsImages/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.videoPhotos.findById() instead.
            "prototype$__findById__videoPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoPhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoPhotos.destroyById() instead.
            "prototype$__destroyById__videoPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoPhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoPhotos.updateById() instead.
            "prototype$__updateById__videoPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/videoPhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.caravanPhotos.findById() instead.
            "prototype$__findById__caravanPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanPhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanPhotos.destroyById() instead.
            "prototype$__destroyById__caravanPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanPhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanPhotos.updateById() instead.
            "prototype$__updateById__caravanPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/caravanPhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.featurePhotos.findById() instead.
            "prototype$__findById__featurePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featurePhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.featurePhotos.destroyById() instead.
            "prototype$__destroyById__featurePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featurePhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featurePhotos.updateById() instead.
            "prototype$__updateById__featurePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/featurePhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.websitePhotos.findById() instead.
            "prototype$__findById__websitePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websitePhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.websitePhotos.destroyById() instead.
            "prototype$__destroyById__websitePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websitePhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websitePhotos.updateById() instead.
            "prototype$__updateById__websitePhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/websitePhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.mlsPhotos.findById() instead.
            "prototype$__findById__mlsPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsPhotos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsPhotos.destroyById() instead.
            "prototype$__destroyById__mlsPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsPhotos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsPhotos.updateById() instead.
            "prototype$__updateById__mlsPhotos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/mlsPhotos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.featureSheet() instead.
            "prototype$__get__featureSheet": {
              url: urlBase + "/Listings/:id/featureSheet",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanBulletin() instead.
            "prototype$__get__caravanBulletin": {
              url: urlBase + "/Listings/:id/caravanBulletin",
              method: "GET",
            },

            // INTERNAL. Use Listing.detailedFeatureSheet() instead.
            "prototype$__get__detailedFeatureSheet": {
              url: urlBase + "/Listings/:id/detailedFeatureSheet",
              method: "GET",
            },

            // INTERNAL. Use Listing.notesAbout.findById() instead.
            "prototype$__findById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notesAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.notesAbout.destroyById() instead.
            "prototype$__destroyById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notesAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notesAbout.updateById() instead.
            "prototype$__updateById__notesAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/notesAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.logsAbout.findById() instead.
            "prototype$__findById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/logsAbout/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.logsAbout.destroyById() instead.
            "prototype$__destroyById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/logsAbout/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.logsAbout.updateById() instead.
            "prototype$__updateById__logsAbout": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/logsAbout/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.mainPhoto() instead.
            "prototype$__get__mainPhoto": {
              url: urlBase + "/Listings/:id/mainPhoto",
              method: "GET",
            },

            // INTERNAL. Use Listing.clicks.findById() instead.
            "prototype$__findById__clicks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/clicks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.clicks.destroyById() instead.
            "prototype$__destroyById__clicks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/clicks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.clicks.updateById() instead.
            "prototype$__updateById__clicks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/clicks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.buyers.findById() instead.
            "prototype$__findById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.buyers.destroyById() instead.
            "prototype$__destroyById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.buyers.updateById() instead.
            "prototype$__updateById__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.buyers.link() instead.
            "prototype$__link__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.buyers.unlink() instead.
            "prototype$__unlink__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.buyers.exists() instead.
            "prototype$__exists__buyers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/buyers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.suppliers.findById() instead.
            "prototype$__findById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.suppliers.destroyById() instead.
            "prototype$__destroyById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.suppliers.updateById() instead.
            "prototype$__updateById__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.suppliers.link() instead.
            "prototype$__link__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.suppliers.unlink() instead.
            "prototype$__unlink__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.suppliers.exists() instead.
            "prototype$__exists__suppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/suppliers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.listingSuppliers.findById() instead.
            "prototype$__findById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingSuppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingSuppliers.destroyById() instead.
            "prototype$__destroyById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingSuppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingSuppliers.updateById() instead.
            "prototype$__updateById__listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingSuppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.shared.findById() instead.
            "prototype$__findById__shared": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/shared/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.shared.destroyById() instead.
            "prototype$__destroyById__shared": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/shared/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.shared.updateById() instead.
            "prototype$__updateById__shared": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/shared/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sharedSmartListings.findById() instead.
            "prototype$__findById__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.sharedSmartListings.destroyById() instead.
            "prototype$__destroyById__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sharedSmartListings.updateById() instead.
            "prototype$__updateById__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sharedSmartListings.link() instead.
            "prototype$__link__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.sharedSmartListings.unlink() instead.
            "prototype$__unlink__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sharedSmartListings.exists() instead.
            "prototype$__exists__sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/sharedSmartListings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Listing.visits() instead.
            "prototype$__get__visits": {
              isArray: true,
              url: urlBase + "/Listings/:id/visits",
              method: "GET",
            },

            // INTERNAL. Use Listing.visits.create() instead.
            "prototype$__create__visits": {
              url: urlBase + "/Listings/:id/visits",
              method: "POST",
            },

            // INTERNAL. Use Listing.visits.destroyAll() instead.
            "prototype$__delete__visits": {
              url: urlBase + "/Listings/:id/visits",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.visits.count() instead.
            "prototype$__count__visits": {
              url: urlBase + "/Listings/:id/visits/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.tasks() instead.
            "prototype$__get__tasks": {
              isArray: true,
              url: urlBase + "/Listings/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use Listing.tasks.create() instead.
            "prototype$__create__tasks": {
              url: urlBase + "/Listings/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Listing.tasks.destroyAll() instead.
            "prototype$__delete__tasks": {
              url: urlBase + "/Listings/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.tasks.count() instead.
            "prototype$__count__tasks": {
              url: urlBase + "/Listings/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.notaries() instead.
            "prototype$__get__notaries": {
              isArray: true,
              url: urlBase + "/Listings/:id/notaries",
              method: "GET",
            },

            // INTERNAL. Use Listing.notaries.create() instead.
            "prototype$__create__notaries": {
              url: urlBase + "/Listings/:id/notaries",
              method: "POST",
            },

            // INTERNAL. Use Listing.notaries.destroyAll() instead.
            "prototype$__delete__notaries": {
              url: urlBase + "/Listings/:id/notaries",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notaries.count() instead.
            "prototype$__count__notaries": {
              url: urlBase + "/Listings/:id/notaries/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingNotaries() instead.
            "prototype$__get__listingNotaries": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingNotaries",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingNotaries.create() instead.
            "prototype$__create__listingNotaries": {
              url: urlBase + "/Listings/:id/listingNotaries",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingNotaries.destroyAll() instead.
            "prototype$__delete__listingNotaries": {
              url: urlBase + "/Listings/:id/listingNotaries",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingNotaries.count() instead.
            "prototype$__count__listingNotaries": {
              url: urlBase + "/Listings/:id/listingNotaries/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/Listings/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use Listing.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/Listings/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use Listing.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/Listings/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/Listings/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingBrokers() instead.
            "prototype$__get__listingBrokers": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingBrokers",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingBrokers.create() instead.
            "prototype$__create__listingBrokers": {
              url: urlBase + "/Listings/:id/listingBrokers",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingBrokers.destroyAll() instead.
            "prototype$__delete__listingBrokers": {
              url: urlBase + "/Listings/:id/listingBrokers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingBrokers.count() instead.
            "prototype$__count__listingBrokers": {
              url: urlBase + "/Listings/:id/listingBrokers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.sellers() instead.
            "prototype$__get__sellers": {
              isArray: true,
              url: urlBase + "/Listings/:id/sellers",
              method: "GET",
            },

            // INTERNAL. Use Listing.sellers.create() instead.
            "prototype$__create__sellers": {
              url: urlBase + "/Listings/:id/sellers",
              method: "POST",
            },

            // INTERNAL. Use Listing.sellers.destroyAll() instead.
            "prototype$__delete__sellers": {
              url: urlBase + "/Listings/:id/sellers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sellers.count() instead.
            "prototype$__count__sellers": {
              url: urlBase + "/Listings/:id/sellers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.folders() instead.
            "prototype$__get__folders": {
              isArray: true,
              url: urlBase + "/Listings/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use Listing.folders.create() instead.
            "prototype$__create__folders": {
              url: urlBase + "/Listings/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use Listing.folders.destroyAll() instead.
            "prototype$__delete__folders": {
              url: urlBase + "/Listings/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.folders.count() instead.
            "prototype$__count__folders": {
              url: urlBase + "/Listings/:id/folders/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Listings/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Listing.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Listings/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Listing.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Listings/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Listings/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentTypes() instead.
            "prototype$__get__documentTypes": {
              isArray: true,
              url: urlBase + "/Listings/:id/documentTypes",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentTypes.create() instead.
            "prototype$__create__documentTypes": {
              url: urlBase + "/Listings/:id/documentTypes",
              method: "POST",
            },

            // INTERNAL. Use Listing.documentTypes.destroyAll() instead.
            "prototype$__delete__documentTypes": {
              url: urlBase + "/Listings/:id/documentTypes",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentTypes.count() instead.
            "prototype$__count__documentTypes": {
              url: urlBase + "/Listings/:id/documentTypes/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentMetadata() instead.
            "prototype$__get__documentMetadata": {
              isArray: true,
              url: urlBase + "/Listings/:id/documentMetadata",
              method: "GET",
            },

            // INTERNAL. Use Listing.documentMetadata.create() instead.
            "prototype$__create__documentMetadata": {
              url: urlBase + "/Listings/:id/documentMetadata",
              method: "POST",
            },

            // INTERNAL. Use Listing.documentMetadata.destroyAll() instead.
            "prototype$__delete__documentMetadata": {
              url: urlBase + "/Listings/:id/documentMetadata",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.documentMetadata.count() instead.
            "prototype$__count__documentMetadata": {
              url: urlBase + "/Listings/:id/documentMetadata/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.offers() instead.
            "prototype$__get__offers": {
              isArray: true,
              url: urlBase + "/Listings/:id/offers",
              method: "GET",
            },

            // INTERNAL. Use Listing.offers.create() instead.
            "prototype$__create__offers": {
              url: urlBase + "/Listings/:id/offers",
              method: "POST",
            },

            // INTERNAL. Use Listing.offers.destroyAll() instead.
            "prototype$__delete__offers": {
              url: urlBase + "/Listings/:id/offers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.offers.count() instead.
            "prototype$__count__offers": {
              url: urlBase + "/Listings/:id/offers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoImages() instead.
            "prototype$__get__videoImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/videoImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoImages.create() instead.
            "prototype$__create__videoImages": {
              url: urlBase + "/Listings/:id/videoImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.videoImages.destroyAll() instead.
            "prototype$__delete__videoImages": {
              url: urlBase + "/Listings/:id/videoImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoImages.count() instead.
            "prototype$__count__videoImages": {
              url: urlBase + "/Listings/:id/videoImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanImages() instead.
            "prototype$__get__caravanImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/caravanImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanImages.create() instead.
            "prototype$__create__caravanImages": {
              url: urlBase + "/Listings/:id/caravanImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.caravanImages.destroyAll() instead.
            "prototype$__delete__caravanImages": {
              url: urlBase + "/Listings/:id/caravanImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanImages.count() instead.
            "prototype$__count__caravanImages": {
              url: urlBase + "/Listings/:id/caravanImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.featureImages() instead.
            "prototype$__get__featureImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/featureImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.featureImages.create() instead.
            "prototype$__create__featureImages": {
              url: urlBase + "/Listings/:id/featureImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.featureImages.destroyAll() instead.
            "prototype$__delete__featureImages": {
              url: urlBase + "/Listings/:id/featureImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featureImages.count() instead.
            "prototype$__count__featureImages": {
              url: urlBase + "/Listings/:id/featureImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.websiteImages() instead.
            "prototype$__get__websiteImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/websiteImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.websiteImages.create() instead.
            "prototype$__create__websiteImages": {
              url: urlBase + "/Listings/:id/websiteImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.websiteImages.destroyAll() instead.
            "prototype$__delete__websiteImages": {
              url: urlBase + "/Listings/:id/websiteImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websiteImages.count() instead.
            "prototype$__count__websiteImages": {
              url: urlBase + "/Listings/:id/websiteImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsImages() instead.
            "prototype$__get__mlsImages": {
              isArray: true,
              url: urlBase + "/Listings/:id/mlsImages",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsImages.create() instead.
            "prototype$__create__mlsImages": {
              url: urlBase + "/Listings/:id/mlsImages",
              method: "POST",
            },

            // INTERNAL. Use Listing.mlsImages.destroyAll() instead.
            "prototype$__delete__mlsImages": {
              url: urlBase + "/Listings/:id/mlsImages",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsImages.count() instead.
            "prototype$__count__mlsImages": {
              url: urlBase + "/Listings/:id/mlsImages/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoPhotos() instead.
            "prototype$__get__videoPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/videoPhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.videoPhotos.create() instead.
            "prototype$__create__videoPhotos": {
              url: urlBase + "/Listings/:id/videoPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.videoPhotos.destroyAll() instead.
            "prototype$__delete__videoPhotos": {
              url: urlBase + "/Listings/:id/videoPhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.videoPhotos.count() instead.
            "prototype$__count__videoPhotos": {
              url: urlBase + "/Listings/:id/videoPhotos/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanPhotos() instead.
            "prototype$__get__caravanPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/caravanPhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.caravanPhotos.create() instead.
            "prototype$__create__caravanPhotos": {
              url: urlBase + "/Listings/:id/caravanPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.caravanPhotos.destroyAll() instead.
            "prototype$__delete__caravanPhotos": {
              url: urlBase + "/Listings/:id/caravanPhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.caravanPhotos.count() instead.
            "prototype$__count__caravanPhotos": {
              url: urlBase + "/Listings/:id/caravanPhotos/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.featurePhotos() instead.
            "prototype$__get__featurePhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/featurePhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.featurePhotos.create() instead.
            "prototype$__create__featurePhotos": {
              url: urlBase + "/Listings/:id/featurePhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.featurePhotos.destroyAll() instead.
            "prototype$__delete__featurePhotos": {
              url: urlBase + "/Listings/:id/featurePhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.featurePhotos.count() instead.
            "prototype$__count__featurePhotos": {
              url: urlBase + "/Listings/:id/featurePhotos/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.websitePhotos() instead.
            "prototype$__get__websitePhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/websitePhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.websitePhotos.create() instead.
            "prototype$__create__websitePhotos": {
              url: urlBase + "/Listings/:id/websitePhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.websitePhotos.destroyAll() instead.
            "prototype$__delete__websitePhotos": {
              url: urlBase + "/Listings/:id/websitePhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.websitePhotos.count() instead.
            "prototype$__count__websitePhotos": {
              url: urlBase + "/Listings/:id/websitePhotos/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsPhotos() instead.
            "prototype$__get__mlsPhotos": {
              isArray: true,
              url: urlBase + "/Listings/:id/mlsPhotos",
              method: "GET",
            },

            // INTERNAL. Use Listing.mlsPhotos.create() instead.
            "prototype$__create__mlsPhotos": {
              url: urlBase + "/Listings/:id/mlsPhotos",
              method: "POST",
            },

            // INTERNAL. Use Listing.mlsPhotos.destroyAll() instead.
            "prototype$__delete__mlsPhotos": {
              url: urlBase + "/Listings/:id/mlsPhotos",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.mlsPhotos.count() instead.
            "prototype$__count__mlsPhotos": {
              url: urlBase + "/Listings/:id/mlsPhotos/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.notesAbout() instead.
            "prototype$__get__notesAbout": {
              isArray: true,
              url: urlBase + "/Listings/:id/notesAbout",
              method: "GET",
            },

            // INTERNAL. Use Listing.notesAbout.create() instead.
            "prototype$__create__notesAbout": {
              url: urlBase + "/Listings/:id/notesAbout",
              method: "POST",
            },

            // INTERNAL. Use Listing.notesAbout.destroyAll() instead.
            "prototype$__delete__notesAbout": {
              url: urlBase + "/Listings/:id/notesAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.notesAbout.count() instead.
            "prototype$__count__notesAbout": {
              url: urlBase + "/Listings/:id/notesAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.logsAbout() instead.
            "prototype$__get__logsAbout": {
              isArray: true,
              url: urlBase + "/Listings/:id/logsAbout",
              method: "GET",
            },

            // INTERNAL. Use Listing.logsAbout.create() instead.
            "prototype$__create__logsAbout": {
              url: urlBase + "/Listings/:id/logsAbout",
              method: "POST",
            },

            // INTERNAL. Use Listing.logsAbout.destroyAll() instead.
            "prototype$__delete__logsAbout": {
              url: urlBase + "/Listings/:id/logsAbout",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.logsAbout.count() instead.
            "prototype$__count__logsAbout": {
              url: urlBase + "/Listings/:id/logsAbout/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.clicks() instead.
            "prototype$__get__clicks": {
              isArray: true,
              url: urlBase + "/Listings/:id/clicks",
              method: "GET",
            },

            // INTERNAL. Use Listing.clicks.create() instead.
            "prototype$__create__clicks": {
              url: urlBase + "/Listings/:id/clicks",
              method: "POST",
            },

            // INTERNAL. Use Listing.clicks.destroyAll() instead.
            "prototype$__delete__clicks": {
              url: urlBase + "/Listings/:id/clicks",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.clicks.count() instead.
            "prototype$__count__clicks": {
              url: urlBase + "/Listings/:id/clicks/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.buyers() instead.
            "prototype$__get__buyers": {
              isArray: true,
              url: urlBase + "/Listings/:id/buyers",
              method: "GET",
            },

            // INTERNAL. Use Listing.buyers.create() instead.
            "prototype$__create__buyers": {
              url: urlBase + "/Listings/:id/buyers",
              method: "POST",
            },

            // INTERNAL. Use Listing.buyers.destroyAll() instead.
            "prototype$__delete__buyers": {
              url: urlBase + "/Listings/:id/buyers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.buyers.count() instead.
            "prototype$__count__buyers": {
              url: urlBase + "/Listings/:id/buyers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.suppliers() instead.
            "prototype$__get__suppliers": {
              isArray: true,
              url: urlBase + "/Listings/:id/suppliers",
              method: "GET",
            },

            // INTERNAL. Use Listing.suppliers.create() instead.
            "prototype$__create__suppliers": {
              url: urlBase + "/Listings/:id/suppliers",
              method: "POST",
            },

            // INTERNAL. Use Listing.suppliers.destroyAll() instead.
            "prototype$__delete__suppliers": {
              url: urlBase + "/Listings/:id/suppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.suppliers.count() instead.
            "prototype$__count__suppliers": {
              url: urlBase + "/Listings/:id/suppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingSuppliers() instead.
            "prototype$__get__listingSuppliers": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingSuppliers",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingSuppliers.create() instead.
            "prototype$__create__listingSuppliers": {
              url: urlBase + "/Listings/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingSuppliers.destroyAll() instead.
            "prototype$__delete__listingSuppliers": {
              url: urlBase + "/Listings/:id/listingSuppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingSuppliers.count() instead.
            "prototype$__count__listingSuppliers": {
              url: urlBase + "/Listings/:id/listingSuppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.shared() instead.
            "prototype$__get__shared": {
              isArray: true,
              url: urlBase + "/Listings/:id/shared",
              method: "GET",
            },

            // INTERNAL. Use Listing.shared.create() instead.
            "prototype$__create__shared": {
              url: urlBase + "/Listings/:id/shared",
              method: "POST",
            },

            // INTERNAL. Use Listing.shared.destroyAll() instead.
            "prototype$__delete__shared": {
              url: urlBase + "/Listings/:id/shared",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.shared.count() instead.
            "prototype$__count__shared": {
              url: urlBase + "/Listings/:id/shared/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.sharedSmartListings() instead.
            "prototype$__get__sharedSmartListings": {
              isArray: true,
              url: urlBase + "/Listings/:id/sharedSmartListings",
              method: "GET",
            },

            // INTERNAL. Use Listing.sharedSmartListings.create() instead.
            "prototype$__create__sharedSmartListings": {
              url: urlBase + "/Listings/:id/sharedSmartListings",
              method: "POST",
            },

            // INTERNAL. Use Listing.sharedSmartListings.destroyAll() instead.
            "prototype$__delete__sharedSmartListings": {
              url: urlBase + "/Listings/:id/sharedSmartListings",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.sharedSmartListings.count() instead.
            "prototype$__count__sharedSmartListings": {
              url: urlBase + "/Listings/:id/sharedSmartListings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#create
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Listings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#patchOrCreate
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Listings",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#replaceOrCreate
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Listings/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#upsertWithWhere
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Listings/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#exists
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Listings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#findById
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Listings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#replaceById
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Listings/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#find
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Listings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#findOne
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Listings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#updateAll
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Listings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#deleteById
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Listings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#count
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Listings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#prototype$patchAttributes
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Listings/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#createChangeStream
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Listings/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#updateDetail
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Updates multilingual detail for specific language
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `languageId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `name` – `{string}` -
             *
             *  - `content` – `{string}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Success boolean.
             */
            "updateDetail": {
              url: urlBase + "/Listings/:id/:languageId/updateDetail",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#saveLot
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Sends feature sheet with content and link to video.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `property` – `{object}` -
             *
             *  - `address` – `{object}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Success boolean.
             */
            "saveLot": {
              url: urlBase + "/Listings/:id/saveLot",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#downloadPhotos
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `photoIds` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadPhotos": {
              url: urlBase + "/Listings/:id/downloadPhotos",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#uploadImage
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Uploads an image for smartbrokr
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "uploadImage": {
              url: urlBase + "/Listings/:id/uploadImage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#uploadDocument
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Uploads a document for smartbrokr
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "uploadDocument": {
              url: urlBase + "/Listings/:id/document/:fk/uploadDocument",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#uploadMarketing
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Uploads an image for smartbrokr
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "uploadMarketing": {
              url: urlBase + "/Listings/:id/uploadMarketing",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#uploadResource
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Uploads an image for smartbrokr
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `options` – `{object=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "uploadResource": {
              url: urlBase + "/Listings/:id/uploadResource",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#generateFeatureSheet
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns boolean on whether video was generated
             */
            "generateFeatureSheet": {
              url: urlBase + "/Listings/:id/generate/featureSheet",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#generateCaravanBulletin
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns boolean on whether video was generated
             */
            "generateCaravanBulletin": {
              url: urlBase + "/Listings/:id/generate/caravanBulletin",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#countAll
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Counts WP Plugins clicks
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fk` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` - Number of clicks.
             */
            "countAll": {
              url: urlBase + "/Listings/:id/clicks/:fk/countAll",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#wpFeaturedListing
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Gets the details for a featured listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Listing details.
             */
            "wpFeaturedListing": {
              url: urlBase + "/Listings/:id/wp/featured",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#wpListingDetails
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Gets the details to show in a wordpress plugin page.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `brokerId` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Listing details.
             */
            "wpListingDetails": {
              url: urlBase + "/Listings/:id/wp/details",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#wpGetBasicInfo
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Gets address and seller information about a listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Listing with seller information.
             */
            "wpGetBasicInfo": {
              url: urlBase + "/Listings/:id/wp/basic",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#wpCreateReport
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Endpoint to create a new daily hit stats report.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `report` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * True if operation ran with no errors
             */
            "wpCreateReport": {
              url: urlBase + "/Listings/wp/createDaily",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#getSummary
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Gets public information about a listing
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Basic listing information
             */
            "getSummary": {
              url: urlBase + "/Listings/:id/summary",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#downloadDocument
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Downloads one document file from a listing
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `docId` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `response` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadDocument": {
              url: urlBase + "/Listings/:id/documents/:docId/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#getDocuments
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Gets array of listing documents by folder (includes docusign data)
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Array of documents organized by folder
             */
            "getDocuments": {
              isArray: true,
              url: urlBase + "/Listings/:id/getDocuments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#emailSellerPassword
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Sends the password for accessing listing information to a seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `sellerId` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "emailSellerPassword": {
              url: urlBase + "/Listings/:id/emailSellerPassword",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#getAddress
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Gets listing address
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Listing address
             */
            "getAddress": {
              url: urlBase + "/Listings/:id/getAddress",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#getSellerPassword
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Gets password for a seller
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `sellerId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Access password for seller
             */
            "getSellerPassword": {
              url: urlBase + "/Listings/:id/getSellerAccess",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#exportSuppliers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `lang` – `{string}` -
             *
             *  - `access_token` – `{string}` -
             *
             *  - `response` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "exportSuppliers": {
              url: urlBase + "/Listings/:id/exportSuppliers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#savePhotos
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Saves all listing photos of a type. After saving, updates the listing's main photo.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `type` – `{string}` -
             *
             *  - `data` – `{*}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The updated listing or true
             */
            "savePhotos": {
              url: urlBase + "/Listings/:id/savePhotos",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#exportFolder
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             *  - `ids` – `{*=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "exportFolder": {
              url: urlBase + "/Listings/:id/documentMetadata/:fk/export",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#downloadFolder
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "downloadFolder": {
              url: urlBase + "/Listings/:id/documentMetadata/:fk/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#generateVideo
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns boolean on whether video was generated
             */
            "generateVideo": {
              url: urlBase + "/Listings/:id/generate/video",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#unarchive
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "unarchive": {
              url: urlBase + "/Listings/:id/unarchive",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#archive
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "archive": {
              url: urlBase + "/Listings/:id/archive",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#linkImageWithSequence
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `relation` – `{string=}` -
             *
             *  - `fk` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `secondId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "linkImageWithSequence": {
              url: urlBase + "/Listings/:id/:relation/:fk/seq",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#duplicateToSmartlisting
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "duplicateToSmartlisting": {
              url: urlBase + "/Listings/:id/smart-duplicate",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#reorderSuppliers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `suppliers` – `{*=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "reorderSuppliers": {
              url: urlBase + "/Listings/:id/suppliers/reorder",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#downloadFile
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/Listings/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#downloadFiles
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/Listings/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#convert
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Smart listing ID for conversion
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` - Contains ID of MLS listing that the smart listing data will be moved to
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns converted listing
             */
            "convert": {
              url: urlBase + "/Listings/:id/convert",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Listing#createMany
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Listings",
              method: "POST",
            },

            // INTERNAL. Use Notary.listings.findById() instead.
            "::findById::Notary::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Notary.listings.destroyById() instead.
            "::destroyById::Notary::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Notary.listings.updateById() instead.
            "::updateById::Notary::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Notary.listings.link() instead.
            "::link::Notary::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Notary.listings.unlink() instead.
            "::unlink::Notary::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Notary.listings.exists() instead.
            "::exists::Notary::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Notaries/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Notary.listings() instead.
            "::get::Notary::listings": {
              isArray: true,
              url: urlBase + "/Notaries/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Notary.listings.create() instead.
            "::create::Notary::listings": {
              url: urlBase + "/Notaries/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Notary.listings.createMany() instead.
            "::createMany::Notary::listings": {
              isArray: true,
              url: urlBase + "/Notaries/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Notary.listings.destroyAll() instead.
            "::delete::Notary::listings": {
              url: urlBase + "/Notaries/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Notary.listings.count() instead.
            "::count::Notary::listings": {
              url: urlBase + "/Notaries/:id/listings/count",
              method: "GET",
            },

            // INTERNAL. Use Seller.listings.findById() instead.
            "::findById::Seller::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Seller.listings.destroyById() instead.
            "::destroyById::Seller::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.listings.updateById() instead.
            "::updateById::Seller::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.listings.link() instead.
            "::link::Seller::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Seller.listings.unlink() instead.
            "::unlink::Seller::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.listings.exists() instead.
            "::exists::Seller::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Sellers/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Seller.listings() instead.
            "::get::Seller::listings": {
              isArray: true,
              url: urlBase + "/Sellers/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Seller.listings.create() instead.
            "::create::Seller::listings": {
              url: urlBase + "/Sellers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Seller.listings.createMany() instead.
            "::createMany::Seller::listings": {
              isArray: true,
              url: urlBase + "/Sellers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Seller.listings.destroyAll() instead.
            "::delete::Seller::listings": {
              url: urlBase + "/Sellers/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Seller.listings.count() instead.
            "::count::Seller::listings": {
              url: urlBase + "/Sellers/:id/listings/count",
              method: "GET",
            },

            // INTERNAL. Use Buyer.listings.findById() instead.
            "::findById::Buyer::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.listings.destroyById() instead.
            "::destroyById::Buyer::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.listings.updateById() instead.
            "::updateById::Buyer::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.listings.link() instead.
            "::link::Buyer::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.listings.unlink() instead.
            "::unlink::Buyer::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.listings.exists() instead.
            "::exists::Buyer::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Buyer.listings() instead.
            "::get::Buyer::listings": {
              isArray: true,
              url: urlBase + "/Buyers/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Buyer.listings.create() instead.
            "::create::Buyer::listings": {
              url: urlBase + "/Buyers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Buyer.listings.createMany() instead.
            "::createMany::Buyer::listings": {
              isArray: true,
              url: urlBase + "/Buyers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Buyer.listings.destroyAll() instead.
            "::delete::Buyer::listings": {
              url: urlBase + "/Buyers/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.listings.count() instead.
            "::count::Buyer::listings": {
              url: urlBase + "/Buyers/:id/listings/count",
              method: "GET",
            },

            // INTERNAL. Use Visit.listing() instead.
            "::get::Visit::listing": {
              url: urlBase + "/Visits/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Property.listing() instead.
            "::get::Property::listing": {
              url: urlBase + "/Properties/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Task.listing() instead.
            "::get::Task::listing": {
              url: urlBase + "/Tasks/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use SmartFile.listing() instead.
            "::get::SmartFile::listing": {
              url: urlBase + "/smartFiles/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Folder.listing() instead.
            "::get::Folder::listing": {
              url: urlBase + "/Folders/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingBroker.listing() instead.
            "::get::ListingBroker::listing": {
              url: urlBase + "/ListingBrokers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.listing() instead.
            "::get::ListingSeller::listing": {
              url: urlBase + "/ListingSellers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingNotary.listing() instead.
            "::get::ListingNotary::listing": {
              url: urlBase + "/ListingNotaries/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingVideoImage.listing() instead.
            "::get::ListingVideoImage::listing": {
              url: urlBase + "/ListingVideoImages/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingCaravanImage.listing() instead.
            "::get::ListingCaravanImage::listing": {
              url: urlBase + "/ListingCaravanImages/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingFeatureImage.listing() instead.
            "::get::ListingFeatureImage::listing": {
              url: urlBase + "/ListingFeatureImages/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingWebsiteImage.listing() instead.
            "::get::ListingWebsiteImage::listing": {
              url: urlBase + "/ListingWebsiteImages/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Offer.listing() instead.
            "::get::Offer::listing": {
              url: urlBase + "/Offers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingDocument.listing() instead.
            "::get::ListingDocument::listing": {
              url: urlBase + "/ListingDocuments/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Manager.listings.findById() instead.
            "::findById::Manager::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Manager.listings.destroyById() instead.
            "::destroyById::Manager::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.listings.updateById() instead.
            "::updateById::Manager::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manager.listings.link() instead.
            "::link::Manager::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manager.listings.unlink() instead.
            "::unlink::Manager::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.listings.exists() instead.
            "::exists::Manager::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Managers/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Manager.listings() instead.
            "::get::Manager::listings": {
              isArray: true,
              url: urlBase + "/Managers/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Manager.listings.create() instead.
            "::create::Manager::listings": {
              url: urlBase + "/Managers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Manager.listings.createMany() instead.
            "::createMany::Manager::listings": {
              isArray: true,
              url: urlBase + "/Managers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Manager.listings.destroyAll() instead.
            "::delete::Manager::listings": {
              url: urlBase + "/Managers/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Manager.listings.count() instead.
            "::count::Manager::listings": {
              url: urlBase + "/Managers/:id/listings/count",
              method: "GET",
            },

            // INTERNAL. Use ListingMlsImage.listing() instead.
            "::get::ListingMlsImage::listing": {
              url: urlBase + "/ListingMlsImages/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use Broker.listings.findById() instead.
            "::findById::Broker::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.listings.destroyById() instead.
            "::destroyById::Broker::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.listings.updateById() instead.
            "::updateById::Broker::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.listings.link() instead.
            "::link::Broker::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.listings.unlink() instead.
            "::unlink::Broker::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.listings.exists() instead.
            "::exists::Broker::listings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/listings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.sharedListings.findById() instead.
            "::findById::Broker::sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedListings.destroyById() instead.
            "::destroyById::Broker::sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedListings.updateById() instead.
            "::updateById::Broker::sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedListings.link() instead.
            "::link::Broker::sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedListings.unlink() instead.
            "::unlink::Broker::sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedListings.exists() instead.
            "::exists::Broker::sharedListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedListings/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Broker.listings() instead.
            "::get::Broker::listings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/listings",
              method: "GET",
            },

            // INTERNAL. Use Broker.listings.create() instead.
            "::create::Broker::listings": {
              url: urlBase + "/Brokers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Broker.listings.createMany() instead.
            "::createMany::Broker::listings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/listings",
              method: "POST",
            },

            // INTERNAL. Use Broker.listings.destroyAll() instead.
            "::delete::Broker::listings": {
              url: urlBase + "/Brokers/:id/listings",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.listings.count() instead.
            "::count::Broker::listings": {
              url: urlBase + "/Brokers/:id/listings/count",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedListings() instead.
            "::get::Broker::sharedListings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedListings",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedListings.create() instead.
            "::create::Broker::sharedListings": {
              url: urlBase + "/Brokers/:id/sharedListings",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedListings.createMany() instead.
            "::createMany::Broker::sharedListings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedListings",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedListings.destroyAll() instead.
            "::delete::Broker::sharedListings": {
              url: urlBase + "/Brokers/:id/sharedListings",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedListings.count() instead.
            "::count::Broker::sharedListings": {
              url: urlBase + "/Brokers/:id/sharedListings/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.convertedTo() instead.
            "::get::Listing::convertedTo": {
              url: urlBase + "/Listings/:id/convertedTo",
              method: "GET",
            },

            // INTERNAL. Use ListingClick.listing() instead.
            "::get::ListingClick::listing": {
              url: urlBase + "/ListingClicks/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingBuyer.listing() instead.
            "::get::ListingBuyer::listing": {
              url: urlBase + "/ListingBuyers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingSupplier.listing() instead.
            "::get::ListingSupplier::listing": {
              url: urlBase + "/ListingSuppliers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use SharedSmartListing.listing() instead.
            "::get::SharedSmartListing::listing": {
              url: urlBase + "/SharedSmartListings/:id/listing",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Listing#upsert
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Listing#updateOrCreate
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Listing#patchOrCreateWithWhere
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Listing#update
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Listing#destroyById
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Listing#removeById
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Listing#prototype$updateAttributes
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Listing#modelName
        * @propertyOf lbServices.Listing
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Listing`.
        */
        R.modelName = "Listing";

    /**
     * @ngdoc object
     * @name lbServices.Listing.visits
     * @header lbServices.Listing.visits
     * @object
     * @description
     *
     * The object `Listing.visits` groups methods
     * manipulating `Visit` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#visits Listing.visits()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#visits
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries visits of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::get::Listing::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.visits#count
             * @methodOf lbServices.Listing.visits
             *
             * @description
             *
             * Counts visits of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.visits.count = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::count::Listing::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.visits#create
             * @methodOf lbServices.Listing.visits
             *
             * @description
             *
             * Creates a new instance in visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.create = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::create::Listing::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.visits#createMany
             * @methodOf lbServices.Listing.visits
             *
             * @description
             *
             * Creates a new instance in visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.createMany = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::createMany::Listing::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.visits#destroyAll
             * @methodOf lbServices.Listing.visits
             *
             * @description
             *
             * Deletes all visits of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.destroyAll = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::delete::Listing::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.visits#destroyById
             * @methodOf lbServices.Listing.visits
             *
             * @description
             *
             * Delete a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.visits.destroyById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::destroyById::Listing::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.visits#findById
             * @methodOf lbServices.Listing.visits
             *
             * @description
             *
             * Find a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.findById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::findById::Listing::visits"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.visits#updateById
             * @methodOf lbServices.Listing.visits
             *
             * @description
             *
             * Update a related item by id for visits.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for visits
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visits.updateById = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::updateById::Listing::visits"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.tasks
     * @header lbServices.Listing.tasks
     * @object
     * @description
     *
     * The object `Listing.tasks` groups methods
     * manipulating `Task` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#tasks Listing.tasks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#tasks
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries tasks of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Listing::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.tasks#count
             * @methodOf lbServices.Listing.tasks
             *
             * @description
             *
             * Counts tasks of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasks.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::Listing::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.tasks#create
             * @methodOf lbServices.Listing.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::Listing::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.tasks#createMany
             * @methodOf lbServices.Listing.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::Listing::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.tasks#destroyAll
             * @methodOf lbServices.Listing.tasks
             *
             * @description
             *
             * Deletes all tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::Listing::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.tasks#destroyById
             * @methodOf lbServices.Listing.tasks
             *
             * @description
             *
             * Delete a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::Listing::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.tasks#findById
             * @methodOf lbServices.Listing.tasks
             *
             * @description
             *
             * Find a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::Listing::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.tasks#updateById
             * @methodOf lbServices.Listing.tasks
             *
             * @description
             *
             * Update a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::Listing::tasks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.property
     * @header lbServices.Listing.property
     * @object
     * @description
     *
     * The object `Listing.property` groups methods
     * manipulating `Property` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#property Listing.property()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#property
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Fetches hasOne relation property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::get::Listing::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.property#create
             * @methodOf lbServices.Listing.property
             *
             * @description
             *
             * Creates a new instance in property of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property.create = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::create::Listing::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.property#createMany
             * @methodOf lbServices.Listing.property
             *
             * @description
             *
             * Creates a new instance in property of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property.createMany = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::createMany::Listing::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.property#destroy
             * @methodOf lbServices.Listing.property
             *
             * @description
             *
             * Deletes property of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.property.destroy = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::destroy::Listing::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.property#update
             * @methodOf lbServices.Listing.property
             *
             * @description
             *
             * Update property of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property.update = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::update::Listing::property"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.notaries
     * @header lbServices.Listing.notaries
     * @object
     * @description
     *
     * The object `Listing.notaries` groups methods
     * manipulating `Notary` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#notaries Listing.notaries()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#notaries
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries notaries of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::get::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#count
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Counts notaries of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notaries.count = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::count::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#create
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Creates a new instance in notaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.create = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::create::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#createMany
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Creates a new instance in notaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.createMany = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::createMany::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#destroyAll
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Deletes all notaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notaries.destroyAll = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::delete::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#destroyById
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Delete a related item by id for notaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notaries.destroyById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::destroyById::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#exists
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Check the existence of notaries relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.exists = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::exists::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#findById
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Find a related item by id for notaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.findById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::findById::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#link
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Add a related item by id for notaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.link = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::link::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#unlink
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Remove the notaries relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notaries.unlink = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::unlink::Listing::notaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notaries#updateById
             * @methodOf lbServices.Listing.notaries
             *
             * @description
             *
             * Update a related item by id for notaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for notaries
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notary` object.)
             * </em>
             */
        R.notaries.updateById = function() {
          var TargetResource = $injector.get("Notary");
          var action = TargetResource["::updateById::Listing::notaries"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.listingNotaries
     * @header lbServices.Listing.listingNotaries
     * @object
     * @description
     *
     * The object `Listing.listingNotaries` groups methods
     * manipulating `ListingNotary` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#listingNotaries Listing.listingNotaries()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#listingNotaries
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries listingNotaries of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R.listingNotaries = function() {
          var TargetResource = $injector.get("ListingNotary");
          var action = TargetResource["::get::Listing::listingNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingNotaries#count
             * @methodOf lbServices.Listing.listingNotaries
             *
             * @description
             *
             * Counts listingNotaries of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listingNotaries.count = function() {
          var TargetResource = $injector.get("ListingNotary");
          var action = TargetResource["::count::Listing::listingNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingNotaries#create
             * @methodOf lbServices.Listing.listingNotaries
             *
             * @description
             *
             * Creates a new instance in listingNotaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R.listingNotaries.create = function() {
          var TargetResource = $injector.get("ListingNotary");
          var action = TargetResource["::create::Listing::listingNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingNotaries#createMany
             * @methodOf lbServices.Listing.listingNotaries
             *
             * @description
             *
             * Creates a new instance in listingNotaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R.listingNotaries.createMany = function() {
          var TargetResource = $injector.get("ListingNotary");
          var action = TargetResource["::createMany::Listing::listingNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingNotaries#destroyAll
             * @methodOf lbServices.Listing.listingNotaries
             *
             * @description
             *
             * Deletes all listingNotaries of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingNotaries.destroyAll = function() {
          var TargetResource = $injector.get("ListingNotary");
          var action = TargetResource["::delete::Listing::listingNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingNotaries#destroyById
             * @methodOf lbServices.Listing.listingNotaries
             *
             * @description
             *
             * Delete a related item by id for listingNotaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingNotaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingNotaries.destroyById = function() {
          var TargetResource = $injector.get("ListingNotary");
          var action = TargetResource["::destroyById::Listing::listingNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingNotaries#findById
             * @methodOf lbServices.Listing.listingNotaries
             *
             * @description
             *
             * Find a related item by id for listingNotaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingNotaries
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R.listingNotaries.findById = function() {
          var TargetResource = $injector.get("ListingNotary");
          var action = TargetResource["::findById::Listing::listingNotaries"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingNotaries#updateById
             * @methodOf lbServices.Listing.listingNotaries
             *
             * @description
             *
             * Update a related item by id for listingNotaries.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for listingNotaries
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingNotary` object.)
             * </em>
             */
        R.listingNotaries.updateById = function() {
          var TargetResource = $injector.get("ListingNotary");
          var action = TargetResource["::updateById::Listing::listingNotaries"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.brokers
     * @header lbServices.Listing.brokers
     * @object
     * @description
     *
     * The object `Listing.brokers` groups methods
     * manipulating `Broker` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#brokers Listing.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#brokers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries brokers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#count
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Counts brokers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#create
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#createMany
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#destroyAll
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#destroyById
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#exists
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Check the existence of brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#findById
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#link
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Add a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#unlink
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Remove the brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::Listing::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.brokers#updateById
             * @methodOf lbServices.Listing.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::Listing::brokers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.listingBrokers
     * @header lbServices.Listing.listingBrokers
     * @object
     * @description
     *
     * The object `Listing.listingBrokers` groups methods
     * manipulating `ListingBroker` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#listingBrokers Listing.listingBrokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#listingBrokers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries listingBrokers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R.listingBrokers = function() {
          var TargetResource = $injector.get("ListingBroker");
          var action = TargetResource["::get::Listing::listingBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingBrokers#count
             * @methodOf lbServices.Listing.listingBrokers
             *
             * @description
             *
             * Counts listingBrokers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listingBrokers.count = function() {
          var TargetResource = $injector.get("ListingBroker");
          var action = TargetResource["::count::Listing::listingBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingBrokers#create
             * @methodOf lbServices.Listing.listingBrokers
             *
             * @description
             *
             * Creates a new instance in listingBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R.listingBrokers.create = function() {
          var TargetResource = $injector.get("ListingBroker");
          var action = TargetResource["::create::Listing::listingBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingBrokers#createMany
             * @methodOf lbServices.Listing.listingBrokers
             *
             * @description
             *
             * Creates a new instance in listingBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R.listingBrokers.createMany = function() {
          var TargetResource = $injector.get("ListingBroker");
          var action = TargetResource["::createMany::Listing::listingBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingBrokers#destroyAll
             * @methodOf lbServices.Listing.listingBrokers
             *
             * @description
             *
             * Deletes all listingBrokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingBrokers.destroyAll = function() {
          var TargetResource = $injector.get("ListingBroker");
          var action = TargetResource["::delete::Listing::listingBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingBrokers#destroyById
             * @methodOf lbServices.Listing.listingBrokers
             *
             * @description
             *
             * Delete a related item by id for listingBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingBrokers.destroyById = function() {
          var TargetResource = $injector.get("ListingBroker");
          var action = TargetResource["::destroyById::Listing::listingBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingBrokers#findById
             * @methodOf lbServices.Listing.listingBrokers
             *
             * @description
             *
             * Find a related item by id for listingBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingBrokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R.listingBrokers.findById = function() {
          var TargetResource = $injector.get("ListingBroker");
          var action = TargetResource["::findById::Listing::listingBrokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingBrokers#updateById
             * @methodOf lbServices.Listing.listingBrokers
             *
             * @description
             *
             * Update a related item by id for listingBrokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for listingBrokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBroker` object.)
             * </em>
             */
        R.listingBrokers.updateById = function() {
          var TargetResource = $injector.get("ListingBroker");
          var action = TargetResource["::updateById::Listing::listingBrokers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.sellers
     * @header lbServices.Listing.sellers
     * @object
     * @description
     *
     * The object `Listing.sellers` groups methods
     * manipulating `Seller` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#sellers Listing.sellers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#sellers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries sellers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::get::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#count
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Counts sellers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sellers.count = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::count::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#create
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Creates a new instance in sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.create = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::create::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#createMany
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Creates a new instance in sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.createMany = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::createMany::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#destroyAll
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Deletes all sellers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.destroyAll = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::delete::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#destroyById
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Delete a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.destroyById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::destroyById::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#exists
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Check the existence of sellers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.exists = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::exists::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#findById
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Find a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.findById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::findById::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#link
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Add a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.link = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::link::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#unlink
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Remove the sellers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sellers.unlink = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::unlink::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sellers#updateById
             * @methodOf lbServices.Listing.sellers
             *
             * @description
             *
             * Update a related item by id for sellers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for sellers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.sellers.updateById = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::updateById::Listing::sellers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing#convertedTo
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Fetches belongsTo relation convertedTo.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.convertedTo = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::Listing::convertedTo"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.folders
     * @header lbServices.Listing.folders
     * @object
     * @description
     *
     * The object `Listing.folders` groups methods
     * manipulating `Folder` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#folders Listing.folders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#folders
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries folders of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::Listing::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.folders#count
             * @methodOf lbServices.Listing.folders
             *
             * @description
             *
             * Counts folders of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.folders.count = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::count::Listing::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.folders#create
             * @methodOf lbServices.Listing.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::Listing::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.folders#createMany
             * @methodOf lbServices.Listing.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::Listing::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.folders#destroyAll
             * @methodOf lbServices.Listing.folders
             *
             * @description
             *
             * Deletes all folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyAll = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::delete::Listing::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.folders#destroyById
             * @methodOf lbServices.Listing.folders
             *
             * @description
             *
             * Delete a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroyById::Listing::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.folders#findById
             * @methodOf lbServices.Listing.folders
             *
             * @description
             *
             * Find a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.findById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::findById::Listing::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.folders#updateById
             * @methodOf lbServices.Listing.folders
             *
             * @description
             *
             * Update a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.updateById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::updateById::Listing::folders"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.files
     * @header lbServices.Listing.files
     * @object
     * @description
     *
     * The object `Listing.files` groups methods
     * manipulating `SmartFile` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#files Listing.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#files
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries files of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.files#count
             * @methodOf lbServices.Listing.files
             *
             * @description
             *
             * Counts files of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Listing::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.files#create
             * @methodOf lbServices.Listing.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Listing::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.files#createMany
             * @methodOf lbServices.Listing.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Listing::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.files#destroyAll
             * @methodOf lbServices.Listing.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Listing::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.files#destroyById
             * @methodOf lbServices.Listing.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Listing::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.files#findById
             * @methodOf lbServices.Listing.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Listing::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.files#updateById
             * @methodOf lbServices.Listing.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Listing::files"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.documentTypes
     * @header lbServices.Listing.documentTypes
     * @object
     * @description
     *
     * The object `Listing.documentTypes` groups methods
     * manipulating `Code` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#documentTypes Listing.documentTypes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#documentTypes
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries documentTypes of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.documentTypes = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#count
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Counts documentTypes of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.documentTypes.count = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::count::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#create
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Creates a new instance in documentTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.documentTypes.create = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::create::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#createMany
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Creates a new instance in documentTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.documentTypes.createMany = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::createMany::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#destroyAll
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Deletes all documentTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documentTypes.destroyAll = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::delete::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#destroyById
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Delete a related item by id for documentTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documentTypes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documentTypes.destroyById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::destroyById::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#exists
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Check the existence of documentTypes relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documentTypes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.documentTypes.exists = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::exists::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#findById
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Find a related item by id for documentTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documentTypes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.documentTypes.findById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::findById::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#link
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Add a related item by id for documentTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for documentTypes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.documentTypes.link = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::link::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#unlink
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Remove the documentTypes relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documentTypes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documentTypes.unlink = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::unlink::Listing::documentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentTypes#updateById
             * @methodOf lbServices.Listing.documentTypes
             *
             * @description
             *
             * Update a related item by id for documentTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for documentTypes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.documentTypes.updateById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::updateById::Listing::documentTypes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.documentMetadata
     * @header lbServices.Listing.documentMetadata
     * @object
     * @description
     *
     * The object `Listing.documentMetadata` groups methods
     * manipulating `ListingDocument` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#documentMetadata Listing.documentMetadata()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#documentMetadata
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries documentMetadata of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R.documentMetadata = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::get::Listing::documentMetadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentMetadata#count
             * @methodOf lbServices.Listing.documentMetadata
             *
             * @description
             *
             * Counts documentMetadata of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.documentMetadata.count = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::count::Listing::documentMetadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentMetadata#create
             * @methodOf lbServices.Listing.documentMetadata
             *
             * @description
             *
             * Creates a new instance in documentMetadata of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R.documentMetadata.create = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::create::Listing::documentMetadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentMetadata#createMany
             * @methodOf lbServices.Listing.documentMetadata
             *
             * @description
             *
             * Creates a new instance in documentMetadata of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R.documentMetadata.createMany = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::createMany::Listing::documentMetadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentMetadata#destroyAll
             * @methodOf lbServices.Listing.documentMetadata
             *
             * @description
             *
             * Deletes all documentMetadata of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documentMetadata.destroyAll = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::delete::Listing::documentMetadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentMetadata#destroyById
             * @methodOf lbServices.Listing.documentMetadata
             *
             * @description
             *
             * Delete a related item by id for documentMetadata.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documentMetadata
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documentMetadata.destroyById = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::destroyById::Listing::documentMetadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentMetadata#findById
             * @methodOf lbServices.Listing.documentMetadata
             *
             * @description
             *
             * Find a related item by id for documentMetadata.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documentMetadata
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R.documentMetadata.findById = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::findById::Listing::documentMetadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.documentMetadata#updateById
             * @methodOf lbServices.Listing.documentMetadata
             *
             * @description
             *
             * Update a related item by id for documentMetadata.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for documentMetadata
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingDocument` object.)
             * </em>
             */
        R.documentMetadata.updateById = function() {
          var TargetResource = $injector.get("ListingDocument");
          var action = TargetResource["::updateById::Listing::documentMetadata"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.offers
     * @header lbServices.Listing.offers
     * @object
     * @description
     *
     * The object `Listing.offers` groups methods
     * manipulating `Offer` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#offers Listing.offers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#offers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries offers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R.offers = function() {
          var TargetResource = $injector.get("Offer");
          var action = TargetResource["::get::Listing::offers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.offers#count
             * @methodOf lbServices.Listing.offers
             *
             * @description
             *
             * Counts offers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.offers.count = function() {
          var TargetResource = $injector.get("Offer");
          var action = TargetResource["::count::Listing::offers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.offers#create
             * @methodOf lbServices.Listing.offers
             *
             * @description
             *
             * Creates a new instance in offers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R.offers.create = function() {
          var TargetResource = $injector.get("Offer");
          var action = TargetResource["::create::Listing::offers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.offers#createMany
             * @methodOf lbServices.Listing.offers
             *
             * @description
             *
             * Creates a new instance in offers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R.offers.createMany = function() {
          var TargetResource = $injector.get("Offer");
          var action = TargetResource["::createMany::Listing::offers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.offers#destroyAll
             * @methodOf lbServices.Listing.offers
             *
             * @description
             *
             * Deletes all offers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.offers.destroyAll = function() {
          var TargetResource = $injector.get("Offer");
          var action = TargetResource["::delete::Listing::offers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.offers#destroyById
             * @methodOf lbServices.Listing.offers
             *
             * @description
             *
             * Delete a related item by id for offers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for offers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.offers.destroyById = function() {
          var TargetResource = $injector.get("Offer");
          var action = TargetResource["::destroyById::Listing::offers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.offers#findById
             * @methodOf lbServices.Listing.offers
             *
             * @description
             *
             * Find a related item by id for offers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for offers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R.offers.findById = function() {
          var TargetResource = $injector.get("Offer");
          var action = TargetResource["::findById::Listing::offers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.offers#updateById
             * @methodOf lbServices.Listing.offers
             *
             * @description
             *
             * Update a related item by id for offers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for offers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Offer` object.)
             * </em>
             */
        R.offers.updateById = function() {
          var TargetResource = $injector.get("Offer");
          var action = TargetResource["::updateById::Listing::offers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.videoImages
     * @header lbServices.Listing.videoImages
     * @object
     * @description
     *
     * The object `Listing.videoImages` groups methods
     * manipulating `SmartFile` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#videoImages Listing.videoImages()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#videoImages
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries videoImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.videoImages = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#count
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Counts videoImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.videoImages.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#create
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Creates a new instance in videoImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.videoImages.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#createMany
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Creates a new instance in videoImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.videoImages.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#destroyAll
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Deletes all videoImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.videoImages.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#destroyById
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Delete a related item by id for videoImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for videoImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.videoImages.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#exists
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Check the existence of videoImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for videoImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.videoImages.exists = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::exists::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#findById
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Find a related item by id for videoImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for videoImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.videoImages.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#link
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Add a related item by id for videoImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for videoImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.videoImages.link = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::link::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#unlink
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Remove the videoImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for videoImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.videoImages.unlink = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::unlink::Listing::videoImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoImages#updateById
             * @methodOf lbServices.Listing.videoImages
             *
             * @description
             *
             * Update a related item by id for videoImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for videoImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.videoImages.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Listing::videoImages"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.caravanImages
     * @header lbServices.Listing.caravanImages
     * @object
     * @description
     *
     * The object `Listing.caravanImages` groups methods
     * manipulating `SmartFile` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#caravanImages Listing.caravanImages()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#caravanImages
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries caravanImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.caravanImages = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#count
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Counts caravanImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.caravanImages.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#create
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Creates a new instance in caravanImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.caravanImages.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#createMany
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Creates a new instance in caravanImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.caravanImages.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#destroyAll
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Deletes all caravanImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.caravanImages.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#destroyById
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Delete a related item by id for caravanImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for caravanImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.caravanImages.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#exists
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Check the existence of caravanImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for caravanImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.caravanImages.exists = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::exists::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#findById
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Find a related item by id for caravanImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for caravanImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.caravanImages.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#link
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Add a related item by id for caravanImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for caravanImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.caravanImages.link = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::link::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#unlink
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Remove the caravanImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for caravanImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.caravanImages.unlink = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::unlink::Listing::caravanImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanImages#updateById
             * @methodOf lbServices.Listing.caravanImages
             *
             * @description
             *
             * Update a related item by id for caravanImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for caravanImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.caravanImages.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Listing::caravanImages"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.featureImages
     * @header lbServices.Listing.featureImages
     * @object
     * @description
     *
     * The object `Listing.featureImages` groups methods
     * manipulating `SmartFile` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#featureImages Listing.featureImages()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#featureImages
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries featureImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.featureImages = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#count
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Counts featureImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.featureImages.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#create
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Creates a new instance in featureImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.featureImages.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#createMany
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Creates a new instance in featureImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.featureImages.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#destroyAll
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Deletes all featureImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.featureImages.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#destroyById
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Delete a related item by id for featureImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for featureImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.featureImages.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#exists
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Check the existence of featureImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for featureImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.featureImages.exists = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::exists::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#findById
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Find a related item by id for featureImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for featureImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.featureImages.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#link
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Add a related item by id for featureImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for featureImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.featureImages.link = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::link::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#unlink
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Remove the featureImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for featureImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.featureImages.unlink = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::unlink::Listing::featureImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featureImages#updateById
             * @methodOf lbServices.Listing.featureImages
             *
             * @description
             *
             * Update a related item by id for featureImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for featureImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.featureImages.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Listing::featureImages"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.websiteImages
     * @header lbServices.Listing.websiteImages
     * @object
     * @description
     *
     * The object `Listing.websiteImages` groups methods
     * manipulating `SmartFile` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#websiteImages Listing.websiteImages()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#websiteImages
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries websiteImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.websiteImages = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#count
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Counts websiteImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.websiteImages.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#create
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Creates a new instance in websiteImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.websiteImages.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#createMany
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Creates a new instance in websiteImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.websiteImages.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#destroyAll
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Deletes all websiteImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.websiteImages.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#destroyById
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Delete a related item by id for websiteImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for websiteImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.websiteImages.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#exists
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Check the existence of websiteImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for websiteImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.websiteImages.exists = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::exists::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#findById
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Find a related item by id for websiteImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for websiteImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.websiteImages.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#link
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Add a related item by id for websiteImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for websiteImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.websiteImages.link = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::link::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#unlink
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Remove the websiteImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for websiteImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.websiteImages.unlink = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::unlink::Listing::websiteImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websiteImages#updateById
             * @methodOf lbServices.Listing.websiteImages
             *
             * @description
             *
             * Update a related item by id for websiteImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for websiteImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.websiteImages.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Listing::websiteImages"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.mlsImages
     * @header lbServices.Listing.mlsImages
     * @object
     * @description
     *
     * The object `Listing.mlsImages` groups methods
     * manipulating `SmartFile` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#mlsImages Listing.mlsImages()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#mlsImages
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries mlsImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.mlsImages = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#count
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Counts mlsImages of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.mlsImages.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#create
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Creates a new instance in mlsImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.mlsImages.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#createMany
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Creates a new instance in mlsImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.mlsImages.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#destroyAll
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Deletes all mlsImages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.mlsImages.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#destroyById
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Delete a related item by id for mlsImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for mlsImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.mlsImages.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#exists
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Check the existence of mlsImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for mlsImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.mlsImages.exists = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::exists::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#findById
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Find a related item by id for mlsImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for mlsImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.mlsImages.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#link
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Add a related item by id for mlsImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for mlsImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.mlsImages.link = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::link::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#unlink
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Remove the mlsImages relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for mlsImages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.mlsImages.unlink = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::unlink::Listing::mlsImages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsImages#updateById
             * @methodOf lbServices.Listing.mlsImages
             *
             * @description
             *
             * Update a related item by id for mlsImages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for mlsImages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.mlsImages.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Listing::mlsImages"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.videoPhotos
     * @header lbServices.Listing.videoPhotos
     * @object
     * @description
     *
     * The object `Listing.videoPhotos` groups methods
     * manipulating `ListingVideoImage` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#videoPhotos Listing.videoPhotos()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#videoPhotos
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries videoPhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R.videoPhotos = function() {
          var TargetResource = $injector.get("ListingVideoImage");
          var action = TargetResource["::get::Listing::videoPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoPhotos#count
             * @methodOf lbServices.Listing.videoPhotos
             *
             * @description
             *
             * Counts videoPhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.videoPhotos.count = function() {
          var TargetResource = $injector.get("ListingVideoImage");
          var action = TargetResource["::count::Listing::videoPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoPhotos#create
             * @methodOf lbServices.Listing.videoPhotos
             *
             * @description
             *
             * Creates a new instance in videoPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R.videoPhotos.create = function() {
          var TargetResource = $injector.get("ListingVideoImage");
          var action = TargetResource["::create::Listing::videoPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoPhotos#createMany
             * @methodOf lbServices.Listing.videoPhotos
             *
             * @description
             *
             * Creates a new instance in videoPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R.videoPhotos.createMany = function() {
          var TargetResource = $injector.get("ListingVideoImage");
          var action = TargetResource["::createMany::Listing::videoPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoPhotos#destroyAll
             * @methodOf lbServices.Listing.videoPhotos
             *
             * @description
             *
             * Deletes all videoPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.videoPhotos.destroyAll = function() {
          var TargetResource = $injector.get("ListingVideoImage");
          var action = TargetResource["::delete::Listing::videoPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoPhotos#destroyById
             * @methodOf lbServices.Listing.videoPhotos
             *
             * @description
             *
             * Delete a related item by id for videoPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for videoPhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.videoPhotos.destroyById = function() {
          var TargetResource = $injector.get("ListingVideoImage");
          var action = TargetResource["::destroyById::Listing::videoPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoPhotos#findById
             * @methodOf lbServices.Listing.videoPhotos
             *
             * @description
             *
             * Find a related item by id for videoPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for videoPhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R.videoPhotos.findById = function() {
          var TargetResource = $injector.get("ListingVideoImage");
          var action = TargetResource["::findById::Listing::videoPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.videoPhotos#updateById
             * @methodOf lbServices.Listing.videoPhotos
             *
             * @description
             *
             * Update a related item by id for videoPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for videoPhotos
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingVideoImage` object.)
             * </em>
             */
        R.videoPhotos.updateById = function() {
          var TargetResource = $injector.get("ListingVideoImage");
          var action = TargetResource["::updateById::Listing::videoPhotos"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.caravanPhotos
     * @header lbServices.Listing.caravanPhotos
     * @object
     * @description
     *
     * The object `Listing.caravanPhotos` groups methods
     * manipulating `ListingCaravanImage` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#caravanPhotos Listing.caravanPhotos()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#caravanPhotos
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries caravanPhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R.caravanPhotos = function() {
          var TargetResource = $injector.get("ListingCaravanImage");
          var action = TargetResource["::get::Listing::caravanPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanPhotos#count
             * @methodOf lbServices.Listing.caravanPhotos
             *
             * @description
             *
             * Counts caravanPhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.caravanPhotos.count = function() {
          var TargetResource = $injector.get("ListingCaravanImage");
          var action = TargetResource["::count::Listing::caravanPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanPhotos#create
             * @methodOf lbServices.Listing.caravanPhotos
             *
             * @description
             *
             * Creates a new instance in caravanPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R.caravanPhotos.create = function() {
          var TargetResource = $injector.get("ListingCaravanImage");
          var action = TargetResource["::create::Listing::caravanPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanPhotos#createMany
             * @methodOf lbServices.Listing.caravanPhotos
             *
             * @description
             *
             * Creates a new instance in caravanPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R.caravanPhotos.createMany = function() {
          var TargetResource = $injector.get("ListingCaravanImage");
          var action = TargetResource["::createMany::Listing::caravanPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanPhotos#destroyAll
             * @methodOf lbServices.Listing.caravanPhotos
             *
             * @description
             *
             * Deletes all caravanPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.caravanPhotos.destroyAll = function() {
          var TargetResource = $injector.get("ListingCaravanImage");
          var action = TargetResource["::delete::Listing::caravanPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanPhotos#destroyById
             * @methodOf lbServices.Listing.caravanPhotos
             *
             * @description
             *
             * Delete a related item by id for caravanPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for caravanPhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.caravanPhotos.destroyById = function() {
          var TargetResource = $injector.get("ListingCaravanImage");
          var action = TargetResource["::destroyById::Listing::caravanPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanPhotos#findById
             * @methodOf lbServices.Listing.caravanPhotos
             *
             * @description
             *
             * Find a related item by id for caravanPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for caravanPhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R.caravanPhotos.findById = function() {
          var TargetResource = $injector.get("ListingCaravanImage");
          var action = TargetResource["::findById::Listing::caravanPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.caravanPhotos#updateById
             * @methodOf lbServices.Listing.caravanPhotos
             *
             * @description
             *
             * Update a related item by id for caravanPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for caravanPhotos
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingCaravanImage` object.)
             * </em>
             */
        R.caravanPhotos.updateById = function() {
          var TargetResource = $injector.get("ListingCaravanImage");
          var action = TargetResource["::updateById::Listing::caravanPhotos"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.featurePhotos
     * @header lbServices.Listing.featurePhotos
     * @object
     * @description
     *
     * The object `Listing.featurePhotos` groups methods
     * manipulating `ListingFeatureImage` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#featurePhotos Listing.featurePhotos()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#featurePhotos
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries featurePhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R.featurePhotos = function() {
          var TargetResource = $injector.get("ListingFeatureImage");
          var action = TargetResource["::get::Listing::featurePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featurePhotos#count
             * @methodOf lbServices.Listing.featurePhotos
             *
             * @description
             *
             * Counts featurePhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.featurePhotos.count = function() {
          var TargetResource = $injector.get("ListingFeatureImage");
          var action = TargetResource["::count::Listing::featurePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featurePhotos#create
             * @methodOf lbServices.Listing.featurePhotos
             *
             * @description
             *
             * Creates a new instance in featurePhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R.featurePhotos.create = function() {
          var TargetResource = $injector.get("ListingFeatureImage");
          var action = TargetResource["::create::Listing::featurePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featurePhotos#createMany
             * @methodOf lbServices.Listing.featurePhotos
             *
             * @description
             *
             * Creates a new instance in featurePhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R.featurePhotos.createMany = function() {
          var TargetResource = $injector.get("ListingFeatureImage");
          var action = TargetResource["::createMany::Listing::featurePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featurePhotos#destroyAll
             * @methodOf lbServices.Listing.featurePhotos
             *
             * @description
             *
             * Deletes all featurePhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.featurePhotos.destroyAll = function() {
          var TargetResource = $injector.get("ListingFeatureImage");
          var action = TargetResource["::delete::Listing::featurePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featurePhotos#destroyById
             * @methodOf lbServices.Listing.featurePhotos
             *
             * @description
             *
             * Delete a related item by id for featurePhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for featurePhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.featurePhotos.destroyById = function() {
          var TargetResource = $injector.get("ListingFeatureImage");
          var action = TargetResource["::destroyById::Listing::featurePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featurePhotos#findById
             * @methodOf lbServices.Listing.featurePhotos
             *
             * @description
             *
             * Find a related item by id for featurePhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for featurePhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R.featurePhotos.findById = function() {
          var TargetResource = $injector.get("ListingFeatureImage");
          var action = TargetResource["::findById::Listing::featurePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.featurePhotos#updateById
             * @methodOf lbServices.Listing.featurePhotos
             *
             * @description
             *
             * Update a related item by id for featurePhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for featurePhotos
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingFeatureImage` object.)
             * </em>
             */
        R.featurePhotos.updateById = function() {
          var TargetResource = $injector.get("ListingFeatureImage");
          var action = TargetResource["::updateById::Listing::featurePhotos"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.websitePhotos
     * @header lbServices.Listing.websitePhotos
     * @object
     * @description
     *
     * The object `Listing.websitePhotos` groups methods
     * manipulating `ListingWebsiteImage` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#websitePhotos Listing.websitePhotos()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#websitePhotos
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries websitePhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R.websitePhotos = function() {
          var TargetResource = $injector.get("ListingWebsiteImage");
          var action = TargetResource["::get::Listing::websitePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websitePhotos#count
             * @methodOf lbServices.Listing.websitePhotos
             *
             * @description
             *
             * Counts websitePhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.websitePhotos.count = function() {
          var TargetResource = $injector.get("ListingWebsiteImage");
          var action = TargetResource["::count::Listing::websitePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websitePhotos#create
             * @methodOf lbServices.Listing.websitePhotos
             *
             * @description
             *
             * Creates a new instance in websitePhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R.websitePhotos.create = function() {
          var TargetResource = $injector.get("ListingWebsiteImage");
          var action = TargetResource["::create::Listing::websitePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websitePhotos#createMany
             * @methodOf lbServices.Listing.websitePhotos
             *
             * @description
             *
             * Creates a new instance in websitePhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R.websitePhotos.createMany = function() {
          var TargetResource = $injector.get("ListingWebsiteImage");
          var action = TargetResource["::createMany::Listing::websitePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websitePhotos#destroyAll
             * @methodOf lbServices.Listing.websitePhotos
             *
             * @description
             *
             * Deletes all websitePhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.websitePhotos.destroyAll = function() {
          var TargetResource = $injector.get("ListingWebsiteImage");
          var action = TargetResource["::delete::Listing::websitePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websitePhotos#destroyById
             * @methodOf lbServices.Listing.websitePhotos
             *
             * @description
             *
             * Delete a related item by id for websitePhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for websitePhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.websitePhotos.destroyById = function() {
          var TargetResource = $injector.get("ListingWebsiteImage");
          var action = TargetResource["::destroyById::Listing::websitePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websitePhotos#findById
             * @methodOf lbServices.Listing.websitePhotos
             *
             * @description
             *
             * Find a related item by id for websitePhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for websitePhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R.websitePhotos.findById = function() {
          var TargetResource = $injector.get("ListingWebsiteImage");
          var action = TargetResource["::findById::Listing::websitePhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.websitePhotos#updateById
             * @methodOf lbServices.Listing.websitePhotos
             *
             * @description
             *
             * Update a related item by id for websitePhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for websitePhotos
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingWebsiteImage` object.)
             * </em>
             */
        R.websitePhotos.updateById = function() {
          var TargetResource = $injector.get("ListingWebsiteImage");
          var action = TargetResource["::updateById::Listing::websitePhotos"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.mlsPhotos
     * @header lbServices.Listing.mlsPhotos
     * @object
     * @description
     *
     * The object `Listing.mlsPhotos` groups methods
     * manipulating `ListingMlsImage` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#mlsPhotos Listing.mlsPhotos()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#mlsPhotos
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries mlsPhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R.mlsPhotos = function() {
          var TargetResource = $injector.get("ListingMlsImage");
          var action = TargetResource["::get::Listing::mlsPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsPhotos#count
             * @methodOf lbServices.Listing.mlsPhotos
             *
             * @description
             *
             * Counts mlsPhotos of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.mlsPhotos.count = function() {
          var TargetResource = $injector.get("ListingMlsImage");
          var action = TargetResource["::count::Listing::mlsPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsPhotos#create
             * @methodOf lbServices.Listing.mlsPhotos
             *
             * @description
             *
             * Creates a new instance in mlsPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R.mlsPhotos.create = function() {
          var TargetResource = $injector.get("ListingMlsImage");
          var action = TargetResource["::create::Listing::mlsPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsPhotos#createMany
             * @methodOf lbServices.Listing.mlsPhotos
             *
             * @description
             *
             * Creates a new instance in mlsPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R.mlsPhotos.createMany = function() {
          var TargetResource = $injector.get("ListingMlsImage");
          var action = TargetResource["::createMany::Listing::mlsPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsPhotos#destroyAll
             * @methodOf lbServices.Listing.mlsPhotos
             *
             * @description
             *
             * Deletes all mlsPhotos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.mlsPhotos.destroyAll = function() {
          var TargetResource = $injector.get("ListingMlsImage");
          var action = TargetResource["::delete::Listing::mlsPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsPhotos#destroyById
             * @methodOf lbServices.Listing.mlsPhotos
             *
             * @description
             *
             * Delete a related item by id for mlsPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for mlsPhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.mlsPhotos.destroyById = function() {
          var TargetResource = $injector.get("ListingMlsImage");
          var action = TargetResource["::destroyById::Listing::mlsPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsPhotos#findById
             * @methodOf lbServices.Listing.mlsPhotos
             *
             * @description
             *
             * Find a related item by id for mlsPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for mlsPhotos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R.mlsPhotos.findById = function() {
          var TargetResource = $injector.get("ListingMlsImage");
          var action = TargetResource["::findById::Listing::mlsPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.mlsPhotos#updateById
             * @methodOf lbServices.Listing.mlsPhotos
             *
             * @description
             *
             * Update a related item by id for mlsPhotos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for mlsPhotos
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingMlsImage` object.)
             * </em>
             */
        R.mlsPhotos.updateById = function() {
          var TargetResource = $injector.get("ListingMlsImage");
          var action = TargetResource["::updateById::Listing::mlsPhotos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing#featureSheet
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Fetches belongsTo relation featureSheet.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.featureSheet = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::featureSheet"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing#caravanBulletin
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Fetches belongsTo relation caravanBulletin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.caravanBulletin = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::caravanBulletin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing#detailedFeatureSheet
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Fetches belongsTo relation detailedFeatureSheet.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.detailedFeatureSheet = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::detailedFeatureSheet"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.notesAbout
     * @header lbServices.Listing.notesAbout
     * @object
     * @description
     *
     * The object `Listing.notesAbout` groups methods
     * manipulating `Note` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#notesAbout Listing.notesAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#notesAbout
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries notesAbout of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::get::Listing::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notesAbout#count
             * @methodOf lbServices.Listing.notesAbout
             *
             * @description
             *
             * Counts notesAbout of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notesAbout.count = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::count::Listing::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notesAbout#create
             * @methodOf lbServices.Listing.notesAbout
             *
             * @description
             *
             * Creates a new instance in notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.create = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::create::Listing::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notesAbout#createMany
             * @methodOf lbServices.Listing.notesAbout
             *
             * @description
             *
             * Creates a new instance in notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.createMany = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::createMany::Listing::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notesAbout#destroyAll
             * @methodOf lbServices.Listing.notesAbout
             *
             * @description
             *
             * Deletes all notesAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notesAbout.destroyAll = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::delete::Listing::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notesAbout#destroyById
             * @methodOf lbServices.Listing.notesAbout
             *
             * @description
             *
             * Delete a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notesAbout.destroyById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::destroyById::Listing::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notesAbout#findById
             * @methodOf lbServices.Listing.notesAbout
             *
             * @description
             *
             * Find a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.findById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::findById::Listing::notesAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.notesAbout#updateById
             * @methodOf lbServices.Listing.notesAbout
             *
             * @description
             *
             * Update a related item by id for notesAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for notesAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notesAbout.updateById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::updateById::Listing::notesAbout"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.logsAbout
     * @header lbServices.Listing.logsAbout
     * @object
     * @description
     *
     * The object `Listing.logsAbout` groups methods
     * manipulating `Log` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#logsAbout Listing.logsAbout()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#logsAbout
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries logsAbout of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::get::Listing::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.logsAbout#count
             * @methodOf lbServices.Listing.logsAbout
             *
             * @description
             *
             * Counts logsAbout of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.logsAbout.count = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::count::Listing::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.logsAbout#create
             * @methodOf lbServices.Listing.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.create = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::create::Listing::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.logsAbout#createMany
             * @methodOf lbServices.Listing.logsAbout
             *
             * @description
             *
             * Creates a new instance in logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.createMany = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::createMany::Listing::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.logsAbout#destroyAll
             * @methodOf lbServices.Listing.logsAbout
             *
             * @description
             *
             * Deletes all logsAbout of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyAll = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::delete::Listing::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.logsAbout#destroyById
             * @methodOf lbServices.Listing.logsAbout
             *
             * @description
             *
             * Delete a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.logsAbout.destroyById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::destroyById::Listing::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.logsAbout#findById
             * @methodOf lbServices.Listing.logsAbout
             *
             * @description
             *
             * Find a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.findById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::findById::Listing::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.logsAbout#updateById
             * @methodOf lbServices.Listing.logsAbout
             *
             * @description
             *
             * Update a related item by id for logsAbout.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for logsAbout
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Log` object.)
             * </em>
             */
        R.logsAbout.updateById = function() {
          var TargetResource = $injector.get("Log");
          var action = TargetResource["::updateById::Listing::logsAbout"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing#mainPhoto
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Fetches belongsTo relation mainPhoto.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.mainPhoto = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Listing::mainPhoto"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.clicks
     * @header lbServices.Listing.clicks
     * @object
     * @description
     *
     * The object `Listing.clicks` groups methods
     * manipulating `ListingClick` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#clicks Listing.clicks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#clicks
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries clicks of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R.clicks = function() {
          var TargetResource = $injector.get("ListingClick");
          var action = TargetResource["::get::Listing::clicks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.clicks#count
             * @methodOf lbServices.Listing.clicks
             *
             * @description
             *
             * Counts clicks of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.clicks.count = function() {
          var TargetResource = $injector.get("ListingClick");
          var action = TargetResource["::count::Listing::clicks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.clicks#create
             * @methodOf lbServices.Listing.clicks
             *
             * @description
             *
             * Creates a new instance in clicks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R.clicks.create = function() {
          var TargetResource = $injector.get("ListingClick");
          var action = TargetResource["::create::Listing::clicks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.clicks#createMany
             * @methodOf lbServices.Listing.clicks
             *
             * @description
             *
             * Creates a new instance in clicks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R.clicks.createMany = function() {
          var TargetResource = $injector.get("ListingClick");
          var action = TargetResource["::createMany::Listing::clicks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.clicks#destroyAll
             * @methodOf lbServices.Listing.clicks
             *
             * @description
             *
             * Deletes all clicks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.clicks.destroyAll = function() {
          var TargetResource = $injector.get("ListingClick");
          var action = TargetResource["::delete::Listing::clicks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.clicks#destroyById
             * @methodOf lbServices.Listing.clicks
             *
             * @description
             *
             * Delete a related item by id for clicks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for clicks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.clicks.destroyById = function() {
          var TargetResource = $injector.get("ListingClick");
          var action = TargetResource["::destroyById::Listing::clicks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.clicks#findById
             * @methodOf lbServices.Listing.clicks
             *
             * @description
             *
             * Find a related item by id for clicks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for clicks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R.clicks.findById = function() {
          var TargetResource = $injector.get("ListingClick");
          var action = TargetResource["::findById::Listing::clicks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.clicks#updateById
             * @methodOf lbServices.Listing.clicks
             *
             * @description
             *
             * Update a related item by id for clicks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for clicks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R.clicks.updateById = function() {
          var TargetResource = $injector.get("ListingClick");
          var action = TargetResource["::updateById::Listing::clicks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.buyers
     * @header lbServices.Listing.buyers
     * @object
     * @description
     *
     * The object `Listing.buyers` groups methods
     * manipulating `Buyer` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#buyers Listing.buyers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#buyers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries buyers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#count
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Counts buyers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.buyers.count = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::count::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#create
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Creates a new instance in buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.create = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::create::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#createMany
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Creates a new instance in buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.createMany = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::createMany::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#destroyAll
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Deletes all buyers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.destroyAll = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::delete::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#destroyById
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Delete a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.destroyById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::destroyById::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#exists
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Check the existence of buyers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.exists = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::exists::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#findById
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Find a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.findById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::findById::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#link
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Add a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.link = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::link::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#unlink
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Remove the buyers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.buyers.unlink = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::unlink::Listing::buyers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.buyers#updateById
             * @methodOf lbServices.Listing.buyers
             *
             * @description
             *
             * Update a related item by id for buyers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for buyers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyers.updateById = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::updateById::Listing::buyers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.suppliers
     * @header lbServices.Listing.suppliers
     * @object
     * @description
     *
     * The object `Listing.suppliers` groups methods
     * manipulating `Supplier` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#suppliers Listing.suppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#suppliers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries suppliers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#count
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Counts suppliers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.suppliers.count = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::count::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#create
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.create = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::create::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#createMany
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Creates a new instance in suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.createMany = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::createMany::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#destroyAll
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Deletes all suppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyAll = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::delete::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#destroyById
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Delete a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.destroyById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::destroyById::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#exists
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Check the existence of suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.exists = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::exists::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#findById
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Find a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.findById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::findById::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#link
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Add a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.link = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::link::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#unlink
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Remove the suppliers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.suppliers.unlink = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::unlink::Listing::suppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.suppliers#updateById
             * @methodOf lbServices.Listing.suppliers
             *
             * @description
             *
             * Update a related item by id for suppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for suppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.suppliers.updateById = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::updateById::Listing::suppliers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.listingSuppliers
     * @header lbServices.Listing.listingSuppliers
     * @object
     * @description
     *
     * The object `Listing.listingSuppliers` groups methods
     * manipulating `ListingSupplier` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#listingSuppliers Listing.listingSuppliers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#listingSuppliers
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries listingSuppliers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::get::Listing::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingSuppliers#count
             * @methodOf lbServices.Listing.listingSuppliers
             *
             * @description
             *
             * Counts listingSuppliers of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.listingSuppliers.count = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::count::Listing::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingSuppliers#create
             * @methodOf lbServices.Listing.listingSuppliers
             *
             * @description
             *
             * Creates a new instance in listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.create = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::create::Listing::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingSuppliers#createMany
             * @methodOf lbServices.Listing.listingSuppliers
             *
             * @description
             *
             * Creates a new instance in listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.createMany = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::createMany::Listing::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingSuppliers#destroyAll
             * @methodOf lbServices.Listing.listingSuppliers
             *
             * @description
             *
             * Deletes all listingSuppliers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingSuppliers.destroyAll = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::delete::Listing::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingSuppliers#destroyById
             * @methodOf lbServices.Listing.listingSuppliers
             *
             * @description
             *
             * Delete a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.listingSuppliers.destroyById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::destroyById::Listing::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingSuppliers#findById
             * @methodOf lbServices.Listing.listingSuppliers
             *
             * @description
             *
             * Find a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.findById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::findById::Listing::listingSuppliers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.listingSuppliers#updateById
             * @methodOf lbServices.Listing.listingSuppliers
             *
             * @description
             *
             * Update a related item by id for listingSuppliers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for listingSuppliers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R.listingSuppliers.updateById = function() {
          var TargetResource = $injector.get("ListingSupplier");
          var action = TargetResource["::updateById::Listing::listingSuppliers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.shared
     * @header lbServices.Listing.shared
     * @object
     * @description
     *
     * The object `Listing.shared` groups methods
     * manipulating `SharedSmartListing` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#shared Listing.shared()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#shared
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries shared of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.shared = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::get::Listing::shared"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.shared#count
             * @methodOf lbServices.Listing.shared
             *
             * @description
             *
             * Counts shared of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.shared.count = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::count::Listing::shared"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.shared#create
             * @methodOf lbServices.Listing.shared
             *
             * @description
             *
             * Creates a new instance in shared of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.shared.create = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::create::Listing::shared"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.shared#createMany
             * @methodOf lbServices.Listing.shared
             *
             * @description
             *
             * Creates a new instance in shared of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.shared.createMany = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::createMany::Listing::shared"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.shared#destroyAll
             * @methodOf lbServices.Listing.shared
             *
             * @description
             *
             * Deletes all shared of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.shared.destroyAll = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::delete::Listing::shared"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.shared#destroyById
             * @methodOf lbServices.Listing.shared
             *
             * @description
             *
             * Delete a related item by id for shared.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for shared
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.shared.destroyById = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::destroyById::Listing::shared"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.shared#findById
             * @methodOf lbServices.Listing.shared
             *
             * @description
             *
             * Find a related item by id for shared.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for shared
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.shared.findById = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::findById::Listing::shared"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.shared#updateById
             * @methodOf lbServices.Listing.shared
             *
             * @description
             *
             * Update a related item by id for shared.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for shared
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R.shared.updateById = function() {
          var TargetResource = $injector.get("SharedSmartListing");
          var action = TargetResource["::updateById::Listing::shared"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Listing.sharedSmartListings
     * @header lbServices.Listing.sharedSmartListings
     * @object
     * @description
     *
     * The object `Listing.sharedSmartListings` groups methods
     * manipulating `Broker` instances related to `Listing`.
     *
     * Call {@link lbServices.Listing#sharedSmartListings Listing.sharedSmartListings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Listing#sharedSmartListings
             * @methodOf lbServices.Listing
             *
             * @description
             *
             * Queries sharedSmartListings of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.sharedSmartListings = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#count
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Counts sharedSmartListings of Listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sharedSmartListings.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#create
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Creates a new instance in sharedSmartListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.sharedSmartListings.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#createMany
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Creates a new instance in sharedSmartListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.sharedSmartListings.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#destroyAll
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Deletes all sharedSmartListings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedSmartListings.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#destroyById
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Delete a related item by id for sharedSmartListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedSmartListings.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#exists
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Check the existence of sharedSmartListings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.sharedSmartListings.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#findById
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Find a related item by id for sharedSmartListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.sharedSmartListings.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#link
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Add a related item by id for sharedSmartListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.sharedSmartListings.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#unlink
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Remove the sharedSmartListings relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sharedSmartListings.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Listing.sharedSmartListings#updateById
             * @methodOf lbServices.Listing.sharedSmartListings
             *
             * @description
             *
             * Update a related item by id for sharedSmartListings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Listing id
             *
             *  - `fk` – `{*}` - Foreign key for sharedSmartListings
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.sharedSmartListings.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::Listing::sharedSmartListings"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Account
 * @header lbServices.Account
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Account` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Account",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Accounts/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Account.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Accounts/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Account.user.create() instead.
            "prototype$__create__user": {
              url: urlBase + "/Accounts/:id/user",
              method: "POST",
            },

            // INTERNAL. Use Account.user.update() instead.
            "prototype$__update__user": {
              url: urlBase + "/Accounts/:id/user",
              method: "PUT",
            },

            // INTERNAL. Use Account.user.destroy() instead.
            "prototype$__destroy__user": {
              url: urlBase + "/Accounts/:id/user",
              method: "DELETE",
            },

            // INTERNAL. Use Account.mainLanguage() instead.
            "prototype$__get__mainLanguage": {
              url: urlBase + "/Accounts/:id/mainLanguage",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__findById__accessTokens
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__findById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/accessTokens/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__destroyById__accessTokens
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/accessTokens/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__updateById__accessTokens
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__updateById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/accessTokens/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.wordpress() instead.
            "prototype$__get__wordpress": {
              url: urlBase + "/Accounts/:id/wordpress",
              method: "GET",
            },

            // INTERNAL. Use Account.wordpress.create() instead.
            "prototype$__create__wordpress": {
              url: urlBase + "/Accounts/:id/wordpress",
              method: "POST",
            },

            // INTERNAL. Use Account.wordpress.update() instead.
            "prototype$__update__wordpress": {
              url: urlBase + "/Accounts/:id/wordpress",
              method: "PUT",
            },

            // INTERNAL. Use Account.wordpress.destroy() instead.
            "prototype$__destroy__wordpress": {
              url: urlBase + "/Accounts/:id/wordpress",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__findById__loginAttempts
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find a related item by id for loginAttempts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for loginAttempts
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__findById__loginAttempts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/loginAttempts/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__destroyById__loginAttempts
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a related item by id for loginAttempts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for loginAttempts
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__loginAttempts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/loginAttempts/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__updateById__loginAttempts
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update a related item by id for loginAttempts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for loginAttempts
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__updateById__loginAttempts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/loginAttempts/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__get__accessTokens
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries accessTokens of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__get__accessTokens": {
              isArray: true,
              url: urlBase + "/Accounts/:id/accessTokens",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__create__accessTokens
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Creates a new instance in accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__create__accessTokens": {
              url: urlBase + "/Accounts/:id/accessTokens",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__delete__accessTokens
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Deletes all accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__accessTokens": {
              url: urlBase + "/Accounts/:id/accessTokens",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__count__accessTokens
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Counts accessTokens of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__accessTokens": {
              url: urlBase + "/Accounts/:id/accessTokens/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__get__loginAttempts
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries loginAttempts of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__get__loginAttempts": {
              isArray: true,
              url: urlBase + "/Accounts/:id/loginAttempts",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__create__loginAttempts
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Creates a new instance in loginAttempts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__create__loginAttempts": {
              url: urlBase + "/Accounts/:id/loginAttempts",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__delete__loginAttempts
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Deletes all loginAttempts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__loginAttempts": {
              url: urlBase + "/Accounts/:id/loginAttempts",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__count__loginAttempts
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Counts loginAttempts of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__loginAttempts": {
              url: urlBase + "/Accounts/:id/loginAttempts/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#create
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Accounts",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#patchOrCreate
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Accounts",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#replaceOrCreate
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Accounts/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#upsertWithWhere
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Accounts/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#exists
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Accounts/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#findById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Accounts/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#replaceById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Accounts/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#find
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Accounts",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#findOne
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Accounts/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#updateAll
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Accounts/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#deleteById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Accounts/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#count
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Accounts/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$patchAttributes
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Accounts/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#createChangeStream
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Accounts/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#login
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  return response.resource;
                },
              },
              url: urlBase + "/Accounts/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#logout
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string=}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/Accounts/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$verify
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Trigger user's identity verification with configured verifyOptions
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `verifyOptions` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$verify": {
              url: urlBase + "/Accounts/:id/verify",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#confirm
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Confirm a user registration with identity verification token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `uid` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `redirect` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "confirm": {
              url: urlBase + "/Accounts/confirm",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#resetPassword
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Reset password for a user with email.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "resetPassword": {
              url: urlBase + "/Accounts/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#changePassword
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Change a user's password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `oldPassword` – `{string}` -
             *
             *  - `newPassword` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "changePassword": {
              url: urlBase + "/Accounts/change-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#setPassword
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Reset user's password via a password-reset token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `newPassword` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "setPassword": {
              url: urlBase + "/Accounts/reset-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#saveAccount
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Save account details.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `account` – `{object}` -
             *
             *  - `user` – `{object}` -
             *
             *  - `agency` – `{object}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Success if saved successfully.
             */
            "saveAccount": {
              url: urlBase + "/Accounts/:id/saveAccount",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getAccount
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Gets account details.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns account details object.
             */
            "getAccount": {
              url: urlBase + "/Accounts/:id/getAccount",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#savePreferences
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Saves Preferences.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns account details object.
             */
            "savePreferences": {
              url: urlBase + "/Accounts/:id/savePreferences",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getWordpress
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Returns an AccountWordpress model related to this Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns Wordpress details object.
             */
            "getWordpress": {
              url: urlBase + "/Accounts/:id/getWordpress",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#chooseRole
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Choose a role by exchanging access tokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns new role-specific access token.
             */
            "chooseRole": {
              url: urlBase + "/Accounts/:id/chooseRole",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#changePassword
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Save account details.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `body` – `{object}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Success if saved successfully.
             */
            "changePassword": {
              url: urlBase + "/Accounts/changePassword",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#createUser
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Create account user and any profiles if added.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns created account.
             */
            "createUser": {
              url: urlBase + "/Accounts/createUser",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#signupBroker
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Creates broker profile for sign up flow
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns success flag.
             */
            "signupBroker": {
              url: urlBase + "/Accounts/signupBroker",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#signupAgency
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Creates agency profile for sign up flow
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns success flag.
             */
            "signupAgency": {
              url: urlBase + "/Accounts/signupAgency",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getSubscription
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Gets account subscription.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns account subscription details.
             */
            "getSubscription": {
              url: urlBase + "/Accounts/:id/getSubscription",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#updateSubscription
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Updates account subscription for agency or broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `body` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns updated account subscription details.
             */
            "updateSubscription": {
              url: urlBase + "/Accounts/:id/updateSubscription",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getUpcomingInvoice
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Preview account's next upcoming invoice.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns account's next upcoming invoice.
             */
            "getUpcomingInvoice": {
              url: urlBase + "/Accounts/:id/getUpcomingInvoice",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getCard
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Retrieve's current subscription's card
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns card
             */
            "getCard": {
              url: urlBase + "/Accounts/:id/getCard",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getSubscriptionHistory
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Retrieve's account's subscription history
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Returns subscription history
             */
            "getSubscriptionHistory": {
              isArray: true,
              url: urlBase + "/Accounts/:id/getSubscriptionHistory",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#isEmailRegistered
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Checks if an account exists with an email
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Whether an account exists or not
             */
            "isEmailRegistered": {
              url: urlBase + "/Accounts/isEmailRegistered",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#updateCard
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Updates the card used by a subscription
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `token` – `{string}` -
             *
             *  - `customerId` – `{string}` -
             *
             *  - `isAdmin` – `{boolean=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Updated card information
             */
            "updateCard": {
              url: urlBase + "/Accounts/:id/updateCard",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getAll
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Gets all Accounts
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * All accounts
             */
            "getAll": {
              isArray: true,
              url: urlBase + "/Accounts/getAll",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#unlock
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Unlocks an account using a token sent via email after the user's account has been locked due to too many consecutive failed login attempts
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `token` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "unlock": {
              url: urlBase + "/Accounts/token",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#stopGoogleWatcher
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `channelId` – `{string}` -
             *
             *  - `resourceId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "stopGoogleWatcher": {
              url: urlBase + "/Accounts/:id/google/watch/:channelId/stop/:resourceId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#createGoogleWatcher
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "createGoogleWatcher": {
              url: urlBase + "/Accounts/:id/google/watch/create",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getGoogleCalendarEvents
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "getGoogleCalendarEvents": {
              url: urlBase + "/Accounts/:id/google/events",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#quickbooksConnect
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "quickbooksConnect": {
              url: urlBase + "/Accounts/quickbooks/connect",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#quickbooksCallback
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `code` – `{string=}` -
             *
             *  - `realmId` – `{string=}` -
             *
             *  - `state` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "quickbooksCallback": {
              url: urlBase + "/Accounts/quickbooks/callback",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#quickbooksDisconnect
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "quickbooksDisconnect": {
              url: urlBase + "/Accounts/quickbooks/disconnect",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getStatus
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "getStatus": {
              url: urlBase + "/Accounts/:id/quickbooks/status",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getUserInfo
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "getUserInfo": {
              url: urlBase + "/Accounts/:id/quickbooks/user-info",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#disableGoogleIntegration
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "disableGoogleIntegration": {
              url: urlBase + "/Accounts/:id/google/disable",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#createMany
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Accounts",
              method: "POST",
            },

            // INTERNAL. Use PendingNotification.account() instead.
            "::get::PendingNotification::account": {
              url: urlBase + "/PendingNotifications/:id/account",
              method: "GET",
            },

            // INTERNAL. Use SbUser.account() instead.
            "::get::SbUser::account": {
              url: urlBase + "/SbUsers/:id/account",
              method: "GET",
            },

            // INTERNAL. Use AccountWordpress.account() instead.
            "::get::AccountWordpress::account": {
              url: urlBase + "/AccountWordpresses/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getCurrent
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/Accounts" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Account#upsert
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Account#updateOrCreate
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Account#patchOrCreateWithWhere
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Account#update
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Account#destroyById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Account#removeById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$updateAttributes
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];

        /**
         * @ngdoc method
         * @name lbServices.Account#getCachedCurrent
         * @methodOf lbServices.Account
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.Account#login} or
         * {@link lbServices.Account#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A Account instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Account#isAuthenticated
         * @methodOf lbServices.Account
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Account#getCurrentId
         * @methodOf lbServices.Account
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.Account#modelName
        * @propertyOf lbServices.Account
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Account`.
        */
        R.modelName = "Account";

    /**
     * @ngdoc object
     * @name lbServices.Account.user
     * @header lbServices.Account.user
     * @object
     * @description
     *
     * The object `Account.user` groups methods
     * manipulating `SbUser` instances related to `Account`.
     *
     * Call {@link lbServices.Account#user Account.user()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#user
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Fetches hasOne relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Account::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.user#create
             * @methodOf lbServices.Account.user
             *
             * @description
             *
             * Creates a new instance in user of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Account::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.user#createMany
             * @methodOf lbServices.Account.user
             *
             * @description
             *
             * Creates a new instance in user of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Account::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.user#destroy
             * @methodOf lbServices.Account.user
             *
             * @description
             *
             * Deletes user of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.user.destroy = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroy::Account::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.user#update
             * @methodOf lbServices.Account.user
             *
             * @description
             *
             * Update user of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user.update = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::update::Account::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account#mainLanguage
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Fetches belongsTo relation mainLanguage.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Language` object.)
             * </em>
             */
        R.mainLanguage = function() {
          var TargetResource = $injector.get("Language");
          var action = TargetResource["::get::Account::mainLanguage"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.wordpress
     * @header lbServices.Account.wordpress
     * @object
     * @description
     *
     * The object `Account.wordpress` groups methods
     * manipulating `AccountWordpress` instances related to `Account`.
     *
     * Call {@link lbServices.Account#wordpress Account.wordpress()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#wordpress
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Fetches hasOne relation wordpress.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R.wordpress = function() {
          var TargetResource = $injector.get("AccountWordpress");
          var action = TargetResource["::get::Account::wordpress"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.wordpress#create
             * @methodOf lbServices.Account.wordpress
             *
             * @description
             *
             * Creates a new instance in wordpress of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R.wordpress.create = function() {
          var TargetResource = $injector.get("AccountWordpress");
          var action = TargetResource["::create::Account::wordpress"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.wordpress#createMany
             * @methodOf lbServices.Account.wordpress
             *
             * @description
             *
             * Creates a new instance in wordpress of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R.wordpress.createMany = function() {
          var TargetResource = $injector.get("AccountWordpress");
          var action = TargetResource["::createMany::Account::wordpress"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.wordpress#destroy
             * @methodOf lbServices.Account.wordpress
             *
             * @description
             *
             * Deletes wordpress of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.wordpress.destroy = function() {
          var TargetResource = $injector.get("AccountWordpress");
          var action = TargetResource["::destroy::Account::wordpress"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.wordpress#update
             * @methodOf lbServices.Account.wordpress
             *
             * @description
             *
             * Update wordpress of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R.wordpress.update = function() {
          var TargetResource = $injector.get("AccountWordpress");
          var action = TargetResource["::update::Account::wordpress"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DataLoadReport
 * @header lbServices.DataLoadReport
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DataLoadReport` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DataLoadReport",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DataLoadReports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#create
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DataLoadReports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#patchOrCreate
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/DataLoadReports",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#replaceOrCreate
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DataLoadReports/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#upsertWithWhere
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DataLoadReports/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#exists
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DataLoadReports/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#findById
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DataLoadReports/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#replaceById
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DataLoadReports/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#find
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DataLoadReports",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#findOne
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DataLoadReports/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#updateAll
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DataLoadReports/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#deleteById
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DataLoadReports/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#count
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DataLoadReports/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#prototype$patchAttributes
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DataLoadReport id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/DataLoadReports/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#createChangeStream
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DataLoadReports/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#createMany
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DataLoadReports",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#upsert
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#updateOrCreate
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#patchOrCreateWithWhere
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#update
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#destroyById
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#removeById
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DataLoadReport#prototype$updateAttributes
             * @methodOf lbServices.DataLoadReport
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DataLoadReport id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DataLoadReport` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DataLoadReport#modelName
        * @propertyOf lbServices.DataLoadReport
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DataLoadReport`.
        */
        R.modelName = "DataLoadReport";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.VisitSbUser
 * @header lbServices.VisitSbUser
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `VisitSbUser` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "VisitSbUser",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/VisitSbUsers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use VisitSbUser.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/VisitSbUsers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use VisitSbUser.visit() instead.
            "prototype$__get__visit": {
              url: urlBase + "/VisitSbUsers/:id/visit",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#create
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/VisitSbUsers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#patchOrCreate
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/VisitSbUsers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#replaceOrCreate
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/VisitSbUsers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#upsertWithWhere
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/VisitSbUsers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#exists
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/VisitSbUsers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#findById
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/VisitSbUsers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#replaceById
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/VisitSbUsers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#find
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/VisitSbUsers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#findOne
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/VisitSbUsers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#updateAll
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/VisitSbUsers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#deleteById
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/VisitSbUsers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#count
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/VisitSbUsers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#prototype$patchAttributes
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - VisitSbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/VisitSbUsers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#createChangeStream
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/VisitSbUsers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#userCancel
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Triggered when user cancels their presence in a visit. Sends email to Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `secret` – `{string}` -
             *
             *  - `isOwner` – `{boolean=}` -
             *
             *  - `reason` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Deleted object
             */
            "userCancel": {
              url: urlBase + "/VisitSbUsers/userCancel",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#userConfirm
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Confirms a visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `secret` – `{string}` -
             *
             *  - `isOwner` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Modified Visit object
             */
            "userConfirm": {
              url: urlBase + "/VisitSbUsers/userConfirm",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#createMany
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/VisitSbUsers",
              method: "POST",
            },

            // INTERNAL. Use Visit.usersVisit.findById() instead.
            "::findById::Visit::usersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/usersVisit/:fk",
              method: "GET",
            },

            // INTERNAL. Use Visit.usersVisit.destroyById() instead.
            "::destroyById::Visit::usersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/usersVisit/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.usersVisit.updateById() instead.
            "::updateById::Visit::usersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/usersVisit/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.usersVisit() instead.
            "::get::Visit::usersVisit": {
              isArray: true,
              url: urlBase + "/Visits/:id/usersVisit",
              method: "GET",
            },

            // INTERNAL. Use Visit.usersVisit.create() instead.
            "::create::Visit::usersVisit": {
              url: urlBase + "/Visits/:id/usersVisit",
              method: "POST",
            },

            // INTERNAL. Use Visit.usersVisit.createMany() instead.
            "::createMany::Visit::usersVisit": {
              isArray: true,
              url: urlBase + "/Visits/:id/usersVisit",
              method: "POST",
            },

            // INTERNAL. Use Visit.usersVisit.destroyAll() instead.
            "::delete::Visit::usersVisit": {
              url: urlBase + "/Visits/:id/usersVisit",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.usersVisit.count() instead.
            "::count::Visit::usersVisit": {
              url: urlBase + "/Visits/:id/usersVisit/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#upsert
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#updateOrCreate
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#patchOrCreateWithWhere
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#update
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#destroyById
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#removeById
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#prototype$updateAttributes
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - VisitSbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitSbUser` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.VisitSbUser#modelName
        * @propertyOf lbServices.VisitSbUser
        * @description
        * The name of the model represented by this $resource,
        * i.e. `VisitSbUser`.
        */
        R.modelName = "VisitSbUser";


            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#user
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - VisitSbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::VisitSbUser::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.VisitSbUser#visit
             * @methodOf lbServices.VisitSbUser
             *
             * @description
             *
             * Fetches belongsTo relation visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - VisitSbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visit = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::get::VisitSbUser::visit"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingClick
 * @header lbServices.ListingClick
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingClick` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingClick",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingClicks/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingClick.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingClicks/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingClick.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/ListingClicks/:id/broker",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#create
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingClicks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#patchOrCreate
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingClicks",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#replaceOrCreate
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingClicks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#upsertWithWhere
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingClicks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#exists
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingClicks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#findById
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingClicks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#replaceById
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingClicks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#find
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingClicks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#findOne
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingClicks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#updateAll
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingClicks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#deleteById
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingClicks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#count
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingClicks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#prototype$patchAttributes
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingClick id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingClicks/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#createChangeStream
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingClicks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#getHits
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Gets hits information about a listing based on listing and broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `listingId` – `{string}` -
             *
             *  - `brokerId` – `{string}` -
             *
             *  - `unique` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Number of hits based on daily reports saved in the DB
             */
            "getHits": {
              url: urlBase + "/ListingClicks/getHits",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#createMany
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingClicks",
              method: "POST",
            },

            // INTERNAL. Use Listing.clicks.findById() instead.
            "::findById::Listing::clicks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/clicks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.clicks.destroyById() instead.
            "::destroyById::Listing::clicks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/clicks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.clicks.updateById() instead.
            "::updateById::Listing::clicks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/clicks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.clicks() instead.
            "::get::Listing::clicks": {
              isArray: true,
              url: urlBase + "/Listings/:id/clicks",
              method: "GET",
            },

            // INTERNAL. Use Listing.clicks.create() instead.
            "::create::Listing::clicks": {
              url: urlBase + "/Listings/:id/clicks",
              method: "POST",
            },

            // INTERNAL. Use Listing.clicks.createMany() instead.
            "::createMany::Listing::clicks": {
              isArray: true,
              url: urlBase + "/Listings/:id/clicks",
              method: "POST",
            },

            // INTERNAL. Use Listing.clicks.destroyAll() instead.
            "::delete::Listing::clicks": {
              url: urlBase + "/Listings/:id/clicks",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.clicks.count() instead.
            "::count::Listing::clicks": {
              url: urlBase + "/Listings/:id/clicks/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingClick#upsert
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#updateOrCreate
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#patchOrCreateWithWhere
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#update
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#destroyById
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#removeById
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#prototype$updateAttributes
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingClick id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingClick` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingClick#modelName
        * @propertyOf lbServices.ListingClick
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingClick`.
        */
        R.modelName = "ListingClick";


            /**
             * @ngdoc method
             * @name lbServices.ListingClick#listing
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingClick id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingClick::listing"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingClick#broker
             * @methodOf lbServices.ListingClick
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingClick id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::ListingClick::broker"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Job
 * @header lbServices.Job
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Job` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Job",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Jobs/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Job#create
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Jobs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#patchOrCreate
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Jobs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#replaceOrCreate
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Jobs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#upsertWithWhere
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Jobs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#exists
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Jobs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#findById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Jobs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#replaceById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Jobs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#find
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Jobs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#findOne
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Jobs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#updateAll
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Jobs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#deleteById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Jobs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#count
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Jobs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#prototype$patchAttributes
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Jobs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#createChangeStream
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Jobs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#createMany
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Jobs",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Job#upsert
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Job#updateOrCreate
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Job#patchOrCreateWithWhere
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Job#update
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Job#destroyById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Job#removeById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Job#prototype$updateAttributes
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Job#modelName
        * @propertyOf lbServices.Job
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Job`.
        */
        R.modelName = "Job";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingBuyer
 * @header lbServices.ListingBuyer
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingBuyer` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingBuyer",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingBuyers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingBuyer.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingBuyers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingBuyer.buyer() instead.
            "prototype$__get__buyer": {
              url: urlBase + "/ListingBuyers/:id/buyer",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#create
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingBuyers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#patchOrCreate
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingBuyers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#replaceOrCreate
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingBuyers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#upsertWithWhere
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingBuyers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#exists
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingBuyers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#findById
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingBuyers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#replaceById
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingBuyers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#find
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingBuyers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#findOne
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingBuyers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#updateAll
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingBuyers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#deleteById
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingBuyers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#count
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingBuyers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#prototype$patchAttributes
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingBuyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingBuyers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#createChangeStream
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingBuyers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#createMany
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingBuyers",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#upsert
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#updateOrCreate
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#patchOrCreateWithWhere
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#update
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#destroyById
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#removeById
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#prototype$updateAttributes
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingBuyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingBuyer` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingBuyer#modelName
        * @propertyOf lbServices.ListingBuyer
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingBuyer`.
        */
        R.modelName = "ListingBuyer";


            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#listing
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingBuyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingBuyer::listing"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingBuyer#buyer
             * @methodOf lbServices.ListingBuyer
             *
             * @description
             *
             * Fetches belongsTo relation buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingBuyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyer = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::ListingBuyer::buyer"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Subscription
 * @header lbServices.Subscription
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Subscription` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Subscription",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Subscriptions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Subscription.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Subscriptions/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Subscription.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/Subscriptions/:id/broker",
              method: "GET",
            },

            // INTERNAL. Use Subscription.invoices.findById() instead.
            "prototype$__findById__invoices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Subscriptions/:id/invoices/:fk",
              method: "GET",
            },

            // INTERNAL. Use Subscription.invoices.destroyById() instead.
            "prototype$__destroyById__invoices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Subscriptions/:id/invoices/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Subscription.invoices.updateById() instead.
            "prototype$__updateById__invoices": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Subscriptions/:id/invoices/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Subscription.invoices() instead.
            "prototype$__get__invoices": {
              isArray: true,
              url: urlBase + "/Subscriptions/:id/invoices",
              method: "GET",
            },

            // INTERNAL. Use Subscription.invoices.create() instead.
            "prototype$__create__invoices": {
              url: urlBase + "/Subscriptions/:id/invoices",
              method: "POST",
            },

            // INTERNAL. Use Subscription.invoices.destroyAll() instead.
            "prototype$__delete__invoices": {
              url: urlBase + "/Subscriptions/:id/invoices",
              method: "DELETE",
            },

            // INTERNAL. Use Subscription.invoices.count() instead.
            "prototype$__count__invoices": {
              url: urlBase + "/Subscriptions/:id/invoices/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#create
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Subscriptions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#patchOrCreate
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Subscriptions",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#replaceOrCreate
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Subscriptions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#upsertWithWhere
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Subscriptions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#exists
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Subscriptions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#findById
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Subscriptions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#replaceById
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Subscriptions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#find
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Subscriptions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#findOne
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Subscriptions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#updateAll
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Subscriptions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#deleteById
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Subscriptions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#count
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Subscriptions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#prototype$patchAttributes
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Subscriptions/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#createChangeStream
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Subscriptions/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#cancelPlan
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Cancels a subscription.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * True if no errors
             */
            "cancelPlan": {
              url: urlBase + "/Subscriptions/:id/cancel",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#renewPlan
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Renews a subscription.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `control` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * New subscription created
             */
            "renewPlan": {
              url: urlBase + "/Subscriptions/:id/renew",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#updatePlan
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Updates a subscription with new plans.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `brokerPlan` – `{object=}` -
             *
             *  - `agencyPlan` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Updated subscription
             */
            "updatePlan": {
              url: urlBase + "/Subscriptions/:id/updatePlan",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscription#createMany
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Subscriptions",
              method: "POST",
            },

            // INTERNAL. Use Agency.subscriptions() instead.
            "::get::Agency::subscriptions": {
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "GET",
            },

            // INTERNAL. Use Agency.subscriptions.create() instead.
            "::create::Agency::subscriptions": {
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "POST",
            },

            // INTERNAL. Use Agency.subscriptions.createMany() instead.
            "::createMany::Agency::subscriptions": {
              isArray: true,
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "POST",
            },

            // INTERNAL. Use Agency.subscriptions.update() instead.
            "::update::Agency::subscriptions": {
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "PUT",
            },

            // INTERNAL. Use Agency.subscriptions.destroy() instead.
            "::destroy::Agency::subscriptions": {
              url: urlBase + "/Agencies/:id/subscriptions",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.subscriptions() instead.
            "::get::Broker::subscriptions": {
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "GET",
            },

            // INTERNAL. Use Broker.subscriptions.create() instead.
            "::create::Broker::subscriptions": {
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "POST",
            },

            // INTERNAL. Use Broker.subscriptions.createMany() instead.
            "::createMany::Broker::subscriptions": {
              isArray: true,
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "POST",
            },

            // INTERNAL. Use Broker.subscriptions.update() instead.
            "::update::Broker::subscriptions": {
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "PUT",
            },

            // INTERNAL. Use Broker.subscriptions.destroy() instead.
            "::destroy::Broker::subscriptions": {
              url: urlBase + "/Brokers/:id/subscriptions",
              method: "DELETE",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Subscription#upsert
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Subscription#updateOrCreate
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Subscription#patchOrCreateWithWhere
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Subscription#update
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Subscription#destroyById
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Subscription#removeById
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Subscription#prototype$updateAttributes
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscription` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Subscription#modelName
        * @propertyOf lbServices.Subscription
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Subscription`.
        */
        R.modelName = "Subscription";


            /**
             * @ngdoc method
             * @name lbServices.Subscription#agency
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Subscription::agency"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Subscription#broker
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::Subscription::broker"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Subscription.invoices
     * @header lbServices.Subscription.invoices
     * @object
     * @description
     *
     * The object `Subscription.invoices` groups methods
     * manipulating `SmartFile` instances related to `Subscription`.
     *
     * Call {@link lbServices.Subscription#invoices Subscription.invoices()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Subscription#invoices
             * @methodOf lbServices.Subscription
             *
             * @description
             *
             * Queries invoices of Subscription.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.invoices = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Subscription::invoices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Subscription.invoices#count
             * @methodOf lbServices.Subscription.invoices
             *
             * @description
             *
             * Counts invoices of Subscription.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.invoices.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Subscription::invoices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Subscription.invoices#create
             * @methodOf lbServices.Subscription.invoices
             *
             * @description
             *
             * Creates a new instance in invoices of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.invoices.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Subscription::invoices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Subscription.invoices#createMany
             * @methodOf lbServices.Subscription.invoices
             *
             * @description
             *
             * Creates a new instance in invoices of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.invoices.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Subscription::invoices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Subscription.invoices#destroyAll
             * @methodOf lbServices.Subscription.invoices
             *
             * @description
             *
             * Deletes all invoices of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.invoices.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Subscription::invoices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Subscription.invoices#destroyById
             * @methodOf lbServices.Subscription.invoices
             *
             * @description
             *
             * Delete a related item by id for invoices.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for invoices
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.invoices.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Subscription::invoices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Subscription.invoices#findById
             * @methodOf lbServices.Subscription.invoices
             *
             * @description
             *
             * Find a related item by id for invoices.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for invoices
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.invoices.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Subscription::invoices"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Subscription.invoices#updateById
             * @methodOf lbServices.Subscription.invoices
             *
             * @description
             *
             * Update a related item by id for invoices.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscription id
             *
             *  - `fk` – `{*}` - Foreign key for invoices
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.invoices.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Subscription::invoices"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Event
 * @header lbServices.Event
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Event` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Event",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Events/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Event#create
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Events",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#patchOrCreate
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Events",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#replaceOrCreate
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Events/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#upsertWithWhere
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Events/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#exists
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Events/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#findById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Events/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#replaceById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Events/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#find
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Events",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#findOne
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Events/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#updateAll
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Events/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#deleteById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Events/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#count
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Events/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#prototype$patchAttributes
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Event id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Events/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#createChangeStream
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Events/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#google
             * @methodOf lbServices.Event
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "google": {
              url: urlBase + "/Events/google",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#incoming
             * @methodOf lbServices.Event
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `summary` – `{string=}` -
             */
            "incoming": {
              url: urlBase + "/Events/incoming",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#remoteProcess
             * @methodOf lbServices.Event
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * What happened.
             */
            "remoteProcess": {
              url: urlBase + "/Events/:id/process",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#createMany
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Events",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Event#upsert
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Event#updateOrCreate
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Event#patchOrCreateWithWhere
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Event#update
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Event#destroyById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Event#removeById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Event#prototype$updateAttributes
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Event id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Event#modelName
        * @propertyOf lbServices.Event
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Event`.
        */
        R.modelName = "Event";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AvailablePlan
 * @header lbServices.AvailablePlan
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AvailablePlan` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AvailablePlan",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AvailablePlans/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#create
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AvailablePlans",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#patchOrCreate
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/AvailablePlans",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#replaceOrCreate
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AvailablePlans/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#upsertWithWhere
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AvailablePlans/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#exists
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AvailablePlans/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#findById
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AvailablePlans/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#replaceById
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AvailablePlans/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#find
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AvailablePlans",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#findOne
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AvailablePlans/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#updateAll
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AvailablePlans/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#deleteById
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AvailablePlans/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#count
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AvailablePlans/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#prototype$patchAttributes
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AvailablePlan id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/AvailablePlans/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#createChangeStream
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AvailablePlans/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#createMany
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AvailablePlans",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#upsert
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#updateOrCreate
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#patchOrCreateWithWhere
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#update
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#destroyById
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#removeById
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AvailablePlan#prototype$updateAttributes
             * @methodOf lbServices.AvailablePlan
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AvailablePlan id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AvailablePlan` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AvailablePlan#modelName
        * @propertyOf lbServices.AvailablePlan
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AvailablePlan`.
        */
        R.modelName = "AvailablePlan";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Conversation
 * @header lbServices.Conversation
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Conversation` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Conversation",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Conversations/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Conversation.messages.findById() instead.
            "prototype$__findById__messages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/messages/:fk",
              method: "GET",
            },

            // INTERNAL. Use Conversation.messages.destroyById() instead.
            "prototype$__destroyById__messages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/messages/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.messages.updateById() instead.
            "prototype$__updateById__messages": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/messages/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.agency() instead.
            "prototype$__get__agency": {
              url: urlBase + "/Conversations/:id/agency",
              method: "GET",
            },

            // INTERNAL. Use Conversation.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Conversation.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.files.link() instead.
            "prototype$__link__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.files.unlink() instead.
            "prototype$__unlink__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.files.exists() instead.
            "prototype$__exists__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/files/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Conversation.users.findById() instead.
            "prototype$__findById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Conversation.users.destroyById() instead.
            "prototype$__destroyById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.users.updateById() instead.
            "prototype$__updateById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.users.link() instead.
            "prototype$__link__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.users.unlink() instead.
            "prototype$__unlink__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.users.exists() instead.
            "prototype$__exists__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Conversation.metadata.findById() instead.
            "prototype$__findById__metadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/metadata/:fk",
              method: "GET",
            },

            // INTERNAL. Use Conversation.metadata.destroyById() instead.
            "prototype$__destroyById__metadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/metadata/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.metadata.updateById() instead.
            "prototype$__updateById__metadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/metadata/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.messages() instead.
            "prototype$__get__messages": {
              isArray: true,
              url: urlBase + "/Conversations/:id/messages",
              method: "GET",
            },

            // INTERNAL. Use Conversation.messages.create() instead.
            "prototype$__create__messages": {
              url: urlBase + "/Conversations/:id/messages",
              method: "POST",
            },

            // INTERNAL. Use Conversation.messages.destroyAll() instead.
            "prototype$__delete__messages": {
              url: urlBase + "/Conversations/:id/messages",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.messages.count() instead.
            "prototype$__count__messages": {
              url: urlBase + "/Conversations/:id/messages/count",
              method: "GET",
            },

            // INTERNAL. Use Conversation.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Conversations/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Conversation.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Conversations/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Conversation.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Conversations/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Conversations/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Conversation.users() instead.
            "prototype$__get__users": {
              isArray: true,
              url: urlBase + "/Conversations/:id/users",
              method: "GET",
            },

            // INTERNAL. Use Conversation.users.create() instead.
            "prototype$__create__users": {
              url: urlBase + "/Conversations/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Conversation.users.destroyAll() instead.
            "prototype$__delete__users": {
              url: urlBase + "/Conversations/:id/users",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.users.count() instead.
            "prototype$__count__users": {
              url: urlBase + "/Conversations/:id/users/count",
              method: "GET",
            },

            // INTERNAL. Use Conversation.metadata() instead.
            "prototype$__get__metadata": {
              isArray: true,
              url: urlBase + "/Conversations/:id/metadata",
              method: "GET",
            },

            // INTERNAL. Use Conversation.metadata.create() instead.
            "prototype$__create__metadata": {
              url: urlBase + "/Conversations/:id/metadata",
              method: "POST",
            },

            // INTERNAL. Use Conversation.metadata.destroyAll() instead.
            "prototype$__delete__metadata": {
              url: urlBase + "/Conversations/:id/metadata",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.metadata.count() instead.
            "prototype$__count__metadata": {
              url: urlBase + "/Conversations/:id/metadata/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#create
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Conversations",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#patchOrCreate
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Conversations",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#replaceOrCreate
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Conversations/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#upsertWithWhere
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Conversations/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#exists
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Conversations/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#findById
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Conversations/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#replaceById
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Conversations/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#find
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Conversations",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#findOne
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Conversations/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#updateAll
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Conversations/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#deleteById
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Conversations/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#count
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Conversations/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#prototype$patchAttributes
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Conversations/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#createChangeStream
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Conversations/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#markAsRead
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "markAsRead": {
              url: urlBase + "/Conversations/:id/markAsRead",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#downloadFile
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileId` – `{string=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFile": {
              url: urlBase + "/Conversations/:id/:fileId/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#downloadFiles
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Downloads one file related to the model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fileIds` – `{*=}` -
             *
             *  - `access_token` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "downloadFiles": {
              url: urlBase + "/Conversations/:id/download-files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Conversation#createMany
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Conversations",
              method: "POST",
            },

            // INTERNAL. Use Message.conversation() instead.
            "::get::Message::conversation": {
              url: urlBase + "/Messages/:id/conversation",
              method: "GET",
            },

            // INTERNAL. Use SbUser.conversations.findById() instead.
            "::findById::SbUser::conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.conversations.destroyById() instead.
            "::destroyById::SbUser::conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.conversations.updateById() instead.
            "::updateById::SbUser::conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.conversations.link() instead.
            "::link::SbUser::conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.conversations.unlink() instead.
            "::unlink::SbUser::conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.conversations.exists() instead.
            "::exists::SbUser::conversations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/conversations/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SbUser.conversations() instead.
            "::get::SbUser::conversations": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/conversations",
              method: "GET",
            },

            // INTERNAL. Use SbUser.conversations.create() instead.
            "::create::SbUser::conversations": {
              url: urlBase + "/SbUsers/:id/conversations",
              method: "POST",
            },

            // INTERNAL. Use SbUser.conversations.createMany() instead.
            "::createMany::SbUser::conversations": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/conversations",
              method: "POST",
            },

            // INTERNAL. Use SbUser.conversations.destroyAll() instead.
            "::delete::SbUser::conversations": {
              url: urlBase + "/SbUsers/:id/conversations",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.conversations.count() instead.
            "::count::SbUser::conversations": {
              url: urlBase + "/SbUsers/:id/conversations/count",
              method: "GET",
            },

            // INTERNAL. Use ConversationSbUser.conversation() instead.
            "::get::ConversationSbUser::conversation": {
              url: urlBase + "/ConversationSbUsers/:id/conversation",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Conversation#upsert
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Conversation#updateOrCreate
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Conversation#patchOrCreateWithWhere
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Conversation#update
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Conversation#destroyById
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Conversation#removeById
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Conversation#prototype$updateAttributes
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Conversation#modelName
        * @propertyOf lbServices.Conversation
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Conversation`.
        */
        R.modelName = "Conversation";

    /**
     * @ngdoc object
     * @name lbServices.Conversation.messages
     * @header lbServices.Conversation.messages
     * @object
     * @description
     *
     * The object `Conversation.messages` groups methods
     * manipulating `Message` instances related to `Conversation`.
     *
     * Call {@link lbServices.Conversation#messages Conversation.messages()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Conversation#messages
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Queries messages of Conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R.messages = function() {
          var TargetResource = $injector.get("Message");
          var action = TargetResource["::get::Conversation::messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.messages#count
             * @methodOf lbServices.Conversation.messages
             *
             * @description
             *
             * Counts messages of Conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.messages.count = function() {
          var TargetResource = $injector.get("Message");
          var action = TargetResource["::count::Conversation::messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.messages#create
             * @methodOf lbServices.Conversation.messages
             *
             * @description
             *
             * Creates a new instance in messages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R.messages.create = function() {
          var TargetResource = $injector.get("Message");
          var action = TargetResource["::create::Conversation::messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.messages#createMany
             * @methodOf lbServices.Conversation.messages
             *
             * @description
             *
             * Creates a new instance in messages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R.messages.createMany = function() {
          var TargetResource = $injector.get("Message");
          var action = TargetResource["::createMany::Conversation::messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.messages#destroyAll
             * @methodOf lbServices.Conversation.messages
             *
             * @description
             *
             * Deletes all messages of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.messages.destroyAll = function() {
          var TargetResource = $injector.get("Message");
          var action = TargetResource["::delete::Conversation::messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.messages#destroyById
             * @methodOf lbServices.Conversation.messages
             *
             * @description
             *
             * Delete a related item by id for messages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for messages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.messages.destroyById = function() {
          var TargetResource = $injector.get("Message");
          var action = TargetResource["::destroyById::Conversation::messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.messages#findById
             * @methodOf lbServices.Conversation.messages
             *
             * @description
             *
             * Find a related item by id for messages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for messages
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R.messages.findById = function() {
          var TargetResource = $injector.get("Message");
          var action = TargetResource["::findById::Conversation::messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.messages#updateById
             * @methodOf lbServices.Conversation.messages
             *
             * @description
             *
             * Update a related item by id for messages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `fk` – `{*}` - Foreign key for messages
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Message` object.)
             * </em>
             */
        R.messages.updateById = function() {
          var TargetResource = $injector.get("Message");
          var action = TargetResource["::updateById::Conversation::messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation#agency
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Fetches belongsTo relation agency.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agency = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::Conversation::agency"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Conversation.files
     * @header lbServices.Conversation.files
     * @object
     * @description
     *
     * The object `Conversation.files` groups methods
     * manipulating `SmartFile` instances related to `Conversation`.
     *
     * Call {@link lbServices.Conversation#files Conversation.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Conversation#files
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Queries files of Conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::get::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#count
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Counts files of Conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::count::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#create
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::create::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#createMany
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::createMany::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#destroyAll
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::delete::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#destroyById
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::destroyById::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#exists
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Check the existence of files relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.exists = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::exists::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#findById
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::findById::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#link
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Add a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.link = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::link::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#unlink
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Remove the files relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.unlink = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::unlink::Conversation::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.files#updateById
             * @methodOf lbServices.Conversation.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartFile` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("SmartFile");
          var action = TargetResource["::updateById::Conversation::files"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Conversation.users
     * @header lbServices.Conversation.users
     * @object
     * @description
     *
     * The object `Conversation.users` groups methods
     * manipulating `SbUser` instances related to `Conversation`.
     *
     * Call {@link lbServices.Conversation#users Conversation.users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Conversation#users
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Queries users of Conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#count
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Counts users of Conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.users.count = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::count::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#create
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.create = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::create::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#createMany
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.createMany = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::createMany::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#destroyAll
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Deletes all users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.destroyAll = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::delete::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#destroyById
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Delete a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.destroyById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::destroyById::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#exists
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Check the existence of users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.exists = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::exists::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#findById
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Find a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.findById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::findById::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#link
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Add a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.link = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::link::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#unlink
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Remove the users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.unlink = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::unlink::Conversation::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.users#updateById
             * @methodOf lbServices.Conversation.users
             *
             * @description
             *
             * Update a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.users.updateById = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::updateById::Conversation::users"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Conversation.metadata
     * @header lbServices.Conversation.metadata
     * @object
     * @description
     *
     * The object `Conversation.metadata` groups methods
     * manipulating `ConversationSbUser` instances related to `Conversation`.
     *
     * Call {@link lbServices.Conversation#metadata Conversation.metadata()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Conversation#metadata
             * @methodOf lbServices.Conversation
             *
             * @description
             *
             * Queries metadata of Conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R.metadata = function() {
          var TargetResource = $injector.get("ConversationSbUser");
          var action = TargetResource["::get::Conversation::metadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.metadata#count
             * @methodOf lbServices.Conversation.metadata
             *
             * @description
             *
             * Counts metadata of Conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.metadata.count = function() {
          var TargetResource = $injector.get("ConversationSbUser");
          var action = TargetResource["::count::Conversation::metadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.metadata#create
             * @methodOf lbServices.Conversation.metadata
             *
             * @description
             *
             * Creates a new instance in metadata of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R.metadata.create = function() {
          var TargetResource = $injector.get("ConversationSbUser");
          var action = TargetResource["::create::Conversation::metadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.metadata#createMany
             * @methodOf lbServices.Conversation.metadata
             *
             * @description
             *
             * Creates a new instance in metadata of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R.metadata.createMany = function() {
          var TargetResource = $injector.get("ConversationSbUser");
          var action = TargetResource["::createMany::Conversation::metadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.metadata#destroyAll
             * @methodOf lbServices.Conversation.metadata
             *
             * @description
             *
             * Deletes all metadata of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.metadata.destroyAll = function() {
          var TargetResource = $injector.get("ConversationSbUser");
          var action = TargetResource["::delete::Conversation::metadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.metadata#destroyById
             * @methodOf lbServices.Conversation.metadata
             *
             * @description
             *
             * Delete a related item by id for metadata.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for metadata
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.metadata.destroyById = function() {
          var TargetResource = $injector.get("ConversationSbUser");
          var action = TargetResource["::destroyById::Conversation::metadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.metadata#findById
             * @methodOf lbServices.Conversation.metadata
             *
             * @description
             *
             * Find a related item by id for metadata.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for metadata
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R.metadata.findById = function() {
          var TargetResource = $injector.get("ConversationSbUser");
          var action = TargetResource["::findById::Conversation::metadata"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Conversation.metadata#updateById
             * @methodOf lbServices.Conversation.metadata
             *
             * @description
             *
             * Update a related item by id for metadata.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Conversation id
             *
             *  - `fk` – `{*}` - Foreign key for metadata
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R.metadata.updateById = function() {
          var TargetResource = $injector.get("ConversationSbUser");
          var action = TargetResource["::updateById::Conversation::metadata"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ConversationSbUser
 * @header lbServices.ConversationSbUser
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ConversationSbUser` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ConversationSbUser",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ConversationSbUsers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ConversationSbUser.conversation() instead.
            "prototype$__get__conversation": {
              url: urlBase + "/ConversationSbUsers/:id/conversation",
              method: "GET",
            },

            // INTERNAL. Use ConversationSbUser.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/ConversationSbUsers/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#create
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ConversationSbUsers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#patchOrCreate
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ConversationSbUsers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#replaceOrCreate
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ConversationSbUsers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#upsertWithWhere
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ConversationSbUsers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#exists
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ConversationSbUsers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#findById
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ConversationSbUsers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#replaceById
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ConversationSbUsers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#find
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ConversationSbUsers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#findOne
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ConversationSbUsers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#updateAll
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ConversationSbUsers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#deleteById
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ConversationSbUsers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#count
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ConversationSbUsers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#prototype$patchAttributes
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ConversationSbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ConversationSbUsers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#createChangeStream
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ConversationSbUsers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#createMany
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ConversationSbUsers",
              method: "POST",
            },

            // INTERNAL. Use Conversation.metadata.findById() instead.
            "::findById::Conversation::metadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/metadata/:fk",
              method: "GET",
            },

            // INTERNAL. Use Conversation.metadata.destroyById() instead.
            "::destroyById::Conversation::metadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/metadata/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.metadata.updateById() instead.
            "::updateById::Conversation::metadata": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Conversations/:id/metadata/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Conversation.metadata() instead.
            "::get::Conversation::metadata": {
              isArray: true,
              url: urlBase + "/Conversations/:id/metadata",
              method: "GET",
            },

            // INTERNAL. Use Conversation.metadata.create() instead.
            "::create::Conversation::metadata": {
              url: urlBase + "/Conversations/:id/metadata",
              method: "POST",
            },

            // INTERNAL. Use Conversation.metadata.createMany() instead.
            "::createMany::Conversation::metadata": {
              isArray: true,
              url: urlBase + "/Conversations/:id/metadata",
              method: "POST",
            },

            // INTERNAL. Use Conversation.metadata.destroyAll() instead.
            "::delete::Conversation::metadata": {
              url: urlBase + "/Conversations/:id/metadata",
              method: "DELETE",
            },

            // INTERNAL. Use Conversation.metadata.count() instead.
            "::count::Conversation::metadata": {
              url: urlBase + "/Conversations/:id/metadata/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#upsert
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#updateOrCreate
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#patchOrCreateWithWhere
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#update
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#destroyById
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#removeById
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#prototype$updateAttributes
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ConversationSbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConversationSbUser` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ConversationSbUser#modelName
        * @propertyOf lbServices.ConversationSbUser
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ConversationSbUser`.
        */
        R.modelName = "ConversationSbUser";


            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#conversation
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Fetches belongsTo relation conversation.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ConversationSbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Conversation` object.)
             * </em>
             */
        R.conversation = function() {
          var TargetResource = $injector.get("Conversation");
          var action = TargetResource["::get::ConversationSbUser::conversation"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ConversationSbUser#user
             * @methodOf lbServices.ConversationSbUser
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ConversationSbUser id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::ConversationSbUser::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PersonalEvent
 * @header lbServices.PersonalEvent
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PersonalEvent` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PersonalEvent",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PersonalEvents/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PersonalEvent.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/PersonalEvents/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#create
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PersonalEvents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#patchOrCreate
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/PersonalEvents",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#replaceOrCreate
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PersonalEvents/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#upsertWithWhere
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PersonalEvents/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#exists
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PersonalEvents/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#findById
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PersonalEvents/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#replaceById
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PersonalEvents/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#find
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PersonalEvents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#findOne
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PersonalEvents/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#updateAll
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PersonalEvents/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#deleteById
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PersonalEvents/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#count
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PersonalEvents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#prototype$patchAttributes
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersonalEvent id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/PersonalEvents/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#createChangeStream
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PersonalEvents/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#createMany
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PersonalEvents",
              method: "POST",
            },

            // INTERNAL. Use SbUser.events.findById() instead.
            "::findById::SbUser::events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/events/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.events.destroyById() instead.
            "::destroyById::SbUser::events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/events/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.events.updateById() instead.
            "::updateById::SbUser::events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/events/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.events() instead.
            "::get::SbUser::events": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/events",
              method: "GET",
            },

            // INTERNAL. Use SbUser.events.create() instead.
            "::create::SbUser::events": {
              url: urlBase + "/SbUsers/:id/events",
              method: "POST",
            },

            // INTERNAL. Use SbUser.events.createMany() instead.
            "::createMany::SbUser::events": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/events",
              method: "POST",
            },

            // INTERNAL. Use SbUser.events.destroyAll() instead.
            "::delete::SbUser::events": {
              url: urlBase + "/SbUsers/:id/events",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.events.count() instead.
            "::count::SbUser::events": {
              url: urlBase + "/SbUsers/:id/events/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#upsert
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#updateOrCreate
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#patchOrCreateWithWhere
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#update
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#destroyById
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#removeById
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#prototype$updateAttributes
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersonalEvent id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PersonalEvent` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PersonalEvent#modelName
        * @propertyOf lbServices.PersonalEvent
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PersonalEvent`.
        */
        R.modelName = "PersonalEvent";


            /**
             * @ngdoc method
             * @name lbServices.PersonalEvent#user
             * @methodOf lbServices.PersonalEvent
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersonalEvent id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::PersonalEvent::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Form
 * @header lbServices.Form
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Form` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Form",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Forms/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Form#create
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Forms",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#patchOrCreate
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Forms",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#replaceOrCreate
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Forms/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#upsertWithWhere
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Forms/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#exists
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Forms/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#findById
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Forms/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#replaceById
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Forms/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#find
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Forms",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#findOne
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Forms/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#updateAll
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Forms/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#deleteById
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Forms/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#count
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Forms/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#prototype$patchAttributes
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Form id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Forms/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#createChangeStream
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Forms/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#publicSupport
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Sends an email from the SmartBrokr public facing website
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `form` – `{object=}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "publicSupport": {
              url: urlBase + "/Forms/support",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Form#createMany
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Forms",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Form#upsert
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Form#updateOrCreate
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Form#patchOrCreateWithWhere
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Form#update
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Form#destroyById
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Form#removeById
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Form#prototype$updateAttributes
             * @methodOf lbServices.Form
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Form id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Form` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Form#modelName
        * @propertyOf lbServices.Form
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Form`.
        */
        R.modelName = "Form";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SmartTask
 * @header lbServices.SmartTask
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SmartTask` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SmartTask",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SmartTasks/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#create
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SmartTasks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#patchOrCreate
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SmartTasks",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#replaceOrCreate
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SmartTasks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#upsertWithWhere
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SmartTasks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#exists
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SmartTasks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#findById
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SmartTasks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#replaceById
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SmartTasks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#find
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SmartTasks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#findOne
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SmartTasks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#updateAll
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SmartTasks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#deleteById
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SmartTasks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#count
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SmartTasks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#prototype$patchAttributes
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartTask id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SmartTasks/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#createChangeStream
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SmartTasks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#createMany
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SmartTasks",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SmartTask#upsert
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#updateOrCreate
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#patchOrCreateWithWhere
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#update
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#destroyById
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#removeById
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SmartTask#prototype$updateAttributes
             * @methodOf lbServices.SmartTask
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartTask id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartTask` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SmartTask#modelName
        * @propertyOf lbServices.SmartTask
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SmartTask`.
        */
        R.modelName = "SmartTask";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AgencySupplier
 * @header lbServices.AgencySupplier
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AgencySupplier` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AgencySupplier",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AgencySuppliers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use AgencySupplier.supplier() instead.
            "prototype$__get__supplier": {
              url: urlBase + "/AgencySuppliers/:id/supplier",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#prototype$__get__agencyPerson
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Fetches belongsTo relation agencyPerson.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "prototype$__get__agencyPerson": {
              url: urlBase + "/AgencySuppliers/:id/agencyPerson",
              method: "GET",
            },

            // INTERNAL. Use AgencySupplier.folders.findById() instead.
            "prototype$__findById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencySuppliers/:id/folders/:fk",
              method: "GET",
            },

            // INTERNAL. Use AgencySupplier.folders.destroyById() instead.
            "prototype$__destroyById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencySuppliers/:id/folders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AgencySupplier.folders.updateById() instead.
            "prototype$__updateById__folders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AgencySuppliers/:id/folders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AgencySupplier.folders() instead.
            "prototype$__get__folders": {
              isArray: true,
              url: urlBase + "/AgencySuppliers/:id/folders",
              method: "GET",
            },

            // INTERNAL. Use AgencySupplier.folders.create() instead.
            "prototype$__create__folders": {
              url: urlBase + "/AgencySuppliers/:id/folders",
              method: "POST",
            },

            // INTERNAL. Use AgencySupplier.folders.destroyAll() instead.
            "prototype$__delete__folders": {
              url: urlBase + "/AgencySuppliers/:id/folders",
              method: "DELETE",
            },

            // INTERNAL. Use AgencySupplier.folders.count() instead.
            "prototype$__count__folders": {
              url: urlBase + "/AgencySuppliers/:id/folders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#create
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AgencySuppliers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#patchOrCreate
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/AgencySuppliers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#replaceOrCreate
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AgencySuppliers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#upsertWithWhere
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AgencySuppliers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#exists
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AgencySuppliers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#findById
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AgencySuppliers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#replaceById
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AgencySuppliers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#find
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AgencySuppliers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#findOne
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AgencySuppliers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#updateAll
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AgencySuppliers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#deleteById
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AgencySuppliers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#count
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AgencySuppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#prototype$patchAttributes
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/AgencySuppliers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#createChangeStream
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AgencySuppliers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#sentEmails
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Gets the emails sent by the buyer
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "sentEmails": {
              isArray: true,
              url: urlBase + "/AgencySuppliers/:id/sent-emails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#createMany
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AgencySuppliers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.supplierAgencies.findById() instead.
            "::findById::Supplier::supplierAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierAgencies/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierAgencies.destroyById() instead.
            "::destroyById::Supplier::supplierAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierAgencies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.supplierAgencies.updateById() instead.
            "::updateById::Supplier::supplierAgencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/supplierAgencies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.supplierAgencies() instead.
            "::get::Supplier::supplierAgencies": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/supplierAgencies",
              method: "GET",
            },

            // INTERNAL. Use Supplier.supplierAgencies.create() instead.
            "::create::Supplier::supplierAgencies": {
              url: urlBase + "/Suppliers/:id/supplierAgencies",
              method: "POST",
            },

            // INTERNAL. Use Supplier.supplierAgencies.createMany() instead.
            "::createMany::Supplier::supplierAgencies": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/supplierAgencies",
              method: "POST",
            },

            // INTERNAL. Use Supplier.supplierAgencies.destroyAll() instead.
            "::delete::Supplier::supplierAgencies": {
              url: urlBase + "/Suppliers/:id/supplierAgencies",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.supplierAgencies.count() instead.
            "::count::Supplier::supplierAgencies": {
              url: urlBase + "/Suppliers/:id/supplierAgencies/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#upsert
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#updateOrCreate
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#patchOrCreateWithWhere
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#update
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#destroyById
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#removeById
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#prototype$updateAttributes
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencySupplier` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AgencySupplier#modelName
        * @propertyOf lbServices.AgencySupplier
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AgencySupplier`.
        */
        R.modelName = "AgencySupplier";


            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#supplier
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Fetches belongsTo relation supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.supplier = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::AgencySupplier::supplier"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AgencySupplier.folders
     * @header lbServices.AgencySupplier.folders
     * @object
     * @description
     *
     * The object `AgencySupplier.folders` groups methods
     * manipulating `Folder` instances related to `AgencySupplier`.
     *
     * Call {@link lbServices.AgencySupplier#folders AgencySupplier.folders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier#folders
             * @methodOf lbServices.AgencySupplier
             *
             * @description
             *
             * Queries folders of AgencySupplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::AgencySupplier::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier.folders#count
             * @methodOf lbServices.AgencySupplier.folders
             *
             * @description
             *
             * Counts folders of AgencySupplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.folders.count = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::count::AgencySupplier::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier.folders#create
             * @methodOf lbServices.AgencySupplier.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::AgencySupplier::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier.folders#createMany
             * @methodOf lbServices.AgencySupplier.folders
             *
             * @description
             *
             * Creates a new instance in folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::AgencySupplier::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier.folders#destroyAll
             * @methodOf lbServices.AgencySupplier.folders
             *
             * @description
             *
             * Deletes all folders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyAll = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::delete::AgencySupplier::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier.folders#destroyById
             * @methodOf lbServices.AgencySupplier.folders
             *
             * @description
             *
             * Delete a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folders.destroyById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroyById::AgencySupplier::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier.folders#findById
             * @methodOf lbServices.AgencySupplier.folders
             *
             * @description
             *
             * Find a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.findById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::findById::AgencySupplier::folders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AgencySupplier.folders#updateById
             * @methodOf lbServices.AgencySupplier.folders
             *
             * @description
             *
             * Update a related item by id for folders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AgencySupplier id
             *
             *  - `fk` – `{*}` - Foreign key for folders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folders.updateById = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::updateById::AgencySupplier::folders"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Docusign
 * @header lbServices.Docusign
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Docusign` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Docusign",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Docusign/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Docusign#auth
             * @methodOf lbServices.Docusign
             *
             * @description
             *
             * Method called by docusign after a user authorizes smartbrokr to be used with docusign
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "auth": {
              url: urlBase + "/Docusign/auth",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Docusign#connect
             * @methodOf lbServices.Docusign
             *
             * @description
             *
             * Redirects the user to the docusign login page to authorize the integration
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "connect": {
              url: urlBase + "/Docusign/connect",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Docusign#disconnect
             * @methodOf lbServices.Docusign
             *
             * @description
             *
             * Disconnects the docusign integration from smartbrokr
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "disconnect": {
              url: urlBase + "/Docusign/disconnect",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Docusign#getStatus
             * @methodOf lbServices.Docusign
             *
             * @description
             *
             * Get the status of the docusign integration
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `connected` – `{boolean=}` -
             */
            "getStatus": {
              url: urlBase + "/Docusign/status",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Docusign#modelName
        * @propertyOf lbServices.Docusign
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Docusign`.
        */
        R.modelName = "Docusign";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AccountWordpress
 * @header lbServices.AccountWordpress
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AccountWordpress` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AccountWordpress",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AccountWordpresses/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use AccountWordpress.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/AccountWordpresses/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#prototype$__get__accessToken
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Fetches belongsTo relation accessToken.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "prototype$__get__accessToken": {
              url: urlBase + "/AccountWordpresses/:id/accessToken",
              method: "GET",
            },

            // INTERNAL. Use AccountWordpress.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use AccountWordpress.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AccountWordpress.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AccountWordpress.brokers.link() instead.
            "prototype$__link__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AccountWordpress.brokers.unlink() instead.
            "prototype$__unlink__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AccountWordpress.brokers.exists() instead.
            "prototype$__exists__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AccountWordpresses/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use AccountWordpress.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/AccountWordpresses/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use AccountWordpress.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/AccountWordpresses/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use AccountWordpress.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/AccountWordpresses/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use AccountWordpress.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/AccountWordpresses/:id/brokers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#create
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AccountWordpresses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#patchOrCreate
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/AccountWordpresses",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#replaceOrCreate
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AccountWordpresses/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#upsertWithWhere
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AccountWordpresses/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#exists
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AccountWordpresses/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#findById
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AccountWordpresses/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#replaceById
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AccountWordpresses/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#find
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AccountWordpresses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#findOne
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AccountWordpresses/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#updateAll
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AccountWordpresses/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#deleteById
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AccountWordpresses/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#count
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AccountWordpresses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#prototype$patchAttributes
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/AccountWordpresses/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#createChangeStream
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AccountWordpresses/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#getRemoteOptions
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `fields` – `{*=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "getRemoteOptions": {
              url: urlBase + "/AccountWordpresses/:id/getRemoteOptions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#finishRemoteLogin
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `token` – `{string}` -
             *
             *  - `oldToken` – `{string=}` -
             *
             *  - `isAccepted` – `{boolean}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with newly created access token id.
             */
            "finishRemoteLogin": {
              url: urlBase + "/AccountWordpresses/finishRemoteLogin",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#remoteLogin
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result of the operation, as sent by our Wordpress plugin
             */
            "remoteLogin": {
              url: urlBase + "/AccountWordpresses/:id/remoteLogin",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#remoteUpdate
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `type` – `{string}` -
             *
             *  - `data` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result of the operation, as sent by our Wordpress plugin
             */
            "remoteUpdate": {
              url: urlBase + "/AccountWordpresses/:id/postUpdate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpGetRecaptcha
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `access_token` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "wpGetRecaptcha": {
              url: urlBase + "/AccountWordpresses/wp/getCaptcha",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpPostEmailData
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "wpPostEmailData": {
              url: urlBase + "/AccountWordpresses/wp/postEmailData",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpVerifyRecaptcha
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `captcha` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result of the operation, as sent by the Google API
             */
            "wpVerifyRecaptcha": {
              url: urlBase + "/AccountWordpresses/wp/verifyCaptcha",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#RestoreDefault
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `page` – `{string=}` -
             *
             *  - `lang` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result of the operation, as sent by our Wordpress plugin
             */
            "RestoreDefault": {
              url: urlBase + "/AccountWordpresses/:id:/restoreDefault",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#RollbackLatestChange
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             *  - `page` – `{string=}` -
             *
             *  - `lang` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result of the operation, as sent by our Wordpress plugin
             */
            "RollbackLatestChange": {
              url: urlBase + "/AccountWordpresses/:id/rollback",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#RecoverListingClicks
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result of the operation, as sent by our Wordpress plugin
             */
            "RecoverListingClicks": {
              url: urlBase + "/AccountWordpresses/:id/recover-listing-clicks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpGetCountries
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "wpGetCountries": {
              isArray: true,
              url: urlBase + "/AccountWordpresses/wp/countries",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpGetMap
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `user_id` – `{string}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `lat` – `{number}` -
             *
             *  - `lng` – `{number}` -
             *
             *  - `details_url` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "wpGetMap": {
              url: urlBase + "/AccountWordpresses/wp/getMap",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpGetMapSingle
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `user_id` – `{string}` -
             *
             *  - `address` – `{string}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "wpGetMapSingle": {
              url: urlBase + "/AccountWordpresses/wp/getMapSingle",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpGetMapMultiple
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `agencyId` – `{string}` -
             *
             *  - `user_id` – `{string}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `skip` – `{number=}` -
             *
             *  - `city` – `{string=}` -
             *
             *  - `province` – `{string=}` -
             *
             *  - `country` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "wpGetMapMultiple": {
              url: urlBase + "/AccountWordpresses/wp/getMapMultiple",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpGetSuppliersMap
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `user_id` – `{string}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `category` – `{string=}` -
             *
             *  - `search` – `{string=}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "wpGetSuppliersMap": {
              url: urlBase + "/AccountWordpresses/wp/getSuppliersMap",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#wpGetFindUsMap
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `user_id` – `{string=}` -
             *
             *  - `lang` – `{string=}` -
             *
             *  - `officeId` – `{string=}` -
             *
             *  - `type` – `{string=}` -
             *
             *  - `lat` – `{number=}` -
             *
             *  - `lng` – `{number=}` -
             *
             *  - `country` – `{string=}` -
             *
             *  - `province` – `{string=}` -
             *
             *  - `city` – `{string=}` -
             *
             *  - `details_url` – `{string=}` -
             *
             *  - `limit` – `{number=}` -
             *
             *  - `skip` – `{number=}` -
             *
             *  - `options` – `{object=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "wpGetFindUsMap": {
              url: urlBase + "/AccountWordpresses/wp/getFindUsMap",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#createMany
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AccountWordpresses",
              method: "POST",
            },

            // INTERNAL. Use Account.wordpress() instead.
            "::get::Account::wordpress": {
              url: urlBase + "/Accounts/:id/wordpress",
              method: "GET",
            },

            // INTERNAL. Use Account.wordpress.create() instead.
            "::create::Account::wordpress": {
              url: urlBase + "/Accounts/:id/wordpress",
              method: "POST",
            },

            // INTERNAL. Use Account.wordpress.createMany() instead.
            "::createMany::Account::wordpress": {
              isArray: true,
              url: urlBase + "/Accounts/:id/wordpress",
              method: "POST",
            },

            // INTERNAL. Use Account.wordpress.update() instead.
            "::update::Account::wordpress": {
              url: urlBase + "/Accounts/:id/wordpress",
              method: "PUT",
            },

            // INTERNAL. Use Account.wordpress.destroy() instead.
            "::destroy::Account::wordpress": {
              url: urlBase + "/Accounts/:id/wordpress",
              method: "DELETE",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#upsert
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#updateOrCreate
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#patchOrCreateWithWhere
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#update
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#destroyById
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#removeById
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#prototype$updateAttributes
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountWordpress` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AccountWordpress#modelName
        * @propertyOf lbServices.AccountWordpress
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AccountWordpress`.
        */
        R.modelName = "AccountWordpress";


            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#account
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::AccountWordpress::account"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AccountWordpress.brokers
     * @header lbServices.AccountWordpress.brokers
     * @object
     * @description
     *
     * The object `AccountWordpress.brokers` groups methods
     * manipulating `Broker` instances related to `AccountWordpress`.
     *
     * Call {@link lbServices.AccountWordpress#brokers AccountWordpress.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress#brokers
             * @methodOf lbServices.AccountWordpress
             *
             * @description
             *
             * Queries brokers of AccountWordpress.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#count
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Counts brokers of AccountWordpress.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#create
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#createMany
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#destroyAll
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#destroyById
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#exists
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Check the existence of brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#findById
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#link
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Add a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#unlink
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Remove the brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountWordpress.brokers#updateById
             * @methodOf lbServices.AccountWordpress.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountWordpress id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::AccountWordpress::brokers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ListingSupplier
 * @header lbServices.ListingSupplier
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ListingSupplier` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ListingSupplier",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ListingSuppliers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ListingSupplier.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/ListingSuppliers/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use ListingSupplier.supplier() instead.
            "prototype$__get__supplier": {
              url: urlBase + "/ListingSuppliers/:id/supplier",
              method: "GET",
            },

            // INTERNAL. Use ListingSupplier.listingSeller() instead.
            "prototype$__get__listingSeller": {
              url: urlBase + "/ListingSuppliers/:id/listingSeller",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#create
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ListingSuppliers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#patchOrCreate
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/ListingSuppliers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#replaceOrCreate
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ListingSuppliers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#upsertWithWhere
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ListingSuppliers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#exists
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ListingSuppliers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#findById
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ListingSuppliers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#replaceById
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ListingSuppliers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#find
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ListingSuppliers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#findOne
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ListingSuppliers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#updateAll
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ListingSuppliers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#deleteById
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ListingSuppliers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#count
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ListingSuppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#prototype$patchAttributes
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/ListingSuppliers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#createChangeStream
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ListingSuppliers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#createMany
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ListingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.listingSuppliers.findById() instead.
            "::findById::Supplier::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/listingSuppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Supplier.listingSuppliers.destroyById() instead.
            "::destroyById::Supplier::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/listingSuppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.listingSuppliers.updateById() instead.
            "::updateById::Supplier::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Suppliers/:id/listingSuppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Supplier.listingSuppliers() instead.
            "::get::Supplier::listingSuppliers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/listingSuppliers",
              method: "GET",
            },

            // INTERNAL. Use Supplier.listingSuppliers.create() instead.
            "::create::Supplier::listingSuppliers": {
              url: urlBase + "/Suppliers/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.listingSuppliers.createMany() instead.
            "::createMany::Supplier::listingSuppliers": {
              isArray: true,
              url: urlBase + "/Suppliers/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use Supplier.listingSuppliers.destroyAll() instead.
            "::delete::Supplier::listingSuppliers": {
              url: urlBase + "/Suppliers/:id/listingSuppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Supplier.listingSuppliers.count() instead.
            "::count::Supplier::listingSuppliers": {
              url: urlBase + "/Suppliers/:id/listingSuppliers/count",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.findById() instead.
            "::findById::ListingSeller::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingSellers/:id/listingSuppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.destroyById() instead.
            "::destroyById::ListingSeller::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingSellers/:id/listingSuppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.updateById() instead.
            "::updateById::ListingSeller::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/ListingSellers/:id/listingSuppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers() instead.
            "::get::ListingSeller::listingSuppliers": {
              isArray: true,
              url: urlBase + "/ListingSellers/:id/listingSuppliers",
              method: "GET",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.create() instead.
            "::create::ListingSeller::listingSuppliers": {
              url: urlBase + "/ListingSellers/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.createMany() instead.
            "::createMany::ListingSeller::listingSuppliers": {
              isArray: true,
              url: urlBase + "/ListingSellers/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.destroyAll() instead.
            "::delete::ListingSeller::listingSuppliers": {
              url: urlBase + "/ListingSellers/:id/listingSuppliers",
              method: "DELETE",
            },

            // INTERNAL. Use ListingSeller.listingSuppliers.count() instead.
            "::count::ListingSeller::listingSuppliers": {
              url: urlBase + "/ListingSellers/:id/listingSuppliers/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingSuppliers.findById() instead.
            "::findById::Listing::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingSuppliers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingSuppliers.destroyById() instead.
            "::destroyById::Listing::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingSuppliers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingSuppliers.updateById() instead.
            "::updateById::Listing::listingSuppliers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/listingSuppliers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.listingSuppliers() instead.
            "::get::Listing::listingSuppliers": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingSuppliers",
              method: "GET",
            },

            // INTERNAL. Use Listing.listingSuppliers.create() instead.
            "::create::Listing::listingSuppliers": {
              url: urlBase + "/Listings/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingSuppliers.createMany() instead.
            "::createMany::Listing::listingSuppliers": {
              isArray: true,
              url: urlBase + "/Listings/:id/listingSuppliers",
              method: "POST",
            },

            // INTERNAL. Use Listing.listingSuppliers.destroyAll() instead.
            "::delete::Listing::listingSuppliers": {
              url: urlBase + "/Listings/:id/listingSuppliers",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.listingSuppliers.count() instead.
            "::count::Listing::listingSuppliers": {
              url: urlBase + "/Listings/:id/listingSuppliers/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#upsert
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#updateOrCreate
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#patchOrCreateWithWhere
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#update
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#destroyById
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#removeById
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#prototype$updateAttributes
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSupplier` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ListingSupplier#modelName
        * @propertyOf lbServices.ListingSupplier
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ListingSupplier`.
        */
        R.modelName = "ListingSupplier";


            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#listing
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::ListingSupplier::listing"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#supplier
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Fetches belongsTo relation supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.supplier = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::ListingSupplier::supplier"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.ListingSupplier#listingSeller
             * @methodOf lbServices.ListingSupplier
             *
             * @description
             *
             * Fetches belongsTo relation listingSeller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ListingSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ListingSeller` object.)
             * </em>
             */
        R.listingSeller = function() {
          var TargetResource = $injector.get("ListingSeller");
          var action = TargetResource["::get::ListingSupplier::listingSeller"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SmartSearch
 * @header lbServices.SmartSearch
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SmartSearch` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SmartSearch",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SmartSearches/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SmartSearch.brokers.findById() instead.
            "prototype$__findById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.brokers.destroyById() instead.
            "prototype$__destroyById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.brokers.updateById() instead.
            "prototype$__updateById__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.brokers.link() instead.
            "prototype$__link__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.brokers.unlink() instead.
            "prototype$__unlink__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.brokers.exists() instead.
            "prototype$__exists__brokers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/brokers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.findById() instead.
            "prototype$__findById__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.destroyById() instead.
            "prototype$__destroyById__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.updateById() instead.
            "prototype$__updateById__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.link() instead.
            "prototype$__link__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.unlink() instead.
            "prototype$__unlink__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.exists() instead.
            "prototype$__exists__agencyMembers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyMembers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.findById() instead.
            "prototype$__findById__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.destroyById() instead.
            "prototype$__destroyById__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.updateById() instead.
            "prototype$__updateById__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.link() instead.
            "prototype$__link__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.unlink() instead.
            "prototype$__unlink__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.exists() instead.
            "prototype$__exists__agencyOwners": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencyOwners/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SmartSearch.agencies.findById() instead.
            "prototype$__findById__agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/:fk",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencies.destroyById() instead.
            "prototype$__destroyById__agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencies.updateById() instead.
            "prototype$__updateById__agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencies.link() instead.
            "prototype$__link__agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SmartSearch.agencies.unlink() instead.
            "prototype$__unlink__agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencies.exists() instead.
            "prototype$__exists__agencies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SmartSearches/:id/agencies/rel/:fk",
              method: "HEAD",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#prototype$__get__related
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Fetches belongsTo relation related.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "prototype$__get__related": {
              url: urlBase + "/SmartSearches/:id/related",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.brokers() instead.
            "prototype$__get__brokers": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/brokers",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.brokers.create() instead.
            "prototype$__create__brokers": {
              url: urlBase + "/SmartSearches/:id/brokers",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.brokers.destroyAll() instead.
            "prototype$__delete__brokers": {
              url: urlBase + "/SmartSearches/:id/brokers",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.brokers.count() instead.
            "prototype$__count__brokers": {
              url: urlBase + "/SmartSearches/:id/brokers/count",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyMembers() instead.
            "prototype$__get__agencyMembers": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencyMembers",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.create() instead.
            "prototype$__create__agencyMembers": {
              url: urlBase + "/SmartSearches/:id/agencyMembers",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.destroyAll() instead.
            "prototype$__delete__agencyMembers": {
              url: urlBase + "/SmartSearches/:id/agencyMembers",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyMembers.count() instead.
            "prototype$__count__agencyMembers": {
              url: urlBase + "/SmartSearches/:id/agencyMembers/count",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyOwners() instead.
            "prototype$__get__agencyOwners": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencyOwners",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.create() instead.
            "prototype$__create__agencyOwners": {
              url: urlBase + "/SmartSearches/:id/agencyOwners",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.destroyAll() instead.
            "prototype$__delete__agencyOwners": {
              url: urlBase + "/SmartSearches/:id/agencyOwners",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencyOwners.count() instead.
            "prototype$__count__agencyOwners": {
              url: urlBase + "/SmartSearches/:id/agencyOwners/count",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencies() instead.
            "prototype$__get__agencies": {
              isArray: true,
              url: urlBase + "/SmartSearches/:id/agencies",
              method: "GET",
            },

            // INTERNAL. Use SmartSearch.agencies.create() instead.
            "prototype$__create__agencies": {
              url: urlBase + "/SmartSearches/:id/agencies",
              method: "POST",
            },

            // INTERNAL. Use SmartSearch.agencies.destroyAll() instead.
            "prototype$__delete__agencies": {
              url: urlBase + "/SmartSearches/:id/agencies",
              method: "DELETE",
            },

            // INTERNAL. Use SmartSearch.agencies.count() instead.
            "prototype$__count__agencies": {
              url: urlBase + "/SmartSearches/:id/agencies/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#create
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SmartSearches",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#patchOrCreate
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SmartSearches",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#replaceOrCreate
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SmartSearches/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#upsertWithWhere
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SmartSearches/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#exists
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SmartSearches/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#findById
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SmartSearches/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#replaceById
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SmartSearches/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#find
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SmartSearches",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#findOne
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SmartSearches/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#updateAll
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SmartSearches/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#deleteById
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SmartSearches/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#count
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SmartSearches/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#prototype$patchAttributes
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SmartSearches/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#createChangeStream
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SmartSearches/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#createMany
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SmartSearches",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#upsert
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#updateOrCreate
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#patchOrCreateWithWhere
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#update
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#destroyById
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#removeById
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#prototype$updateAttributes
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SmartSearch` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SmartSearch#modelName
        * @propertyOf lbServices.SmartSearch
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SmartSearch`.
        */
        R.modelName = "SmartSearch";

    /**
     * @ngdoc object
     * @name lbServices.SmartSearch.brokers
     * @header lbServices.SmartSearch.brokers
     * @object
     * @description
     *
     * The object `SmartSearch.brokers` groups methods
     * manipulating `Broker` instances related to `SmartSearch`.
     *
     * Call {@link lbServices.SmartSearch#brokers SmartSearch.brokers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#brokers
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Queries brokers of SmartSearch.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#count
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Counts brokers of SmartSearch.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.brokers.count = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::count::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#create
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.create = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::create::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#createMany
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Creates a new instance in brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.createMany = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::createMany::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#destroyAll
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Deletes all brokers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyAll = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::delete::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#destroyById
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Delete a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.destroyById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::destroyById::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#exists
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Check the existence of brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.exists = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::exists::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#findById
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Find a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.findById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::findById::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#link
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Add a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.link = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::link::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#unlink
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Remove the brokers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.brokers.unlink = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::unlink::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.brokers#updateById
             * @methodOf lbServices.SmartSearch.brokers
             *
             * @description
             *
             * Update a related item by id for brokers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `fk` – `{*}` - Foreign key for brokers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.brokers.updateById = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::updateById::SmartSearch::brokers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SmartSearch.agencyMembers
     * @header lbServices.SmartSearch.agencyMembers
     * @object
     * @description
     *
     * The object `SmartSearch.agencyMembers` groups methods
     * manipulating `AgencyMember` instances related to `SmartSearch`.
     *
     * Call {@link lbServices.SmartSearch#agencyMembers SmartSearch.agencyMembers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#agencyMembers
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Queries agencyMembers of SmartSearch.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMembers = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::get::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#count
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Counts agencyMembers of SmartSearch.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.agencyMembers.count = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::count::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#create
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Creates a new instance in agencyMembers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMembers.create = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::create::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#createMany
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Creates a new instance in agencyMembers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMembers.createMany = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::createMany::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#destroyAll
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Deletes all agencyMembers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyMembers.destroyAll = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::delete::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#destroyById
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Delete a related item by id for agencyMembers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyMembers.destroyById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::destroyById::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#exists
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Check the existence of agencyMembers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMembers.exists = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::exists::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#findById
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Find a related item by id for agencyMembers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMembers.findById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::findById::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#link
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Add a related item by id for agencyMembers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMembers.link = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::link::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#unlink
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Remove the agencyMembers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyMembers.unlink = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::unlink::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyMembers#updateById
             * @methodOf lbServices.SmartSearch.agencyMembers
             *
             * @description
             *
             * Update a related item by id for agencyMembers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `fk` – `{*}` - Foreign key for agencyMembers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyMember` object.)
             * </em>
             */
        R.agencyMembers.updateById = function() {
          var TargetResource = $injector.get("AgencyMember");
          var action = TargetResource["::updateById::SmartSearch::agencyMembers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SmartSearch.agencyOwners
     * @header lbServices.SmartSearch.agencyOwners
     * @object
     * @description
     *
     * The object `SmartSearch.agencyOwners` groups methods
     * manipulating `AgencyOwner` instances related to `SmartSearch`.
     *
     * Call {@link lbServices.SmartSearch#agencyOwners SmartSearch.agencyOwners()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#agencyOwners
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Queries agencyOwners of SmartSearch.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwners = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::get::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#count
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Counts agencyOwners of SmartSearch.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.agencyOwners.count = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::count::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#create
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Creates a new instance in agencyOwners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwners.create = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::create::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#createMany
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Creates a new instance in agencyOwners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwners.createMany = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::createMany::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#destroyAll
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Deletes all agencyOwners of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyOwners.destroyAll = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::delete::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#destroyById
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Delete a related item by id for agencyOwners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyOwners.destroyById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::destroyById::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#exists
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Check the existence of agencyOwners relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwners.exists = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::exists::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#findById
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Find a related item by id for agencyOwners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwners.findById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::findById::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#link
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Add a related item by id for agencyOwners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwners.link = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::link::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#unlink
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Remove the agencyOwners relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencyOwners.unlink = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::unlink::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencyOwners#updateById
             * @methodOf lbServices.SmartSearch.agencyOwners
             *
             * @description
             *
             * Update a related item by id for agencyOwners.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `fk` – `{*}` - Foreign key for agencyOwners
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AgencyOwner` object.)
             * </em>
             */
        R.agencyOwners.updateById = function() {
          var TargetResource = $injector.get("AgencyOwner");
          var action = TargetResource["::updateById::SmartSearch::agencyOwners"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SmartSearch.agencies
     * @header lbServices.SmartSearch.agencies
     * @object
     * @description
     *
     * The object `SmartSearch.agencies` groups methods
     * manipulating `Agency` instances related to `SmartSearch`.
     *
     * Call {@link lbServices.SmartSearch#agencies SmartSearch.agencies()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SmartSearch#agencies
             * @methodOf lbServices.SmartSearch
             *
             * @description
             *
             * Queries agencies of SmartSearch.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agencies = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::get::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#count
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Counts agencies of SmartSearch.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.agencies.count = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::count::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#create
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Creates a new instance in agencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agencies.create = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::create::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#createMany
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Creates a new instance in agencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agencies.createMany = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::createMany::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#destroyAll
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Deletes all agencies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencies.destroyAll = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::delete::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#destroyById
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Delete a related item by id for agencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencies
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencies.destroyById = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::destroyById::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#exists
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Check the existence of agencies relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencies
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agencies.exists = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::exists::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#findById
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Find a related item by id for agencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencies
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agencies.findById = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::findById::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#link
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Add a related item by id for agencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `fk` – `{*}` - Foreign key for agencies
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agencies.link = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::link::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#unlink
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Remove the agencies relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for agencies
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.agencies.unlink = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::unlink::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SmartSearch.agencies#updateById
             * @methodOf lbServices.SmartSearch.agencies
             *
             * @description
             *
             * Update a related item by id for agencies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SmartSearch id
             *
             *  - `fk` – `{*}` - Foreign key for agencies
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Agency` object.)
             * </em>
             */
        R.agencies.updateById = function() {
          var TargetResource = $injector.get("Agency");
          var action = TargetResource["::updateById::SmartSearch::agencies"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Notification
 * @header lbServices.Notification
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Notification` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Notification",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Notifications/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Notification.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/Notifications/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#prototype$__get__related
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Fetches belongsTo relation related.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "prototype$__get__related": {
              url: urlBase + "/Notifications/:id/related",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#create
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Notifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#patchOrCreate
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Notifications",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#replaceOrCreate
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Notifications/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#upsertWithWhere
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Notifications/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#exists
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Notifications/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#findById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Notifications/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#replaceById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Notifications/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#find
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Notifications",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#findOne
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Notifications/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#updateAll
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Notifications/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#deleteById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Notifications/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#count
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Notifications/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#prototype$patchAttributes
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Notifications/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#createChangeStream
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Notifications/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#createMany
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Notifications",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notifications.findById() instead.
            "::findById::SbUser::notifications": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notifications/:fk",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notifications.destroyById() instead.
            "::destroyById::SbUser::notifications": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notifications/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notifications.updateById() instead.
            "::updateById::SbUser::notifications": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SbUsers/:id/notifications/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SbUser.notifications() instead.
            "::get::SbUser::notifications": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notifications",
              method: "GET",
            },

            // INTERNAL. Use SbUser.notifications.create() instead.
            "::create::SbUser::notifications": {
              url: urlBase + "/SbUsers/:id/notifications",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notifications.createMany() instead.
            "::createMany::SbUser::notifications": {
              isArray: true,
              url: urlBase + "/SbUsers/:id/notifications",
              method: "POST",
            },

            // INTERNAL. Use SbUser.notifications.destroyAll() instead.
            "::delete::SbUser::notifications": {
              url: urlBase + "/SbUsers/:id/notifications",
              method: "DELETE",
            },

            // INTERNAL. Use SbUser.notifications.count() instead.
            "::count::SbUser::notifications": {
              url: urlBase + "/SbUsers/:id/notifications/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Notification#upsert
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#updateOrCreate
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#patchOrCreateWithWhere
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#update
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#destroyById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#removeById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#prototype$updateAttributes
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Notification#modelName
        * @propertyOf lbServices.Notification
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Notification`.
        */
        R.modelName = "Notification";


            /**
             * @ngdoc method
             * @name lbServices.Notification#user
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::Notification::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.HelpArticle
 * @header lbServices.HelpArticle
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `HelpArticle` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "HelpArticle",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/HelpArticles/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#create
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/HelpArticles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#patchOrCreate
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/HelpArticles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#replaceOrCreate
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/HelpArticles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#upsertWithWhere
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/HelpArticles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#exists
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/HelpArticles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#findById
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/HelpArticles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#replaceById
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/HelpArticles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#find
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/HelpArticles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#findOne
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/HelpArticles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#updateAll
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/HelpArticles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#deleteById
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/HelpArticles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#count
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/HelpArticles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#prototype$patchAttributes
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpArticle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/HelpArticles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#createChangeStream
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/HelpArticles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#createMany
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/HelpArticles",
              method: "POST",
            },

            // INTERNAL. Use HelpTopic._articles.findById() instead.
            "::findById::HelpTopic::_articles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/HelpTopics/:id/_articles/:fk",
              method: "GET",
            },

            // INTERNAL. Use HelpTopic._articles.destroyById() instead.
            "::destroyById::HelpTopic::_articles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/HelpTopics/:id/_articles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use HelpTopic._articles.updateById() instead.
            "::updateById::HelpTopic::_articles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/HelpTopics/:id/_articles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use HelpTopic._articles() instead.
            "::get::HelpTopic::_articles": {
              isArray: true,
              url: urlBase + "/HelpTopics/:id/_articles",
              method: "GET",
            },

            // INTERNAL. Use HelpTopic._articles.create() instead.
            "::create::HelpTopic::_articles": {
              url: urlBase + "/HelpTopics/:id/_articles",
              method: "POST",
            },

            // INTERNAL. Use HelpTopic._articles.createMany() instead.
            "::createMany::HelpTopic::_articles": {
              isArray: true,
              url: urlBase + "/HelpTopics/:id/_articles",
              method: "POST",
            },

            // INTERNAL. Use HelpTopic._articles.destroyAll() instead.
            "::delete::HelpTopic::_articles": {
              url: urlBase + "/HelpTopics/:id/_articles",
              method: "DELETE",
            },

            // INTERNAL. Use HelpTopic._articles.count() instead.
            "::count::HelpTopic::_articles": {
              url: urlBase + "/HelpTopics/:id/_articles/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#upsert
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#updateOrCreate
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#patchOrCreateWithWhere
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#update
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#destroyById
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#removeById
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.HelpArticle#prototype$updateAttributes
             * @methodOf lbServices.HelpArticle
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpArticle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.HelpArticle#modelName
        * @propertyOf lbServices.HelpArticle
        * @description
        * The name of the model represented by this $resource,
        * i.e. `HelpArticle`.
        */
        R.modelName = "HelpArticle";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.HelpTopic
 * @header lbServices.HelpTopic
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `HelpTopic` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "HelpTopic",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/HelpTopics/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use HelpTopic._articles.findById() instead.
            "prototype$__findById___articles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/HelpTopics/:id/_articles/:fk",
              method: "GET",
            },

            // INTERNAL. Use HelpTopic._articles.destroyById() instead.
            "prototype$__destroyById___articles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/HelpTopics/:id/_articles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use HelpTopic._articles.updateById() instead.
            "prototype$__updateById___articles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/HelpTopics/:id/_articles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use HelpTopic.folder() instead.
            "prototype$__get__folder": {
              url: urlBase + "/HelpTopics/:id/folder",
              method: "GET",
            },

            // INTERNAL. Use HelpTopic.folder.create() instead.
            "prototype$__create__folder": {
              url: urlBase + "/HelpTopics/:id/folder",
              method: "POST",
            },

            // INTERNAL. Use HelpTopic.folder.update() instead.
            "prototype$__update__folder": {
              url: urlBase + "/HelpTopics/:id/folder",
              method: "PUT",
            },

            // INTERNAL. Use HelpTopic.folder.destroy() instead.
            "prototype$__destroy__folder": {
              url: urlBase + "/HelpTopics/:id/folder",
              method: "DELETE",
            },

            // INTERNAL. Use HelpTopic._articles() instead.
            "prototype$__get___articles": {
              isArray: true,
              url: urlBase + "/HelpTopics/:id/_articles",
              method: "GET",
            },

            // INTERNAL. Use HelpTopic._articles.create() instead.
            "prototype$__create___articles": {
              url: urlBase + "/HelpTopics/:id/_articles",
              method: "POST",
            },

            // INTERNAL. Use HelpTopic._articles.destroyAll() instead.
            "prototype$__delete___articles": {
              url: urlBase + "/HelpTopics/:id/_articles",
              method: "DELETE",
            },

            // INTERNAL. Use HelpTopic._articles.count() instead.
            "prototype$__count___articles": {
              url: urlBase + "/HelpTopics/:id/_articles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#create
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/HelpTopics",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#patchOrCreate
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/HelpTopics",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#replaceOrCreate
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/HelpTopics/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#upsertWithWhere
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/HelpTopics/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#exists
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/HelpTopics/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#findById
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/HelpTopics/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#replaceById
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/HelpTopics/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#find
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/HelpTopics",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#findOne
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/HelpTopics/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#updateAll
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/HelpTopics/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#deleteById
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/HelpTopics/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#count
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/HelpTopics/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#prototype$patchAttributes
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/HelpTopics/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#createChangeStream
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/HelpTopics/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#deleteHelpFile
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Deletes a file related to a Help Topic
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `fileId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "deleteHelpFile": {
              url: urlBase + "/HelpTopics/:id/deleteHelpFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#getTopics
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Retrieves all Help Topics for a given language
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `lang` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "getTopics": {
              isArray: true,
              url: urlBase + "/HelpTopics/getTopics/:lang",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#createMany
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/HelpTopics",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#upsert
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#updateOrCreate
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#patchOrCreateWithWhere
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#update
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#destroyById
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#removeById
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#prototype$updateAttributes
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpTopic` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.HelpTopic#modelName
        * @propertyOf lbServices.HelpTopic
        * @description
        * The name of the model represented by this $resource,
        * i.e. `HelpTopic`.
        */
        R.modelName = "HelpTopic";

    /**
     * @ngdoc object
     * @name lbServices.HelpTopic._articles
     * @header lbServices.HelpTopic._articles
     * @object
     * @description
     *
     * The object `HelpTopic._articles` groups methods
     * manipulating `HelpArticle` instances related to `HelpTopic`.
     *
     * Call {@link lbServices.HelpTopic#_articles HelpTopic._articles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#_articles
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Queries _articles of HelpTopic.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R._articles = function() {
          var TargetResource = $injector.get("HelpArticle");
          var action = TargetResource["::get::HelpTopic::_articles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic._articles#count
             * @methodOf lbServices.HelpTopic._articles
             *
             * @description
             *
             * Counts _articles of HelpTopic.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R._articles.count = function() {
          var TargetResource = $injector.get("HelpArticle");
          var action = TargetResource["::count::HelpTopic::_articles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic._articles#create
             * @methodOf lbServices.HelpTopic._articles
             *
             * @description
             *
             * Creates a new instance in _articles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R._articles.create = function() {
          var TargetResource = $injector.get("HelpArticle");
          var action = TargetResource["::create::HelpTopic::_articles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic._articles#createMany
             * @methodOf lbServices.HelpTopic._articles
             *
             * @description
             *
             * Creates a new instance in _articles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R._articles.createMany = function() {
          var TargetResource = $injector.get("HelpArticle");
          var action = TargetResource["::createMany::HelpTopic::_articles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic._articles#destroyAll
             * @methodOf lbServices.HelpTopic._articles
             *
             * @description
             *
             * Deletes all _articles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R._articles.destroyAll = function() {
          var TargetResource = $injector.get("HelpArticle");
          var action = TargetResource["::delete::HelpTopic::_articles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic._articles#destroyById
             * @methodOf lbServices.HelpTopic._articles
             *
             * @description
             *
             * Delete a related item by id for _articles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for _articles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R._articles.destroyById = function() {
          var TargetResource = $injector.get("HelpArticle");
          var action = TargetResource["::destroyById::HelpTopic::_articles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic._articles#findById
             * @methodOf lbServices.HelpTopic._articles
             *
             * @description
             *
             * Find a related item by id for _articles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for _articles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R._articles.findById = function() {
          var TargetResource = $injector.get("HelpArticle");
          var action = TargetResource["::findById::HelpTopic::_articles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic._articles#updateById
             * @methodOf lbServices.HelpTopic._articles
             *
             * @description
             *
             * Update a related item by id for _articles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `fk` – `{*}` - Foreign key for _articles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HelpArticle` object.)
             * </em>
             */
        R._articles.updateById = function() {
          var TargetResource = $injector.get("HelpArticle");
          var action = TargetResource["::updateById::HelpTopic::_articles"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.HelpTopic.folder
     * @header lbServices.HelpTopic.folder
     * @object
     * @description
     *
     * The object `HelpTopic.folder` groups methods
     * manipulating `Folder` instances related to `HelpTopic`.
     *
     * Call {@link lbServices.HelpTopic#folder HelpTopic.folder()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.HelpTopic#folder
             * @methodOf lbServices.HelpTopic
             *
             * @description
             *
             * Fetches hasOne relation folder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folder = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::get::HelpTopic::folder"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic.folder#create
             * @methodOf lbServices.HelpTopic.folder
             *
             * @description
             *
             * Creates a new instance in folder of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folder.create = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::create::HelpTopic::folder"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic.folder#createMany
             * @methodOf lbServices.HelpTopic.folder
             *
             * @description
             *
             * Creates a new instance in folder of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folder.createMany = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::createMany::HelpTopic::folder"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic.folder#destroy
             * @methodOf lbServices.HelpTopic.folder
             *
             * @description
             *
             * Deletes folder of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.folder.destroy = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::destroy::HelpTopic::folder"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.HelpTopic.folder#update
             * @methodOf lbServices.HelpTopic.folder
             *
             * @description
             *
             * Update folder of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - HelpTopic id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Folder` object.)
             * </em>
             */
        R.folder.update = function() {
          var TargetResource = $injector.get("Folder");
          var action = TargetResource["::update::HelpTopic::folder"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SharedSmartListing
 * @header lbServices.SharedSmartListing
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SharedSmartListing` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SharedSmartListing",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SharedSmartListings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SharedSmartListing.listing() instead.
            "prototype$__get__listing": {
              url: urlBase + "/SharedSmartListings/:id/listing",
              method: "GET",
            },

            // INTERNAL. Use SharedSmartListing.broker() instead.
            "prototype$__get__broker": {
              url: urlBase + "/SharedSmartListings/:id/broker",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#create
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SharedSmartListings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#patchOrCreate
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SharedSmartListings",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#replaceOrCreate
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SharedSmartListings/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#upsertWithWhere
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SharedSmartListings/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#exists
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SharedSmartListings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#findById
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SharedSmartListings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#replaceById
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SharedSmartListings/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#find
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SharedSmartListings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#findOne
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SharedSmartListings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#updateAll
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SharedSmartListings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#deleteById
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SharedSmartListings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#count
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SharedSmartListings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#prototype$patchAttributes
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedSmartListing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SharedSmartListings/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#createChangeStream
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SharedSmartListings/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#createMany
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SharedSmartListings",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedSmartListings.findById() instead.
            "::findById::Broker::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedSmartListings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedSmartListings.destroyById() instead.
            "::destroyById::Broker::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedSmartListings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedSmartListings.updateById() instead.
            "::updateById::Broker::sharedSmartListings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Brokers/:id/sharedSmartListings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Broker.sharedSmartListings() instead.
            "::get::Broker::sharedSmartListings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedSmartListings",
              method: "GET",
            },

            // INTERNAL. Use Broker.sharedSmartListings.create() instead.
            "::create::Broker::sharedSmartListings": {
              url: urlBase + "/Brokers/:id/sharedSmartListings",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedSmartListings.createMany() instead.
            "::createMany::Broker::sharedSmartListings": {
              isArray: true,
              url: urlBase + "/Brokers/:id/sharedSmartListings",
              method: "POST",
            },

            // INTERNAL. Use Broker.sharedSmartListings.destroyAll() instead.
            "::delete::Broker::sharedSmartListings": {
              url: urlBase + "/Brokers/:id/sharedSmartListings",
              method: "DELETE",
            },

            // INTERNAL. Use Broker.sharedSmartListings.count() instead.
            "::count::Broker::sharedSmartListings": {
              url: urlBase + "/Brokers/:id/sharedSmartListings/count",
              method: "GET",
            },

            // INTERNAL. Use Listing.shared.findById() instead.
            "::findById::Listing::shared": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/shared/:fk",
              method: "GET",
            },

            // INTERNAL. Use Listing.shared.destroyById() instead.
            "::destroyById::Listing::shared": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/shared/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.shared.updateById() instead.
            "::updateById::Listing::shared": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Listings/:id/shared/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Listing.shared() instead.
            "::get::Listing::shared": {
              isArray: true,
              url: urlBase + "/Listings/:id/shared",
              method: "GET",
            },

            // INTERNAL. Use Listing.shared.create() instead.
            "::create::Listing::shared": {
              url: urlBase + "/Listings/:id/shared",
              method: "POST",
            },

            // INTERNAL. Use Listing.shared.createMany() instead.
            "::createMany::Listing::shared": {
              isArray: true,
              url: urlBase + "/Listings/:id/shared",
              method: "POST",
            },

            // INTERNAL. Use Listing.shared.destroyAll() instead.
            "::delete::Listing::shared": {
              url: urlBase + "/Listings/:id/shared",
              method: "DELETE",
            },

            // INTERNAL. Use Listing.shared.count() instead.
            "::count::Listing::shared": {
              url: urlBase + "/Listings/:id/shared/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#upsert
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#updateOrCreate
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#patchOrCreateWithWhere
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#update
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#destroyById
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#removeById
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#prototype$updateAttributes
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedSmartListing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedSmartListing` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SharedSmartListing#modelName
        * @propertyOf lbServices.SharedSmartListing
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SharedSmartListing`.
        */
        R.modelName = "SharedSmartListing";


            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#listing
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Fetches belongsTo relation listing.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedSmartListing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Listing` object.)
             * </em>
             */
        R.listing = function() {
          var TargetResource = $injector.get("Listing");
          var action = TargetResource["::get::SharedSmartListing::listing"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SharedSmartListing#broker
             * @methodOf lbServices.SharedSmartListing
             *
             * @description
             *
             * Fetches belongsTo relation broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedSmartListing id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Broker` object.)
             * </em>
             */
        R.broker = function() {
          var TargetResource = $injector.get("Broker");
          var action = TargetResource["::get::SharedSmartListing::broker"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.VisitCoBuyer
 * @header lbServices.VisitCoBuyer
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `VisitCoBuyer` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "VisitCoBuyer",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/VisitCoBuyers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use VisitCoBuyer.buyer() instead.
            "prototype$__get__buyer": {
              url: urlBase + "/VisitCoBuyers/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use VisitCoBuyer.visit() instead.
            "prototype$__get__visit": {
              url: urlBase + "/VisitCoBuyers/:id/visit",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#create
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/VisitCoBuyers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#patchOrCreate
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/VisitCoBuyers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#replaceOrCreate
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/VisitCoBuyers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#upsertWithWhere
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/VisitCoBuyers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#exists
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/VisitCoBuyers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#findById
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/VisitCoBuyers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#replaceById
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/VisitCoBuyers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#find
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/VisitCoBuyers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#findOne
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/VisitCoBuyers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#updateAll
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/VisitCoBuyers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#deleteById
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/VisitCoBuyers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#count
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/VisitCoBuyers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#prototype$patchAttributes
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - VisitCoBuyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/VisitCoBuyers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#createChangeStream
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/VisitCoBuyers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#userCancel
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Triggered when user cancels their presence in a visit. Sends email to Broker.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `secret` – `{string}` -
             *
             *  - `isOwner` – `{boolean=}` -
             *
             *  - `reason` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Deleted object
             */
            "userCancel": {
              url: urlBase + "/VisitCoBuyers/userCancel",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#userConfirm
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Confirms a visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `secret` – `{string}` -
             *
             *  - `isOwner` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Modified Visit object
             */
            "userConfirm": {
              url: urlBase + "/VisitCoBuyers/userConfirm",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#createMany
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/VisitCoBuyers",
              method: "POST",
            },

            // INTERNAL. Use Visit.coBuyersVisit.findById() instead.
            "::findById::Visit::coBuyersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyersVisit/:fk",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyersVisit.destroyById() instead.
            "::destroyById::Visit::coBuyersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyersVisit/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyersVisit.updateById() instead.
            "::updateById::Visit::coBuyersVisit": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Visits/:id/coBuyersVisit/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Visit.coBuyersVisit() instead.
            "::get::Visit::coBuyersVisit": {
              isArray: true,
              url: urlBase + "/Visits/:id/coBuyersVisit",
              method: "GET",
            },

            // INTERNAL. Use Visit.coBuyersVisit.create() instead.
            "::create::Visit::coBuyersVisit": {
              url: urlBase + "/Visits/:id/coBuyersVisit",
              method: "POST",
            },

            // INTERNAL. Use Visit.coBuyersVisit.createMany() instead.
            "::createMany::Visit::coBuyersVisit": {
              isArray: true,
              url: urlBase + "/Visits/:id/coBuyersVisit",
              method: "POST",
            },

            // INTERNAL. Use Visit.coBuyersVisit.destroyAll() instead.
            "::delete::Visit::coBuyersVisit": {
              url: urlBase + "/Visits/:id/coBuyersVisit",
              method: "DELETE",
            },

            // INTERNAL. Use Visit.coBuyersVisit.count() instead.
            "::count::Visit::coBuyersVisit": {
              url: urlBase + "/Visits/:id/coBuyersVisit/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#upsert
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#updateOrCreate
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#patchOrCreateWithWhere
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#update
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#destroyById
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#removeById
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#prototype$updateAttributes
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - VisitCoBuyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `VisitCoBuyer` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.VisitCoBuyer#modelName
        * @propertyOf lbServices.VisitCoBuyer
        * @description
        * The name of the model represented by this $resource,
        * i.e. `VisitCoBuyer`.
        */
        R.modelName = "VisitCoBuyer";


            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#buyer
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Fetches belongsTo relation buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - VisitCoBuyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyer = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::VisitCoBuyer::buyer"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.VisitCoBuyer#visit
             * @methodOf lbServices.VisitCoBuyer
             *
             * @description
             *
             * Fetches belongsTo relation visit.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - VisitCoBuyer id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Visit` object.)
             * </em>
             */
        R.visit = function() {
          var TargetResource = $injector.get("Visit");
          var action = TargetResource["::get::VisitCoBuyer::visit"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SharedTaskTemplate
 * @header lbServices.SharedTaskTemplate
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SharedTaskTemplate` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SharedTaskTemplate",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SharedTaskTemplates/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SharedTaskTemplate.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/SharedTaskTemplates/:id/user",
              method: "GET",
            },

            // INTERNAL. Use SharedTaskTemplate.taskTemplate() instead.
            "prototype$__get__taskTemplate": {
              url: urlBase + "/SharedTaskTemplates/:id/taskTemplate",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#create
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SharedTaskTemplates",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#patchOrCreate
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SharedTaskTemplates",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#replaceOrCreate
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SharedTaskTemplates/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#upsertWithWhere
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SharedTaskTemplates/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#exists
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SharedTaskTemplates/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#findById
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SharedTaskTemplates/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#replaceById
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SharedTaskTemplates/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#find
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SharedTaskTemplates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#findOne
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SharedTaskTemplates/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#updateAll
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SharedTaskTemplates/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#deleteById
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SharedTaskTemplates/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#count
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SharedTaskTemplates/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#prototype$patchAttributes
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedTaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SharedTaskTemplates/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#createChangeStream
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SharedTaskTemplates/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#createMany
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SharedTaskTemplates",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#upsert
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#updateOrCreate
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#patchOrCreateWithWhere
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#update
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#destroyById
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#removeById
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#prototype$updateAttributes
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedTaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedTaskTemplate` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SharedTaskTemplate#modelName
        * @propertyOf lbServices.SharedTaskTemplate
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SharedTaskTemplate`.
        */
        R.modelName = "SharedTaskTemplate";


            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#user
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedTaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::SharedTaskTemplate::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SharedTaskTemplate#taskTemplate
             * @methodOf lbServices.SharedTaskTemplate
             *
             * @description
             *
             * Fetches belongsTo relation taskTemplate.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedTaskTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `TaskTemplate` object.)
             * </em>
             */
        R.taskTemplate = function() {
          var TargetResource = $injector.get("TaskTemplate");
          var action = TargetResource["::get::SharedTaskTemplate::taskTemplate"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SharedEmailTemplate
 * @header lbServices.SharedEmailTemplate
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SharedEmailTemplate` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SharedEmailTemplate",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SharedEmailTemplates/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SharedEmailTemplate.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/SharedEmailTemplates/:id/user",
              method: "GET",
            },

            // INTERNAL. Use SharedEmailTemplate.emailTemplate() instead.
            "prototype$__get__emailTemplate": {
              url: urlBase + "/SharedEmailTemplates/:id/emailTemplate",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#create
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SharedEmailTemplates",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#patchOrCreate
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SharedEmailTemplates",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#replaceOrCreate
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SharedEmailTemplates/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#upsertWithWhere
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SharedEmailTemplates/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#exists
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SharedEmailTemplates/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#findById
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SharedEmailTemplates/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#replaceById
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SharedEmailTemplates/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#find
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SharedEmailTemplates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#findOne
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SharedEmailTemplates/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#updateAll
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SharedEmailTemplates/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#deleteById
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SharedEmailTemplates/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#count
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SharedEmailTemplates/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#prototype$patchAttributes
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedEmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SharedEmailTemplates/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#createChangeStream
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SharedEmailTemplates/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#createMany
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SharedEmailTemplates",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#upsert
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#updateOrCreate
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#patchOrCreateWithWhere
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#update
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#destroyById
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#removeById
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#prototype$updateAttributes
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedEmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SharedEmailTemplate` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SharedEmailTemplate#modelName
        * @propertyOf lbServices.SharedEmailTemplate
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SharedEmailTemplate`.
        */
        R.modelName = "SharedEmailTemplate";


            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#user
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedEmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SbUser` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("SbUser");
          var action = TargetResource["::get::SharedEmailTemplate::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SharedEmailTemplate#emailTemplate
             * @methodOf lbServices.SharedEmailTemplate
             *
             * @description
             *
             * Fetches belongsTo relation emailTemplate.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SharedEmailTemplate id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EmailTemplate` object.)
             * </em>
             */
        R.emailTemplate = function() {
          var TargetResource = $injector.get("EmailTemplate");
          var action = TargetResource["::get::SharedEmailTemplate::emailTemplate"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PropertyCode
 * @header lbServices.PropertyCode
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PropertyCode` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PropertyCode",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PropertyCodes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PropertyCode.property() instead.
            "prototype$__get__property": {
              url: urlBase + "/PropertyCodes/:id/property",
              method: "GET",
            },

            // INTERNAL. Use PropertyCode.code() instead.
            "prototype$__get__code": {
              url: urlBase + "/PropertyCodes/:id/code",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#create
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PropertyCodes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#patchOrCreate
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/PropertyCodes",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#replaceOrCreate
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PropertyCodes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#upsertWithWhere
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PropertyCodes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#exists
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PropertyCodes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#findById
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PropertyCodes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#replaceById
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PropertyCodes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#find
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PropertyCodes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#findOne
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PropertyCodes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#updateAll
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PropertyCodes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#deleteById
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PropertyCodes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#count
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PropertyCodes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#prototype$patchAttributes
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PropertyCode id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/PropertyCodes/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#createChangeStream
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PropertyCodes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#createMany
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PropertyCodes",
              method: "POST",
            },

            // INTERNAL. Use Property.propertyCodes.findById() instead.
            "::findById::Property::propertyCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/propertyCodes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.propertyCodes.destroyById() instead.
            "::destroyById::Property::propertyCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/propertyCodes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.propertyCodes.updateById() instead.
            "::updateById::Property::propertyCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/propertyCodes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.propertyCodes() instead.
            "::get::Property::propertyCodes": {
              isArray: true,
              url: urlBase + "/Properties/:id/propertyCodes",
              method: "GET",
            },

            // INTERNAL. Use Property.propertyCodes.create() instead.
            "::create::Property::propertyCodes": {
              url: urlBase + "/Properties/:id/propertyCodes",
              method: "POST",
            },

            // INTERNAL. Use Property.propertyCodes.createMany() instead.
            "::createMany::Property::propertyCodes": {
              isArray: true,
              url: urlBase + "/Properties/:id/propertyCodes",
              method: "POST",
            },

            // INTERNAL. Use Property.propertyCodes.destroyAll() instead.
            "::delete::Property::propertyCodes": {
              url: urlBase + "/Properties/:id/propertyCodes",
              method: "DELETE",
            },

            // INTERNAL. Use Property.propertyCodes.count() instead.
            "::count::Property::propertyCodes": {
              url: urlBase + "/Properties/:id/propertyCodes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#upsert
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#updateOrCreate
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#patchOrCreateWithWhere
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#update
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#destroyById
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#removeById
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#prototype$updateAttributes
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PropertyCode id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PropertyCode` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PropertyCode#modelName
        * @propertyOf lbServices.PropertyCode
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PropertyCode`.
        */
        R.modelName = "PropertyCode";


            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#property
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Fetches belongsTo relation property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PropertyCode id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::get::PropertyCode::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PropertyCode#code
             * @methodOf lbServices.PropertyCode
             *
             * @description
             *
             * Fetches belongsTo relation code.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PropertyCode id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.code = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::PropertyCode::code"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.RemoteEditingLog
 * @header lbServices.RemoteEditingLog
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RemoteEditingLog` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RemoteEditingLog",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/RemoteEditingLogs/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#create
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/RemoteEditingLogs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#patchOrCreate
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/RemoteEditingLogs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#replaceOrCreate
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/RemoteEditingLogs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#upsertWithWhere
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/RemoteEditingLogs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#exists
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/RemoteEditingLogs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#findById
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/RemoteEditingLogs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#replaceById
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/RemoteEditingLogs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#find
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/RemoteEditingLogs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#findOne
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/RemoteEditingLogs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#updateAll
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/RemoteEditingLogs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#deleteById
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/RemoteEditingLogs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#count
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/RemoteEditingLogs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#prototype$patchAttributes
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RemoteEditingLog id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/RemoteEditingLogs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#createChangeStream
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/RemoteEditingLogs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#createMany
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/RemoteEditingLogs",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#upsert
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#updateOrCreate
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#patchOrCreateWithWhere
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#update
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#destroyById
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#removeById
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RemoteEditingLog#prototype$updateAttributes
             * @methodOf lbServices.RemoteEditingLog
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RemoteEditingLog id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RemoteEditingLog` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.RemoteEditingLog#modelName
        * @propertyOf lbServices.RemoteEditingLog
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RemoteEditingLog`.
        */
        R.modelName = "RemoteEditingLog";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.BuyerCity
 * @header lbServices.BuyerCity
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `BuyerCity` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "BuyerCity",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/BuyerCities/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use BuyerCity.buyer() instead.
            "prototype$__get__buyer": {
              url: urlBase + "/BuyerCities/:id/buyer",
              method: "GET",
            },

            // INTERNAL. Use BuyerCity.city() instead.
            "prototype$__get__city": {
              url: urlBase + "/BuyerCities/:id/city",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#create
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/BuyerCities",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#patchOrCreate
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/BuyerCities",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#replaceOrCreate
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/BuyerCities/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#upsertWithWhere
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/BuyerCities/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#exists
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/BuyerCities/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#findById
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/BuyerCities/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#replaceById
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/BuyerCities/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#find
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/BuyerCities",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#findOne
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/BuyerCities/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#updateAll
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/BuyerCities/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#deleteById
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/BuyerCities/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#count
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/BuyerCities/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#prototype$patchAttributes
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerCity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/BuyerCities/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#createChangeStream
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/BuyerCities/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#createMany
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/BuyerCities",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerCities.findById() instead.
            "::findById::Buyer::buyerCities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerCities/:fk",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerCities.destroyById() instead.
            "::destroyById::Buyer::buyerCities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerCities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerCities.updateById() instead.
            "::updateById::Buyer::buyerCities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Buyers/:id/buyerCities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Buyer.buyerCities() instead.
            "::get::Buyer::buyerCities": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerCities",
              method: "GET",
            },

            // INTERNAL. Use Buyer.buyerCities.create() instead.
            "::create::Buyer::buyerCities": {
              url: urlBase + "/Buyers/:id/buyerCities",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerCities.createMany() instead.
            "::createMany::Buyer::buyerCities": {
              isArray: true,
              url: urlBase + "/Buyers/:id/buyerCities",
              method: "POST",
            },

            // INTERNAL. Use Buyer.buyerCities.destroyAll() instead.
            "::delete::Buyer::buyerCities": {
              url: urlBase + "/Buyers/:id/buyerCities",
              method: "DELETE",
            },

            // INTERNAL. Use Buyer.buyerCities.count() instead.
            "::count::Buyer::buyerCities": {
              url: urlBase + "/Buyers/:id/buyerCities/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#upsert
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#updateOrCreate
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#patchOrCreateWithWhere
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#update
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#destroyById
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#removeById
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#prototype$updateAttributes
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerCity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerCity` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.BuyerCity#modelName
        * @propertyOf lbServices.BuyerCity
        * @description
        * The name of the model represented by this $resource,
        * i.e. `BuyerCity`.
        */
        R.modelName = "BuyerCity";


            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#buyer
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Fetches belongsTo relation buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerCity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyer = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::BuyerCity::buyer"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerCity#city
             * @methodOf lbServices.BuyerCity
             *
             * @description
             *
             * Fetches belongsTo relation city.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerCity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `City` object.)
             * </em>
             */
        R.city = function() {
          var TargetResource = $injector.get("City");
          var action = TargetResource["::get::BuyerCity::city"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.BuyerSupplier
 * @header lbServices.BuyerSupplier
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `BuyerSupplier` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "BuyerSupplier",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/BuyerSuppliers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use BuyerSupplier.supplier() instead.
            "prototype$__get__supplier": {
              url: urlBase + "/BuyerSuppliers/:id/supplier",
              method: "GET",
            },

            // INTERNAL. Use BuyerSupplier.buyer() instead.
            "prototype$__get__buyer": {
              url: urlBase + "/BuyerSuppliers/:id/buyer",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#create
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/BuyerSuppliers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#patchOrCreate
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/BuyerSuppliers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#replaceOrCreate
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/BuyerSuppliers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#upsertWithWhere
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/BuyerSuppliers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#exists
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/BuyerSuppliers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#findById
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/BuyerSuppliers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#replaceById
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/BuyerSuppliers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#find
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/BuyerSuppliers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#findOne
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/BuyerSuppliers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#updateAll
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/BuyerSuppliers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#deleteById
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/BuyerSuppliers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#count
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/BuyerSuppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#prototype$patchAttributes
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/BuyerSuppliers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#createChangeStream
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/BuyerSuppliers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#createMany
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/BuyerSuppliers",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#upsert
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#updateOrCreate
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#patchOrCreateWithWhere
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#update
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#destroyById
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#removeById
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#prototype$updateAttributes
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BuyerSupplier` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.BuyerSupplier#modelName
        * @propertyOf lbServices.BuyerSupplier
        * @description
        * The name of the model represented by this $resource,
        * i.e. `BuyerSupplier`.
        */
        R.modelName = "BuyerSupplier";


            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#supplier
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Fetches belongsTo relation supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.supplier = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::BuyerSupplier::supplier"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.BuyerSupplier#buyer
             * @methodOf lbServices.BuyerSupplier
             *
             * @description
             *
             * Fetches belongsTo relation buyer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BuyerSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Buyer` object.)
             * </em>
             */
        R.buyer = function() {
          var TargetResource = $injector.get("Buyer");
          var action = TargetResource["::get::BuyerSupplier::buyer"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SellerSupplier
 * @header lbServices.SellerSupplier
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SellerSupplier` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SellerSupplier",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SellerSuppliers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SellerSupplier.supplier() instead.
            "prototype$__get__supplier": {
              url: urlBase + "/SellerSuppliers/:id/supplier",
              method: "GET",
            },

            // INTERNAL. Use SellerSupplier.seller() instead.
            "prototype$__get__seller": {
              url: urlBase + "/SellerSuppliers/:id/seller",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#create
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SellerSuppliers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#patchOrCreate
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/SellerSuppliers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#replaceOrCreate
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SellerSuppliers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#upsertWithWhere
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SellerSuppliers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#exists
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SellerSuppliers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#findById
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SellerSuppliers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#replaceById
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SellerSuppliers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#find
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SellerSuppliers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#findOne
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SellerSuppliers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#updateAll
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SellerSuppliers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#deleteById
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SellerSuppliers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#count
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SellerSuppliers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#prototype$patchAttributes
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/SellerSuppliers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#createChangeStream
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SellerSuppliers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#createMany
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SellerSuppliers",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#upsert
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#updateOrCreate
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#patchOrCreateWithWhere
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#update
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#destroyById
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#removeById
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#prototype$updateAttributes
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SellerSupplier` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SellerSupplier#modelName
        * @propertyOf lbServices.SellerSupplier
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SellerSupplier`.
        */
        R.modelName = "SellerSupplier";


            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#supplier
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Fetches belongsTo relation supplier.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Supplier` object.)
             * </em>
             */
        R.supplier = function() {
          var TargetResource = $injector.get("Supplier");
          var action = TargetResource["::get::SellerSupplier::supplier"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SellerSupplier#seller
             * @methodOf lbServices.SellerSupplier
             *
             * @description
             *
             * Fetches belongsTo relation seller.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SellerSupplier id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Seller` object.)
             * </em>
             */
        R.seller = function() {
          var TargetResource = $injector.get("Seller");
          var action = TargetResource["::get::SellerSupplier::seller"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AdditionalBuilding
 * @header lbServices.AdditionalBuilding
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AdditionalBuilding` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AdditionalBuilding",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AdditionalBuildings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use AdditionalBuilding.property() instead.
            "prototype$__get__property": {
              url: urlBase + "/AdditionalBuildings/:id/property",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.buildingType() instead.
            "prototype$__get__buildingType": {
              url: urlBase + "/AdditionalBuildings/:id/buildingType",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.findById() instead.
            "prototype$__findById__characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/characteristics/:fk",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.destroyById() instead.
            "prototype$__destroyById__characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/characteristics/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.updateById() instead.
            "prototype$__updateById__characteristics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/characteristics/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AdditionalBuilding.styles.findById() instead.
            "prototype$__findById__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/:fk",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.styles.destroyById() instead.
            "prototype$__destroyById__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.styles.updateById() instead.
            "prototype$__updateById__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AdditionalBuilding.styles.link() instead.
            "prototype$__link__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use AdditionalBuilding.styles.unlink() instead.
            "prototype$__unlink__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.styles.exists() instead.
            "prototype$__exists__styles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/AdditionalBuildings/:id/styles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics() instead.
            "prototype$__get__characteristics": {
              isArray: true,
              url: urlBase + "/AdditionalBuildings/:id/characteristics",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.create() instead.
            "prototype$__create__characteristics": {
              url: urlBase + "/AdditionalBuildings/:id/characteristics",
              method: "POST",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.destroyAll() instead.
            "prototype$__delete__characteristics": {
              url: urlBase + "/AdditionalBuildings/:id/characteristics",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.characteristics.count() instead.
            "prototype$__count__characteristics": {
              url: urlBase + "/AdditionalBuildings/:id/characteristics/count",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.styles() instead.
            "prototype$__get__styles": {
              isArray: true,
              url: urlBase + "/AdditionalBuildings/:id/styles",
              method: "GET",
            },

            // INTERNAL. Use AdditionalBuilding.styles.create() instead.
            "prototype$__create__styles": {
              url: urlBase + "/AdditionalBuildings/:id/styles",
              method: "POST",
            },

            // INTERNAL. Use AdditionalBuilding.styles.destroyAll() instead.
            "prototype$__delete__styles": {
              url: urlBase + "/AdditionalBuildings/:id/styles",
              method: "DELETE",
            },

            // INTERNAL. Use AdditionalBuilding.styles.count() instead.
            "prototype$__count__styles": {
              url: urlBase + "/AdditionalBuildings/:id/styles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#create
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AdditionalBuildings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#patchOrCreate
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/AdditionalBuildings",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#replaceOrCreate
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AdditionalBuildings/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#upsertWithWhere
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AdditionalBuildings/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#exists
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AdditionalBuildings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#findById
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AdditionalBuildings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#replaceById
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AdditionalBuildings/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#find
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AdditionalBuildings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#findOne
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AdditionalBuildings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#updateAll
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AdditionalBuildings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#deleteById
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AdditionalBuildings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#count
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AdditionalBuildings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#prototype$patchAttributes
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/AdditionalBuildings/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#createChangeStream
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AdditionalBuildings/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#createMany
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AdditionalBuildings",
              method: "POST",
            },

            // INTERNAL. Use Property.additionalBuildings.findById() instead.
            "::findById::Property::additionalBuildings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/additionalBuildings/:fk",
              method: "GET",
            },

            // INTERNAL. Use Property.additionalBuildings.destroyById() instead.
            "::destroyById::Property::additionalBuildings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/additionalBuildings/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Property.additionalBuildings.updateById() instead.
            "::updateById::Property::additionalBuildings": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Properties/:id/additionalBuildings/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Property.additionalBuildings() instead.
            "::get::Property::additionalBuildings": {
              isArray: true,
              url: urlBase + "/Properties/:id/additionalBuildings",
              method: "GET",
            },

            // INTERNAL. Use Property.additionalBuildings.create() instead.
            "::create::Property::additionalBuildings": {
              url: urlBase + "/Properties/:id/additionalBuildings",
              method: "POST",
            },

            // INTERNAL. Use Property.additionalBuildings.createMany() instead.
            "::createMany::Property::additionalBuildings": {
              isArray: true,
              url: urlBase + "/Properties/:id/additionalBuildings",
              method: "POST",
            },

            // INTERNAL. Use Property.additionalBuildings.destroyAll() instead.
            "::delete::Property::additionalBuildings": {
              url: urlBase + "/Properties/:id/additionalBuildings",
              method: "DELETE",
            },

            // INTERNAL. Use Property.additionalBuildings.count() instead.
            "::count::Property::additionalBuildings": {
              url: urlBase + "/Properties/:id/additionalBuildings/count",
              method: "GET",
            },

            // INTERNAL. Use Characteristic.additionalBuilding() instead.
            "::get::Characteristic::additionalBuilding": {
              url: urlBase + "/Characteristics/:id/additionalBuilding",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#upsert
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#updateOrCreate
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#patchOrCreateWithWhere
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#update
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#destroyById
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#removeById
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#prototype$updateAttributes
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AdditionalBuilding` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AdditionalBuilding#modelName
        * @propertyOf lbServices.AdditionalBuilding
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AdditionalBuilding`.
        */
        R.modelName = "AdditionalBuilding";


            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#property
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Fetches belongsTo relation property.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Property` object.)
             * </em>
             */
        R.property = function() {
          var TargetResource = $injector.get("Property");
          var action = TargetResource["::get::AdditionalBuilding::property"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#buildingType
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Fetches belongsTo relation buildingType.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.buildingType = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::AdditionalBuilding::buildingType"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AdditionalBuilding.characteristics
     * @header lbServices.AdditionalBuilding.characteristics
     * @object
     * @description
     *
     * The object `AdditionalBuilding.characteristics` groups methods
     * manipulating `Characteristic` instances related to `AdditionalBuilding`.
     *
     * Call {@link lbServices.AdditionalBuilding#characteristics AdditionalBuilding.characteristics()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#characteristics
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Queries characteristics of AdditionalBuilding.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::get::AdditionalBuilding::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.characteristics#count
             * @methodOf lbServices.AdditionalBuilding.characteristics
             *
             * @description
             *
             * Counts characteristics of AdditionalBuilding.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.characteristics.count = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::count::AdditionalBuilding::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.characteristics#create
             * @methodOf lbServices.AdditionalBuilding.characteristics
             *
             * @description
             *
             * Creates a new instance in characteristics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics.create = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::create::AdditionalBuilding::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.characteristics#createMany
             * @methodOf lbServices.AdditionalBuilding.characteristics
             *
             * @description
             *
             * Creates a new instance in characteristics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics.createMany = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::createMany::AdditionalBuilding::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.characteristics#destroyAll
             * @methodOf lbServices.AdditionalBuilding.characteristics
             *
             * @description
             *
             * Deletes all characteristics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.characteristics.destroyAll = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::delete::AdditionalBuilding::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.characteristics#destroyById
             * @methodOf lbServices.AdditionalBuilding.characteristics
             *
             * @description
             *
             * Delete a related item by id for characteristics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for characteristics
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.characteristics.destroyById = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::destroyById::AdditionalBuilding::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.characteristics#findById
             * @methodOf lbServices.AdditionalBuilding.characteristics
             *
             * @description
             *
             * Find a related item by id for characteristics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for characteristics
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics.findById = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::findById::AdditionalBuilding::characteristics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.characteristics#updateById
             * @methodOf lbServices.AdditionalBuilding.characteristics
             *
             * @description
             *
             * Update a related item by id for characteristics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `fk` – `{*}` - Foreign key for characteristics
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Characteristic` object.)
             * </em>
             */
        R.characteristics.updateById = function() {
          var TargetResource = $injector.get("Characteristic");
          var action = TargetResource["::updateById::AdditionalBuilding::characteristics"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.AdditionalBuilding.styles
     * @header lbServices.AdditionalBuilding.styles
     * @object
     * @description
     *
     * The object `AdditionalBuilding.styles` groups methods
     * manipulating `Code` instances related to `AdditionalBuilding`.
     *
     * Call {@link lbServices.AdditionalBuilding#styles AdditionalBuilding.styles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding#styles
             * @methodOf lbServices.AdditionalBuilding
             *
             * @description
             *
             * Queries styles of AdditionalBuilding.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::get::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#count
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Counts styles of AdditionalBuilding.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.styles.count = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::count::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#create
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Creates a new instance in styles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.create = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::create::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#createMany
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Creates a new instance in styles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.createMany = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::createMany::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#destroyAll
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Deletes all styles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.styles.destroyAll = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::delete::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#destroyById
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Delete a related item by id for styles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.styles.destroyById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::destroyById::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#exists
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Check the existence of styles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.exists = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::exists::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#findById
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Find a related item by id for styles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.findById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::findById::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#link
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Add a related item by id for styles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.link = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::link::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#unlink
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Remove the styles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.styles.unlink = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::unlink::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AdditionalBuilding.styles#updateById
             * @methodOf lbServices.AdditionalBuilding.styles
             *
             * @description
             *
             * Update a related item by id for styles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AdditionalBuilding id
             *
             *  - `fk` – `{*}` - Foreign key for styles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Code` object.)
             * </em>
             */
        R.styles.updateById = function() {
          var TargetResource = $injector.get("Code");
          var action = TargetResource["::updateById::AdditionalBuilding::styles"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Migration
 * @header lbServices.Migration
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Migration` model.
 *
 * **Details**
 *
 * View and run pending migrations.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Migration",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Migrations/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Migration#create
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Migrations",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#patchOrCreate
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Migrations",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#replaceOrCreate
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Migrations/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#upsertWithWhere
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Migrations/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#exists
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Migrations/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#findById
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Migrations/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#replaceById
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Migrations/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#find
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Migrations",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#findOne
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Migrations/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#updateAll
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Migrations/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#deleteById
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Migrations/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#count
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Migrations/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#prototype$patchAttributes
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Migration id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Migrations/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#createChangeStream
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Migrations/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#migrateByName
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Run specific migration by name
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `name` – `{String}` - Name of the migration script to run.
             *
             *  - `record` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "migrateByName": {
              url: urlBase + "/Migrations/migrateByName",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Migration#createMany
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Migrations",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Migration#upsert
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Migration#updateOrCreate
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Migration#patchOrCreateWithWhere
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Migration#update
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Migration#destroyById
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Migration#removeById
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Migration#prototype$updateAttributes
             * @methodOf lbServices.Migration
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Migration id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Migration` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Migration#modelName
        * @propertyOf lbServices.Migration
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Migration`.
        */
        R.modelName = "Migration";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.MigrationMap
 * @header lbServices.MigrationMap
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `MigrationMap` model.
 *
 * **Details**
 *
 * Migration Mappings.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "MigrationMap",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/MigrationMaps/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#create
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/MigrationMaps",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#patchOrCreate
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/MigrationMaps",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#replaceOrCreate
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/MigrationMaps/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#upsertWithWhere
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/MigrationMaps/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#exists
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/MigrationMaps/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#findById
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/MigrationMaps/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#replaceById
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/MigrationMaps/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#find
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/MigrationMaps",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#findOne
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/MigrationMaps/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#updateAll
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/MigrationMaps/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#deleteById
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/MigrationMaps/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#count
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/MigrationMaps/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#prototype$patchAttributes
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - MigrationMap id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/MigrationMaps/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#createChangeStream
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/MigrationMaps/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#createMany
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/MigrationMaps",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#upsert
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#updateOrCreate
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#patchOrCreateWithWhere
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#update
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#destroyById
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#removeById
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.MigrationMap#prototype$updateAttributes
             * @methodOf lbServices.MigrationMap
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - MigrationMap id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MigrationMap` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.MigrationMap#modelName
        * @propertyOf lbServices.MigrationMap
        * @description
        * The name of the model represented by this $resource,
        * i.e. `MigrationMap`.
        */
        R.modelName = "MigrationMap";



        return R;
      }]);


  module
  .factory('LoopBackAuth', function() {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function(name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function() {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function(name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function(accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function() {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function() {
      props.forEach(function(name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    // Note: LocalStorage converts the value to string
    // We are using empty string as a marker for null/undefined values.
    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
  }])
  .factory('LoopBackAuthRequestInterceptor', ['$q', 'LoopBackAuth',
    function($q, LoopBackAuth) {
      return {
        'request': function(config) {
          // filter out external requests
          var host = getHost(config.url);
          if (host && config.url.indexOf(urlBaseHost) === -1) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            var res = {
              body: { error: { status: 401 }},
              status: 401,
              config: config,
              headers: function() { return undefined; },
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        },
      };
    }])

  /**
   * @ngdoc object
   * @name lbServices.LoopBackResourceProvider
   * @header lbServices.LoopBackResourceProvider
   * @description
   * Use `LoopBackResourceProvider` to change the global configuration
   * settings used by all models. Note that the provider is available
   * to Configuration Blocks only, see
   * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
   * for more details.
   *
   * ## Example
   *
   * ```js
   * angular.module('app')
   *  .config(function(LoopBackResourceProvider) {
   *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
   *  });
   * ```
   */
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} header The header name to use, e.g. `X-Access-Token`
     * @description
     * Configure the REST transport to use a different header for sending
     * the authentication token. It is sent in the `Authorization` header
     * by default.
     */
    this.setAuthHeader = function(header) {
      authHeader = header;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the header name that is used for sending the authentication token.
     */
    this.getAuthHeader = function() {
      return authHeader;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
     * @description
     * Change the URL of the REST API server. By default, the URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.setUrlBase = function(url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the URL of the REST API server. The URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.getUrlBase = function() {
      return urlBase;
    };

    this.$get = ['$resource', function($resource) {
      var LoopBackResource = function(url, params, actions) {
        var resource = $resource(url, params, actions);

        // Angular always calls POST on $save()
        // This hack is based on
        // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
        resource.prototype.$save = function(success, error) {
          // Fortunately, LoopBack provides a convenient `upsert` method
          // that exactly fits our needs.
          var result = resource.upsert.call(this, {}, this, success, error);
          return result.$promise || result;
        };
        return resource;
      };

      LoopBackResource.getUrlBase = function() {
        return urlBase;
      };

      LoopBackResource.getAuthHeader = function() {
        return authHeader;
      };

      return LoopBackResource;
    }];
  });
})(window, window.angular);
